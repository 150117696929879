import { Fragment } from "react";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import copy from "copy-to-clipboard";

import { type CodeSnippet } from "../../types";

type CommandLineDialogProps = {
  open: boolean;
  setOpen: (boolean) => void;
  cliCommands: string[];
  onCopy: () => void;
  codeSnippets: CodeSnippet[];
};

/**
 * Show CLI commands for stack creation
 * @param open
 * @param setOpen
 * @param cliCommands
 * @param onCopy - callback that will be called once use copied the commands
 * @param codeSnippets
 * @constructor
 */
export const CommandLineDialog = ({ open, setOpen, cliCommands, onCopy, codeSnippets }: CommandLineDialogProps) => (
  <Dialog
    open={open}
    onClose={() => {
      setOpen(false);
    }}
    maxWidth="md"
  >
    <DialogTitle>AWS CLI instructions</DialogTitle>
    <DialogContent>
      <Alert severity="info">
        You can run the following commands in AWS CloudShell, just open your AWS console and type CloudShell in the
        search box.
      </Alert>
    </DialogContent>
    <DialogContent>
      {cliCommands.map((c, index) => (
        <Box key={index} bgcolor="background.default" p={1} display="flex">
          <Box pr={1}>
            <IconButton
              onClick={() => {
                copy(c);
                onCopy();
              }}
              size="large"
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Box>
          <Typography sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }} variant="body1">
            {c}
          </Typography>
        </Box>
      ))}
      {codeSnippets.map((c, index) => (
        <Fragment key={index}>
          <Divider />
          <Typography fontStyle="italic" variant="body2" p={1}>
            {c.name}
          </Typography>
          <Box bgcolor="background.default" p={1} display="flex">
            <Box pr={1}>
              <IconButton
                onClick={() => {
                  copy(c.code);
                  onCopy();
                }}
                size="large"
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Box>
            <Typography sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }} variant="body1">
              <DialogContent key={index}>
                <Typography variant="body2">
                  <code>{c.code}</code>
                </Typography>
              </DialogContent>
            </Typography>
          </Box>
        </Fragment>
      ))}
    </DialogContent>

    <Divider />
    <DialogActions>
      <Button
        onClick={() => {
          setOpen(false);
        }}
        color="primary"
      >
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);
