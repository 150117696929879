import { type Cluster, ClusterType as ClusterTypes } from "@doitintl/cmp-models";
import { Stack } from "@mui/system";

import awsLogo from "../../../assets/aws-logo.svg";
import awsLogoDark from "../../../assets/aws-logo-dark-mode.svg";
import gcpLogo from "../../../assets/gcp-logo.svg";
import k8sLogo from "../../../assets/kubernetes-logo.svg";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

interface ClusterTypeProps {
  type: Cluster["type"];
}

const displayTypeNames: Record<ClusterTypes, string> = {
  [ClusterTypes.GKE]: "GKE",
  [ClusterTypes.EKS]: "EKS",
  [ClusterTypes.SELF_MANAGED]: "Self-managed",
};

const ClusterType = ({ type }: ClusterTypeProps) => {
  const darkMode = useDarkThemeCheck();

  const logoSrcs: Record<ClusterTypes, string> = {
    [ClusterTypes.GKE]: gcpLogo,
    [ClusterTypes.EKS]: darkMode ? awsLogoDark : awsLogo,
    [ClusterTypes.SELF_MANAGED]: k8sLogo,
  };

  const src = logoSrcs[type];
  const displayName = displayTypeNames[type];

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {src && <img src={src} width={24} alt={displayName} title={displayName} />}
      <span>{displayName}</span>
    </Stack>
  );
};

export default ClusterType;
