import { useEffect, useState } from "react";

import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { getUserOrgID, PresetOrganizations, RootOrgId } from "../../utils/common";
import { getWidgetDocId } from "../../utils/widgets";

// hook checks for BigQuery SKU data - if no SKU is detected, BigQuery is not being used
export const useIsCustomerUsingBQ = () => {
  const bqSpendBySku = "IQYiclcHa3KdjWGV3Lph";
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization, customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const [isCustomerUsingBQ, setIsCustomerUsingBQ] = useState<boolean>();
  const presentationMode = customer && isCustomerInPresentationMode(customer);

  useEffect(() => {
    if (isDoitEmployee === undefined) {
      return;
    }

    if (presentationMode === undefined || presentationMode === null) {
      return;
    }

    if (userOrganization === undefined || !customerOrPresentationModeCustomer) {
      return;
    }

    const customerID = customerOrPresentationModeCustomer?.id;

    const cloudAnalyticsWidgets = getCollection(CloudAnalyticsModel).doc("widgets").collection("cloudAnalyticsWidgets");
    let orgId = getUserOrgID(isDoitEmployee, userOrganization);
    if (orgId === PresetOrganizations.DOITOrg) {
      orgId = RootOrgId;
    }
    const bqBySkuDocId = getWidgetDocId(customerID, bqSpendBySku, orgId);

    cloudAnalyticsWidgets
      .doc(bqBySkuDocId)
      .get()
      .then((bqBySkuDoc) => {
        const bqBySkuData = bqBySkuDoc.asModelData();
        const bqBySkuRowsLength =
          (bqBySkuData?.data?.rows && Object.entries(bqBySkuData?.data?.rows).reduce((prevR) => prevR + 1, 0)) || 0;

        setIsCustomerUsingBQ(bqBySkuRowsLength > 0 || presentationMode);
      });
  }, [customerOrPresentationModeCustomer, userOrganization, isDoitEmployee, presentationMode]);

  return { isCustomerUsingBQ };
};
