import {
  type BQLensBillingMode,
  type BQLensMeasurement,
  type BQLensMetric,
  type BQLensTimeFrame,
  BQLensTimeFrames,
} from "@doitintl/cmp-models";

export const selectedMetric = "selectedMetric";
export const selectedMeasurement = "selectedMeasurement";
export const selectedBillingMode = "selectedBillingMode";
export const availableUnitOptions = "availableUnitOptions";
export const selectedTimeframe = "selectedTimeframe";
export const recommendationKey = "recommendationKey";

export const timeframeOptions: Array<{ label: string; value: BQLensTimeFrames }> = [
  { label: "Last day", value: BQLensTimeFrames.pastOneDay },
  { label: "Last 7 days", value: BQLensTimeFrames.pastSevenDays },
  { label: "Last 30 days", value: BQLensTimeFrames.pastThirtyDays },
];

export const scanPrice = "scanPrice";
export const scanTb = "scanTB";
export const slots = "slots";
export const storagePrice = "storagePrice";
export const storageTB = "storageTB";

export const units = [
  { title: "Scan Price", value: "scanPrice", sign: "$" },
  { title: "Scan TB", value: "scanTB", sign: "TB" },
  { title: "Storage Price", value: "storagePrice", sign: "$" },
  { title: "Storage TB", value: "storageTB", sign: "TB" },
  { title: "Average Slots", value: "slots", sign: "" },
] as const;

export type unitsType = (typeof units)[number];

export const datasetTableProjectFlatRateFilterOptions: Array<BQLensMeasurement> = [storagePrice, storageTB];
export const datasetTableProjectOnDemandFilterOptions: Array<BQLensMeasurement> = [
  scanPrice,
  scanTb,
  storagePrice,
  storageTB,
];
export const billingProjectUserOnDemandOptions: Array<BQLensMeasurement> = [scanPrice, scanTb];
export const billingProjectUserFlatRate: Array<BQLensMeasurement> = [slots];

export const defaultMetricType: BQLensMetric = "billingProject";
export const defaultMeasurement = scanPrice;
export const defaultMetricOptions = billingProjectUserOnDemandOptions;

export const mainFilterChipId = 0;
export const narrowFilterChipId = 2;

export const mainFilterIndex = 0;
export const secondaryFilterIndex = 1;

export type UpdateProps = {
  selectedBillingMode?: BQLensBillingMode;
  metricType?: BQLensMetric;
  measurement?: BQLensMeasurement;
  selectedTimeframe?: BQLensTimeFrame;
};

export enum SlotGroupingMode {
  day = "day",
  hour = "hour",
}
