import { CloudFlowNodeType } from "@doitintl/cmp-models";

import DisablePublishedFlowGuard from "../../Common/DisablePublishedFlowGuard";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import APIParametersTab from "./APIParametersTab";
import { ConditionParametersTab } from "./ConditionParametersTab";
import FilterParametersTab from "./FilterParametersTab";
import { ManualTriggerTab } from "./ManualTriggerTab";
import PermissionsTab from "./PermissionsTab";
import ScheduleTab from "./ScheduleTab";
import TestsTab from "./TestsTab";
import TransformParametersTab from "./TransformParametersTab";

export const TabContent = () => {
  const { activeTab, nodeConfig } = useNodeConfigurationContext();

  switch (activeTab) {
    case "Permissions":
      return <PermissionsTab />;
    case "Test":
      return <TestsTab />;
  }

  // Components that rely on nodeConfig
  switch (nodeConfig.type) {
    case CloudFlowNodeType.ACTION:
      return <APIParametersTab />;
    case CloudFlowNodeType.CONDITION:
      return (
        <DisablePublishedFlowGuard>
          <ConditionParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.MANUAL_TRIGGER:
      return (
        <DisablePublishedFlowGuard>
          <ManualTriggerTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.FILTER:
      return (
        <DisablePublishedFlowGuard>
          <FilterParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.TRANSFORMATION:
      return (
        <DisablePublishedFlowGuard>
          <TransformParametersTab />
        </DisablePublishedFlowGuard>
      );
    case CloudFlowNodeType.TRIGGER:
      return (
        <DisablePublishedFlowGuard>
          <ScheduleTab />
        </DisablePublishedFlowGuard>
      );
  }

  return null;
};
