import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { AssetTypeAmazonWebServices, SaaSConsoleType } from "@doitintl/cmp-models";
import { useCollectionDataOnce } from "@doitintl/models-firestore";
import { Box, Stack, Typography } from "@mui/material";
import uniqBy from "lodash/uniqBy";

import awsLogoDark from "../../../assets/amazon-web-services-logo-white.png";
import awsLogo from "../../../assets/amazon-web-services-new-wide.png";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { type FilterTableToolbarProps } from "../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { type ToolbarButton } from "../../../Components/FilterTable/types";
import Hide from "../../../Components/HideChildren/Hide";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useMasterPayerAccounts } from "../../../Components/hooks/useMasterPayerAccounts";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type AmazonWebServicesAssetsType } from "../../../types";
import { getSaaSConsoleOnboardingRoute } from "../../../utils/common";
import { exportCSVFile } from "../../../utils/csv";
import { buildBillingProfileString, filterRemoveAwsAssetsWithoutWorkload } from "../assetUtils";
import CreateAmazonWebServicesAsset from "../Forms/CreateAmazonWebServicesAsset";
import InviteAmazonWebServicesAsset from "../Forms/InviteAmazonWebServicesAsset";
import { useAllowedToAddAssets } from "../hooks";
import { useHandshakes } from "../Hooks/useHandshakes";
import { type AssetsTabProps, type PayerAccountOption } from "../types";
import { sortPayersByFriendlyName } from "../utils";
import { type AWSAssetExportData, type AWSAssetRowData } from "./components/AwsAssetRow";
import AwsAssetTable from "./components/AwsAssetTable";
import ExportToGoogleSheetsDialog from "./ExportToGoogleSheetsDialog";

type Props = AssetsTabProps<AmazonWebServicesAssetsType>;

const getMenuOptions = (
  handleCSVExport: () => void,
  setExportGoogleSheetsDialogOpen: (open: boolean) => void
): ThreeDotsMenuOption[] => [
  { label: "Download as CSV", action: handleCSVExport, key: "csv" },
  {
    label: "Export to Google sheet",
    action: () => {
      setExportGoogleSheetsDialogOpen(true);
    },
    key: "googleSheets",
  },
];

const AmazonWebServicesAssetsTab = ({ assets, onRemoveAsset }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<"create" | "invite" | false>(false);
  const [tableData, setTableData] = useState([] as AWSAssetRowData[]);
  const [exportGoogleSheetsDialogOpen, setExportGoogleSheetsDialogOpen] = useState<boolean>(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rowData, setRowData] = useState<AWSAssetExportData[]>([]);

  const [handshakes] = useHandshakes();
  const { customer, entities } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const payers = useMasterPayerAccounts(!isDoitEmployee ? customer.id : undefined);
  const history = useHistory();
  const { allowedToAddResoldAssets, allowedToAddStandaloneAssets } = useAllowedToAddAssets(AssetTypeAmazonWebServices);

  // Return all Assets EXCEPT where ("cloudhealth" is present, AND "organization" is not).
  // Handshakes don't have either of those fields, so don't bother applying this filter to that array
  const assetsRemoveCloudhealthWithoutOrg = useMemo(
    () => (assets ? filterRemoveAwsAssetsWithoutWorkload(assets) : []),
    [assets]
  );

  const tableItems = useMemo(
    () => [...assetsRemoveCloudhealthWithoutOrg, ...(handshakes ?? [])],
    [assetsRemoveCloudhealthWithoutOrg, handshakes]
  );

  const payerOptions: PayerAccountOption[] = useMemo(() => {
    if (payers === null) {
      return [{ value: "", label: "", disabled: false }];
    }

    const mpaAccounts = payers.map((payer) => ({
      value: payer.accountNumber,
      label: payer.friendlyName,
      disabled: payer.status === "retired",
    }));

    const payerAccounts = sortPayersByFriendlyName(mpaAccounts);
    const customerPayerAccountIds = new Set<string>();

    for (const asset of assets ?? []) {
      if (asset.data.type === AssetTypeAmazonWebServices) {
        const assetPayerAccount = asset.data.properties.organization?.payerAccount;

        if (assetPayerAccount?.id) {
          customerPayerAccountIds.add(assetPayerAccount.id);
        }
      }
    }

    for (const handshake of handshakes ?? []) {
      if (handshake.data.state === "OPEN" || handshake.data.state === "ACCEPTED") {
        customerPayerAccountIds.add(handshake.data.payerAccount.id);
      }
    }

    const customerPayerAccounts = payerAccounts.filter((account) => customerPayerAccountIds.has(account.value));

    if (!isDoitEmployee) {
      return customerPayerAccounts;
    }

    return uniqBy([...customerPayerAccounts, ...payerAccounts], "value");
  }, [assets, handshakes, isDoitEmployee, payers]);

  const eligibleEntities = entities.filter((e) => e.active);
  const darkMode = useDarkThemeCheck();
  const { userRoles } = useUserContext();

  const entity = useMemo(
    () => eligibleEntities.find((e) => e.id === (tableData[0]?.entityId || "")),
    [eligibleEntities, tableData]
  );

  const [entityBuckets] = useCollectionDataOnce(
    useMemo(() => entity?.ref.collection("buckets").orderBy("name"), [entity?.ref]),
    { idField: "id" }
  );

  useEffect(() => {
    const fetchAndSetRowData = async () => {
      const newData: AWSAssetExportData[] = await Promise.all(
        tableData.map(async (row) => {
          const awsAccountId = row.id.startsWith("amazon-web-services-")
            ? row.id.replace("amazon-web-services-", "").trim()
            : row.id;

          const bucketName = entityBuckets?.find((bucket) => bucket.id === row.bucketId)?.name || "";

          return {
            assetName: row.assetName,
            awsAccountId,
            payerAccountName: row.payerAccountName,
            payerAccountId: row.awsAccountId,
            rootEmail: row.rootEmail,
            inviteState: row.inviteStatus,
            billingProfile: entity ? buildBillingProfileString(entity) : row.billingProfile,
            entityId: row.entityId || "",
            bucketId: row.bucketId || "",
            bucketName,
          };
        })
      );

      setRowData(newData);

      const headers = [
        "Asset name",
        "Account ID",
        "Payer account name",
        "Payer account ID",
        "Root email",
        "Invite state",
        "Billing profile",
        "Entity ID",
        "Bucket ID",
        "Bucket name",
      ];
      setHeaders(headers);
    };

    if (tableData.length > 0) {
      fetchAndSetRowData();
    }
  }, [entity, entityBuckets, tableData]);

  const handleCSVExport = useCallback(() => {
    const name = `${customer.name.replace(/\s/g, "-")}-aws-assets`;
    if (headers && rowData) {
      exportCSVFile(headers, rowData, name);
    }
  }, [customer.name, headers, rowData]);

  const title = (
    <Hide mdDown>
      <Stack direction="row" spacing={1}>
        <Typography variant="h1" sx={{ mr: "auto", fontWeight: "medium" }} data-cy="title">
          <Box
            component="img"
            src={darkMode ? awsLogoDark : awsLogo}
            sx={{ height: "25px", mb: "-4px", mr: 1 }}
            alt="AWS icon"
            aria-hidden
          />
          Amazon Web Services
        </Typography>
        <ThreeDotsMenu options={getMenuOptions(handleCSVExport, setExportGoogleSheetsDialogOpen)} size="small" />
      </Stack>
    </Hide>
  );

  const toolbarProps: FilterTableToolbarProps = {
    title,
    allowToEditColumns: true,
  };

  if (userRoles?.assetsManager) {
    let toolbarButtons: ToolbarButton[] = [];

    if (eligibleEntities?.length > 0 && allowedToAddResoldAssets) {
      toolbarButtons = [
        {
          text: "Invite account",
          onClick: () => {
            setDialogOpen("invite");
          },
          "data-cy": "inviteAccountButton",
        },
        {
          text: "Create account",
          onClick: () => {
            setDialogOpen("create");
          },
          "data-cy": "createAccountButton",
        },
      ];
    }

    if (allowedToAddStandaloneAssets) {
      {
        toolbarButtons = [
          ...toolbarButtons,
          {
            text: "Connect new account",
            onClick: () => {
              history.push(getSaaSConsoleOnboardingRoute(customer, SaaSConsoleType.AWS));
            },
            "data-cy": "connectAccountButton",
          },
        ];
      }
    }

    toolbarProps.primaryButton = toolbarButtons[0];
    toolbarProps.secondaryButtons = toolbarButtons.slice(1);
  }

  const content =
    assets === undefined ? (
      <FilterTableSkeleton />
    ) : (
      <AwsAssetTable
        items={tableItems}
        onRemoveAsset={onRemoveAsset}
        toolbar={toolbarProps}
        mpaAccounts={payers}
        tableData={tableData}
        setTableData={setTableData}
      />
    );

  return (
    <>
      {eligibleEntities.length > 0 && [
        dialogOpen === "invite" && (
          <InviteAmazonWebServicesAsset
            key="invite-aws-account"
            type="invite"
            onClose={() => {
              setDialogOpen(false);
            }}
            entities={eligibleEntities}
            payerAccountOptions={payerOptions}
          />
        ),
        dialogOpen === "create" && (
          <CreateAmazonWebServicesAsset
            key="create-aws-account"
            onClose={() => {
              setDialogOpen(false);
            }}
            entities={eligibleEntities}
            payerAccountOptions={payerOptions}
          />
        ),
      ]}
      {exportGoogleSheetsDialogOpen && (
        <ExportToGoogleSheetsDialog
          isDialog={true}
          isDialogOpen={exportGoogleSheetsDialogOpen}
          onClose={() => {
            setExportGoogleSheetsDialogOpen(false);
          }}
          headers={headers}
          rowData={rowData}
        />
      )}
      {content}
    </>
  );
};

export default AmazonWebServicesAssetsTab;
