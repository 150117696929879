import { type ChartSeriesRecord } from "../../useChartsRenderer";
import { type EChartType } from "../types";

export const typeSupportsClosesIndexSearch = (type: EChartType) =>
  type === "line" || type === "line-spline" || type === "area-spline" || type === "area";

export const findClosestIndex = (target: number, numbers: (number | undefined | null)[]): number => {
  if (numbers.length === 0) {
    throw new Error("The array must contain at least one element.");
  }

  // find first non-undefined element
  const firstDefinedValueIndex = numbers.findIndex((value) => value !== undefined && value !== null);

  if (firstDefinedValueIndex < 0) {
    return -1;
  }

  let closestIndex = firstDefinedValueIndex;
  // @ts-expect-error
  let closestDistance = Math.abs(target - numbers[firstDefinedValueIndex]);

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] === undefined || numbers[i] === null) {
      continue;
    }
    // @ts-expect-error
    const currentDistance = Math.abs(target - numbers[i]);
    if (currentDistance < closestDistance) {
      closestDistance = currentDistance;
      closestIndex = i;
    }
  }

  return closestIndex;
};

// dataset number that can start creating performance issues
export const isBigDataSet = (series: ChartSeriesRecord[]) => series.length > 100;
