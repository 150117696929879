import { useMemo, useState } from "react";

import { Alert, Box, Paper, Stack, Typography } from "@mui/material";
import { grey, pink } from "@mui/material/colors";

import { cmpBaseColors } from "../../cmpBaseColors";
import { ThemeModes } from "../../muiThemeTypes";
import { CopyToClipboardButton } from "../CopyToClipboardButton";

type Props = {
  base: string;
  variables?: { [key: string]: { value: string; name: string } };
  callbackClick?: () => void;
  maxHeight?: number;
};

// This function splits the string into an array of simple text and the variables and add the red format to the variables
// In this iteration also creates the text that is going to be copied and the empty variables errors names
export const replaceWithJSX = (base: string, variables?: { [key: string]: { value: string; name: string } }) => {
  const textArray = base.split(/(\$\w+)/);
  const codeToCopy: string[] = [];
  const emptyVariables: string[] = [];
  const formattedCode = textArray.map((text, index) => {
    if (text.startsWith("$") && variables !== undefined) {
      const formattedVariable = (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "monospace",
            lineHeight: 2,
            color: pink.A400,
            fontStyle: "italic",
            fontWeight: "bold",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
          display="inline"
          key={index}
        >
          {variables[text].value || text}
        </Typography>
      );
      codeToCopy.push(variables[text].value);
      if (variables[text].value === "" && emptyVariables.indexOf(variables[text].name) === -1) {
        emptyVariables.push(variables[text].name);
      }
      return formattedVariable;
    } else {
      const formattedText = (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "monospace",
            lineHeight: 2,
            overflowWrap: "anywhere",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          display="inline"
          key={index}
        >
          {text}
        </Typography>
      );
      codeToCopy.push(text);
      return formattedText;
    }
  });
  return { formattedCode, codeToCopy: codeToCopy.join(""), emptyVariables };
};

export const CopyCodeBlock = ({ base, variables, callbackClick, maxHeight }: Props) => {
  const [error, setError] = useState<boolean>();
  const { formattedCode, codeToCopy, emptyVariables } = useMemo(
    () => replaceWithJSX(base, variables),
    [base, variables]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (emptyVariables.length > 0) {
      event.stopPropagation();
      setError(true);
    } else {
      setError(false);
    }

    callbackClick?.();
  };
  return (
    <>
      <Paper
        elevation={0}
        sx={(theme) => ({
          bgcolor: theme.palette.mode === ThemeModes.LIGHT ? grey[100] : cmpBaseColors.backgroundDark,
          p: 1.5,
        })}
      >
        <Stack direction="row" alignItems="flex-start">
          <Box onClickCapture={handleClick} sx={{ mt: -0.5 }}>
            <CopyToClipboardButton text={codeToCopy} />
          </Box>
          <Box sx={maxHeight ? { maxHeight, overflow: "auto" } : undefined} data-testid="code-block">
            {formattedCode}
          </Box>
        </Stack>
      </Paper>
      {error && emptyVariables.length > 0 && (
        <Alert severity="error">Fill in your {emptyVariables.join(", ")}, before running the code</Alert>
      )}
    </>
  );
};
