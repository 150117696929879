import { useState } from "react";

import { Button, Dialog, DialogActions, Divider } from "@mui/material";

import mixpanel from "../../utils/mixpanel";
import TicketEscalation from "./EscalateStepper/TicketEscalation";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  billingProfile?: string;
  setBillingProfile: (profile: string) => void;
};

export const TermisAgreementDialog = ({ open, onClose, onConfirm, billingProfile, setBillingProfile }: Props) => {
  const [hasAgreedToCosts, setCostChecked] = useState<boolean>(false);
  const [isAuthorizedToMakeDecisions, setAuthorizedChecked] = useState<boolean>(false);
  const [hasAgreedToTerms, setAgreedToTerms] = useState<boolean>(false);

  const hasConfirmed = hasAgreedToCosts && isAuthorizedToMakeDecisions && hasAgreedToTerms && billingProfile;

  const handleConfirm = () => {
    if (hasConfirmed) {
      onConfirm();
      mixpanel.track("ava.essentials.escalate.confirm-payment", {
        flow: "ava.essentials.open-ticket",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ sx: { pt: 2 } }}>
      <TicketEscalation
        billingProfile={billingProfile}
        setBillingProfile={setBillingProfile}
        hasAgreedToCosts={hasAgreedToCosts}
        setCostChecked={setCostChecked}
        isAuthorizedToMakeDecisions={isAuthorizedToMakeDecisions}
        setAuthorizedChecked={setAuthorizedChecked}
        hasAgreedToTerms={hasAgreedToTerms}
        setAgreedToTerms={setAgreedToTerms}
      />
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" disabled={!hasConfirmed}>
          Confirm Payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};
