import { type Cluster } from "@doitintl/cmp-models";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type HeaderColumn } from "../../../types/FilterTable";
import { useClusters } from "../hooks";
import ClusterTableRow from "./ClusterRow";

const itemDescriptions: FilterTableItemDescriptor<Cluster>[] = [
  {
    headerText: "Name",
    value: "name",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Project ID",
    value: "projectId",
    filterType: "string",
  },
  {
    headerText: "Version",
    value: "kubernetesControlPlaneVersion",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "connectionStatus",
    filterType: "string",
  },
  {
    headerText: "Is activated",
    value: "isActivated",
    filterType: "boolean",
  },
];

const threeDotsHeaderItem: HeaderColumn = {
  value: "",
  disableSort: true,
};

const { headerColumns, filters } = generateHeadersAndFilters(itemDescriptions);
const extendedHeaderColumns = [...headerColumns, threeDotsHeaderItem];

const ClusterTable = () => {
  const [clusters] = useClusters();

  return (
    <FilterTable<Cluster>
      filterColumns={filters}
      headerColumns={extendedHeaderColumns}
      rowComponent={ClusterTableRow}
      tableItems={clusters}
      toolbarProps={{
        allowToEditColumns: true,
      }}
    />
  );
};
export default ClusterTable;
