import { Box } from "@mui/material";

import { useIsCloudflowPublished } from "./hooks/useIsCloudflowPublished";

const DisablePublishedFlowGuard = ({ children }) => {
  const isPublished = useIsCloudflowPublished();

  if (!isPublished) {
    return children;
  }
  return (
    <Box
      component="fieldset"
      disabled={true}
      sx={{
        opacity: 0.5,
        pointerEvents: "none",
        flex: "inherit",
        margin: "inherit",
        padding: "inherit",
        border: "inherit",
        width: "inherit",
        height: "inherit",
      }}
    >
      {children}
    </Box>
  );
};

export default DisablePublishedFlowGuard;
