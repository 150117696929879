import { type AxiosError } from "axios";

export const getAxiosErrorMessage = (axiosError: AxiosError): string => {
  const errorMessage =
    axiosError.response?.data && typeof axiosError.response.data === "object" && "error" in axiosError.response.data
      ? (axiosError.response.data.error as string)
      : "Unknown error";

  return errorMessage;
};
