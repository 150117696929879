import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

export enum AvaPlatforms {
  AWS = "amazon_web_services",
  AZURE = "microsoft_azure",
  CMP = "cloud_management_platform",
  CREDITS = "credits___request",
  FINANCE = "finance___billing",
  GCP = "google_cloud_platform",
}

export type AvaConversationTags = {
  platform: AvaPlatforms | keyof typeof AvaPlatforms;
  product: string;
  tags?: string[];
};

export type AvaFeedback = {
  positive: boolean;
  text?: string;
};

@subCollection("chatHistory")
export class AvaMessageModel {
  role!: "user" | "ava";

  content!: string;

  timeCreated!: Timestamp;

  ephemeralCreatedAt?: Timestamp;

  stopped?: boolean;

  messageFeedback?: AvaFeedback;

  additionalData?: string;

  runId?: string;
}

@collection("ava")
export class AvaConversationsModel {
  userId!: string;

  timeCreated!: Timestamp;

  timeUpdated!: Timestamp;

  client!: string;

  timeHistoryUpdated?: Timestamp;

  timeFeedbackUpdated?: Timestamp;

  title!: string;

  email?: string;

  customerName?: string;

  customerId?: string;

  ephemeralCreatedAt?: Timestamp;

  tags?: AvaConversationTags;

  subCollections?: {
    chatHistory: AvaMessageModel;
  };
}
