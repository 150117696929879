import {
  type ApprovalConfig,
  type CloudFlowNodeType,
  NotificationProviders,
  type SlackChannel,
} from "@doitintl/cmp-models";
import {
  Alert,
  alpha,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { useCustomerId } from "../../../../../../Components/hooks/useCustomerId";
import { useNodeConfigurationContext } from "../../NodeConfigurationContext";

type Props = {
  loadingSlackChannels: boolean;
  slackChannels: SlackChannel[];
  onUpdateConfig: (name: keyof ApprovalConfig, value: any) => void;
};

type SlackNotificationProviderProps = Omit<Props, "onUpdateConfig"> & {
  slackChannelId?: string;
  onSlackChannelChange: (e: SelectChangeEvent<string>) => void;
};

const SlackNotificationProvider = ({
  loadingSlackChannels,
  slackChannels,
  slackChannelId,
  onSlackChannelChange,
}: SlackNotificationProviderProps) => {
  const customerId = useCustomerId();
  const theme = useTheme();
  const isSlackConfigured = !loadingSlackChannels && slackChannels.length > 0;

  return (
    <Stack spacing={2}>
      {!loadingSlackChannels && slackChannels.length === 0 && (
        <Alert severity="warning">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Configure Slack integration to select your Slack channels
            </Typography>
            <Link
              color={alpha(theme.palette.warning.light, 0.15)}
              underline="hover"
              href={`/customers/${customerId}/integrations/slack`}
              target="_blank"
            >
              Configure
            </Link>
          </Stack>
        </Alert>
      )}
      <FormControl fullWidth required size="small" disabled={!isSlackConfigured}>
        <InputLabel>Slack channel</InputLabel>
        <Select
          name="slackChannelId"
          label="Slack channel"
          value={slackChannelId || ""}
          onChange={onSlackChannelChange}
          displayEmpty
          renderValue={(value) => {
            const item = slackChannels.find(({ id }) => id === value);
            return <Typography>{item?.name}</Typography>;
          }}
        >
          {loadingSlackChannels ? (
            <MenuItem disabled>Loading...</MenuItem>
          ) : (
            slackChannels.map((channel) => (
              <MenuItem key={channel.id} value={channel.id}>
                {channel.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Stack>
  );
};

export const NotificationProvider = ({ loadingSlackChannels, slackChannels, onUpdateConfig }: Props) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { recipient } = nodeConfig.approval || {};
  const { notificationProvider } = recipient || {};

  const getEmailValue = () => {
    if (recipient && recipient.notificationProvider === NotificationProviders.EMAIL) {
      return recipient.emails?.[0] || "";
    }
    return "";
  };

  const getSlackChannelId = () => {
    if (recipient && recipient.notificationProvider === NotificationProviders.SLACK) {
      return recipient.slackChannels?.[0]?.id || "";
    }
    return "";
  };

  return (
    <Stack pt={1} pb={1} spacing={3}>
      <FormControl fullWidth required size="small">
        <InputLabel>Notification provider</InputLabel>
        <Select
          name="notificationProvider"
          label="Notification provider"
          value={notificationProvider || ""}
          onChange={(e) => {
            onUpdateConfig("recipient", {
              notificationProvider: e.target.value as NotificationProviders,
              ...(e.target.value === NotificationProviders.EMAIL ? { emails: [] } : { slackChannels: [] }),
            });
          }}
        >
          {Object.values(NotificationProviders).map((provider: string) => (
            <MenuItem key={provider} value={provider}>
              {provider.charAt(0).toUpperCase() + provider.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {notificationProvider === NotificationProviders.SLACK && (
        <SlackNotificationProvider
          loadingSlackChannels={loadingSlackChannels}
          slackChannels={slackChannels}
          slackChannelId={getSlackChannelId()}
          onSlackChannelChange={(e) => {
            const selectedChannel = slackChannels.find((channel) => channel.id === e.target.value);
            onUpdateConfig("recipient", {
              notificationProvider: NotificationProviders.SLACK,
              slackChannels: selectedChannel ? [selectedChannel] : [],
            });
          }}
        />
      )}
      {/* TODO: support mulitple emails */}
      {notificationProvider === NotificationProviders.EMAIL && (
        <TextField
          fullWidth
          name="emails"
          label="Email Address"
          type="email"
          value={getEmailValue()}
          onChange={(e) => {
            onUpdateConfig("recipient", {
              notificationProvider: NotificationProviders.EMAIL,
              emails: e.target.value ? [e.target.value] : [],
            });
          }}
          required
        />
      )}
    </Stack>
  );
};
