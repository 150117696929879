import { Stack } from "@mui/material";

import illustration from "../../../assets/GKE-empty-illustration.svg";
import { lensEnticementTexts } from "../../../assets/texts";
import { LensEnticement } from "../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../LensEnticement/types";
import useFetchAmTamEmail from "../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";

export const MAPLensEnticement = ({ state }: { state: EnticementState }) => {
  const { accountManagers, loading } = useFetchAmTamEmail();

  return (
    <LensEnticement
      illustration={<img src={illustration} alt="MAP Lens empty Illustration" width="auto" height="250" />}
      title={lensEnticementTexts.AWS_MAP_LENS_TITLE}
      listItems={lensEnticementTexts.AWS_MAP_LENS_LIST}
    >
      {state === "upgrade" && (
        <Stack
          direction="column"
          spacing={4}
          sx={{
            "&&": {
              mt: 2,
            },
          }}
        >
          <ContactSalesButton sendTo={accountManagers} loading={loading} />
        </Stack>
      )}
    </LensEnticement>
  );
};
