import { useEffect, useMemo, useState } from "react";

import {
  BigQueryLensModel,
  type BQLensBillingMode,
  BQLensBillingModes,
  BQLensMeasurements,
  BQLensMetrics,
  BQLensOptimizerModes,
  BQLensTimeFrames,
  type SpendProfile,
  SuperQueryModel,
} from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import keys from "lodash/keys";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { isEdition } from "./utils";

export type RollsUpDefault = {
  defaultMetric: BQLensMetrics | undefined;
  availableMetrics: BQLensMetrics[];
  loading: boolean;
};

const useRollsUpsData = (
  mode: BQLensOptimizerModes,
  timeFrame: BQLensTimeFrames,
  metric: BQLensMetrics,
  scanType: BQLensMeasurements
) => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const ref = useMemo(
    () =>
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection(mode)
        .doc(customer.id)
        .collection("rollUps")
        .doc(timeFrame)
        .collection(metric)
        .doc(scanType),
    [mode, timeFrame, metric, customer, scanType]
  );

  const [data, loading, error] = useDocumentDataOnce(ref);

  return { data, loading, error };
};

export const useRollsUpDefaultData = (mode: BQLensOptimizerModes, measurement: BQLensMeasurements): RollsUpDefault => {
  const flexibleMeasurement = isEdition(mode) ? BQLensMeasurements.storagePrice : measurement;
  const flexibleMode = isEdition(mode) ? BQLensOptimizerModes.output : mode;

  const billingProject = useRollsUpsData(
    mode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.billingProject,
    measurement
  );

  const dataset = useRollsUpsData(
    flexibleMode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.dataset,
    flexibleMeasurement
  );

  const project = useRollsUpsData(
    flexibleMode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.project,
    flexibleMeasurement
  );

  const table = useRollsUpsData(
    flexibleMode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.table,
    flexibleMeasurement
  );

  const user = useRollsUpsData(mode, BQLensTimeFrames.pastThirtyDays, BQLensMetrics.user, measurement);

  const loading = billingProject.loading || dataset.loading || project.loading || table.loading || user.loading;

  const availableMetrics = useMemo(() => {
    const metrics: BQLensMetrics[] = [];

    const hasValidData = (data: any) => data && keys(data).length > 0;

    if (hasValidData(billingProject.data)) {
      metrics.push(BQLensMetrics.billingProject);
    }
    if (hasValidData(dataset.data)) {
      metrics.push(BQLensMetrics.dataset);
    }
    if (hasValidData(project.data)) {
      metrics.push(BQLensMetrics.project);
    }
    if (hasValidData(table.data)) {
      metrics.push(BQLensMetrics.table);
    }
    if (hasValidData(user.data)) {
      metrics.push(BQLensMetrics.user);
    }

    return metrics;
  }, [billingProject.data, dataset.data, project.data, table.data, user.data]);

  const defaultMetric = useMemo(
    () => (availableMetrics.length > 0 ? availableMetrics[0] : undefined),
    [availableMetrics]
  );

  return { defaultMetric, availableMetrics, loading };
};

export const useRollsUpData = (selectedBillingMode: BQLensBillingMode): RollsUpDefault => {
  const onDemandData = useRollsUpDefaultData(BQLensOptimizerModes.onDemand, BQLensMeasurements.scanPrice);
  const standardData = useRollsUpDefaultData(BQLensOptimizerModes.standardEdition, BQLensMeasurements.slots);
  const enterpriseData = useRollsUpDefaultData(BQLensOptimizerModes.enterpriseEdition, BQLensMeasurements.slots);
  const enterprisePlusData = useRollsUpDefaultData(
    BQLensOptimizerModes.enterprisePlusEdition,
    BQLensMeasurements.slots
  );

  switch (selectedBillingMode) {
    case BQLensBillingModes.onDemand:
      return onDemandData;
    case BQLensBillingModes.standardEdition:
      return standardData;
    case BQLensBillingModes.enterpriseEdition:
      return enterpriseData;
    case BQLensBillingModes.enterprisePlusEdition:
      return enterprisePlusData;
    default:
      return onDemandData;
  }
};

export function useBqSpendProfile(): { bqSpendProfile: SpendProfile } {
  const [bqSpendProfile, setBqSpendProfile] = useState<SpendProfile>({} as SpendProfile);
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const ref = useMemo(
    () =>
      getCollection(BigQueryLensModel)
        .doc("customers")
        .collection("bqLensCustomers")
        .doc(customer.id)
        .collection("bqLensOptimizer")
        .doc("spendProfile"),
    [customer]
  );

  const [bqlensConfig, bqlensConfigLoading] = useDocumentDataOnce(ref);

  useEffect(() => {
    if (bqlensConfig && !bqlensConfigLoading) {
      setBqSpendProfile((prev) => ({ ...prev, ...bqlensConfig }));
    }
  }, [bqlensConfigLoading, bqlensConfig, setBqSpendProfile]);

  return {
    bqSpendProfile,
  };
}
