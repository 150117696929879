import { type Dispatch, type JSX, type SetStateAction, useMemo } from "react";

import { Box, Button, type SelectChangeEvent, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import keys from "lodash/keys";
import { DateTime } from "luxon";

import { type AvaConversationRef, truncatedQueryLimit } from "../useAvaConversationRefs";

export type AvaHistorySelectProps = {
  conversations: AvaConversationRef[];
  selectedConversationId?: string;
  newChatMode: boolean;
  handleChatHistoryChanged: (event: SelectChangeEvent) => void;
  expandedQuery: boolean;
  setExpandedQuery: Dispatch<SetStateAction<boolean>>;
};

export enum ConversationHistoryPeriod {
  TODAY = "today",
  PREV_7 = "prev7",
  PREV_30 = "prev30",
  OLDER = "older",
}

type ConversationHistory = Partial<Record<ConversationHistoryPeriod, AvaConversationRef[]>>;

export const AvaHistorySelect = ({
  conversations,
  selectedConversationId,
  newChatMode,
  handleChatHistoryChanged,
  expandedQuery,
  setExpandedQuery,
}: AvaHistorySelectProps) => {
  // If the query is already fully expanded, or the conversations count is less than the truncated limilt, we don't need to show the "Show older chat history" button because there's nothing more that the query may encompass.
  const allowShowMoreButton = useMemo<boolean>(
    () => !expandedQuery && conversations.length >= truncatedQueryLimit,
    [conversations.length, expandedQuery]
  );

  const conversationHistory = useMemo<ConversationHistory>(
    () =>
      conversations.reduce((acc, conversation) => {
        if (!conversation.timeUpdated.isValid) {
          return acc;
        }

        const updated = conversation.timeUpdated.toUTC();
        const now = DateTime.now().toUTC();

        if (updated.hasSame(now.startOf("day"), "day")) {
          acc[ConversationHistoryPeriod.TODAY] = acc[ConversationHistoryPeriod.TODAY]
            ? [...acc[ConversationHistoryPeriod.TODAY], conversation]
            : [conversation];
          return acc;
        }

        if (now.minus({ days: 7 }).startOf("day") <= updated) {
          acc[ConversationHistoryPeriod.PREV_7] = acc[ConversationHistoryPeriod.PREV_7]
            ? [...acc.prev7, conversation]
            : [conversation];
          return acc;
        }

        if (now.minus({ days: 30 }).startOf("day") <= updated) {
          acc[ConversationHistoryPeriod.PREV_30] = acc[ConversationHistoryPeriod.PREV_30]
            ? [...acc.prev30, conversation]
            : [conversation];
          return acc;
        }

        acc[ConversationHistoryPeriod.OLDER] = acc[ConversationHistoryPeriod.OLDER]
          ? [...acc[ConversationHistoryPeriod.OLDER], conversation]
          : [conversation];

        return acc;
      }, {} as ConversationHistory),
    [conversations]
  );

  const options = useMemo<JSX.Element[]>(() => {
    const truncatedOptions = (text: string, length: number) =>
      text.length > length ? `${text.slice(0, length - 3)}...` : text;

    const avaPeriods: Record<ConversationHistoryPeriod, string> = {
      [ConversationHistoryPeriod.TODAY]: "Today",
      [ConversationHistoryPeriod.PREV_7]: "Previous 7 days",
      [ConversationHistoryPeriod.PREV_30]: "Previous 30 days",
      [ConversationHistoryPeriod.OLDER]: "Over 30 days",
    };

    const result: JSX.Element[] = [];
    keys(conversationHistory).forEach((period) => {
      if (!conversationHistory[period]?.length) {
        return;
      }

      result.push(
        <Typography
          variant="subtitle2"
          key={period}
          data-cy={`sectionHeader-${period}`}
          sx={{ my: 1, mx: 1.25, color: "text.secondary" }}
        >
          {avaPeriods[period]}
        </Typography>,
        ...conversationHistory[period].map((conversation: AvaConversationRef) => (
          <MenuItem
            key={conversation.conversationId}
            value={conversation.conversationId}
            data-cy={`avaHistoryMenuItem-${period}-${conversation.conversationId}`}
          >
            {truncatedOptions(conversation.title, 60)}
          </MenuItem>
        ))
      );
    });

    return result;
  }, [conversationHistory]);

  return (
    <Select
      size="small"
      displayEmpty
      value={newChatMode ? "" : selectedConversationId}
      onChange={handleChatHistoryChanged}
      sx={{ width: "250px", height: "34px" }}
      data-cy="chat-history-select"
    >
      {newChatMode && (
        <MenuItem value="" disabled data-cy="newChatPlaceholder">
          <Typography variant="subtitle2">New Chat</Typography>
        </MenuItem>
      )}
      {options}
      {allowShowMoreButton && (
        <Box>
          {/* DO NOT REMOVE this box, no matter how tempting it might be to neaten things up. This box is necessary to allow `stopPropagation()` to work before the event makes its way to the `Select`. */}
          <Button
            variant="text"
            sx={{ position: "sticky", bottom: 0, backgroundColor: "background.default", width: "100%" }}
            onClick={(event) => {
              event.stopPropagation();
              setExpandedQuery(true);
            }}
            data-cy="showOlderChatHistoryButton"
          >
            Show older chat history
          </Button>
        </Box>
      )}
    </Select>
  );
};
