import { cloneElement, useEffect, useMemo, useState } from "react";

import { type SimulationRecommenderExplorerDoc, SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Card, CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { getTreeMapColors } from "../../../Pages/CloudAnalytics/utilities";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { SkeletonCard } from "../SkeletonCard";
import { type WidgetItemProps } from "../types";
import { useBigQueryLensDashboardContext } from "./BigQueryLensDashboard";
import { SlotGroupingMode } from "./constants";
import TimeSelect from "./TimeSelect";

const useStyles = makeStyles(() => ({
  action: {
    marginTop: 8,
    marginRight: 8,
  },
}));

export function SlotExplorerCard({ fallbackComponent, widgetHeight = 200 }: WidgetItemProps) {
  const classes = useStyles();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const theme = useTheme();
  const [selectedMode, setSelectedMode] = useState(SlotGroupingMode.hour);
  const [data, setData] = useState<SimulationRecommenderExplorerDoc | null>();
  const { selectedBillingMode, selectedTimeframe } = useBigQueryLensDashboardContext();

  useEffect(() => {
    getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection(selectedBillingMode)
      .doc(customer.id)
      .collection("explorer")
      .doc(selectedTimeframe)
      .get()
      .then((doc) => {
        setData(doc.asModelData() ?? null);
      });
  }, [selectedBillingMode, selectedTimeframe, customer.id]);

  const options = useMemo(() => {
    if (!data) {
      return {};
    }

    const usedData = data[selectedMode];
    return {
      chart: {
        zoomType: "xy",
        height: widgetHeight - 20,
        backgroundColor: "transparent",
        animation: true,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        x: 0,
        verticalAlign: "top",
        y: -14,
        itemStyle: {
          fontSize: 10,
          color: theme.palette.text.primary,
        },
        floating: false,
        backgroundColor: "transparent",
      },
      yAxis: [
        {
          gridLineColor: theme.palette.general.divider,
          title: {
            text: null,
          },
          labels: {
            style: {
              color: theme.palette.text.primary,
            },
          },
        },
      ],
      xAxis: [
        {
          categories: usedData.xAxis,
          crosshair: true,
          labels: {
            style: {
              color: theme.palette.text.primary,
            },
          },
        },
      ],
      series: [
        {
          name: "Peak usage",
          type: "line",
          color: getTreeMapColors(theme.palette.mode)[1],
          animation: true,
          data: usedData.line,
        },

        {
          name: "Average usage",
          type: "column",
          color: getTreeMapColors(theme.palette.mode)[0],
          animation: true,
          data: usedData.bar,
        },
      ],
      tooltip: {
        valueDecimals: 0,
      },
    };
  }, [data, selectedMode, widgetHeight, theme.palette.text.primary, theme.palette.mode, theme.palette.general.divider]);

  const subheader = "Slots consumption per hour or day";
  const title = "Slots Consumption";
  if (data === null) {
    if (!fallbackComponent) {
      return null;
    }
    return cloneElement(fallbackComponent, { title, subheader, noData: true });
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card>
      <WidgetCardHeader
        title={title}
        subheader={subheader}
        action={
          !data ? undefined : (
            <div className={classes.action}>
              <TimeSelect selectedValue={selectedMode} onChange={setSelectedMode} />
            </div>
          )
        }
      />
      <CardContent sx={{ height: widgetHeight }}>
        <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} />
      </CardContent>
    </Card>
  );
}
