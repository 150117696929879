import { type ServiceTier, type ServiceType, type SupportModel, type SupportTier } from "@doitintl/cmp-models";

import { isProduction } from "../../constants";

export const supportModels: Exclude<SupportModel, null>[] = ["resold", "partner-led"];
export const availableSupportTiers: Record<Exclude<SupportModel, null>, SupportTier[]> = {
  ["resold"]: ["basic", "developer", "business", "enterprise", "enterprise-on-ramp"],
  ["partner-led"]: ["enterprise", "enterprise-on-ramp"],
};
export const availableSupportTiersLabels: Partial<Record<SupportTier, string>> = {
  "enterprise-on-ramp": "Enterprise On-Ramp",
};

export const mpaVaultId = isProduction ? "rahkkon4drtfqry35yfcw3c4fq" : "tjp2jpaps54gxm2pwqnztynqxe";
export const serviceTypes: ServiceType[] = ["advantage", "navigator", "solve"];
export const serviceTiers: ServiceTier[] = ["standard", "enhanced", "premium", "enterprise"];
