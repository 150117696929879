import { useState } from "react";

import { type Cluster, ClusterDiscoveryStatus } from "@doitintl/cmp-models";
import { type SxProps, TableCell, type Theme } from "@mui/material";
import { type AxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { getAxiosErrorMessage } from "../../../utils/axios";
import { activateCluster, deactivateCluster, deleteCluster, testClusterConnection } from "../api";
import { ClusterDialog } from "../ClusterDialog";
import DeleteClusterDialog from "../Components/DeleteClusterDialog";
import BooleanValueCell from "./BooleanValueCell";
import ClusterStatus from "./ClusterStatus";
import ClusterThreeDotsMenu from "./ClusterThreeDotsMenu";
import ClusterTypeDisplay from "./ClusterType";

interface ClusterTableRowProps {
  row: Cluster;
}

const ClusterTableRow = ({ row }: ClusterTableRowProps) => {
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();

  const [actionLoading, setActionLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const runClusterAction = async (action: () => Promise<any>) => {
    try {
      setActionLoading(true);
      await action();
    } catch (e) {
      const message = getAxiosErrorMessage(e as AxiosError);
      errorSnackbar(`Error performing action on cluster ${row.name}: ${message}`);
    } finally {
      setActionLoading(false);
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleTestConnection = async () => {
    await runClusterAction(() => testClusterConnection(api, row.id));
  };

  const handleActivateCluster = async () => {
    await runClusterAction(() => activateCluster(api, row.id));
  };

  const handleDeactivateCluster = async () => {
    await runClusterAction(() => deactivateCluster(api, row.id));
  };

  const handleDeleteCluster = async () => {
    setDeleteDialogOpen(false);
    await runClusterAction(() => deleteCluster(api, row.id));
  };

  const clusterNameStyle: SxProps<Theme> = {
    textDecoration: row.discoveryStatus === ClusterDiscoveryStatus.DELETED ? "line-through" : "none",
  };

  return (
    <>
      {deleteDialogOpen && (
        <DeleteClusterDialog
          cluster={row}
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onDelete={handleDeleteCluster}
        />
      )}
      {editDialogOpen && <ClusterDialog open={editDialogOpen} setOpen={setEditDialogOpen} cluster={row} />}
      <CellsWrapper>
        <TableCell sx={clusterNameStyle}>{row.name}</TableCell>
        <TableCell>
          <ClusterTypeDisplay type={row.type} />
        </TableCell>
        <TableCell>{row.region}</TableCell>
        <TableCell>{row.projectId || "N/A"}</TableCell>
        <TableCell>{row.kubernetesControlPlaneVersion || "Unknown"}</TableCell>
        <TableCell>
          <ClusterStatus cluster={row} />
        </TableCell>
        <BooleanValueCell value={row.isActivated} />
        <TableCell>
          <ClusterThreeDotsMenu
            cluster={row}
            onTestConnection={handleTestConnection}
            onActivateCluster={handleActivateCluster}
            onDeactivateCluster={handleDeactivateCluster}
            onDeleteCluster={openDeleteDialog}
            onEditCluster={openEditDialog}
            loading={actionLoading}
          />
        </TableCell>
      </CellsWrapper>
    </>
  );
};

export default ClusterTableRow;
