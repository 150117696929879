import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";

type Props = {
  requestID: string;
  comment: string;
  reason: string;
  requestTitle: string;
  setComment: (comment: string) => void;
  setReason: (reason: string) => void;
};

export default function StepEscalate({ comment, setComment, reason, setReason, requestID, requestTitle }: Props) {
  return (
    <Box>
      <DialogTitle>
        Escalate request #{requestID} | {requestTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the reason for escalation</DialogContentText>

        <Grid container spacing={2} mt={0}>
          <Grid item xs={12}>
            <TextField
              data-cy={"select-reason"}
              id="reason"
              name="reason"
              label="Escalation Reason"
              required
              variant="outlined"
              value={reason}
              onChange={(event) => {
                setReason(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              select
              fullWidth
            >
              <MenuItem value="time">Long resolution time</MenuItem>
              <MenuItem value="business">Business Impact</MenuItem>
              <MenuItem value="technical">Technical expertise</MenuItem>
              <MenuItem value="avaResponseNotSufficient">Ava's answer was insufficient</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              data-cy="comment"
              id="comment"
              name="comment"
              label="Add Comment"
              variant="outlined"
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={3}
              maxRows={3}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Box>
  );
}
