import { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

import LoadingButton from "../../Components/LoadingButton";
import { useFullScreen } from "../../utils/dialog";

export default function EscalateDialog({ open, onClose, onSubmit, loading, requestID }) {
  const { fullScreen } = useFullScreen();
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (open && loading) {
      return () => {
        onClose();
        setComment("");
        setReason("");
      };
    }
  }, [open, onClose, loading]);

  const handleSubmit = () => {
    onSubmit(requestID, reason, comment);
  };

  return (
    <Dialog open={open} fullScreen={fullScreen} aria-labelledby="form-dialog-escalate">
      <DialogTitle id="form-dialog-title">{`Escalate request #${requestID}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the reason for escalation</DialogContentText>

        <Grid container spacing={2} mt={0}>
          <Grid item xs={12}>
            <TextField
              id="reason"
              name="reason"
              label="Escalation Reason"
              variant="outlined"
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              select
              helperText="Please select the reason for escalation"
              fullWidth
            >
              <MenuItem value="time">Long resolution time</MenuItem>
              <MenuItem value="business">Business Impact</MenuItem>
              <MenuItem value="technical">Technical expertise</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="comment"
              name="comment"
              label="Add Comment"
              variant="outlined"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={3}
              maxRows={3}
              helperText="Optional"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button color="primary" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          size="medium"
          variant="contained"
          onClick={handleSubmit}
          disabled={!reason || loading}
          loading={loading}
          mixpanelEventId="support.escalate.submit"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
