import { useMemo } from "react";

import { InsightModel, type InsightStatusChangeModel, type InsightStatusChangesModel } from "@doitintl/cmp-models/src";
import { getCollection, useCollectionData } from "@doitintl/models-firestore/src";
import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import orderBy from "lodash/orderBy";

import { useApiContext } from "../../api/context";
import { queryKeys } from "../../constants";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import {
  ALL_CATEGORIES,
  type Category,
  type Insight,
  type InsightResultsResponse,
  type NonEntitledInsightsResults,
} from "./types";

const getInsightStatusDetails = (matchingStatusChangeDocument: InsightStatusChangeModel): InsightStatusChangesModel => {
  const sortedStatusChanges = orderBy(matchingStatusChangeDocument.statusChanges, ["timestamp.seconds"], ["desc"]);

  return sortedStatusChanges[0];
};

function normalizeInsightCategories(results: Insight[] | undefined): Insight[] {
  return (
    results?.map((datum) => {
      const categories = datum.categories?.length ? datum.categories : datum.otherTags;
      const validCategories = categories?.filter((category): category is Category =>
        ALL_CATEGORIES.some((ac) => ac.toLowerCase() === category.toLowerCase())
      );

      return {
        ...datum,
        categories: validCategories,
      };
    }) || []
  );
}

type InsightsState = {
  insights: Insight[] | undefined;
  nonEntitledSummary: NonEntitledInsightsResults | undefined;
  isFetching: boolean;
  error: Error | null;
};

export const useInsights = (): InsightsState => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();

  const { isFetching, data, error } = useQuery<InsightResultsResponse, AxiosError>({
    queryKey: [customer.id, queryKeys.insights],
    queryFn: async (): Promise<InsightResultsResponse> => {
      const response = await api.get<InsightResultsResponse>("insights/results", {
        params: { customerID: customer.id },
      });

      return { ...response.data, results: normalizeInsightCategories(response.data.results) };
    },

    // Keep the data for an hour for now, as it's unlikely to change often - this way,
    // the user can navigate between insights without getting a loading indicator
    staleTime: 60 * 60 * 1000,
  });

  const [insightsStatusChanges] = useCollectionData(
    getCollection(InsightModel).doc("api").collection("insightsStatusChanges").where("customerId", "==", customer.id)
  );

  const insightsWithStatuses = useMemo(() => {
    if (!data?.results) {
      return [];
    }

    if (!insightsStatusChanges) {
      return data.results;
    }

    return data.results.map((insight) => {
      const matchingStatusChangeDocument = insightsStatusChanges.find(
        (statusChange) => statusChange.insightRef.id === `${insight.customerId}#${insight.providerId}#${insight.key}`
      );

      if (!matchingStatusChangeDocument) {
        return { ...insight } as any;
      }

      const userStatusChanges = getInsightStatusDetails(matchingStatusChangeDocument);

      return {
        ...insight,
        userStatusChanges,
      };
    });
  }, [data?.results, insightsStatusChanges]);

  // Double safety - the API does not return internal insights for non-DoiT employees,
  // but just in case something goes wrong there, filter them again
  return useMemo(() => {
    const finalInsights = isDoitEmployee
      ? insightsWithStatuses
      : insightsWithStatuses.filter((insight) => !insight.isInternal);

    if (finalInsights.length === 0) {
      return { insights: undefined, nonEntitledSummary: undefined, isFetching, error };
    }

    if (data === undefined) {
      return {
        insights: undefined,
        nonEntitledSummary: undefined,
        isFetching,
        error,
      };
    }

    if (!isDoitEmployee) {
      const nonInternalInsights = finalInsights
        ? finalInsights.filter((insight) => !insight.isInternal)
        : finalInsights;

      return {
        insights: nonInternalInsights,
        nonEntitledSummary: data.nonEntitledResultsSummary,
        isFetching,
        error,
      };
    }

    return {
      insights: finalInsights,
      nonEntitledSummary: data.nonEntitledResultsSummary,
      isFetching,
      error,
    };
  }, [data, error, isDoitEmployee, isFetching, insightsWithStatuses]);
};

export const createInsight = async ({ api, insight }) => api.post(`/insights/results`, insight);
