import { TierPackageTypes } from "@doitintl/cmp-models";

import { useAuthContext } from "../Context/AuthContext";
import { useCustomerContext } from "../Context/CustomerContext";
import { useTier } from "../Context/TierProvider";
import { useUserContext } from "../Context/UserContext";
import { consoleErrorWithSentry } from "./errorHandling";
import { type CommonSegmentEventTraits } from "./segment";

const useSegmentTrackEvent = () => {
  const { currentUser, isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext({
    allowNull: true,
  });

  const { userModel: user } = useUserContext();

  let customerTier = "";

  const { getCustomerTier } = useTier();

  if (customer) {
    const customerTierData = getCustomerTier(TierPackageTypes.NAVIGATOR);
    customerTier = customerTierData?.displayName ?? "";
  }

  const trackEvent = (eventName, properties = {}) => {
    try {
      if (!globalThis.analytics) {
        return;
      }

      const traits: CommonSegmentEventTraits = {
        authUserId: currentUser?.uid || "",
        userEmail: currentUser?.email || "",
        isDoitEmployee: isDoitEmployee || false,
        firestoreUserId: user?.id || "",
        customerId: customer?.id || "",
        customerName: customer?.name || "",
        customerPrimaryDomain: customer?.primaryDomain || "",
        customerTier: customerTier || "",
      };

      const options = { context: { traits, groupId: customer?.id || "" } };

      globalThis.analytics.track(eventName, properties, options);
    } catch (e) {
      consoleErrorWithSentry(e);
    }
  };

  return { trackEvent };
};

export default useSegmentTrackEvent;
