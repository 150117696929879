import { useCallback } from "react";

import { Aggregator, ComparativeFeature, type CurrencyCode, Metric } from "@doitintl/cmp-models";

import { convertSavingsTotalOverTotalValue, isComparative } from "../../Pages/CloudAnalytics/utilities";
import { formatCurrency, formatDecimalNumber, formatNumber, getCurrencySymbol } from "../../utils/common";

type FormatterOptions = {
  maxDigits?: number;
  extendedMetricMode?: string;
};

type Formatter = {
  aggregator: Aggregator;
  currency: CurrencyCode;
  metric: Metric;
  options?: FormatterOptions;
};

export const formatValueWithCurrency = (
  value: number,
  maxDigits: number,
  currency: CurrencyCode,
  short: boolean
): string => {
  if (short) {
    const prefix = getCurrencySymbol(currency);
    if (value < 0) {
      const abs = Math.abs(value);
      return `-${prefix}${formatNumber(abs, maxDigits)}`;
    }
    return prefix + formatNumber(value, maxDigits);
  }
  return formatCurrency(value, currency, maxDigits);
};

export const formatValueWithSuffix = (value: number, maxDigits: number, suffix: string, short: boolean): string => {
  if (short) {
    return formatNumber(value, maxDigits) + suffix;
  }
  return String(formatDecimalNumber(value, maxDigits, 0)) + suffix;
};

export const comparativeFormat = (
  val: number,
  comparative: ComparativeFeature,
  currency: CurrencyCode,
  metric: Metric,
  maxDigits: number,
  short: boolean = false
): string => {
  let suffix = false;
  let prefix = false;
  const value = Math.abs(val) < 0.001 ? 0 : val;
  if (comparative === ComparativeFeature.PERCENT) {
    suffix = true;
  } else if (comparative === ComparativeFeature.VALUES && metric !== Metric.USAGE) {
    prefix = true;
  }
  if (suffix) {
    return formatValueWithSuffix(value, maxDigits, "%", short);
  }
  if (prefix) {
    return formatValueWithCurrency(value, maxDigits, currency, short);
  }
  if (short) {
    return formatNumber(value, maxDigits);
  }
  return formatDecimalNumber(value, maxDigits, 0);
};

const useFormatter = ({
  aggregator,
  currency,
  metric,
  options = { maxDigits: 2, extendedMetricMode: "usage" },
}: Formatter) =>
  useCallback(
    (value, short = false, comparative = ComparativeFeature.NONE) => {
      if (isNaN(value ?? undefined) || !isFinite(value)) {
        return "";
      }

      const { maxDigits = 2, extendedMetricMode } = options;
      if (isComparative(comparative)) {
        return comparativeFormat(value, comparative, currency, metric, maxDigits, short);
      }

      if (aggregator === Aggregator.TOTAL) {
        // for attribution case //== ""
        if (
          (metric !== Metric.USAGE && metric !== Metric.EXTENDED) ||
          (metric === Metric.EXTENDED && extendedMetricMode !== "usage" && extendedMetricMode !== "datahub")
        ) {
          return formatValueWithCurrency(value, maxDigits, currency, short);
        }
        if (short) {
          return formatNumber(value, maxDigits);
        }
        return formatDecimalNumber(value, maxDigits, 0);
      } else if (aggregator === Aggregator.TOTAL_OVER_TOTAL) {
        // for attribution case //== ""
        let suffix = "";
        switch (metric) {
          case Metric.SAVINGS:
            // Savings/Cost
            suffix = "%";
            value = convertSavingsTotalOverTotalValue(value) * 100;
            break;
          case Metric.MARGIN:
            // Margin/Cost
            suffix = "%";
            // value is already margin-savings
            // value represents margin/cost_after_discount
            value = value * 100;
            break;
          default:
        }
        const d = value % 1;
        let maxFracDigits = maxDigits;
        if (suffix !== "%") {
          if (d < 9e-5) {
            maxFracDigits = 6;
          } else if (d < 9e-4) {
            maxFracDigits = 5;
          } else if (d < 9e-3) {
            maxFracDigits = 4;
          } else if (d < 9e-2) {
            maxFracDigits = 3;
          }
          return formatValueWithCurrency(value, maxFracDigits, currency, false);
        }
        return formatValueWithSuffix(value, maxFracDigits, suffix, false);
      } else if (aggregator === Aggregator.COUNT) {
        return formatNumber(value, maxDigits);
      } else {
        return formatValueWithSuffix(value * 100, maxDigits, "%", false);
      }
    },
    [aggregator, currency, metric, options]
  );

export default useFormatter;
