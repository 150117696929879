import type { JSX } from "react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { MAPLensEnticement } from "./MAPLensEnticement";

type Props = {
  children: JSX.Element;
};

export const MAPLensGuard = ({ children }: Props) => {
  const { customer } = useCustomerContext();
  const isEntitled = useIsFeatureEntitled("lens:awsmap");

  if (customer.presentationMode?.enabled || customer.presentationMode?.isPredefined) {
    return children;
  }

  if (isEntitled) {
    return children;
  }

  return <MAPLensEnticement state={isEntitled ? "activate" : "upgrade"} />;
};
