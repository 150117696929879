import { DashboardType, type EntitlementKey } from "@doitintl/cmp-models";

import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { useIsFeatureEntitledMultiple } from "../../../Context/TierProvider";

const dashboardTypeToEntitlementMap: Partial<Record<DashboardType, EntitlementKey>> = {
  [DashboardType.EKSLens]: "lens:eks",
  [DashboardType.SnowflakeLens]: "snowflake:connect",
  [DashboardType.GkeLensV2]: "lens:gke",
  [DashboardType.BqLens]: "lens:bigquery",
};

const entitlementKeys = Object.values(dashboardTypeToEntitlementMap);

export function useIsEntitledToCurrentDashboardType() {
  const { dashboardType } = useCurrentDashboardContext();
  const featuresEntitlements = useIsFeatureEntitledMultiple(entitlementKeys);

  if (!dashboardType) {
    return true;
  }

  const entitlementKey = dashboardTypeToEntitlementMap[dashboardType];
  if (!entitlementKey) {
    return true;
  }

  return !!featuresEntitlements[entitlementKey];
}
