import { type Cluster } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

export type CreateClusterPayload = {
  name: string;
  region: string;
  host: string;
  caCertBase64: string;
  serviceAccountKey: string;
};

export type UpdateClusterPayload = Omit<CreateClusterPayload, "name" | "region" | "type">;

export type TestClusterConnectionPayload = {
  host: string;
  caCertBase64: string;
  token: string;
  clusterId?: string;
};

export const createCluster = async (api: AxiosInstance, cluster: CreateClusterPayload, customerId: string) =>
  api.post(`fsk8s/clusters?customerID=${customerId}`, cluster);

export const testClusterConnection = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/connectioncheck`);

export const testClusterConnectionFromPayload = async (api: AxiosInstance, payload: TestClusterConnectionPayload) =>
  api.post(`fsk8s/clusters/connectioncheck`, payload);

export const activateCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/activate`);

export const deactivateCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/deactivate`);

export const deleteCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.delete(`fsk8s/clusters/${clusterId}`);

export const updateCluster = async (api: AxiosInstance, cluster: UpdateClusterPayload, clusterId: Cluster["id"]) =>
  api.patch(`fsk8s/clusters/${clusterId}`, cluster);
