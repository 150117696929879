import { type JSX } from "react";

import { Box, Card } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type SxProps } from "@mui/system";

import CountUpContent, { type CountUpContentProps } from "./CountUpContent";
import { CountUpNumber } from "./CountUpNumber";
import { CountUpText, type CountUpTextProps } from "./CountUpText";

type CountUpCardProps = CountUpContentProps &
  CountUpTextProps & {
    childBeforeContent?: JSX.Element;
    childAfterContent?: JSX.Element;
    childRightToText?: JSX.Element;
    appendChildrenDirection?: "row" | "column";
    contentPadding?: number;
    numberColor?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
    numberOnClick?: () => void;
    sx?: SxProps<Theme>;
  };

function CountUpCard({
  text,
  tooltipText,
  number,
  numberColor = "textPrimary",
  numberOnClick,
  prefix = "",
  suffix = "",
  decimals,
  duration,
  childBeforeContent,
  childAfterContent,
  childRightToText,
  appendChildrenDirection = "row",
  contentPadding = 2,
  sx,
}: CountUpCardProps) {
  return (
    <Card>
      <Box
        p={contentPadding}
        display="flex"
        flexDirection={appendChildrenDirection}
        justifyContent="space-between"
        width="100%"
        sx={sx}
        data-testid={tooltipText || "data-card"}
      >
        {childBeforeContent ?? <div />}
        <Box textAlign="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <CountUpNumber numberOnClick={numberOnClick} numberColor={numberColor}>
              <CountUpContent number={number} decimals={decimals} prefix={prefix} suffix={suffix} duration={duration} />
            </CountUpNumber>

            {childRightToText}
          </Box>
          <CountUpText text={text} tooltipText={tooltipText} />
        </Box>
        {childAfterContent ?? <div />}
      </Box>
    </Card>
  );
}

export default CountUpCard;
