import { type ReactNode } from "react";

import { Skeleton } from "@mui/material";

type LoadingWrapperProps = {
  loading: boolean;
  children: ReactNode;
};

const LoadingBudgetCardWrapper = ({ loading, children }: LoadingWrapperProps) =>
  loading ? <Skeleton width="40%" /> : <>{children}</>;

export default LoadingBudgetCardWrapper;
