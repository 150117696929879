import { useMemo } from "react";

import { CLOUD_FLOW_EXECUTION_STATUS } from "@doitintl/cmp-models";
import { capitalize, Chip, Link, TableCell, useTheme } from "@mui/material";
import { amber, yellow } from "@mui/material/colors";
import isEmail from "is-email";
import { DateTime } from "luxon";

import { type CloudflowExecutionSnap } from "../../../types/Cloudflow";
import { useEditLink } from "../CloudflowBrowser/utils";

type CloudflowHistoryRowProps = {
  row: CloudflowExecutionSnap;
  actions: {
    onNameClick: (link: string) => void;
  };
};

const CloudflowHistoryRow = ({ row, actions: { onNameClick } }: CloudflowHistoryRowProps) => {
  const theme = useTheme();

  const statusInfo = useMemo(
    () => ({
      [CLOUD_FLOW_EXECUTION_STATUS.COMPLETED]: { name: "Complete", color: theme.palette.success.main },
      [CLOUD_FLOW_EXECUTION_STATUS.ERROR]: { name: "Failed", color: theme.palette.error.main },
      [CLOUD_FLOW_EXECUTION_STATUS.REJECTED]: { name: "Rejected", color: theme.palette.error.main },
      [CLOUD_FLOW_EXECUTION_STATUS.RUNNING]: { name: "In progress", color: theme.palette.info.main },
      [CLOUD_FLOW_EXECUTION_STATUS.PENDING]: { name: "Pending", color: yellow[500] },
      [CLOUD_FLOW_EXECUTION_STATUS.AWAITING_APPROVAL]: { name: "Awaiting approval", color: amber[500] },
      [CLOUD_FLOW_EXECUTION_STATUS.APPROVAL_TIMED_OUT]: { name: "Approval timed out", color: theme.palette.error.main },
    }),
    [theme.palette.error.main, theme.palette.info.main, theme.palette.success.main]
  );

  const formattedEndTime = useMemo(() => {
    if (!row.data?.endTime) return "";
    const date = DateTime.fromJSDate(row.data.endTime.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
  }, [row.data?.endTime]);

  const linkDestination = useEditLink()(row.ref.id);

  const status = statusInfo[row.data.status];

  const triggeredByText = isEmail(row.data.triggeredBy)
    ? `Manually by ${row.data.triggeredBy}`
    : capitalize(row.data.triggeredBy);

  return (
    <>
      <TableCell>
        <Link
          sx={{ cursor: "pointer" }}
          color="inherit"
          onClick={() => {
            onNameClick(linkDestination);
          }}
          variant="body2"
        >
          {row.data.cloudflow?.data.name}
        </Link>
      </TableCell>
      <TableCell>
        <Chip
          label={status.name}
          size="small"
          sx={{
            backgroundColor: status.color,
            color: theme.palette.getContrastText(status.color),
          }}
        />
      </TableCell>
      <TableCell>{triggeredByText}</TableCell>
      <TableCell>{formattedEndTime}</TableCell>
    </>
  );
};

export default CloudflowHistoryRow;
