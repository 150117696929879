import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { DashboardModel, type DashboardModelCompaniesModelDomain } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import FilterList from "@mui/icons-material/FilterList";
import { Card, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import orderBy from "lodash/orderBy";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import { DateTime } from "luxon";
import type { Property } from "csstype";

import { useCustomerContext } from "../../Context/CustomerContext";
import { getColors, getTreeMapColors } from "../../Pages/CloudAnalytics/utilities";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetMenuWrapperContext } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/widgetMenuContext";
import { getDiffWeek } from "../../utils";
import { formatNumber } from "../../utils/common";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemProps } from "./types";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(2, 0, 0),
  },
  font: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

type ChartMap = { name: string; data: { x: string; y: number }[]; quantity: number }[];

type Props = WidgetItemProps & {
  data?: ChartMap;
  secondaryColor?: Property.BackgroundColor;
};

export const LicensesCardWithData = ({
  isCustomizeMode,
  fallbackComponent,
  secondaryColor,
  data,
  widgetHeight = 200,
}: Props) => {
  const chartRef = useRef<any>();
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [productVal, setProductVal] = useState(0);

  const [_deleteWidgetOperation, threeDotsDeleteMenu] = useContext(WidgetMenuWrapperContext);

  useEffect(() => {
    if (isCustomizeMode && chartRef.current) {
      chartRef.current.chart.reflow();
    }
  }, [chartRef, isCustomizeMode]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const subscriptionSelect = useCallback(
    (event) => {
      setProductVal(event.target.value);
      handleClose();
    },
    [handleClose]
  );

  const openSubscriptionMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  if (data && data.length === 0) {
    return fallbackComponent;
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card>
      <WidgetCardHeader
        title="Licensing Activity"
        subheader={data ? data[productVal].name : ""}
        action={
          <Stack direction="row">
            <IconButton onClick={openSubscriptionMenu} aria-label="Subscriptions Menu" size="large">
              <FilterList />
            </IconButton>
            <Menu
              id="subscription-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                dense: true,
                disablePadding: true,
              }}
            >
              {data?.map((item, index) => (
                <MenuItem key={item.name} value={index} onClick={subscriptionSelect} className={classes.menuItem}>
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
            {threeDotsDeleteMenu}
          </Stack>
        }
      />
      <CardContent
        sx={{
          height: widgetHeight,
        }}
        className={classes.cardContent}
      >
        {data && (
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={{
              colors: getColors(theme.palette.mode),
              xAxis: {
                categories: data[productVal]?.data.filter((item) => item.y > 0).map((item) => item.x),
                tickInterval: 15,
                labels: {
                  formatter(this: any) {
                    return DateTime.fromFormat(this.value, "yyyy-LL-dd").toFormat("LLL yy");
                  },
                  style: {
                    color: theme.palette.text.primary,
                  },
                },
              },
              yAxis: {
                title: null,
                labels: {
                  style: {
                    color: theme.palette.text.primary,
                  },
                },
                endOnTick: false,
              },
              title: {
                text: null,
              },
              subtitle: {
                text: null,
              },
              credits: false,
              chart: { type: "column", backgroundColor: "transparent", animation: false },
              plotOptions: {
                column: {
                  pointPadding: 0,
                  borderWidth: 0,
                  groupPadding: 0.0,
                },
                series: {
                  animation: false,
                },
              },
              tooltip: {
                formatter(this: any) {
                  return (
                    `<b>Period: ${DateTime.fromFormat(this.x, "yyyy-LL-dd").toFormat("dd LLLL yyyy")}</b><br/>` +
                    `Licenses: ${formatNumber(this.y)}`
                  );
                },
              },
              series: [
                {
                  showInLegend: false,
                  data: data[productVal].data.filter((item) => item.y > 0).map((item) => item.y),
                  color: secondaryColor,
                },
              ],
              exporting: {
                enabled: false,
              },
            }}
            containerProps={{ style: { height: "100%" } }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default function LicensesCard({ fallbackComponent, widgetHeight }: Omit<Props, "data">) {
  const { customer } = useCustomerContext();
  const [data, setData] = useState<ChartMap>();

  const theme = useTheme();

  useEffect(() => {
    getCollection(DashboardModel)
      .doc("licenseChart")
      .collection("companies")
      .doc(customer.id)
      .get()
      .then((dataRaw) => {
        const docData = dataRaw.data();
        if (!docData) {
          setData([]);
          return;
        }
        const domains = Object.keys(docData).filter((key) => key !== "info");
        let chartMap: { name: string; data: { x: string; y: number }[]; quantity: number }[] = [];
        domains.forEach((domain) => {
          const domainSkus = docData[domain] as Record<string, DashboardModelCompaniesModelDomain>;
          Object.entries(domainSkus).forEach((skuName) => {
            const dataArr: { x: string; y: number }[] = [];
            let countLic = 0;
            Object.entries(skuName[1].quantity).forEach((weekItem) => {
              const obj = {
                x: weekItem[0],
                y: weekItem[1],
              };
              countLic += weekItem[1];
              dataArr.push(obj);
            });
            const diff = getDiffWeek(dataArr[dataArr.length - 1].x);
            diff.forEach((element) => {
              dataArr.push({
                x: element,
                y: 0,
              });
            });
            const tmp = {
              name: `${skuName[1].skuName} (${domain[0]})`,
              data: dataArr,
              quantity: countLic,
            };
            if (countLic > 0) {
              chartMap.push(tmp);
              chartMap = reverse(sortBy(chartMap, ["quantity"]));
            }
          });
        });
        chartMap.forEach((asset) => {
          asset.data = orderBy(asset.data, ["x"], ["asc"]);
        });

        setData(chartMap);
      });
  }, [customer.id]);

  return (
    <LicensesCardWithData
      widgetHeight={widgetHeight}
      fallbackComponent={fallbackComponent}
      secondaryColor={alpha(getTreeMapColors(theme.palette.mode)[0], 0.8)}
      data={data}
    />
  );
}
