import { type FC } from "react";

import AssignedAgentIcon from "@mui/icons-material/AssignmentInd";
import SolvedRequestIcon from "@mui/icons-material/Check";
import EscalateRequestIcon from "@mui/icons-material/NotificationsActive";
import ReopenRequestIcon from "@mui/icons-material/Replay";
import ClosedRequestIcon from "@mui/icons-material/Star";
import CreatedRequestIcon from "@mui/icons-material/StarBorder";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

type TicketTimelineEvent = {
  title: string;
  created: string;
  type: number;
};

type CustomizedTimelineProps = {
  ticketTimelineData?: TicketTimelineEvent[];
};

const iconArr = [
  <CreatedRequestIcon key={0} />,
  <AssignedAgentIcon key={1} />,
  <SolvedRequestIcon key={2} />,
  <ReopenRequestIcon key={3} />,
  <ClosedRequestIcon key={4} />,
  <EscalateRequestIcon key={5} />,
];

const CustomizedTimeline: FC<CustomizedTimelineProps> = ({ ticketTimelineData }) => {
  if (!ticketTimelineData?.length) {
    return null;
  }

  return (
    <Timeline sx={{ align: "left", mb: "120px", mt: 1 }}>
      {ticketTimelineData.map((event, i) => (
        <TimelineItem
          key={`${event.title}-${event.created}`}
          sx={{ "&.MuiTimelineItem-missingOppositeContent": { "&::before": { flex: 0, padding: 0 } } }}
        >
          <TimelineSeparator>
            <TimelineDot variant="outlined" color={ticketTimelineData.length - 1 <= i ? "primary" : "inherit"}>
              {iconArr[event.type]}
            </TimelineDot>
            {ticketTimelineData.length - 1 > i && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={2} sx={{ py: "6px", px: "16px", width: "100%" }}>
              <Typography variant="subtitle2">{event.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {DateTime.fromISO(event.created).toLocaleString(DateTime.DATETIME_SHORT)}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomizedTimeline;
