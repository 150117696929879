import { useEffect, useState } from "react";

import { type MSTeamsTarget } from "@doitintl/cmp-models";
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Autocomplete, Button, Checkbox, Chip, MenuItem, Stack, TextField, Typography } from "@mui/material";

import { ConnectMSTeamsButton } from "./ConnectMSTeamsButton";
import { useMSTeamsChannels } from "./utils";

type Props = {
  updateChannels: (channels: MSTeamsTarget[]) => void;
  savedChannels: MSTeamsTarget[];
};
export const MSTeamsSelection = ({ updateChannels, savedChannels }: Props) => {
  const customerchannels = useMSTeamsChannels();

  const [channels, setChannels] = useState<MSTeamsTarget[]>(customerchannels);

  const checkBoxOutlineBlankIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkBoxIcon = <CheckBoxIcon fontSize="small" />;

  const OptionLabel = ({ option }: { option: MSTeamsTarget }) => (
    <Typography variant="body1">
      {option.channel?.team_name} {">"} {option.channel?.name}
    </Typography>
  );

  useEffect(() => {
    setChannels(savedChannels);
  }, [savedChannels]);

  return (
    <Stack spacing={4}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={3}
        options={customerchannels}
        isOptionEqualToValue={(option, value) => option.channelRef === value.channelRef}
        value={channels}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              size="small"
              label={<OptionLabel option={option} />}
              color="primary"
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} key={option.channel?.name}>
            <Checkbox icon={checkBoxOutlineBlankIcon} checkedIcon={checkBoxIcon} checked={selected} />
            <OptionLabel option={option} />
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select Microsoft Teams channels" fullWidth size="small" />
        )}
        onChange={(_, value: MSTeamsTarget[]) => {
          setChannels(value);
          updateChannels(value);
        }}
      />

      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight={500}>
          Can't find your Microsoft Teams channels?
        </Typography>
        <Typography variant="body1" color="text.secondary">
          To add additional channels to an already connected team, select the button below
        </Typography>
        <ConnectMSTeamsButton />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="body1" color="text.secondary">
          To connect a new team, install the DoiT app to the team by selecting the button below
        </Typography>
        <Button
          variant="outlined"
          sx={{ width: "fit-content" }}
          startIcon={<OpenInNewIcon />}
          onClick={() =>
            window.open("https://teams.microsoft.com/l/app/3a949400-9470-47c3-98d9-9c5ab5bd1c55", "_blank")
          }
        >
          Add to a new team
        </Button>
      </Stack>
    </Stack>
  );
};
