import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { type GcpRecommendation, IntegrationModel, type RecommenderModel } from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";
import NotificationsIcon from "@mui/icons-material/DirectionsOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";
import WarningIcon from "@mui/icons-material/Warning";
import { Card, Chip, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { deepPurple as purple, orange } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { ThemeModes } from "../../muiThemeTypes";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetMenuWrapperContext } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/widgetMenuContext";
import { exportCSVFile } from "../../utils/csv";
import { useFullScreen } from "../../utils/dialog";
import mixpanel from "../../utils/mixpanel";
import { useInterval } from "../../utils/useInterval";
import Hide from "../HideChildren/Hide";
import { useCloudConnect } from "../hooks/useCloudConnect";
import LoadingButton from "../LoadingButton";
import { useRowPerPage } from "./hooks/useRowPerPage";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemProps } from "./types";

const headers = {
  project: "Project",
  name: "Name",
  zone: "Zone",
  saving: "Impact",
  currentMachine: "Current Machine",
  recommendedMachine: "Recommended Machine",
};

function urlify(text?: string) {
  if (!text) {
    return;
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  if (!text.match(urlRegex)) {
    return;
  }

  return text;
}

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  changeMachineText: {
    marginBottom: 12,
    display: "flex",
  },
  circleIcon: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 22,
    height: 22,
    borderRadius: 11,
  },
  noServiceAccount: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  enableService: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chip: {
    color: "white",
  },
  serviceName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      maxWidth: 90,
    },
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  downloadCsv: {
    marginLeft: 3,
  },
}));

export type SelectedInstanceResponse = {
  status: "TERMINATED" | "RUNNING";
  name: string;
  machineType: string;
};

type RecommenderDialogProps = {
  open: boolean;
  selectedValue: WithFirebaseModel<GcpRecommendation>;
  selectedInstance?: SelectedInstanceResponse | null;
  onClose: (value: WithFirebaseModel<GcpRecommendation>) => void;
  onApply: () => Promise<void>;
  stopInstance: (isStop: boolean) => void;
  loadingChangeMachine: boolean;
  showSuccess: boolean;
  hasError: boolean;
};

const RecommenderDialog = ({
  onClose,
  selectedValue,
  open,
  onApply,
  selectedInstance,
  stopInstance,
  loadingChangeMachine,
  showSuccess,
  hasError,
}: RecommenderDialogProps) => {
  const classes = useStyles();
  const { isMobile: matches } = useFullScreen("sm");
  const [openError, setOpenError] = useState(false);
  const [loadingInstance, setLoadingInstance] = useState(false);

  useEffect(() => {
    setOpenError(false);
    setLoadingInstance(false);
  }, [open, selectedValue]);

  useEffect(() => {
    if (hasError) {
      setLoadingInstance(true);
    }
  }, [hasError]);

  useEffect(() => {
    if (selectedInstance) {
      if (selectedInstance.status === "TERMINATED" || selectedInstance.status === "RUNNING") {
        setLoadingInstance(false);
      } else {
        setLoadingInstance(true);
      }
    }
  }, [selectedInstance]);
  const handleClose = () => {
    onClose(selectedValue);
  };
  const onStopInstance = (isStop) => {
    setLoadingInstance(true);
    setOpenError(false);
    stopInstance(isStop);
  };
  const handleChangeMachineClick = async () => {
    setOpenError(false);
    if (selectedInstance?.status === "TERMINATED") {
      await onApply();
    } else {
      setOpenError(true);
    }
  };
  if (!selectedValue) {
    return null;
  }
  return (
    <Dialog fullScreen={matches} fullWidth onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title">Recommendation</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialogText">
          Save {selectedValue.saving} per month by changing the machine type from{" "}
          {selectedValue.currentInstance.substring(selectedValue.currentInstance.lastIndexOf("/") + 1)} to{" "}
          {selectedValue.replaceResource.substring(selectedValue.replaceResource.lastIndexOf("/") + 1)}.
        </DialogContentText>
        <div className={classes.changeMachineText}>
          <div>
            <Typography style={{ fontWeight: 500 }} variant="caption">
              Current machine type
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {selectedValue.currentInstance.substring(selectedValue.currentInstance.lastIndexOf("/") + 1)}
            </Typography>
          </div>
          <div style={{ width: 52 }} />
          <div>
            <Typography style={{ fontWeight: 500 }} variant="caption">
              New machine type
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {selectedValue.replaceResource.substring(selectedValue.replaceResource.lastIndexOf("/") + 1)}
            </Typography>
          </div>
        </div>
        <Alert variant="outlined" severity="warning">
          To change the machine type, we need to restart this instance. Stopping and starting the machine may cause
          resources, such as Local SSDs and ephemeral IP addresses, to be lost.
        </Alert>
        <div style={{ height: 14 }} />
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell padding="none" size="small">
                Instance
              </TableCell>
              <TableCell padding="none" size="small">
                Type
              </TableCell>
              <TableCell padding="none" size="small">
                Status
              </TableCell>
              <TableCell padding="none" align="center" size="small" />
            </TableRow>
          </TableHead>

          {selectedInstance ? (
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell padding="none" size="small">
                  {selectedInstance.name}
                </TableCell>
                <TableCell padding="none" size="small">
                  {selectedInstance.machineType.substring(selectedInstance.machineType.lastIndexOf("/") + 1)}
                </TableCell>
                <TableCell padding="none" size="small">
                  {selectedInstance.status.charAt(0) + selectedInstance.status.toLowerCase().slice(1)}
                </TableCell>
                <TableCell padding="none" size="small">
                  <div style={{ minWidth: 34, display: "flex", justifyContent: "center" }}>
                    {!loadingInstance ? (
                      <Link
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          onStopInstance(selectedInstance.status === "RUNNING");
                        }}
                      >
                        {selectedInstance.status === "TERMINATED" && <Typography variant="body1">Start</Typography>}
                        {selectedInstance.status === "RUNNING" && (
                          <div>
                            {/* <StopIcon style={{ fontSize: 14 }} /> */}
                            <Typography variant="body1">Stop</Typography>
                          </div>
                        )}
                      </Link>
                    ) : (
                      <CircularProgress disableShrink={true} size={20} thickness={4.5} />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell padding="none" size="small" />
                <TableCell padding="none" size="small" />
                <TableCell padding="none" size="small" />
                <TableCell padding="none" size="small">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress disableShrink={true} size={20} thickness={4.5} />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <br />

        <Collapse in={openError || hasError}>
          <Alert severity="error">
            {hasError ?? "You have to stop the instance before applying the recommendation."}
          </Alert>
        </Collapse>
      </DialogContent>
      <Divider />
      <DialogActions>
        {selectedInstance && (
          <Collapse style={{ width: "100%" }} in={showSuccess}>
            <Alert
              severity="success"
              action={
                <Button onClick={handleClose} color="primary">
                  Done
                </Button>
              }
            >
              Done changing machine to{" "}
              {selectedInstance.machineType.substring(selectedInstance.machineType.lastIndexOf("/") + 1)}, starting
              instance...
            </Alert>
          </Collapse>
        )}
        {!showSuccess && (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={handleChangeMachineClick}
              disabled={loadingChangeMachine}
              loading={loadingChangeMachine}
              mixpanelEventId="dashboard.recommender-card.apply"
            >
              Apply
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

type Data = {
  data: WithFirebaseModel<RecommenderModel>;
  id: string;
  needToUpdateData: boolean;
  isServiceAccountExist: boolean;
};

export default function RecommenderCard({ fallbackComponent, widgetHeight = 200 }: WidgetItemProps) {
  const classes = useStyles();
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const theme = useTheme();
  const [rows, setRows] = useState<WithFirebaseModel<GcpRecommendation>[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState<WithFirebaseModel<GcpRecommendation> | null>();
  const [selectedInstanceResponse, setSelectedInstanceResponse] = useState<SelectedInstanceResponse | null>();
  const [loadingChangeMachine, setLoadingChangeMachine] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isServiceEnabled, setIsServiceEnabled] = useState(true);
  const [serviceAccountStatus, setServiceAccountStatus] = useState<number>();
  const [loadingRecommender, setLoadingRecommender] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalSaving, setTotalSaving] = useState(0);
  const [cloudConnect] = useCloudConnect(customer);
  const [data, setData] = useState<Data | null>();
  const { clearInterval: clearIntervalStatus, setInterval: setIntervalStatus } = useInterval();
  const { clearInterval: clearIntervalMachineType, setInterval: setIntervalMachineType } = useInterval();
  const { emptyRows, page, rowsPerPage, handleChangePage } = useRowPerPage({
    totalRows: rows.length,
    widgetHeight,
  });

  const [_deleteWidgetOperation, threeDotsDeleteMenu] = useContext(WidgetMenuWrapperContext);

  useEffect(
    () =>
      getCollection(IntegrationModel)
        .doc("google-cloud")
        .collection("recommender")
        .doc(customer.id)
        .onSnapshot(async (querySnapshot) => {
          const data = querySnapshot.asModelData();
          if (!data) {
            setData(null);
            return;
          }
          setData({
            data,
            id: querySnapshot.id,
            needToUpdateData: true,
            isServiceAccountExist: true,
          });
        }),
    [customer.id]
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.data.isServiceEnabled && data.data.errorMsg) {
      setIsServiceEnabled(false);
      return;
    } else {
      setIsServiceEnabled(true);
    }

    const rows: WithFirebaseModel<GcpRecommendation>[] = [];
    let total = 0;
    if (data.data.recommendations) {
      data.data.recommendations.forEach((rec) => {
        if (rec.state === "ACTIVE") {
          rows.push(rec);
          total += parseInt(rec.saving.substring(1));
        }
      });
    }
    setTotalSaving(total);
    setRows(rows);
  }, [data]);

  useEffect(() => {
    clearIntervalStatus();
    if (!open) {
      setShowSuccess(false);
    } else {
      setError(null);
    }
  }, [clearIntervalStatus, open]);

  useEffect(() => {
    cloudConnect?.forEach((platform) => {
      if (platform.cloudPlatform === "google-cloud") {
        setServiceAccountStatus(platform?.categoriesStatus?.["rightsizing-recommendation"] ?? 4);
      }
    });
  }, [cloudConnect]);

  const exportRecommendationsToCSV = () => {
    if (rows.length === 0) {
      return;
    }

    const rowData = rows.map((row) => ({
      project: row.projectId,
      name: row.instance.substring(row.instance.lastIndexOf("/") + 1),
      zone: row.zone,
      saving: row.saving,
      currentMachine: row.currentInstance.substring(row.currentInstance.lastIndexOf("/") + 1),
      recommendedMachine: row.replaceResource.substring(row.replaceResource.lastIndexOf("/") + 1),
    }));

    const fileName = `recommendations-${DateTime.now().toFormat("dd LLL yyyy")}`;
    exportCSVFile(headers, rowData, fileName);
  };

  const updateRecommenderEntitles = useCallback(async () => {
    setLoadingRecommender(true);
    try {
      await api.request({
        method: "POST",
        url: `/v1/customers/${customer.id}/recommender/update`,
      });
    } finally {
      setLoadingRecommender(false);
    }
  }, [api, customer.id]);

  const instanceApi = useCallback(
    async (
      selectedInstance: WithFirebaseModel<GcpRecommendation>,
      action: "get-instance-status" | "stop-instance" | "start-instance" | "change-type"
    ) => {
      try {
        const res = await api.request<SelectedInstanceResponse>({
          method: "POST",
          url: `/v1/customers/${customer.id}/recommender/${action}`,
          data: {
            projectId: selectedInstance.projectId,
            zone: selectedInstance.zone,
            name: selectedInstance.name,
            etag: selectedInstance.etag,
            machineType: selectedInstance.replaceResource,
            instance: selectedInstance.instance.substring(selectedInstance.instance.lastIndexOf("/") + 1),
          },
        });
        const resData = res?.data;
        switch (action) {
          case "get-instance-status":
            setSelectedInstanceResponse(resData);
            if (resData.status === "TERMINATED" || resData.status === "RUNNING") {
              clearIntervalStatus();
            }

            if (
              resData.machineType &&
              !showSuccess &&
              resData.status !== "RUNNING" &&
              resData.machineType.substring(resData.machineType.lastIndexOf("/") + 1) ===
                selectedInstance.replaceResource.substring(selectedInstance.replaceResource.lastIndexOf("/") + 1)
            ) {
              // Done replace machine
              setLoadingChangeMachine(false);
              clearIntervalMachineType();
              clearIntervalStatus();
              setShowSuccess(true);
              await updateRecommenderEntitles();
              setTimeout(() => {
                instanceApi(selectedInstance, "get-instance-status");
              }, 4000);
            }

            break;
          case "stop-instance":
          case "start-instance":
            setIntervalStatus(() => {
              instanceApi(selectedInstance, "get-instance-status");
            }, 3000);
            break;
          case "change-type":
            setIntervalMachineType(() => {
              instanceApi(selectedInstance, "get-instance-status");
            }, 3000);
            break;

          default:
            break;
        }
      } catch (error: any) {
        setLoadingChangeMachine(false);
        setLoadingRecommender(false);
        setError(error.response.data.error);
      }
    },
    [
      api,
      customer.id,
      showSuccess,
      clearIntervalStatus,
      clearIntervalMachineType,
      setIntervalMachineType,
      setIntervalStatus,
      updateRecommenderEntitles,
    ]
  );
  const handleClickOpen = async (selected: WithFirebaseModel<GcpRecommendation>) => {
    mixpanel.track("dashboards.account.rightsizing-widget");
    setSelectedRecommendation(selected);
    setOpen(true);
    await instanceApi(selected, "get-instance-status");
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedInstanceResponse(null);
    setSelectedRecommendation(null);
  };

  const onApplyChange = async (selectedInstance: WithFirebaseModel<GcpRecommendation>) => {
    setLoadingChangeMachine(true);
    await instanceApi(selectedInstance, "change-type");
  };

  const noAccess = useMemo(
    () => (
      <div className={classes.noServiceAccount}>
        <WarningIcon style={{ color: orange[400] }} fontSize="large" />
        <Typography variant="subtitle1" color="textPrimary">
          No Access
        </Typography>
        <Typography style={{ maxWidth: "85%", textAlign: "center" }} variant="body2" color="textSecondary">
          To use this feature, please connect your account/s using Google Cloud on the{" "}
          <Link href={`/customers/${customer.id}/settings`}>Settings page</Link>
        </Typography>
      </div>
    ),
    [classes.noServiceAccount, customer.id]
  );

  const waitForServiceEnabled = useMemo(() => {
    if (serviceAccountStatus && serviceAccountStatus >= 2) {
      return noAccess;
    }

    if (!data?.data || loadingRecommender) {
      // no document
      return (
        <div style={{ justifyContent: "center", height: "80%" }} className={classes.enableService}>
          <CircularProgress disableShrink={true} size={40} thickness={4.5} />
        </div>
      );
    }
    return (
      <div style={{ textAlign: "center" }} className={classes.enableService}>
        <Typography>You need to enable the Service Usage API</Typography>
        <div style={{ display: "flex" }}>
          <Link target="_blank" variant="body2" href={urlify(data.data.errorMsg)}>
            Click Here
          </Link>
          <Typography variant="body2">&nbsp;to enable the service</Typography>
        </div>
        <LoadingButton
          loading={false}
          disabled={false}
          variant="contained"
          onClick={() => updateRecommenderEntitles()}
          color="primary"
          style={{ marginTop: 15 }}
          mixpanelEventId="dashboard.recommender-card.enable"
        >
          I have Enabled the Service
        </LoadingButton>
      </div>
    );
  }, [classes, data?.data, loadingRecommender, noAccess, serviceAccountStatus, updateRecommenderEntitles]);

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (data === null) {
    return fallbackComponent;
  }

  return (
    <Card>
      <WidgetCardHeader
        title={
          <Stack direction="row" alignItems="center">
            Google Cloud Recommendations
            {
              <Tooltip arrow placement="left" title="Total impact per month">
                <Chip
                  className={classes.chip}
                  size="small"
                  label={`$${totalSaving}/mo`}
                  sx={{ backgroundColor: purple[500], ml: 1 }}
                />
              </Tooltip>
            }
          </Stack>
        }
        subheader="Suggestions for optimizing your usage of resources"
        action={
          <Stack direction="row">
            {rows.length > 0 && (
              <Tooltip arrow placement="left" title="Download CSV">
                <IconButton onClick={exportRecommendationsToCSV} sx={{ ml: 1 }} size="small">
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            )}
            {threeDotsDeleteMenu}
          </Stack>
        }
      />
      <CardContent sx={{ height: widgetHeight }}>
        {!data.isServiceAccountExist || (serviceAccountStatus && serviceAccountStatus >= 2) ? (
          noAccess
        ) : (
          <>
            {isServiceEnabled && data.data ? (
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell padding="none" size="small">
                      Project
                    </TableCell>
                    <Hide mdDown>
                      <TableCell padding="none" size="small">
                        Name
                      </TableCell>
                    </Hide>
                    <TableCell padding="none" size="small">
                      Zone
                    </TableCell>
                    <TableCell padding="none" align="center" size="small">
                      Impact
                    </TableCell>
                    <Hide mdDown>
                      <TableCell padding="none" align="right" size="small" />
                    </Hide>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loadingRecommender &&
                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.name} hover className={classes.tableRow}>
                        <TableCell className={classes.serviceName} padding="none" align="left" size="small">
                          {row.projectId}
                        </TableCell>
                        <Hide mdDown>
                          <TableCell className={classes.serviceName} padding="none" align="left" size="small">
                            {row.instance.substring(row.instance.lastIndexOf("/") + 1)}
                          </TableCell>
                        </Hide>
                        <TableCell padding="none" size="small">
                          {row.zone}
                        </TableCell>

                        <TableCell padding="none" align="center" size="small">
                          <Link
                            style={{
                              color: theme.palette.mode === ThemeModes.DARK ? purple[200] : purple[500],
                              cursor: "pointer",
                            }}
                            onClick={() => handleClickOpen(row)}
                          >
                            Save {row.saving}/{row.duration === "2592000s" ? "mo" : row.duration}
                          </Link>
                        </TableCell>
                        <Hide mdDown>
                          <TableCell padding="none" align="center" size="small">
                            <IconButton
                              size="small"
                              onClick={() => handleClickOpen(row)}
                              data-cy={`${row.instance}-recommendation-action`}
                            >
                              <NotificationsIcon
                                style={{
                                  color: theme.palette.mode === ThemeModes.DARK ? purple[200] : purple[500],
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </Hide>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && rows.length !== 0 && !loadingRecommender && (
                    <TableRow style={{ height: 32 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                  {(rows.length === 0 || loadingRecommender) && (
                    <TableRow style={{ height: 32 * emptyRows }}>
                      <TableCell style={{ textAlign: "center" }} colSpan={5}>
                        {loadingRecommender ? (
                          <CircularProgress disableShrink={true} size={20} thickness={4.5} />
                        ) : (
                          "No Recommendation"
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      className={classes.tablePagination}
                      rowsPerPageOptions={[rowsPerPage]}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                        size: "small",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                        size: "small",
                      }}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              waitForServiceEnabled
            )}
          </>
        )}
      </CardContent>
      {selectedRecommendation && (
        <RecommenderDialog
          onApply={() => onApplyChange(selectedRecommendation)}
          open={open}
          onClose={handleClose}
          selectedValue={selectedRecommendation}
          selectedInstance={selectedInstanceResponse}
          stopInstance={(isStop) => instanceApi(selectedRecommendation, isStop ? "stop-instance" : "start-instance")}
          loadingChangeMachine={loadingChangeMachine}
          showSuccess={showSuccess}
          hasError={!!error}
        />
      )}
    </Card>
  );
}
