import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type NotificationSettings, type UserPersonalNotificationModel } from "@doitintl/cmp-models";
import { Stack, TableCell, Typography } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { formatToLocaleTimestamp } from "../../../utils/common";
import { type LabelWithRef } from "../labels/types";
import { type AllocationWRefRowItem } from "./AllocationBrowser";
import { useAllocationsThreeDotsMenu } from "./hooks";

const { attributions: attributionIds } = cloudAnalytics;

export type AllocationRowData = {
  row: AllocationWRefRowItem;
  labels?: LabelWithRef[];
  handleShare: () => void;
  handleSettings: () => void;
  userEmailNotification?: UserPersonalNotificationModel;
  updateUserEmailNotification: (selectedNotifications: NotificationSettings) => Promise<void>;
};

export const AllocationRow = ({
  row,
  labels,
  handleShare,
  handleSettings,
  userEmailNotification,
  updateUserEmailNotification,
}: AllocationRowData) => {
  const routeMatchURL = useRouteMatchURL();
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  const threeDotsMenu = useAllocationsThreeDotsMenu({
    row,
    handleShare,
    handleSettings,
    userEmailNotification,
    updateUserEmailNotification,
  });

  return (
    <CellsWrapper>
      <TableCell style={{ maxWidth: "50vw", overflowWrap: "break-word" }}>
        <Stack spacing={1}>
          <Stack>
            <Typography
              component={RouterLink}
              to={{
                pathname: `${routeMatchURL}/${row.ref.id}`,
                state: {
                  prevPage: routeMatchURL, // or any other value you want to pass as state
                },
              }}
              color="inherit"
              variant="body2"
              data-cy={attributionIds.browser.attributionName}
            >
              {row.data.name}
            </Typography>
            {!!row.data.description && (
              <Typography
                color="textSecondary"
                variant="body2"
                sx={{
                  whiteSpace: "noWrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "calc(100vw/4)",
                }}
                title={row.data.description}
              >
                {row.data.description}
              </Typography>
            )}
          </Stack>
          <LabelList sx={{ maxWidth: "25vw" }} labels={rowLabels} />
        </Stack>
      </TableCell>
      <TableCell>
        <Typography color="inherit" variant="body2">
          {row.type}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="inherit" variant="body2">
          {row.owner}
        </Typography>
      </TableCell>
      <Hide mdDown>
        <TableCell>{formatToLocaleTimestamp(row.data.timeModified)}</TableCell>
      </Hide>
      <TableCell padding="none">{threeDotsMenu}</TableCell>
    </CellsWrapper>
  );
};
