import { type ForecastMode } from "@doitintl/cmp-models";
import { type Theme } from "@mui/material";

import { type ForecastItem } from "../../../ReportData";
import { type ChartSeriesRecord } from "../../useChartsRenderer";
import { FORECAST_MODE_GROUPING, FORECAST_MODE_TOTALS } from "../const";
import { analyticsToEChartsTypesMapping, isStackChart } from "../ECharts";
import { type EChartType } from "../types";
import getErrorBarSeries from "./errorBarSeries";

type Props = {
  type: EChartType;
  forecasts: ForecastItem[];
  series: ChartSeriesRecord[];
  forecastMode?: ForecastMode;
  chartSettings: Record<string, string | number | undefined | boolean | number[]>;
  theme: Theme;
};

export const getTotalForecastBarStyle = (theme: Theme) =>
  theme.palette.mode === "light"
    ? { color: "rgba(0, 0, 0, 0.07)", borderColor: "rgba(0, 0, 0, 0.38)" }
    : { color: "#999", borderColor: "rgba(255, 255, 255)" };

export const getForecastSeries = ({ type, forecasts, forecastMode, chartSettings, series, theme }: Props) => {
  if (!forecasts?.length || type === "tree-map") {
    return [];
  }

  const chartType = analyticsToEChartsTypesMapping[type];

  if (forecastMode === FORECAST_MODE_GROUPING) {
    const forecastSeries: any[] = [];
    const forecastIdValues: (number | null)[] = [];
    let forecastStartIndex: number | null = null; // index of a first value in forecast row which is not null

    forecasts?.forEach(({ value, id }, index) => {
      if (!id) {
        return;
      }

      if (forecastStartIndex === null && value !== null) {
        forecastStartIndex = index;
      }

      // in case of overlapping current period in stacked type of charts subtract value of actual data from forecast
      let currentPeriodValue = 0;
      if (forecastStartIndex !== null && forecastIdValues.length === forecastStartIndex && isStackChart(type)) {
        currentPeriodValue = series.find((s) => s.name === id)?.data?.[forecastStartIndex] ?? 0;
      }

      const forecastValueWithoutCurrent =
        value !== null && value > currentPeriodValue ? value - currentPeriodValue : null;

      forecastIdValues.push(forecastValueWithoutCurrent);
      if (index === forecasts.length - 1 || id !== forecasts[index + 1].id) {
        forecastSeries.push({
          type: chartType,
          ...chartSettings,
          itemStyle: {
            opacity: 0.5,
            emphasis: {
              borderWidth: 4,
            },
          },
          data: [...forecastIdValues],
          name: id,
        });

        forecastIdValues.splice(0, forecastIdValues.length);
      }
    });

    return forecastSeries;
  }

  if (forecastMode === FORECAST_MODE_TOTALS || !forecastMode) {
    const errorBarSeries = getErrorBarSeries(theme);
    const errorBarData: number[][] = [];
    const values: number[] = [];

    let forecastStartIndex: number | null = null;

    forecasts?.forEach((forecastRow, index) => {
      let value = forecastRow?.value;
      const { yhatLower, yhatUpper } = forecastRow || {};
      // in case of overlapping current period in stacked type of charts subtract value of actual data from forecast
      if (forecastStartIndex === null && value !== null && isStackChart(type)) {
        forecastStartIndex = index;
        const currentPeriodSeriesSum = series.reduce((a, b) => a + (b.data[index] || 0), 0);
        value = value > currentPeriodSeriesSum ? value - currentPeriodSeriesSum : 0;
      }

      values.push(value);

      if (value) {
        errorBarData.push([index, yhatLower ?? 0, yhatUpper ?? 0]);
      }
    });

    const forecastSeries = [
      {
        type: chartType,
        ...chartSettings,
        itemStyle: getTotalForecastBarStyle(theme),
        data: values,
        name: "Total Forecast",
      },
    ] as any;

    if (type === "stacked-column" && errorBarData.length) {
      forecastSeries.push({ ...errorBarSeries, data: errorBarData } as any);
    }

    return forecastSeries;
  }
};
