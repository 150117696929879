import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { CHANGE_TRIGGER_OPTIONS } from "../../types";

export const getTriggerByOption = (option: CHANGE_TRIGGER_OPTIONS) => {
  switch (option) {
    case CHANGE_TRIGGER_OPTIONS.SCHEDULED:
      return CloudFlowNodeType.TRIGGER;
    case CHANGE_TRIGGER_OPTIONS.MANUAL:
      return CloudFlowNodeType.MANUAL_TRIGGER;
    default:
      return undefined;
  }
};

export const getOptionByTrigger = (trigger: CloudFlowNodeType) => {
  switch (trigger) {
    case CloudFlowNodeType.TRIGGER:
      return CHANGE_TRIGGER_OPTIONS.SCHEDULED;
    case CloudFlowNodeType.MANUAL_TRIGGER:
      return CHANGE_TRIGGER_OPTIONS.MANUAL;
    default:
      return CHANGE_TRIGGER_OPTIONS.NONE;
  }
};
