import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Chip, Grid, Link, Popover, Stack } from "@mui/material";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { defaultCurrencySymbol } from "../../../constants/common";
import { asyncConvertCurrencyTo } from "../../../Context/AsyncCurrencyConverterContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { formatCurrency } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { getAnomalyInactiveDate, getCostOfAnomaly, getElementTimestamp } from "../AlertTools";
import { type AlertData, getAnomalyDuration, getAnomalyStartTime, levelChipColor, statusChipColor } from "../utils";
import AnomalyAcknowledgementForm from "./AnomalyAcknowledgementForm";
import InfoBlock from "./AnomalyInfoBlock";
import InfoRow from "./AnomalyInfoRow";
import EditAcknowledgementDialog from "./EditAcknowledgementDialog";

const levelText = ["No alert", "Information", "Warning", "Critical"];

export const formatDate = (date: DateTime, isMobile: boolean) =>
  date.toUTC().toLocaleString(isMobile ? DateTime.DATE_SHORT : DateTime.DATETIME_MED);

const AnomalyDetailsContent = ({ alertData }: { alertData: AlertData }) => {
  const { isMobile } = useFullScreen("md", { noSsr: true }); // noSsr flag needed to get exact response on first render
  const [acknowledgeFormOpen, setAcknowledgeFormOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { customer, assets } = useCustomerContext();
  const isInPresentationMode = isCustomerInPresentationMode(customer);
  const isEntitledAcknowledgement = useIsFeatureEntitled("governance:costAnomalies:acknowledgement");
  const customerCurrency = customer.settings?.currency ?? defaultCurrencySymbol;
  const manageButtonRef = useRef({ clientWidth: 150 });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "manage-anomaly-popover" : undefined;
  const { customerFeedback } = alertData;
  const acknowledged = !!customerFeedback;

  const acknowledgementAllowed = useMemo(
    () => isEntitledAcknowledgement || isInPresentationMode,
    [isEntitledAcknowledgement, isInPresentationMode]
  );

  const closeManagePopover = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const [anomalyCostInCurrency, setAnomalyCostInCurrency] = useState<string>();

  useEffect(() => {
    const getAnomalyCostInCurrency = async () => {
      const costOfAnomaly = alertData ? getCostOfAnomaly(alertData) : 0;

      const converted = await asyncConvertCurrencyTo(
        costOfAnomaly,
        new Date(),
        defaultCurrencySymbol,
        customerCurrency
      );

      setAnomalyCostInCurrency(formatCurrency(converted, customerCurrency));
    };
    getAnomalyCostInCurrency();
  }, [alertData, customerCurrency]);

  const billingAccountName: string | null = useMemo(() => {
    if (!assets || !["google-cloud", "microsoft-azure"].includes(alertData.metadata.platform)) {
      return null;
    }

    const billingAccount = Object.values(assets)
      .flat()
      .find(
        (asset) => alertData.metadata.billing_account_id && asset.id.includes(alertData.metadata.billing_account_id)
      );

    if (!billingAccount) {
      return null;
    }
    if (alertData.metadata.platform === "google-cloud") {
      return billingAccount.data.properties.displayName;
    }
    if (alertData.metadata.platform === "microsoft-azure") {
      return billingAccount.data.properties.subscription.displayName;
    }
  }, [alertData?.metadata.billing_account_id, alertData?.metadata.platform, assets]);

  const anomalyStartDate = getAnomalyStartTime(alertData.metadata, { zone: "America/Los_Angeles" });
  const anomalyInactiveDate = getAnomalyInactiveDate(alertData);

  return (
    <Grid container spacing={2} direction="row" mb={5}>
      <InfoBlock title="Status">
        <InfoRow
          field="Status"
          value={
            <Chip size="small" label={capitalize(alertData.status ?? "INACTIVE")} sx={statusChipColor(alertData)} />
          }
        />
        <InfoRow field="Start date" value={formatDate(anomalyStartDate, isMobile)} />
        <InfoRow field="End date" value={anomalyInactiveDate ? formatDate(anomalyInactiveDate, isMobile) : "-"} />
        <InfoRow
          field="Duration"
          value={getAnomalyDuration({
            status: alertData.status,
            inactiveDate: anomalyInactiveDate,
            _ts: getElementTimestamp(alertData),
          })}
        />
      </InfoBlock>

      <InfoBlock title="Cost impact">
        <InfoRow field="Cost impact" value={anomalyCostInCurrency} />
        <InfoRow field="Cost impact type" value="Increase" />
        <InfoRow
          field="Severity"
          value={
            <Chip
              size="small"
              label={
                !alertData.metadata.severity || alertData.metadata.severity === 1
                  ? "Info"
                  : levelText[alertData.metadata.severity]
              }
              sx={{ ...levelChipColor[alertData.metadata.severity ?? 1] }}
            />
          }
        />
      </InfoBlock>

      <InfoBlock title="Context">
        <InfoRow
          field="Account name"
          value={
            <>
              {billingAccountName && (
                <>
                  ({billingAccountName}) <br />
                </>
              )}
              {alertData.metadata.billing_account_id}
            </>
          }
        />
        <InfoRow field="Project/Account" value={alertData.metadata.project_id ?? "All"} />
        <InfoRow field="Service" value={alertData.metadata.service_name} />
        <InfoRow field="SKU" value={alertData.metadata.sku_name ?? "All"} />
        {/* <InfoRow field="Source" value="Billing data" /> */}
      </InfoBlock>

      <InfoBlock title="Next steps">
        <InfoRow
          field="Acknowledged"
          value={acknowledged && acknowledgementAllowed ? "Yes" : "No"}
          onClick={
            acknowledged && acknowledgementAllowed
              ? () => {
                  setEditDialogOpen(true);
                }
              : undefined
          }
        />

        {!isMobile && acknowledgementAllowed && (
          <Box sx={{ flexGrow: 1, alignContent: "flex-end", width: "100%" }} ref={manageButtonRef}>
            <Button
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(event.currentTarget);
              }}
              sx={{ width: "100%", cursor: "pointer" }}
              variant="contained"
              data-cy="manage-anomaly-button"
              disabled={acknowledged}
            >
              Manage anomaly <ExpandMoreIcon />
            </Button>
            <Popover
              data-cy="manage-anomaly-popover"
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={closeManagePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: -10,
                horizontal: "center",
              }}
            >
              <Stack width={manageButtonRef?.current?.clientWidth}>
                <Box px={2} py={2}>
                  <Link
                    data-cy="open-acknowledgement-button"
                    component="button"
                    color="inherit"
                    underline="none"
                    onClick={() => {
                      setAcknowledgeFormOpen(true);
                    }}
                  >
                    Acknowledge anomaly
                  </Link>
                </Box>
              </Stack>
            </Popover>
          </Box>
        )}

        {editDialogOpen && customerFeedback && (
          <EditAcknowledgementDialog
            customerFeedback={customerFeedback}
            onConfirm={() => {
              setAcknowledgeFormOpen(true);
              setEditDialogOpen(false);
              closeManagePopover();
            }}
            onCancel={() => {
              setEditDialogOpen(false);
              closeManagePopover();
            }}
          />
        )}

        {acknowledgeFormOpen && (
          <AnomalyAcknowledgementForm
            onClose={() => {
              setAcknowledgeFormOpen(false);
              closeManagePopover();
            }}
            customerFeedback={customerFeedback}
          />
        )}
      </InfoBlock>
    </Grid>
  );
};

export default AnomalyDetailsContent;
