import { useEffect } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import { VerifyEntitiesInvoice } from "../../Context/verifyEntitiesInvoice";
import mixpanel from "../../utils/mixpanel";
import { CloudConnect } from "./CloudConnect";
import CloudIncidentsSection from "./CloudIncidentsSection";
import Dashboards from "./Dashboards";
import { KeyContactsSection } from "./KeyContactsSection/KeyContactsSection";
import QuickLinksSection from "./QuickLinksSection";
import { Reports } from "./Reports";
import { WelcomeSection } from "./WelcomeSection/WelcomeSection";

export const HomePage = () => {
  useEffect(() => {
    mixpanel.track(`home.page.view`);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ pb: 8, mt: 4 }}>
      <DoitConsoleTitle pageName="Home" />
      <Stack rowGap={6}>
        <WelcomeSection />
        <QuickLinksSection />
        <Dashboards />
        <Reports />
        <CloudIncidentsSection />
        <CloudConnect />
        <KeyContactsSection />
        <VerifyEntitiesInvoice />
      </Stack>
    </Container>
  );
};
