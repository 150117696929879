import { Box, Checkbox, DialogContent, Divider, FormControlLabel, Stack, TextField, Typography } from "@mui/material";

import EntitySelect from "../../../Components/Selects/EntitySelect";
import { essentialsEscalatePrice } from "../../../constants";
import mixpanel from "../../../utils/mixpanel";

type Props = {
  billingProfile?: string;
  hasAgreedToCosts: boolean;
  isAuthorizedToMakeDecisions: boolean;
  hasAgreedToTerms: boolean;
  setBillingProfile: (profile: string) => void;
  setCostChecked: (checked: boolean) => void;
  setAuthorizedChecked: (checked: boolean) => void;
  setAgreedToTerms: (checked: boolean) => void;
};
export default function TicketEscalation({
  setAgreedToTerms,
  setAuthorizedChecked,
  setBillingProfile,
  setCostChecked,
  billingProfile,
  hasAgreedToCosts,
  hasAgreedToTerms,
  isAuthorizedToMakeDecisions,
}: Props) {
  return (
    <Box>
      <DialogContent>
        <Stack gap={1}>
          <Typography variant="h3">DoiT International Services Agreement - Single ticket</Typography>
          <Typography variant="body1" color="text.secondary">
            As an Essentials tier customer, a one-time payment of US${essentialsEscalatePrice} is required to escalate
            to one of our human experts
          </Typography>

          <TextField
            value="For the avoidance of doubt, upon Customer’s express prior request, Customer can opt to (i) escalate a ticket or (ii) raise a ticket to DoiT solve a specific and technical support issue. Such a ticket will be charged with a single fee of US$750 per ticket (billed on a monthly basis and subject to the then-current DCI applicable terms and payment terms and/or the executed Contract)."
            InputProps={{
              readOnly: true,
            }}
            multiline
            rows={4}
            variant="outlined"
            sx={{
              maxHeight: "235px",
              "& .MuiInputBase-root": {
                height: "100%",
              },
              "& .MuiInputBase-input": {
                typography: "body2",
                color: "text.secondary",
                overflowY: "auto",
                height: "100%",
              },
            }}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Stack>
          <div>
            <EntitySelect
              sx={{
                maxWidth: "300px",
                width: "100%",
              }}
              label="Select billing profile"
              margin="dense"
              value={billingProfile}
              onChange={(e) => {
                setBillingProfile(e.target.value);
              }}
            />
          </div>

          <Box paddingLeft={1} paddingTop={1}>
            <FormControlLabel
              label={`I understand that this single ticket escalation will cost US$${essentialsEscalatePrice}`}
              control={
                <Checkbox
                  data-cy="hasAgreedToCosts"
                  checked={hasAgreedToCosts}
                  onChange={(e) => {
                    setCostChecked(e.target.checked);
                    mixpanel.track("ava.essentials.escalate.understanding-checkbox", {
                      flow: "ava.essentials.open-ticket",
                    });
                  }}
                />
              }
            />
            <FormControlLabel
              label={`I am authorized to make financial decisions and sign this agreement`}
              control={
                <Checkbox
                  data-cy="isAuthorizedToMakeDecisions"
                  checked={isAuthorizedToMakeDecisions}
                  onChange={(e) => {
                    setAuthorizedChecked(e.target.checked);
                    mixpanel.track("ava.essentials.escalate.authorized-checkbox", {
                      flow: "ava.essentials.open-ticket",
                    });
                  }}
                />
              }
            />
            <FormControlLabel
              label={`I agree to the terms of the agreement`}
              control={
                <Checkbox
                  data-cy="hasAgreedToTerms"
                  checked={hasAgreedToTerms}
                  onChange={(e) => {
                    setAgreedToTerms(e.target.checked);
                    mixpanel.track("ava.essentials.escalate.agreement-checkbox", {
                      flow: "ava.essentials.open-ticket",
                    });
                  }}
                />
              }
            />
          </Box>
        </Stack>
      </DialogContent>
    </Box>
  );
}
