import { useCallback, useEffect, useRef, useState } from "react";

import BackIcon from "@mui/icons-material/ArrowBackRounded";
import NotesIcon from "@mui/icons-material/Notes";
import { Box, Chip, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

import { globalText, reportText } from "../../assets/texts";
import { templateLibraryTxt } from "../../assets/texts/CloudAnalytics";
import { useHotkeyContext } from "../../Context/HotkeyContext";
import { useFullScreen } from "../../utils/dialog";
import DescriptionDialog from "./DescriptionDialog";

const defaultTitleLength = 64;
const defaultDescriptionLength = 1000;

const styles = {
  titleContainer: {
    alignItems: "center",
    flexWrap: { xs: "nowrap", md: "wrap" },
    minWidth: 0,
    "&:hover": {
      ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "general.outlineBorder",
      },
      "#description-icon": {
        visibility: "visible",
      },
    },
    "&:has(.MuiOutlinedInput-root.Mui-focused) #description-icon": {
      visibility: "visible",
    },
  },
  titleWrap: {
    maxWidth: { xs: 1, md: "calc((100% - 64px)/2)" },
    width: { xs: 1, md: "auto" },
    mr: { sm: 1, md: 0 },
  },
  titleInput: {
    maxWidth: "100%",
    minWidth: 150,
    width: { xs: 1, md: "auto" },

    ".MuiOutlinedInput-root": {
      fontWeight: 500,
      "&:not(.Mui-disabled)": {
        paddingRight: "10px",
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "general.outlineBorder",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary.main",
      },
    },
  },
  inputText: {
    position: "absolute",
    opacity: 0,
    zIndex: "-100",
    whiteSpace: "pre",
    maxWidth: "100%",
  },
  descriptionWrap: {
    maxWidth: "calc((100% - 64px)/2.75)",
    boxSizing: "content-box",
    "&.MuiGrid-item": {
      pl: { sm: 1, lg: 3 },
    },
  },
  descriptionText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  templateChip: {
    backgroundColor: "#9575CD",
    color: "white",
    height: 20,
  },
};

export type TitleProps = {
  onChange: (values: { title?: string; description?: string }) => void;
  onBlur?: () => void;
  values: { title: string; description: string };
  handleBack: () => void;
  placeholder: string;
  disabled?: boolean;
  maxTitleLength?: number;
  maxDescriptionLength?: number;
  titleTransformation?: (title: string) => string;
  showTemplateChip?: boolean;
};

const Title = ({
  onChange,
  onBlur = () => {},
  values,
  handleBack,
  placeholder,
  disabled = false,
  maxTitleLength = defaultTitleLength,
  maxDescriptionLength = defaultDescriptionLength,
  titleTransformation,
  showTemplateChip,
}: TitleProps) => {
  const [title, setTitle] = useState(values.title);
  const [description, setDescription] = useState(values.description);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState(1);
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();
  const { isMobile } = useFullScreen();
  const inputTextRef = useRef<HTMLElement>();

  useEffect(() => {
    setTitle(values.title);
  }, [values.title]);

  useEffect(() => {
    setDescription(values.description);
  }, [values.description]);

  useEffect(() => {
    const offsetWidth = inputTextRef.current?.offsetWidth;
    setInputWidth(!offsetWidth ? 5 : offsetWidth);
  }, [title, inputTextRef.current?.offsetWidth]);

  const calculateMinWidth = (width: number) => (width <= 150 ? 150 : Math.ceil((width - 150) / 30) * 30 + 150);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      if (name === "title") {
        const transformedVal = titleTransformation ? titleTransformation(value) : value;
        setTitle(transformedVal);
        onChange({ title: transformedVal });
      } else if (name === "description") {
        setDialogOpen(false);
        setHotKeysEnabled();
        setDescription(value);
        onChange({ description: value });
      }
    },
    [onChange, setHotKeysEnabled, titleTransformation]
  );

  const handleOnBlur = () => {
    setHotKeysEnabled();
    onBlur();
  };

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
    setHotKeysDisabled();
  }, [setHotKeysDisabled]);

  const handleCloseDialog = useCallback(() => {
    setHotKeysEnabled();
    setDialogOpen(false);
  }, [setHotKeysEnabled]);

  return (
    <>
      <Grid container sx={styles.titleContainer}>
        <Grid item>
          <IconButton sx={{ p: 0.5 }} aria-label="Back" size="large" onClick={handleBack} data-cy="back-icon">
            <BackIcon fontSize="medium" color="action" />
          </IconButton>
        </Grid>
        <Grid item sx={styles.titleWrap}>
          <Tooltip title={disabled ? reportText.ONLY_OWNER_TITLE : null}>
            <TextField
              name="title"
              variant="outlined"
              value={title}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleOnBlur}
              onFocus={setHotKeysDisabled}
              disabled={disabled}
              inputProps={{ "data-cy": "title-input", maxLength: maxTitleLength }}
              sx={{
                ...styles.titleInput,
                pl: 0.75,
                pr: 1.25,
                ".MuiOutlinedInput-input": {
                  pl: 1.25,
                  width: { xs: 1, md: inputWidth },
                  minWidth: calculateMinWidth(inputWidth),
                },
              }}
              InputProps={{
                endAdornment: (
                  <Box component="span" ref={inputTextRef} sx={styles.inputText}>
                    {title ? title : placeholder}
                  </Box>
                ),
              }}
            />
          </Tooltip>
        </Grid>
        {showTemplateChip && (
          <Grid item>
            <Chip sx={styles.templateChip} label={templateLibraryTxt.TEMPLATE} data-cy="template-chip" />
          </Grid>
        )}
        {!isMobile && (
          <Grid item sx={styles.descriptionWrap}>
            <Typography variant="body1" color="textSecondary" sx={styles.descriptionText}>
              {description}
            </Typography>
          </Grid>
        )}
        {!disabled && !isMobile && (
          <Grid item id="description-icon" sx={{ visibility: "hidden" }}>
            <Tooltip title={description ? globalText.UPDATE_DESCRIPTION : globalText.ADD_DESCRIPTION}>
              <IconButton onClick={handleOpenDialog} size="large" data-cy="description-icon">
                <NotesIcon fontSize="medium" color="action" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <DescriptionDialog
        open={dialogOpen}
        description={description}
        onClose={handleCloseDialog}
        onConfirm={handleChange}
        maxLength={maxDescriptionLength}
      />
    </>
  );
};

export default Title;
