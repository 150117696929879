import { createContext, type ReactNode, useContext, useMemo } from "react";

import noop from "lodash/noop";

import { AvaMessageHandlersProvider } from "./AvaMessageHandlersProvider";

type ContextProps = {
  fetcher: (data: any) => Promise<Response>;
};

const defaultValue: ContextProps = {
  fetcher: noop as any,
};

const avaContext = createContext(defaultValue);

export const AvaContextProvider = ({
  children,
  fetcher,
}: {
  children: ReactNode;
  fetcher: (data: any) => Promise<Response>;
}) => {
  const value = useMemo(() => ({ fetcher }), [fetcher]);

  return (
    <AvaMessageHandlersProvider>
      <avaContext.Provider value={value}>{children}</avaContext.Provider>
    </AvaMessageHandlersProvider>
  );
};
export const useAvaContext = () => useContext(avaContext);
