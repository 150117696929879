import { type Dispatch, type FC, useEffect } from "react";

import { useFormikContext } from "formik";

export const FormChangesListener: FC<{
  onValuesChange?: (parameters: any) => void;
  onValidityChange?: Dispatch<boolean>;
}> = ({ onValuesChange, onValidityChange }) => {
  const { dirty, isValid, values } = useFormikContext();

  useEffect(() => {
    dirty && onValuesChange?.(values);
  }, [dirty, values, onValuesChange]);

  useEffect(() => {
    onValidityChange?.(isValid);
  }, [isValid, onValidityChange]);

  return null;
};
