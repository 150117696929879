import "./full-screen.css";

import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { type ForecastMode } from "@doitintl/cmp-models";
import { ClickAwayListener, MenuItem, MenuList, Paper, useTheme } from "@mui/material";
import ReactECharts, { type EChartsInstance } from "echarts-for-react";
import capitalize from "lodash/capitalize";
import sum from "lodash/sum";
import throttle from "lodash/throttle";

import { getBudgetChartTooltip, getYExtendedMax } from "../../../../Pages/CloudAnalytics/budgets/utils";
import { type ForecastItem } from "../../../../Pages/CloudAnalytics/ReportData";
import { type ChartSeriesRecord } from "../useChartsRenderer";
import { type EChartType, type FormatterFunc } from "./types";
import { useColors } from "./useColors";
import { useSumSeries } from "./useSumSeries";
import { findClosestIndex, isBigDataSet, typeSupportsClosesIndexSearch } from "./utils/echarts";
import { getForecastSeries } from "./utils/forecastSeries";
import { getLegendConfig, shouldLegendWrap } from "./utils/legend";

const SPLIT_NUMBER_WIDGET = 4;
const SPLIT_NUMBER_NON_WIDGET = 9;

const stackedChartTypes: EChartType[] = ["stacked-column", "stacked-bar", "stacked-area"];

export const isStackChart = (type: EChartType) => stackedChartTypes.includes(type);

type Props = {
  type: EChartType;
  categories: string[];
  series: ChartSeriesRecord[];
  valueFormatter: FormatterFunc;
  categoryFormatter: (value: string) => string;
  logScale: boolean;
  widgetView: boolean;
  isCustomMode?: boolean;
  forecastMode?: ForecastMode;
  forecasts?: ForecastItem[] | null;
};

const style = { height: "100%" };

export const analyticsToEChartsTypesMapping: Record<Props["type"], string> = {
  "stacked-area": "line",
  "stacked-column": "bar",
  "line-spline": "line",
  "area-spline": "line",
  "tree-map": "treemap",
  column: "bar",
  bar: "bar",
  line: "line",
  area: "line",
  "stacked-bar": "bar",
};

const areaStyle = { opacity: 0.5 };

const extraTypeConfiguration: Record<Props["type"], any> = {
  "stacked-area": { areaStyle },
  "stacked-column": {},
  "stacked-bar": {},
  "line-spline": {},
  "area-spline": { areaStyle },
  area: { areaStyle },
  "tree-map": {
    breadcrumb: {
      show: false,
    },
  },
  column: {},
  bar: {},
  line: {},
};

export const ECharts = forwardRef(
  (
    {
      categories,
      series,
      type,
      valueFormatter,
      categoryFormatter,
      logScale,
      widgetView,
      forecastMode,
      forecasts,
      isCustomMode,
    }: Props,
    ref
  ) => {
    const supportLogarithmicScale = type === "line" || type === "line-spline" || type === "area-spline";
    const stack = isStackChart(type);

    const echartsRef = useRef<EChartsInstance>(null);
    const hasUpdatedYAxisRef = useRef<boolean>(true);
    const [withEmphasis, setWithEmphasis] = useState(!isBigDataSet(series));
    const currentSeriesIndexRef = useRef<number>(-1);
    const highlightedSeriesIndexRef = useRef<number>(-1);

    const shiftKeyPressed = useRef<boolean>(false);

    const theme = useTheme();

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "Shift") {
          shiftKeyPressed.current = true;
        }
      };

      const handleKeyUp = (event) => {
        if (event.key === "Shift") {
          shiftKeyPressed.current = false;
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      };
    }, []);

    const [menuVisible, setMenuVisible] = useState(false);
    const [logarithmic, setLogarithmic] = useState(logScale);

    const handleMenuClose = () => {
      setMenuVisible(false);
    };

    useEffect(() => {
      currentSeriesIndexRef.current = -1;
      highlightedSeriesIndexRef.current = -1;
    }, [type]);

    const handle = useFullScreenHandle();
    const handleFullScreenClick = useCallback(() => {
      if (handle.active) {
        handle.exit();
      } else {
        handle.enter();
      }
      handleMenuClose();
    }, [handle]);

    const handleLogarithmicScaleClick = useCallback(() => {
      setLogarithmic((value) => !value);
      handleMenuClose();
    }, []);

    const formatterByType = useCallback(
      (type: "category" | "value" | "log", value: number) => {
        if (type === "category") {
          return categoryFormatter(value.toString());
        } else {
          return valueFormatter(value, true);
        }
      },
      [categoryFormatter, valueFormatter]
    );

    const { colors, fontColor, toolboxColor, gridLineColor, pageIconColor, backgroundColor } = useColors(
      series.length,
      stack
    );

    const [seriesNamesToSkipForSum, setSeriesNamesToSkipForSum] = useState<Record<string, boolean>>({});
    const chartSettings = useMemo(
      () => ({
        smooth: type === "line-spline" || type === "area-spline",
        barWidth: type === "stacked-column" && !widgetView && categories.length < 25 ? 50 : undefined,
        silent: type === "line" || type === "line-spline",
        barCategoryGap: categories.length < 7 ? undefined : "15%",
        symbolSize: type === "line" ? 6 : undefined,
        stack: stack ? "stack" : undefined,
        clip: true,
        barMinHeight: 1,
        symbol: "square",
        itemStyle: {
          emphasis: {
            borderWidth: 4,
          },
        },
        lineStyle: {
          width: 2,
          emphasis: {
            width: 3,
          },
        },
        emphasis: {
          disabled: !withEmphasis,
          focus: "series",
        },
        tooltip: {
          valueFormatter: (value) => valueFormatter(value, false),
        },
        universalTransition: true,
        ...extraTypeConfiguration[type],
      }),
      [categories.length, stack, type, valueFormatter, widgetView, withEmphasis]
    );
    const forecastSeries = useMemo(
      () =>
        getForecastSeries({
          type,
          forecasts: forecasts || [],
          series,
          forecastMode,
          chartSettings,
          theme,
        }),
      [type, forecasts, series, chartSettings, forecastMode, theme]
    );

    const sumSeries = useSumSeries(
      type,
      categories,
      forecastMode === "grouping" ? [...series, ...forecastSeries] : series,
      seriesNamesToSkipForSum,
      widgetView,
      valueFormatter,
      fontColor
    );

    const seriesMapped = useMemo(() => {
      if (type === "tree-map") {
        return [
          {
            type: "treemap",
            data: series.map((s) => ({
              name: s.name,
              value: sum(s.data),
            })),
            itemStyle: {
              gapWidth: 2,
            },
            roam: false,
            nodeClick: "none",
            width: "97%",
            height: "97%",
            breadcrumb: {
              show: false,
            },
            label: {
              formatter: (params) => `${params.name} \n ${valueFormatter(params.value, true)}`, // Display both name and value
            },
          },
        ];
      }

      const mappedSeries = series.map((s) => {
        const seriesItem: any = {
          type: analyticsToEChartsTypesMapping[type],
          ...chartSettings,
          data: s.data.map((value) => value),
          name: s.name,
        };

        if (s.color) seriesItem.color = s.color;
        if (s.areaStyle) seriesItem.areaStyle = s.areaStyle;
        if (s.symbol) seriesItem.symbol = s.symbol;
        if (s.symbolSize) seriesItem.symbolSize = s.symbolSize;
        if (s.itemStyle) seriesItem.itemStyle = s.itemStyle;
        if (s.lineStyle) seriesItem.lineStyle = s.lineStyle;
        if (s.markPoint) seriesItem.markPoint = s.markPoint;

        return seriesItem;
      });

      if (isStackChart(type)) {
        return mappedSeries.reverse();
      }
      return mappedSeries;
    }, [type, series, valueFormatter, chartSettings]);

    const allSeries: ChartSeriesRecord[] = useMemo(() => {
      if (forecastMode === "grouping") {
        const all = [...sumSeries];
        seriesMapped.forEach((s) => {
          all.push(s);

          const foundForecastSeries = forecastSeries.find((fs) => fs.name === s.name);
          // adding forecast series with the same ID right after series with actual report data to show them together in current (overlapped) period
          all.push(foundForecastSeries);
        });
        return all;
      }

      return [...sumSeries, ...seriesMapped, ...forecastSeries];
    }, [forecastMode, forecastSeries, seriesMapped, sumSeries]);

    const option = useMemo(() => {
      const xAxesType = type === "bar" || type === "stacked-bar" ? "value" : "category";
      const yAxesType = type === "bar" || type === "stacked-bar" ? "category" : logarithmic ? "log" : "value";
      const isLegendWrapped = shouldLegendWrap(isCustomMode ?? false, allSeries);

      let grid = {
        top: "9%",
        left: "0.5%",
        // "right" needs to be 1 because of some bug in echarts with rendering
        // if it's 0 it just cuts of one pixel of the chart
        right: "1",
        bottom: isLegendWrapped ? "16%" : "9%",
      };

      if (widgetView) {
        grid = {
          top: "5%",
          left: "2%",
          right: "28px",
          bottom: "7%",
        };
      }

      return {
        type,
        animation: !widgetView,
        backgroundColor,
        exportBackgroundColor: backgroundColor,
        color: colors,
        grid: {
          containLabel: true,
          ...grid,
        },
        toolbox: {
          show: type !== "tree-map" && !widgetView,
          top: 10,
          right: 30,
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: "Enable Zoom",
                back: "Zoom out",
              },
              filterMode: "weakFilter",
              iconStyle: {
                borderWidth: 1.3,
                borderColor: fontColor,
                emphasis: {
                  borderWidth: 1.5,
                  borderColor: toolboxColor,
                },
              },
            },
            myTool1: {
              show: true,
              selected: true,
              title: "Emphasis",
              iconStyle: {
                borderWidth: 1.1,
                borderColor: withEmphasis ? toolboxColor : fontColor,
                emphasis: {
                  borderWidth: 1.3,
                  borderColor: toolboxColor,
                },
              },
              icon: "path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891",
              onclick() {
                setWithEmphasis((value) => !value);
              },
            },
            myTool2: {
              show: true,
              title: "",
              iconStyle: {
                borderWidth: 1.1,
                borderColor: withEmphasis ? toolboxColor : fontColor,
                emphasis: {
                  borderWidth: 1.3,
                  borderColor: toolboxColor,
                },
              },
              icon: "path://M3 12h18M3 6h18M3 18h18",
              onclick: () => {
                setMenuVisible(!menuVisible);
              },
            },
          },
        },
        xAxis: {
          show: type !== "tree-map",
          type: xAxesType,
          data: xAxesType === "category" ? categories : undefined,
          z: xAxesType === "category" ? 10 : undefined,
          axisLabel: {
            formatter: (value) => formatterByType(xAxesType, value),
            color: fontColor,
            fontSize: widgetView ? 10 : undefined,
          },
          splitLine: {
            lineStyle: {
              color: gridLineColor,
            },
          },
        },
        yAxis: {
          type: yAxesType,
          inverse: type === "stacked-bar" || type === "bar",
          data: yAxesType === "category" ? categories : undefined,
          splitNumber: widgetView ? SPLIT_NUMBER_WIDGET : SPLIT_NUMBER_NON_WIDGET,
          z: yAxesType === "category" ? 10 : undefined,
          axisLabel: {
            formatter: (value) => formatterByType(yAxesType, value),
            color: fontColor,
            fontSize: widgetView ? 10 : undefined,
          },
          splitLine: {
            lineStyle: {
              color: gridLineColor,
            },
          },
        },
        tooltip:
          type === "tree-map"
            ? {
                trigger: "item",
                formatter: (params) => `${params.name}: ${valueFormatter(params.value, false)}`,
              }
            : {
                appendTo: document.body,
                transitionDuration: 0,
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
                textStyle: {
                  color: "rgba(0, 0, 0, 0.87)",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontFamily: "Roboto",
                  fontSize: "12",
                  lineHeight: "22",
                  padding: "16",
                },
                formatter: (params) => {
                  /* helper elements */
                  const getDataRow = (
                    field: string,
                    style: string,
                    value: number | string,
                    valueFormatter?: (value, short: boolean) => string
                  ) => `
                  <div style="${style}">
                    <div>${capitalize(field)}</div>
                    <div>${valueFormatter ? valueFormatter(value, false) : value}</div>
                  </div>`;

                  const horizontalSeparator =
                    '<div style="width:100%;height:1px;background-color:#C0C0C0;margin:9px 0 9px 0;"></div>';
                  const dataRowStyle =
                    "display:flex;justify-items:space-between;justify-content:space-between;width:100%;margin-top:6px;";
                  const forecastDataRowStyle = `${dataRowStyle}font-weight:700`;
                  /* end of helper elements */

                  if (
                    highlightedSeriesIndexRef.current < 0 &&
                    currentSeriesIndexRef.current < 0 &&
                    (type !== "line" || highlightedSeriesIndexRef.current !== -1)
                  ) {
                    return ""; // No series highlighted, don't show tooltip
                  }

                  let foundSeries = params.find(
                    (param) =>
                      param.seriesIndex === highlightedSeriesIndexRef.current ||
                      param.seriesIndex === currentSeriesIndexRef.current
                  );

                  if (!foundSeries) {
                    // to show forecast on line chart
                    if (type === "line" && params?.length === 1) {
                      foundSeries = params[0];
                    } else {
                      return "";
                    }
                  }

                  const rowValues = allSeries[foundSeries?.seriesIndex]?.data;
                  const isForecastSeries = !!forecasts?.length && rowValues?.[rowValues.length - 1] !== null;

                  // add current period
                  let tooltip = `<div style="font-size:14px;margin-bottom:9px;width:240px;">${foundSeries.axisValueLabel}</div>`;

                  // add tooltip value for actual data (non-forecast) value
                  if (!isForecastSeries && !isCustomMode) {
                    const value = ` <b>${valueFormatter(foundSeries.value, false)}</b>`;
                    tooltip += `<div>${foundSeries.marker}${foundSeries.seriesName}${value}</div>`;
                    return tooltip;
                  }

                  if (isCustomMode) {
                    tooltip = getBudgetChartTooltip(params, theme, valueFormatter);
                  }

                  if (!isCustomMode) {
                    if (forecastMode === "grouping") {
                      tooltip += `<div>${foundSeries.marker}${foundSeries.seriesName}</div>`;
                    }

                    let foundElement: ForecastItem | undefined;
                    let prevElementValue: number | undefined;

                    if (forecastMode === "totals" || !forecastMode) {
                      foundElement = forecasts?.[foundSeries.dataIndex];
                      prevElementValue = forecasts?.[foundSeries.dataIndex - 1]?.value;
                    } else if (forecastMode === "grouping") {
                      const foundGroupSeries = forecasts?.filter(({ id }) => id === foundSeries.seriesName);
                      foundElement = foundGroupSeries?.[foundSeries.dataIndex];
                      prevElementValue = foundGroupSeries?.[foundSeries.dataIndex - 1]?.value;
                    }

                    // add forecast value
                    tooltip += `${horizontalSeparator}${getDataRow("forecast", forecastDataRowStyle, foundElement?.value ?? 0, valueFormatter)}`;

                    // add lower and upper bounds
                    if (foundElement?.yhatLower !== null && foundElement?.yhatUpper !== null) {
                      tooltip += getDataRow("upper bound", dataRowStyle, foundElement?.yhatUpper ?? 0, valueFormatter);
                      tooltip += getDataRow("lower bound", dataRowStyle, foundElement?.yhatLower ?? 0, valueFormatter);
                    }

                    // calculate prev period diff
                    let prevPeriodDiff =
                      foundElement?.value && prevElementValue ? foundElement?.value / prevElementValue : 0;

                    if (!prevPeriodDiff) {
                      return tooltip;
                    }

                    let prevPeriodPercent = "";

                    if (prevPeriodDiff > 1) {
                      prevPeriodDiff -= 1;
                      prevPeriodPercent = `+${Math.round(prevPeriodDiff * 10000) / 100}%`;
                    } else {
                      prevPeriodDiff = 1 - prevPeriodDiff;
                      prevPeriodPercent = `-${Math.round(prevPeriodDiff * 10000) / 100}%`;
                    }

                    tooltip += horizontalSeparator;
                    tooltip += getDataRow("diff. from previous period", dataRowStyle, prevPeriodPercent);
                  }
                  return tooltip;
                },
              },
        legend: getLegendConfig(isCustomMode ?? false, fontColor, pageIconColor, widgetView, isLegendWrapped),
        series: allSeries,
      };
    }, [
      type,
      logarithmic,
      widgetView,
      categories,
      backgroundColor,
      colors,
      fontColor,
      toolboxColor,
      withEmphasis,
      gridLineColor,
      pageIconColor,
      allSeries,
      menuVisible,
      formatterByType,
      valueFormatter,
      forecasts,
      isCustomMode,
      theme,
      forecastMode,
    ]);

    const onEvents = useMemo(
      () => ({
        highlight: (event) => {
          if (typeSupportsClosesIndexSearch(type)) {
            // this will prevent a flickering in the highlight
            if (event.escapeConnect) {
              echartsRef.current?.dispatchAction({
                type: "highlight",
                seriesIndex: currentSeriesIndexRef.current,
              });
            }
          }
        },
        mouseover: (params) => {
          highlightedSeriesIndexRef.current = params.seriesIndex;
        },
        mouseout: () => {
          highlightedSeriesIndexRef.current = -1;
        },
        legendselectchanged: (params: { name: string; selected: Record<string, boolean> }) => {
          const selected = params.selected[params.name];

          if (shiftKeyPressed.current) {
            if (selected) {
              echartsRef.current?.dispatchAction({
                type: "legendAllSelect",
              });
              echartsRef.current?.dispatchAction({
                type: "legendUnSelect",
                name: params.name,
              });

              const newSelected = Object.fromEntries(
                Object.entries(params.selected).map(([name]) => [name, name !== params.name])
              );
              setSeriesNamesToSkipForSum(newSelected);
            } else {
              echartsRef.current?.dispatchAction({
                type: "legendAllSelect",
              });
              echartsRef.current?.dispatchAction({
                type: "legendInverseSelect",
              });
              echartsRef.current?.dispatchAction({
                type: "legendSelect",
                name: params.name,
              });

              const newSelected = Object.fromEntries(
                Object.entries(params.selected).map(([name]) => [name, name === params.name])
              );

              setSeriesNamesToSkipForSum(newSelected);
            }
          } else {
            setSeriesNamesToSkipForSum(params.selected);
          }
        },
        legendscroll: (params: { scrollDataIndex: number; legendId: string }) => {
          const updatedOption = echartsRef.current.getOption();
          // Synchronize the scroll between two legends
          updatedOption.legend[0].scrollDataIndex = params.scrollDataIndex;
          if (updatedOption.legend[1]) {
            updatedOption.legend[1].scrollDataIndex = params.scrollDataIndex;
          }
          echartsRef.current.setOption(updatedOption, { notMerge: true });
        },
      }),
      [type]
    );

    const onChartReady = useCallback(
      (instance) => {
        echartsRef.current = instance;

        // @ts-expect-error
        ref.current = instance;

        instance.chart = {
          exportChart: (params: { type: string; filename: string }) => {
            const img = new Image();
            img.src = instance.getDataURL({
              type: "png",
              pixelRatio: 2,
              backgroundColor: instance.getOption().exportBackgroundColor,
            });

            // Create a link element to trigger the download
            const link = document.createElement("a");
            link.href = img.src;
            link.download = params.filename;
            link.click();
          },
        };

        const findAndUpdateClosestIndex = (currentValue: number, values: number[]) => {
          const closestSeriesIndex = findClosestIndex(currentValue, values);
          if (closestSeriesIndex < 0) {
            return;
          }
          currentSeriesIndexRef.current = closestSeriesIndex;
          instance.dispatchAction({
            type: "highlight",
            seriesIndex: closestSeriesIndex,
          });
        };

        const throttleFindAndUpdateClosestIndex = throttle(findAndUpdateClosestIndex, 10, {
          leading: true,
          trailing: true,
        });

        let lastDataIndex = -1;

        instance.getZr().on("mousemove", (params) => {
          const type = instance.getOption().type as EChartType;
          if (!typeSupportsClosesIndexSearch(type)) {
            lastDataIndex = -1;
            return;
          }

          const pointInPixel = [params.offsetX, params.offsetY];
          if (instance.containPixel("grid", pointInPixel)) {
            const [dataIndex, currentValue] = instance.convertFromPixel("grid", pointInPixel);
            const values = seriesMapped.map((series) => series.data[dataIndex]);
            if (lastDataIndex === dataIndex) {
              throttleFindAndUpdateClosestIndex(currentValue, values);
            } else {
              lastDataIndex = dataIndex;
              findAndUpdateClosestIndex(currentValue, values);
            }
          }
        });

        if (isCustomMode) {
          instance.on("finished", () => {
            if (hasUpdatedYAxisRef.current) {
              instance.setOption({
                yAxis: {
                  max: getYExtendedMax(instance),
                },
              });

              hasUpdatedYAxisRef.current = false;
            }
          });
        }
      },
      [isCustomMode, ref, seriesMapped]
    );

    return (
      <FullScreen handle={handle}>
        <div style={{ height: "100%", position: "relative" }}>
          <ReactECharts
            onChartReady={onChartReady}
            onEvents={onEvents}
            style={style}
            option={option}
            notMerge={false}
            lazyUpdate={true}
            theme="theme_name"
          />

          {menuVisible && (
            <div
              style={{
                position: "absolute",
                top: "50px",
                right: "30px",
                zIndex: 1000,
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList onKeyDown={handleMenuClose}>
                    <MenuItem onClick={handleFullScreenClick}>
                      {handle.active ? "Exit fullscreen" : "View as fullscreen"}
                    </MenuItem>
                    <MenuItem disabled={!supportLogarithmicScale} onClick={handleLogarithmicScaleClick}>
                      {logarithmic ? "Liner Scale" : "Logarithmic Scale"}{" "}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </div>
          )}
        </div>
      </FullScreen>
    );
  }
);

ECharts.displayName = "ECharts";
