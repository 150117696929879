import { useCallback, useMemo } from "react";

import { type AttributionFilter, type CurrencyCode, type ExtendedMetric } from "@doitintl/cmp-models";
import { Grid, Link, Tooltip, Typography } from "@mui/material";

import { analyticsAlertText, globalText } from "../../../../assets/texts";
import { AlertsTxt, filterDialogText } from "../../../../assets/texts/CloudAnalytics";
import { CustomCard } from "../../../../Components/CustomCard/CustomCard";
import {
  type AttributionMetadataOption,
  type AttributionWRef,
  type MetadataOption,
  type MetricWSnap,
} from "../../../../types";
import { assetTypeName } from "../../../../utils/common";
import { useAnalyticsContext } from "../../CloudAnalyticsContext";
import { AnalyticsAlertCondition } from "../AnalyticsAlertCondition";
import { getScopeDescription } from "../shared";

type AnalyticsAlertsInfoPanelProps = {
  alertName: string;
  alertValue: number | "";
  calculatedMetric: MetricWSnap | null;
  condition: string;
  currency: CurrencyCode;
  currentStep: number;
  dimensionOptions: MetadataOption[];
  extendedMetricOptions: ExtendedMetric[];
  filter: AttributionFilter | null;
  metric: number;
  operator: string;
  scope: AttributionWRef[];
  evaluateByDimension: AttributionMetadataOption | null;
  selectedExtendedMetric: string | null;
  setOverrideStep: (step: number) => void;
  timeInterval: string;
};

export const AnalyticsAlertsInfoPanel = ({
  alertName,
  alertValue,
  calculatedMetric,
  condition,
  currency,
  currentStep,
  dimensionOptions,
  extendedMetricOptions,
  filter,
  metric,
  operator,
  scope,
  evaluateByDimension,
  selectedExtendedMetric,
  setOverrideStep,
  timeInterval,
}: AnalyticsAlertsInfoPanelProps) => {
  const { transforms } = useAnalyticsContext();

  const getDimensionLabelFromId = useCallback(
    (id: string) => {
      const dimension = dimensionOptions.find((d) => d.id === id);
      return dimension?.data?.label ?? id;
    },
    [dimensionOptions]
  );

  const scopeTooltips = useMemo(
    () =>
      scope.map((s, index) => (
        <Tooltip
          title={
            <>
              {s.data.filters?.map((f) => (
                <Typography variant="body2" key={f.id}>
                  <b>{getDimensionLabelFromId(f.id)}</b> {f.values ? "is " : "matches regexp "}
                  <b>{f.values?.map((value) => assetTypeName(value)).join(", ") ?? `"${f.regexp}"`}</b>
                </Typography>
              ))}
            </>
          }
          placement="top"
          arrow
          key={s.ref.id}
        >
          <Typography variant="body2" display="inline" component="span">
            <Typography
              variant="body2"
              display="inline"
              sx={{ textDecorationLine: "underline", textDecorationStyle: "dotted" }}
            >
              {s.data.name}
            </Typography>
            {index === scope.length - 1 ? "" : ","}{" "}
          </Typography>
        </Tooltip>
      )),
    [getDimensionLabelFromId, scope]
  );

  const dimensionsTooltip = useMemo(() => {
    const { label, values, desc } = getScopeDescription(filter ? [filter] : undefined, dimensionOptions, transforms);
    return (
      <Tooltip
        title={
          <Typography variant="body2">
            <b>{label}</b> {desc}
            <b>{values}</b>
          </Typography>
        }
        placement="top"
        arrow
      >
        <Typography
          variant="body2"
          display="inline"
          sx={{ textDecorationLine: "underline", textDecorationStyle: "dotted" }}
        >
          {label} (
          {filter?.regexp
            ? filterDialogText.USING_REGEX_LABEL
            : `${filter?.values?.length} ${AlertsTxt.VALUES_SELECTED}`}
          )
        </Typography>
      </Tooltip>
    );
  }, [filter, dimensionOptions, transforms]);

  const [conditionText] = AnalyticsAlertCondition({
    condition,
    currency,
    calculatedMetric,
    selectedExtendedMetric,
    extendedMetricOptions,
    metric,
    operator,
    timeInterval,
    alertValue,
    evaluateByDimension,
  });

  return (
    <Grid item xs={12}>
      <CustomCard style={{ mb: "2.5rem" }}>
        <Grid container item xs={12}>
          <Grid item container xs={8} sx={{ variant: "outlined" }}>
            <Typography variant="body2" sx={{ alignSelf: "right" }}>
              <b>{globalText.NAME}:</b> {alertName}
            </Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <Link
              color="inherit"
              variant="body2"
              underline="always"
              href="#"
              onClick={() => {
                setOverrideStep(0);
              }}
            >
              {analyticsAlertText.EDIT_ALERT_SCOPE}
            </Link>
          </Grid>
          <Grid item display="inline-flex" xs={9.3} sx={{ variant: "outlined" }}>
            <Typography variant="body2" sx={{ alignSelf: "right", mt: 0.2 }}>
              <b>{analyticsAlertText.STEPPER.SCOPE}:</b>
            </Typography>
            &nbsp;
            <Typography component="span">{scopeTooltips.length > 0 ? scopeTooltips : dimensionsTooltip}</Typography>
          </Grid>
          {currentStep === 2 && (
            <>
              <Grid item display="inline-flex" xs={9.3} sx={{ variant: "outlined" }}>
                <Typography variant="body2" sx={{ alignSelf: "right" }}>
                  <b>{analyticsAlertText.STEPPER.CONDITION}:</b>
                </Typography>
                &nbsp;
                {conditionText}
              </Grid>
              <Grid item container xs={2.7} justifyContent="flex-end">
                <Link
                  color="inherit"
                  variant="body2"
                  underline="always"
                  href="#"
                  onClick={() => {
                    setOverrideStep(1);
                  }}
                >
                  {analyticsAlertText.EDIT_CONDITIONS}
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </CustomCard>
    </Grid>
  );
};
