import { useState } from "react";
import { withStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionActions from "@mui/material/AccordionActions";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import find from "lodash/find";
import { transformPriority } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import SelectCategory from "./SelectCategory";
import { usePriorities } from "../consts";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;

const styles = (theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    marginRight: 7,
    width: 100,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
      width: 400,
    },
  },
  suggestionsContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
});

function TicketDetailsExpansionPanels(props) {
  const { classes } = props;
  const [expanded, setExpanded] = useState(null);
  const { fullScreen: xsDown } = useFullScreen("xs");
  const { t } = useTranslation("services");

  let selectedPlatform;
  if (props?.data?.categoryObject?.platforms) {
    selectedPlatform = find(props.platforms, {
      value: props.data.categoryObject.platforms[0].platform,
    });
  } else if (props?.data?.categoryObject?.platform) {
    selectedPlatform = find(props.platforms, {
      value: props.data.categoryObject.platform,
    });
  }

  const handleChangeCategory = (cat) => {
    if (cat) {
      props.handleChangeTicketCategory(cat);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangePriority = (event) => {
    props.handleChangeSeverity(event.target.value);
  };
  const changeCategoryInfo = (inputData) => {
    if (inputData) {
      if (!inputData.target) {
        props.changeCategoryInfo(inputData.identifier);
      } else {
        props.changeCategoryInfo(inputData.target.value);
      }
    }
  };
  const savePriorityComp = () => {
    props.savePriority(props.data.id);
    setExpanded(false);
  };
  const saveqCategoryComp = () => {
    props.saveCategory(props.data.id);
    setExpanded(false);
  };

  const priorities = usePriorities();

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {!xsDown && <Typography className={classes.heading}>{t("TicketDetailsExpansionPanels.severity")}</Typography>}
          <Typography className={classes.secondaryHeading} color="textSecondary">
            {transformPriority(props.data.priority, t)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="priority-select">{t("TicketDetailsExpansionPanels.severity")}</InputLabel>
            {props.data.categoryObject && (
              <Select
                disabled={false}
                value={props.data.priority}
                onChange={handleChangePriority}
                input={<Input name="selectedPriority" id="priority-select" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 6 + 16,
                    },
                  },
                }}
              >
                {priorities.map((p, i) => (
                  <MenuItem key={i} value={p.value}>
                    {transformPriority(p.value, t)}
                  </MenuItem>
                ))}
              </Select>
            )}
            <FormHelperText>{t("TicketDetailsExpansionPanels.severityHelper")}</FormHelperText>
          </FormControl>
        </AccordionDetails>
        <Divider />
        <AccordionActions className={classes.actions}>
          <Button variant="contained" size="small" color="primary" disabled={false} onClick={() => savePriorityComp()}>
            {t("TicketDetailsExpansionPanels.save")}
          </Button>
        </AccordionActions>
      </Accordion>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {!xsDown && <Typography className={classes.heading}>{t("TicketDetailsExpansionPanels.category")}</Typography>}
          <Typography className={classes.secondaryHeading} color="textSecondary">
            {props.data.fields && props.data?.categoryObject?.product ? props.data.categoryObject.product : "N/A"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.suggestionsContainer}>
            {props.data.categoryObject && (
              <SelectCategory
                helperText={
                  props.data.categoryObject.platforms ? props.data.categoryObject.platforms[0].details.helperText : ""
                }
                platforms={props.platforms}
                selectedPlatform={selectedPlatform}
                handleChangeCategory={handleChangeCategory}
                category={props.data.categoryObject}
                categories={props.categories}
                changeCategoryInfo={changeCategoryInfo}
                categoryInfo={props.data.categoryObject.categoryInfo}
                categoryInfoList={props.clientProductsProperties}
              />
            )}
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions className={classes.actions}>
          <Button variant="contained" size="small" color="primary" disabled={false} onClick={saveqCategoryComp}>
            {t("TicketDetailsExpansionPanels.save")}
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
}

export default withStyles(styles)(TicketDetailsExpansionPanels);
