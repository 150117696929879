import { type ReactNode } from "react";

import { Box, Card, CardContent, CardHeader, Typography, useTheme } from "@mui/material";

type MetadataCardProps = {
  title: string;
  children: ReactNode;
  error?: boolean;
  errorContent?: ReactNode;
};

export const MetadataCard = ({ title, children, error = false, errorContent }: MetadataCardProps) => {
  const theme = useTheme();

  const content = error
    ? errorContent || (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6" color="textSecondary">
            An error occurred while loading content.
          </Typography>
        </Box>
      )
    : children;

  return (
    <Card
      sx={{
        flex: 1,
        "& .MuiCardHeader-root": {
          paddingBottom: theme.spacing(1),
        },
      }}
    >
      <CardHeader title={title} />
      <CardContent>{content}</CardContent>
    </Card>
  );
};
