import { type AssetTypeGoogleCloud, type SaaSConsoleType } from "@doitintl/cmp-models";
import { type AxiosError, type AxiosInstance } from "axios";

import { isProduction } from "../../../constants";

export type TestConnectionResponse = true | false | "timeout";

export class SaaSConsoleApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    private readonly customerId: string,
    private readonly type: SaaSConsoleType
  ) {
    this.api = api;
    this.customerId = customerId;
    this.apiPrefix = `/v1/saas-console-${type.toLowerCase()}`;
    this.type = type;
  }

  private async post(url: string, reqBody: Record<string, unknown>) {
    try {
      const response = await this.api.post(url, reqBody, {
        timeout: 1000 * 60 * 4,
      });
      return response.data?.success ?? false;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status === 524) {
        return "timeout";
      }
      return false;
    }
  }

  public agreeContract(email: string, additionalParams?: Record<string, unknown>) {
    return this.api.post(`${this.apiPrefix}/contract-agreed/`, {
      ...additionalParams,
      customerId: this.customerId,
      email,
    });
  }

  public async testBillingConnectionGCP(
    billingAccountId: string,
    projectId: string,
    datasetId: string,
    dryRun = !isProduction
  ): Promise<Promise<TestConnectionResponse>> {
    return this.post(`${this.apiPrefix}/activate`, {
      billingAccountId,
      customerId: this.customerId,
      projectId,
      datasetId,
      dryRun,
    });
  }

  public initOnboarding() {
    return this.api.get(`${this.apiPrefix}/init-onboarding/${this.customerId}`);
  }

  public async initAWSOnboardingWithAccountId(accountId: string) {
    return this.api.post(`${this.apiPrefix}/init-onboarding/${this.customerId}`, { accountId });
  }

  public async refreshAWSCurPath(accountId: string) {
    return this.post(`${this.apiPrefix}/refresh`, { accountId, customerId: this.customerId });
  }

  public async activateAWSBilling(accountId: string, curPathIndex: number) {
    return this.post(`${this.apiPrefix}/activate`, { accountId, customerId: this.customerId, curPathIndex });
  }

  public async checkAccountExists(cloud: typeof AssetTypeGoogleCloud, accountId: string) {
    const response = await this.api.get(`/v1/assets/${cloud}/${accountId}`);
    return response?.data;
  }
}
