import { channel, eventChannel } from "redux-saga";
import { takeEvery, call, cancelled, put, fork, cancel, all, select, take, takeLatest } from "redux-saga/effects";
import { DateTime } from "luxon";
import find from "lodash/find";
import remove from "lodash/remove";
import sortBy from "lodash/sortBy";

import {
  AssetModel,
  AssetTypeGoogleCloudProject,
  CustomerModel,
  CustomerSecurityMode,
  UserModel,
  IntegrationModel,
  AppModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { ticketsCsvFileName, ticketFieldIds } from "../consts";

import {
  INIT_TICKETS_LIST,
  UPDATE_TICKETS_LIST,
  UPDATE_USERS_LIST,
  LOAD_TICKETS,
  LOAD_TICKET_DETAILS,
  DOWNLOAD_TICKETS,
  UPDATE_TICKET_DETAILS,
  UPDATE_TIMELINE_EVENTS,
  SAVE_PRIORITY,
  UPDATE_CATEGORIES,
  SAVE_CATEGORY,
  CHANGE_CATEGORY,
  UPDATE_CATEGORY_OBJECT,
  UPDATE_PRODUCT_PROPS,
  SAVE_NEW_TICKET,
  UPDATE_USER_DETAILS,
  DONE_LOADING,
  GET_COMMENTS,
  SUBSCRIBE_COMMENTS,
  UNSUBSCRIBE_COMMENTS,
  UPDATE_COMMENTS,
  SEND_COMMENT,
  SEND_ESCALATE,
  SEND_SURVEY,
  DONE_SURVEY,
  UPDATE_USER_CONTEXT,
  UPDATE_BUTTON_MODE,
  UPDATE_CUSTOMER_USERS,
  UPDATE_USER_PHONE,
  TOGGLE_ORGANIZATION_SHARING,
  UPDATE_ORGANIZATION,
  LOAD_TEMPLATE,
  UPDATE_TEMPLATE,
  CONCEDEFY_READ_ONLY_ACCESS_EMAIL,
  toggleDownloadCsvSnackbar,
  UPDATE_DEFAULT_EMAILS_LIST,
  GET_DEFAULT_EMAILS_LIST,
  SEND_NEW_DEFAULT_EMAILS,
  SEND_TAG_UPDATE,
  DONE_TAG_UPDATE,
} from "../Actions/TicketAction";

import { consoleErrorWithSentry } from "../../utils";
import { addOtherCategory, buildTopServices, isAvaTheAuthor, isAvaFeedbackRequestComment } from "../utils";
import { CHARITY_PREFIX, VENDOR_SCORE_PREFIX } from "../Components/SurveyDialog";
import zendeskTimeZones from "./zd_time_zones";

const baseUrl = "/support";
let api;
let customer;
const issuesChannel = channel();
let ajaxRequest = null;

export function setSagaAPI(apiObj) {
  api = apiObj;
}
export function setSagaCustomer(c) {
  customer = c;
  if (!ajaxRequest) {
    ajaxRequest = new AbortController();
  }
}
const getTimeZone = () => {
  const local = DateTime.local();
  let timeZone;
  // Try to find zone using iana name
  const zoneName = local.zoneName;
  if (zoneName) {
    timeZone = zendeskTimeZones.find((tz) => tz.ianaName === zoneName);
  }
  // If zone not found, select one using offset
  if (!timeZone?.value) {
    const offset = local.toISOTime().slice(12);
    timeZone = zendeskTimeZones.find((tz) => tz.offset === offset);
  }
  return timeZone;
};
const categoryField = (fields) =>
  find(fields, { id: ticketFieldIds.category }) || {
    value: null,
  };
const categoryInfo = (fields) =>
  find(fields, { id: ticketFieldIds.platformInfo }) || {
    value: "",
  };
const getCategoryObject = (fields, val) =>
  find(fields, { value: val }) || {
    value: null,
  };
const getPlatformObject = (fields, val) =>
  find(fields, { value: val }) || {
    value: null,
  };
const getProductsProperties = (obj) => {
  let productProperties;
  switch (obj.type) {
    case "amazon-web-services":
    case "amazon-web-services-standalone":
      productProperties = {
        asset: obj.type,
        identifier: obj.properties.friendlyName,
        accountId: obj.properties.accountId,
        label: `${obj.properties.name} (${obj.properties.accountId})`,
      };
      break;
    case "google-cloud-project":
    case "google-cloud-project-standalone":
      productProperties = { asset: obj.type, identifier: obj.properties.projectId };
      break;
    case "g-suite":
      productProperties = { asset: obj.type, identifier: obj.properties.customerDomain };
      break;
    case "office-365":
      productProperties = { asset: obj.type, identifier: obj.properties.customerDomain };
      break;
    case "microsoft-azure":
    case "microsoft-azure-standalone":
      productProperties = {
        asset: obj.type,
        identifier: obj.properties.subscription.subscriptionId,
      };
      break;

    default:
      productProperties = null;
      break;
  }
  return productProperties;
};

const downloadTicketsCsv = (data, filename) => {
  const element = document.createElement("a");
  element.setAttribute("href", `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getStore = (state) => state.tickets;
/** *************************** Subroutines ************************************/
function* changeCategory(action) {
  yield put({ type: UPDATE_CATEGORY_OBJECT, payload: action.payload });
}
export function* initTickets() {
  const store = yield select(getStore);

  if (store.contextUser.id && store.contextUser.id !== customer.id) {
    if (store.contextUser.id) {
      ajaxRequest.abort();
      ajaxRequest = new AbortController();
    }
    yield put({ type: INIT_TICKETS_LIST });
  }
}

export function* updatePhoneNumber(action) {
  const formData = new FormData();
  if (action?.phone) {
    formData.append("phone", action.phone);
  }
  if (action?.timeZone?.value) {
    formData.append("time_zone", action.timeZone.value);
  }
  if (Array.from(formData.keys()).length > 0) {
    yield api.request({
      method: "patch",
      url: `${baseUrl}/customers/${customer.id}/users`,
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
  }
}

export function* getUserDetails(action) {
  let assetsQuery = getCollection(AssetModel).where("customer", "==", customer.ref);
  if (customer.securityMode === CustomerSecurityMode.RESTRICTED) {
    assetsQuery = assetsQuery.where("type", "!=", AssetTypeGoogleCloudProject);
  }
  const [userDetails, clientProjects] = yield all([
    api.request({
      method: "get",
      url: `${baseUrl}/customers/${customer.id}/users`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }),
    assetsQuery.get(),
  ]);

  if (action.userId && !action.isDoitEmployee) {
    const userFS = yield getCollection(UserModel).doc(action.userId).get();
    const phone = userFS.get("phone")?.replace(/[^+\d]+/g, "");
    const timeZone = getTimeZone();

    if ((phone && !userDetails.data.user.phone) || timeZone?.value !== userDetails.data.user.time_zone) {
      yield updatePhoneNumber({ phone, timeZone });
    }
  }

  const clientProductsProperties = [];
  clientProjects.forEach((change) => {
    const data = change.asModelData();
    const res = getProductsProperties(data);
    if (res) {
      clientProductsProperties.push(res);
    }
  });

  yield all([
    put({
      type: UPDATE_USER_DETAILS,
      payload: userDetails.data.user,
      organization: userDetails.data.organization,
    }),
    put({ type: UPDATE_PRODUCT_PROPS, payload: clientProductsProperties }),
  ]);
}

export function* getDefaultEmailsList() {
  try {
    const defaultEmailsList = yield getCollection(CustomerModel)
      .doc(customer.id)
      .collection("defaultEmails")
      .doc("emailCC")
      .get();
    if (defaultEmailsList.asModelData() !== undefined) {
      const dataArr = Object.values(defaultEmailsList.asModelData());
      yield put({ type: UPDATE_DEFAULT_EMAILS_LIST, payload: dataArr });
    }
  } catch (error) {
    consoleErrorWithSentry(error);
  }
}

export function* getCustomerUsers() {
  const allUsers = yield getCollection(UserModel).where("customer.ref", "==", customer.ref).get();
  const arr = [];
  allUsers.forEach((user) => {
    arr.push(user.asModelData());
  });
  yield put({ type: UPDATE_CUSTOMER_USERS, payload: arr });
}

export function* loadTickets(action) {
  const quickLoad = action.payload;

  yield getUserDetails(action);
  yield initTickets();
  yield getCustomerUsers();
  yield getDefaultEmailsList();
  yield put({ type: UPDATE_USER_CONTEXT, payload: customer });
  try {
    const resp = yield api.get(`${baseUrl}/customers/${customer.id}/tickets?quickLoad=${quickLoad}`, {
      signal: ajaxRequest.signal,
    });

    if (!resp.data.tickets) {
      return;
    }
    const users = resp.data.users || [];
    const tickets = resp.data.tickets.map((ticket) => {
      const u = users.find((user) => ticket.assignee_id === user.id);
      ticket.assignee = u;
      return ticket;
    });
    yield put({ type: UPDATE_TICKETS_LIST, payload: tickets, loading: false });
    yield put({ type: UPDATE_USERS_LIST, payload: users });
    const [platforms, categories, sla, topServices] = yield all([
      getCollection(IntegrationModel).doc("zendesk").collection("ticketFields").doc("platforms").get(),
      getCollection(AppModel).doc("support").collection("services").where("blacklisted", "==", false).get(),
      api.get(`${baseUrl}/customers/${customer.id}/slas`, { signal: ajaxRequest.signal }),
      getCollection(AppModel).doc("support").collection("geckoStatistics").doc("top-services").get(),
    ]);

    const topService = topServices?.data();
    const productsWithPopularServices = buildTopServices(
      topService,
      categories.docs.map((category) => category.data())
    );
    const products = addOtherCategory(productsWithPopularServices);

    let tmpPlatforms = platforms.data().values;
    if (!tmpPlatforms) {
      return;
    }
    tmpPlatforms = tmpPlatforms.map((platform) => ({
      ...platform,
      details: getPlatformObject(platforms.data().values, platform.value),
    }));
    tmpPlatforms = sortBy(tmpPlatforms, ["order"]);

    yield all([
      put({
        type: UPDATE_CATEGORIES,
        payload: products,
        payloadPlatforms: tmpPlatforms,
        payloadSlas: sla.data,
      }),
    ]);
    if (quickLoad) {
      yield loadTickets({ type: action.type, payload: false });
    }
  } catch (error) {
    consoleErrorWithSentry(error);
  }
}

export function* loadTicketDetails(action) {
  let data;
  try {
    data = yield api.get(`${baseUrl}/customers/${customer.id}/tickets/${action.payload}/`, {
      signal: ajaxRequest.signal,
    });
  } catch (error) {
    yield put({
      type: UPDATE_TICKET_DETAILS,
      payload: {
        error: "ticket sharing disabeld",
      },
    });
    return;
  }
  const ticket = data.data.ticket;
  yield put({ type: UPDATE_TICKET_DETAILS, payload: ticket });
  let store = yield select(getStore);
  if (store.categories.length === 0) {
    yield loadTickets({ type: LOAD_TICKETS, payload: true });
    store = yield select(getStore);
  }
  if (action.payload === "new") {
    return;
  }

  const categoryObject = getCategoryObject(store.categories, categoryField(ticket.fields).value);
  if (categoryObject.platforms) {
    categoryObject.platforms = categoryObject.platforms.map((platform) => ({
      platform,
      details: getPlatformObject(store.platforms, platform),
    }));
  }
  categoryObject.categoryInfo = categoryInfo(ticket.fields).value;
  categoryObject.product = ticket.fields.find((field) => field.id === ticketFieldIds.product)?.value;
  categoryObject.platform = ticket.fields.find((field) => field.id === ticketFieldIds.platform)?.value;
  ticket.categoryObject = categoryObject;
  yield put({ type: UPDATE_TICKET_DETAILS, payload: ticket });

  const [timelineEvents] = yield all([api.get(`${baseUrl}/customers/${customer.id}/tickets/${action.payload}/events`)]);
  yield all([put({ type: UPDATE_TIMELINE_EVENTS, payload: timelineEvents.data })]);
}

export function* downloadTickets() {
  try {
    yield put(toggleDownloadCsvSnackbar());
    const { data } = yield api.get(`${baseUrl}/customers/${customer.id}/tickets_csv`);
    downloadTicketsCsv(data, ticketsCsvFileName);
  } catch (error) {
    yield consoleErrorWithSentry(error); // remove this with proper error handling
  }
  yield put(toggleDownloadCsvSnackbar());
}

export function* savePriority(action) {
  const formData = new FormData();
  const store = yield select(getStore);
  formData.append("priority", store.ticketDetails.priority);
  yield api.request({
    method: "patch",
    url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload}`,
    data: formData,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  });
}
export function* saveCategory(action) {
  const formData = new FormData();
  const store = yield select(getStore);
  formData.append("categoryField", store.ticketDetails.categoryObject.value);
  if (store.ticketDetails.categoryObject.categoryInfo) {
    formData.append("platformInfoField", store.ticketDetails.categoryObject.categoryInfo);
  }
  yield api.request({
    method: "patch",
    url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload}`,
    data: formData,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  });
}
export function* saveNewTicket(action) {
  const store = yield select(getStore);
  const formData = new FormData();
  formData.append("subject", action.payload.subject);
  formData.append("description", action.payload.textArea);
  formData.append("platformField", action.payload.platform);
  formData.append("platformInfoField", action.payload.categoryInfo);
  formData.append("productField", action.payload.product.name);
  if (action.payload.billingProfile) {
    formData.append("billingProfile", action.payload.billingProfile);
  }

  const resourceDetails = action.payload.resourceDetails.map((resource) => {
    const copyResource = { ...resource };
    delete copyResource.assetIndex;
    Object.keys(copyResource).forEach((key) => {
      if (copyResource[key] === "") {
        delete copyResource[key];
      }
    });

    return Object.entries(copyResource)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");
  });

  formData.append("resourceDetails", resourceDetails.join("<br>"));

  formData.append("isAbTestingEligible", action.payload.isAbTestingEligible);

  formData.append("priority", action.payload.priority);
  if (action.payload.selectedCustomerUser) {
    formData.append("userEmail", action.payload.selectedCustomerUser);
  }
  if (action.payload.selectedItem) {
    for (const collaborator of action.payload.selectedEmails) {
      formData.append("collaborators[]", collaborator);
    }
  }
  if (action.payload.files) {
    for (const upload of action.payload.files) {
      formData.append("uploads[]", upload);
    }
  }
  if (action.payload.product.tags) {
    for (const tag of action.payload.product.tags) {
      formData.append("tags", tag);
    }
  }
  if (action.payload.followUp) {
    formData.append("followup", action.payload.followUp);
  }
  if (action.payload.unsupportedAsset) {
    formData.append("unsupportedAsset", action.payload.unsupportedAsset);
    formData.append("accountManagersEmail", action.payload.accountManagersEmail);
  }

  try {
    const ticket = yield api.request({
      method: "post",
      url: `${baseUrl}/customers/${customer.id}/tickets`,
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });

    if (ticket.data.id && !store.organization?.organization_fields?.concedefy_disabled) {
      const projectId = new FormData();
      projectId.append("platformInfoField", action.payload.categoryInfo);
      try {
        const concedefyRes = yield api.request({
          method: "post",
          url: `${baseUrl}/customers/${customer.id}/concedefy/${ticket.data.id}`,
          data: projectId,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        });

        if (concedefyRes.data?.email) {
          yield put({ type: CONCEDEFY_READ_ONLY_ACCESS_EMAIL, payload: concedefyRes.data.email });
          yield put({ type: DONE_LOADING, payload: false });
        } else {
          yield put({ type: DONE_LOADING, payload: false });
        }
      } catch (error) {
        yield put({ type: DONE_LOADING, payload: false });
        yield consoleErrorWithSentry(error);
      }
    } else {
      yield put({ type: DONE_LOADING, payload: false });
    }
  } catch (error) {
    yield put({ type: DONE_LOADING, payload: false, error });
    yield consoleErrorWithSentry(error);
  }
}

export function* getComments(action) {
  try {
    const ticketComments = yield api.request({
      method: "get",
      url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload}/comments`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });

    const {
      data: { users, comments },
    } = ticketComments;

    // given we detect ava comment and ava "provide feedback" comment next to each other, we should merge them into one message
    const mergedComments = [];

    for (const id in comments) {
      const comment = comments[id];

      const author = find(users, { id: comment.author_id });

      if (isAvaFeedbackRequestComment(comment.body, author.id)) {
        continue;
      }

      const previousComment = comments[id - 1];
      const previousCommentAuthor = find(users, { id: previousComment?.author_id });

      if (
        previousComment &&
        previousCommentAuthor &&
        isAvaTheAuthor(previousCommentAuthor.id) &&
        isAvaFeedbackRequestComment(previousComment.body, previousCommentAuthor.id)
      ) {
        const withoutAuthor = previousComment.html_body.split("\n");
        withoutAuthor.pop();

        mergedComments.push({
          ...comment,
          body: comment.body + previousComment.body,
          html_body: comment.html_body + withoutAuthor.join("\n"),
        });

        continue;
      }

      mergedComments.push(comment);
    }

    yield put({
      type: UPDATE_COMMENTS,
      payload: {
        comments: mergedComments,
        users: ticketComments.data.users,
      },
    });
  } catch (error) {
    consoleErrorWithSentry(error);
  }
}

export function* subscribeComments(action) {
  const channel = yield call(
    ticketCommentsChannel,
    action.payload.id,
    action.payload.isDoitEmployee,
    action.payload.customerId
  );
  try {
    while (true) {
      const change = yield take(channel);
      if (change.type === "added") {
        yield call(getComments, { payload: action.payload.id });
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

function ticketCommentsChannel(ticketId, isDoitEmployee, customerId) {
  return eventChannel((emit) => {
    let commentsRef = getCollection(IntegrationModel)
      .doc("zendesk")
      .collection("comments")
      .where("ticket_id", "==", ticketId);

    if (!isDoitEmployee) {
      commentsRef = commentsRef.where("public", "==", true).where("customer_id", "==", customerId);
    }

    // first snapshot returns all existing saved comments, we only want to listen to new comments
    let firstSnapshot = true;
    const unsub = commentsRef.onSnapshot((snapshot) => {
      if (firstSnapshot) {
        firstSnapshot = false;
        return;
      }
      snapshot.docChanges().forEach(emit);
    });

    return unsub;
  });
}

export function* sendComment(action) {
  const formData = new FormData();
  if (action.payload.body) {
    formData.append("body", action.payload.body);
  }
  formData.append("solved", action.payload.solved);

  if (action.payload.collaborators) {
    for (const collaborator of action.payload.collaborators) {
      formData.append("collaborators[]", collaborator);
    }
  }
  if (action.payload.attachments) {
    for (const upload of action.payload.attachments) {
      formData.append("uploads[]", upload);
    }
  }
  yield api.request({
    method: "put",
    data: formData,
    url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload.id}/comments`,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  });
  yield getComments({ payload: action.payload.id });
  yield put({ type: DONE_LOADING, payload: false });
  yield loadTicketDetails({ payload: action.payload.id });
}
export function* sendEscalate(action) {
  const formData = new FormData();
  for (const key of Object.keys(action.payload)) {
    formData.append(key, action.payload[key]);
  }

  try {
    yield api.request({
      method: "put",
      data: formData,
      url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload.id}/escalate`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
    const data = yield api.get(`${baseUrl}/customers/${customer.id}/tickets/${action.payload.id}`);

    data.data.ticket.tags.push("ticket_escalated");

    yield put({ type: UPDATE_TICKET_DETAILS, payload: data.data.ticket });
  } catch (error) {
    consoleErrorWithSentry(error);
  } finally {
    yield put({ type: DONE_LOADING, payload: false });
  }
}

export function* sendTagUpdates(action) {
  try {
    const ticket = yield api.get(`${baseUrl}/customers/${customer.id}/tickets/${action.payload.id}`);
    const tags = ticket.data.ticket.tags;

    // If either the charity or the vendor score have changed, we'll need to remove the old tags from the list of tags on the ticket before attaching new ones.
    if (action.payload.charityChanged) {
      remove(tags, (tag) => tag.startsWith(CHARITY_PREFIX));
    }

    if (action.payload.vendorScoreChanged) {
      remove(tags, (tag) => tag.startsWith(VENDOR_SCORE_PREFIX));
    }

    let newTags;
    if (action.payload.tags.length) {
      newTags = [...tags, ...action.payload.tags];
    } else {
      newTags = tags;
    }

    yield api.request({
      method: "patch",
      data: { tags: newTags },
      url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload.id}`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
  } catch (e) {
    consoleErrorWithSentry(e);
  }
  yield put({ type: DONE_TAG_UPDATE, payload: false });
}

export function* sendSurvey(action) {
  try {
    const formData = new FormData();
    formData.append("score", action.payload.score);
    formData.append("comment", action.payload.comment);

    yield api.request({
      method: "put",
      data: formData,
      url: `${baseUrl}/customers/${customer.id}/tickets/${action.payload.id}/satisfaction`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
  } catch (error) {
    consoleErrorWithSentry(error);
  }
  yield put({ type: DONE_SURVEY, payload: true });
}

export function* toggleOrganizationSharing({ payload: organization }) {
  const nextValue = !organization.shared_tickets;
  const resp = yield api.request({
    method: "put",
    data: {
      organization: {
        shared_tickets: nextValue,
        shared_comments: nextValue,
      },
    },
    url: `${baseUrl}/customers/${customer.id}/sharing`,
  });
  yield put({ type: UPDATE_ORGANIZATION, payload: resp.data.organization });
}

// Find all variable occurrences in the string template and replace them with their values
const enhanceContent = (content = "", params = {}) => {
  let newContent = content;

  for (const key in params) {
    newContent = newContent.replaceAll(`{{${key}}}`, params[key]);
  }
  return newContent;
};

export function* loadTemplate(action) {
  const ticketTemplate = yield getCollection(AppModel)
    .doc("support")
    .collection("ticketTemplate")
    .doc(action.payload)
    .get();

  // Check if any query params are available. If yes, they can be used in two places:
  // - as keys for the payload (overriding template from firestore)
  // - to enhance the content from the template
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const data = ticketTemplate.data();
  const payload = {
    ...data,
    ...params,
    body: enhanceContent(data.body, params),
  };

  yield put({ type: UPDATE_TEMPLATE, payload });
}

export function* sendNewDefaultEmailCC(data) {
  try {
    yield getCollection(CustomerModel)
      .doc(customer.id)
      .collection("defaultEmails")
      .doc("emailCC")
      .set({ ...data.payload });
    yield put({ type: UPDATE_DEFAULT_EMAILS_LIST, payload: Object.values(data.payload) });
  } catch (error) {
    consoleErrorWithSentry(error);
  }
}

/** ***************************** WATCHERS *************************************/

function* watchLoadTickets() {
  yield takeEvery(LOAD_TICKETS, loadTickets);
}
function* watchLoadTicketDetails() {
  yield takeEvery(LOAD_TICKET_DETAILS, loadTicketDetails);
}
function* watchDownloadTickets() {
  yield takeLatest(DOWNLOAD_TICKETS, downloadTickets);
}
function* watchSavePriority() {
  yield takeEvery(SAVE_PRIORITY, savePriority);
}
function* watchSaveCategory() {
  yield takeEvery(SAVE_CATEGORY, saveCategory);
}
function* watchChangeCategory() {
  yield takeEvery(CHANGE_CATEGORY, changeCategory);
}
function* watchSaveNewTicket() {
  yield takeEvery(SAVE_NEW_TICKET, saveNewTicket);
}
function* watchGetComments() {
  yield takeEvery(GET_COMMENTS, getComments);
  yield takeEvery(SEND_COMMENT, sendComment);
}
function* watchSubscribeComments() {
  const subs = {};
  while (true) {
    const action = yield take([SUBSCRIBE_COMMENTS, UNSUBSCRIBE_COMMENTS]);
    switch (action.type) {
      case SUBSCRIBE_COMMENTS:
        subs[action.payload.id] = yield fork(subscribeComments, action);
        break;
      case UNSUBSCRIBE_COMMENTS:
        yield cancel(subs[action.payload.id]);
        delete subs[action.payload.id];
        break;
    }
  }
}
function* watchEscalate() {
  yield takeEvery(SEND_ESCALATE, sendEscalate);
}
function* watchSurvey() {
  yield takeEvery(SEND_SURVEY, sendSurvey);
}
function* watchTagUpdate() {
  yield takeEvery(SEND_TAG_UPDATE, sendTagUpdates);
}
function* watchIssueChannel() {
  while (true) {
    const action = yield take(issuesChannel);
    yield put(action);
  }
}
function* watchButtonMode() {
  yield takeEvery(UPDATE_BUTTON_MODE, getCustomerUsers);
}
function* watchUpdatePhone() {
  yield takeEvery(UPDATE_USER_PHONE, updatePhoneNumber);
}
function* watchOrganizationSharingToggle() {
  yield takeEvery(TOGGLE_ORGANIZATION_SHARING, toggleOrganizationSharing);
}
function* watchLoadTemplate() {
  yield takeEvery(LOAD_TEMPLATE, loadTemplate);
}
function* watchGetDefaultEmailsList() {
  yield takeEvery(GET_DEFAULT_EMAILS_LIST, getDefaultEmailsList);
}
function* watchSendNewDefaultEmailCC() {
  yield takeEvery(SEND_NEW_DEFAULT_EMAILS, sendNewDefaultEmailCC);
}

export default function* root() {
  yield all([
    watchLoadTickets(),
    watchLoadTicketDetails(),
    watchDownloadTickets(),
    watchSavePriority(),
    watchSaveCategory(),
    watchChangeCategory(),
    watchSaveNewTicket(),
    watchGetComments(),
    watchEscalate(),
    watchSurvey(),
    watchTagUpdate(),
    watchIssueChannel(),
    watchButtonMode(),
    watchUpdatePhone(),
    watchOrganizationSharingToggle(),
    watchLoadTemplate(),
    watchGetDefaultEmailsList(),
    watchSendNewDefaultEmailCC(),
    watchSubscribeComments(),
  ]);
}
