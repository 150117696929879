import { globalText } from "../../../assets/texts";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { threeDotHeaderSection } from "../../../Pages/Assets/assetUtils";
import { type AllocationWRefRowItem } from "./AllocationBrowser";

export type AllocationRowItem =
  | {
      name: string;
      type: "Single" | "Group";
      owner: string;
      timeModified: string;
    }
  | AllocationWRefRowItem;

const itemDescriptors: FilterTableItemDescriptor<AllocationRowItem>[] = [
  {
    headerText: allocationTxt.TABLE_HEADERS.NAME,
    value: "name",
    filterType: "string",
  },
  {
    headerText: allocationTxt.TABLE_HEADERS.TYPE,
    value: "type",
    filterType: "string",
  },
  {
    headerText: allocationTxt.TABLE_HEADERS.OWNER,
    value: "owner",
    filterType: "string",
  },
  {
    headerText: globalText.LAST_MODIFIED,
    value: "timeModified",
    filterType: "date",
  },
] as const;

const { filters, headerColumns: baseHeaderColumns } = generateHeadersAndFilters(itemDescriptors);
const headerColumns = [...baseHeaderColumns, threeDotHeaderSection] as const;
export { filters, headerColumns };
