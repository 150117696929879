import { Box, List, ListItem, Stack, Typography } from "@mui/material";

import spotScalingImgDark from "../../assets/spotScaling/spot-scaling-dark.svg";
import spotScalingImg from "../../assets/spotScaling/spot-scaling-img.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { SideContentLayout } from "../../Components/PageLayout/VideoLanding/SideContentLayout";
import { SetUpSpotScaling } from "./SetUpSpotScaling";

export const NoAccess = () => {
  const isDarkMode = useDarkThemeCheck();

  const img = (
    <Box
      component="img"
      src={isDarkMode ? spotScalingImgDark : spotScalingImg}
      alt="Flexsave"
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: { lg: 595, md: 500 },
      }}
    />
  );

  return (
    <>
      <SideContentLayout rightSideContent={img} height="auto">
        <Box mt={4} mb={2} display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Typography variant="h1" pb={2} data-testid="title">
            Spot Scaling for AWS
          </Typography>
          <Typography color="text.secondary" data-testid="description">
            Spot Scaling monitors your <b>AWS Auto-Scaling Groups (ASGs)</b> replacing all or some of your ASG's
            On-Demand EC2 instances with Spot Instances. Saving you time and money.
          </Typography>
          <Typography color="text.secondary" pt={2}>
            Spot Scaling is great for those who use:
          </Typography>
          <List sx={{ listStyleType: "disc", color: "text.secondary", pl: 3 }}>
            <ListItem sx={{ display: "list-item" }} alignItems="flex-start" disablePadding>
              AWS Auto Scaling Groups
            </ListItem>
            <ListItem sx={{ display: "list-item" }} disablePadding>
              Staging or test environments
            </ListItem>
            <ListItem sx={{ display: "list-item" }} disablePadding>
              Containerized workloads
            </ListItem>
            <ListItem sx={{ display: "list-item" }} disablePadding>
              Stateless workloads
            </ListItem>
          </List>
        </Box>
      </SideContentLayout>
      <Stack sx={{ mr: -2, ml: -2 }}>
        <Stack sx={{ backgroundColor: "general.backgroundDark", height: { sm: "60vh" } }}>
          <SetUpSpotScaling />
        </Stack>
      </Stack>
    </>
  );
};
