import { type ComponentType } from "react";

import { BigQueryLensDashboardContextProvider } from "../Components/Dashboard/BigQueryLens/BigQueryLensDashboard";
import { CurrentDashboardContextProvider } from "./CurrentDashboardContext";
import { DashboardsContextProvider } from "./DashboardContext";
import { DashboardProcessingContextProvider } from "./DashboardProcessingContext";

function withDashboardProviders<P extends object>(Component: ComponentType<P>): ComponentType<P> {
  function inner(props: P) {
    return (
      <DashboardsContextProvider>
        <CurrentDashboardContextProvider>
          <DashboardProcessingContextProvider>
            <BigQueryLensDashboardContextProvider>
              <Component {...props} />
            </BigQueryLensDashboardContextProvider>
          </DashboardProcessingContextProvider>
        </CurrentDashboardContextProvider>
      </DashboardsContextProvider>
    );
  }

  inner.displayName = `withDashboardProviders(${Component.displayName || Component.name})`;

  return inner;
}

export default withDashboardProviders;
