import { useCallback, useEffect, useMemo } from "react";

import { TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { MenuItem, TextField } from "@mui/material";

import { contractTypeTiers, dciCommitmentTermOptions, packageTypeMap } from "../../../const";
import { stateDefaultValues, useContractFormContext } from "../../../ContractsFormContext";

const tiersOrder = ["essentials", "enhanced", "enterprise"];

export const DciTierSelector = () => {
  const { state, setState } = useContractFormContext();

  const [tiers] = useCollectionDataOnce(
    getCollection(TierModel)
      .where("packageType", "==", packageTypeMap[state.type])
      .where("name", "in", contractTypeTiers[state.type]),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const dciTiers = useMemo(
    () =>
      (tiers ?? [])
        .filter((tier) => tier.displayName.toLowerCase().includes("dci"))
        .sort((a, b) => tiersOrder.indexOf(a.name) - tiersOrder.indexOf(b.name)),
    [tiers]
  );

  useEffect(() => {
    if (dciTiers.length) {
      let tier;
      if (!state.tierId) {
        tier = dciTiers.find((tier) => tier.name === "essentials");
      } else {
        tier = dciTiers.find((tier) => tier.id === state.tierId);
      }

      setState((prevState) => ({
        ...prevState,
        tierId: tier?.id || prevState.tierId,
        tier,
      }));
    }
  }, [dciTiers, setState, state.tierId]);

  const onTierChange = useCallback(
    ({ target: { value } }) => {
      const newTier = dciTiers?.find((tier) => tier.id === value);

      setState((prevState) => {
        const baseUpdate = {
          ...prevState,
          tierId: value,
          tier: newTier,
          errors: {
            ...prevState.errors,
            tier: false,
          },
        };

        if (newTier?.name !== "essentials") {
          return {
            ...baseUpdate,
            isCommitment: true,
            commitmentMonths: dciCommitmentTermOptions[0],
            endDate: stateDefaultValues.startDate.plus({ months: dciCommitmentTermOptions[0] }),
            minimumServiceFee: newTier?.name === "enhanced" ? 3500 : 10000,
            cloudSpendPercentage: newTier?.name === "enhanced" ? 3 : 2,
            paymentTerm: "monthly",
            errors: {
              ...baseUpdate.errors,
              isCommitment: false,
              commitmentMonths: false,
              startDate: false,
              endDate: false,
              minimumServiceFee: false,
              cloudSpendPercentage: false,
              paymentTerm: false,
            },
          };
        }

        return baseUpdate;
      });
    },
    [dciTiers, setState]
  );

  return (
    <TextField
      value={state.tierId ?? ""}
      onChange={onTierChange}
      label="Tier"
      select
      fullWidth
      error={state.errors.tier}
    >
      {dciTiers.map((tier) => (
        <MenuItem key={tier.id} value={tier.id}>
          {tier.displayName}
        </MenuItem>
      ))}
    </TextField>
  );
};
