import { type ReactNode, useCallback, useState } from "react";

import { AvaContextProvider, Messenger, TrpcContextProvider, useTrpcContext } from "@doitintl/ava-components";
import { PresetPrompts } from "@doitintl/ava-components/src/Messenger/PresetPrompts";
import { AppModel, type Renderer } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Dialog, DialogTitle, Link, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { httpMethods } from "../../../assets/texts";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type createReportParams, type createReportPayload } from "../../../Pages/CloudAnalytics/generateReport/types";
import { type QueryResponse } from "../../../Pages/CloudAnalytics/report/ReportQuery";
import { consoleErrorWithSentry } from "../../../utils";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";
import { useGenerateReportWithCustomer } from "../../hooks/useGenerateReport";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";
import { useErrorSnackbar } from "../../SharedSnackbar/SharedSnackbar.context";
import { verifyAndUpdateReportConfig } from "../reportDB";
import { type AvaQueryRequest, type AvaWidgetData } from "../types";
import { useAvaConversation } from "../useAvaConversation";
import { useAvaConversationRefs } from "../useAvaConversationRefs";
import {
  AvaMixpanelCallbackFunc,
  constructReportPayloadFromAvaResponse,
  constructWidgetFromAvaResponse,
} from "../utils";
import { AvaWidgetCard } from "./AvaWidgetCard";
import { ChatToolbar } from "./ChatToolbar";
import LoadingCustomData from "./LoadingCustomData";

type Props = {
  handleClose: (withoutConversation: boolean, mixpanelCallback?: (event: string) => void) => void;
  dashboardId?: string;
  existingConversationId?: string;
};

export const AVADialog = ({ handleClose, existingConversationId, dashboardId }: Props) => {
  const { tokenValue, currentUser } = useAuthContext({ mustHaveUser: true });
  const [expandedQuery, setExpandedQuery] = useState<boolean>(false);
  const [allConversations] = useAvaConversationRefs(expandedQuery);
  const [conversationUuid, setConversationUuid] = useState(new Date().getTime().toString());
  const [currentConversationId, setCurrentConversationId] = useState<string | undefined>(existingConversationId);
  const [conversationHasOccurred, setConversationHasOccurred] = useState<boolean>(false);
  const [conversationChanged, setConversationChanged] = useState<boolean>(false);
  const isDarkMode = useDarkThemeCheck();
  const { deleteConversation, addReportLinkAndChartTypeToAvaConfig } = useTrpcContext();
  const [widgetsDataMap, setWidgetsDataMap] = useState<Record<string, AvaWidgetData>>({});
  const currentConversationHistory = useAvaConversation(currentConversationId);
  const { userOrganization, customer } = useCustomerContext({ allowNull: true });
  const [reportCreationLoading, setReportCreationLoading] = useState<boolean>(false);
  const api = useApiContext();
  const customerId = customer?.id || "no-customer-id";
  const [customerMetadata, loadingMetadataInfo, MetadataError] = useDocumentDataOnce(
    getCollection(AppModel).doc("ava").collection("customersMetadata").doc(customerId)
  );
  const generateReport = useGenerateReportWithCustomer(
    customer?.ref ?? null,
    userOrganization?.snapshot.ref ? userOrganization?.snapshot.ref : null
  );

  const messengerInvalidateKey = () => {
    setConversationUuid(new Date().getTime().toString());
  };
  const handleDeleteChat = async (conversationId: string) => {
    await deleteConversation(conversationId);
    setCurrentConversationId(undefined);
    messengerInvalidateKey();
  };
  const showErrorSnackbar = useErrorSnackbar();
  const onAvaReportResponse = (report: string, mapKey: string) => {
    const extractReportJson = (input: string): string => {
      const match = input.match(/{.*}/s);
      return match ? match[0].replace(/\\"/g, '"') : "";
    };
    const extractedContent = extractReportJson(report);
    let parsedReport;
    try {
      parsedReport = JSON.parse(extractedContent);
    } catch (e) {
      // catch error to avoid crashing the entire client for bad json. instead - simply to not show report widget
      return;
    }
    const queryRequest: AvaQueryRequest = parsedReport.reportConfig;
    const queryResponse: QueryResponse = parsedReport.reportResult;
    if (!customer || !queryRequest || !queryResponse) {
      return;
    }
    const w = constructWidgetFromAvaResponse(queryRequest, queryResponse, customer, currentUser.email);
    const draftReport = constructReportPayloadFromAvaResponse(queryRequest, customer);
    const widgetData = {
      widget: w,
      height: 300,
      draftReportLink: parsedReport.reportLink ?? "",
      reportCreationPayload: draftReport,
    };
    setWidgetsDataMap((prevData) => ({
      ...prevData,
      [mapKey]: widgetData,
    }));
  };

  const createReportCallback = async (
    payload: createReportPayload | createReportParams,
    widgetKey: string
  ): Promise<string> => {
    let reportId = "";
    let reportLink = "";
    if (widgetsDataMap[widgetKey].draftReportLink) {
      try {
        const parts = widgetsDataMap[widgetKey].draftReportLink.split("/");
        const reportIdWQuery = parts[parts.indexOf("reports") + 1];
        const id = reportIdWQuery.split("?")[0];
        const existsAndUpToDate = await verifyAndUpdateReportConfig(id, payload);
        if (existsAndUpToDate) {
          return widgetsDataMap[widgetKey].draftReportLink;
        }
      } catch (e) {
        showErrorSnackbar("Sorry, Unable to create a report, please try again...");
        return "";
      }
    }
    try {
      reportId = await generateReport(payload, true);
    } catch (e) {
      showErrorSnackbar("Sorry, Unable to create a report, please try again...");
      return "";
    }
    if (reportId) {
      if (!customer) {
        return reportLink;
      }

      reportLink = `/customers/${customer?.id}/analytics/reports/${reportId}?run-on-open=true`;

      setWidgetsDataMap((prevData) => ({
        ...prevData,
        [widgetKey]: {
          ...prevData[widgetKey],
          draftReportLink: reportLink,
        },
      }));
    }
    if (!currentConversationId) {
      return reportLink;
    }
    try {
      await addReportLinkAndChartTypeToAvaConfig({
        conversationId: currentConversationId,
        messageId: widgetKey,
        reportLink,
      });
    } catch (e) {
      showErrorSnackbar("Sorry, Unable to save the report link, please try again...");
    }
    return reportLink;
  };

  const handleReportCreate = async (widgetKey: string) => {
    if (!reportCreationLoading) {
      const widgetData = widgetsDataMap[widgetKey];
      if (!widgetData) {
        return;
      }
      setReportCreationLoading(true);
      const newTab = window.open("", "_blank");
      let link = "";
      try {
        link = await createReportCallback(widgetData.reportCreationPayload, widgetKey);
      } finally {
        if (link && newTab) {
          newTab.location.href = dashboardId && !conversationChanged ? `${link}&dashboardId=${dashboardId}` : link;
        } else if (newTab) {
          // close the tab if no link was returned
          newTab.close();
        }
        setReportCreationLoading(false);
      }
    }
  };

  const onWidgetRendererChange = async (rendererOption: Renderer, widgetKey: string) => {
    const widget = widgetsDataMap[widgetKey].widget;
    const reportPayload = widgetsDataMap[widgetKey].reportCreationPayload;
    if (widget && widget.config.renderer !== rendererOption) {
      const updatedWidget = { ...widget, config: { ...widget.config, renderer: rendererOption } };
      if (reportPayload.config?.renderer) {
        reportPayload.config.renderer = rendererOption;
      }
      setWidgetsDataMap((prevData) => ({
        ...prevData,
        [widgetKey]: {
          ...prevData[widgetKey],
          widget: updatedWidget,
          height: 300,
          reportCreationPayload: reportPayload,
        },
      }));
    }
    if (!currentConversationId) {
      return;
    }
    try {
      await addReportLinkAndChartTypeToAvaConfig({
        conversationId: currentConversationId,
        messageId: widgetKey,
        chartType: rendererOption,
      });
    } catch (e) {
      showErrorSnackbar("Sorry, unable to change the chart type, please try again later...");
    }
  };

  const generateWidgetCards = (widgetDataMap: Record<string, AvaWidgetData>): Record<string, ReactNode> =>
    Object.keys(widgetDataMap).reduce<Record<string, ReactNode>>((acc: Record<string, ReactNode>, key: string) => {
      acc[key] = (
        <AvaWidgetCard
          widgetKey={key}
          onWidgetRendererChange={onWidgetRendererChange}
          widgetData={widgetsDataMap[key]}
          handleReportCreate={handleReportCreate}
        />
      );
      return acc;
    }, {});

  const fetcher = useCallback(
    (data: any) =>
      fetch("/api/ava/ask", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenValue}`,
        },
        body: JSON.stringify(data),
      }),
    [tokenValue]
  );

  const updateCustomerMetadata = useCallback(async () => {
    if (!customer || loadingMetadataInfo) {
      return;
    }

    if (
      !MetadataError &&
      customerMetadata?.lastUpdated &&
      Math.abs(DateTime.fromJSDate(customerMetadata.lastUpdated.toDate()).diffNow("days").days) < 30
    ) {
      return;
    }

    try {
      await api.request({
        method: httpMethods.POST,
        url: `/v1/customers/${customer.id}/ava/customer-metadata`,
        data: { customerId: customer.id },
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [customer, loadingMetadataInfo, customerMetadata, MetadataError, api]);

  const closeDialog = useCallback(() => {
    handleClose(!conversationHasOccurred, AvaMixpanelCallbackFunc);
  }, [conversationHasOccurred, handleClose]);

  return (
    <AvaContextProvider fetcher={fetcher}>
      <TrpcContextProvider value={{ baseURL: window.location.origin }}>
        <Dialog
          fullWidth
          maxWidth="md"
          sx={{
            m: 0,
            overflow: "hidden",
          }}
          open={true}
          onClose={closeDialog}
        >
          <DialogTitle sx={{ borderBottom: 1, borderColor: "divider" }}>
            <ChatToolbar
              conversations={allConversations}
              selectedConversationId={currentConversationId}
              onDeleteClicked={handleDeleteChat}
              onNewChatClicked={() => {
                setCurrentConversationId(undefined);
                messengerInvalidateKey();
                setConversationChanged(true);
              }}
              onSelectConversation={(conversationId) => {
                setCurrentConversationId(conversationId);
                messengerInvalidateKey();
                setConversationChanged(true);
              }}
              onCloseClicked={closeDialog}
              expandedQuery={expandedQuery}
              setExpandedQuery={setExpandedQuery}
            />
          </DialogTitle>
          <Box height="75vh" width="100%" overflow="hidden">
            <Messenger
              progressComponent={<LoadingCustomData customerId={customerId} />}
              onAvaReportResponse={onAvaReportResponse}
              widgetCards={generateWidgetCards(widgetsDataMap)}
              handleReportCreate={handleReportCreate}
              key={conversationUuid}
              contactSalesButton={<ContactSalesButton customMixpanelEventId="ava.upselling-contact.button" />}
              isDarkMode={isDarkMode}
              getAvatar={() => currentUser.photoURL}
              conversationData={
                currentConversationId
                  ? { conversationId: currentConversationId, history: currentConversationHistory }
                  : undefined
              }
              onConversationsStarted={async (conversationId) => {
                setConversationHasOccurred(true);
                setCurrentConversationId(conversationId);
                await updateCustomerMetadata();
              }}
            >
              {!currentConversationId && (
                <Stack
                  sx={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden auto",
                    backgroundColor: !isDarkMode ? "#FAFAFA" : "",
                  }}
                >
                  <Stack alignItems="center">
                    <PresetPrompts isHelpCenter={false} darkMode={isDarkMode} />
                    <Typography mt={2}>
                      <Link
                        href="https://help.doit.com/docs/general/ava"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                      >
                        Learn more about how to interact with Ava
                        <OpenNewIcon color="primary" sx={{ fontSize: 16, verticalAlign: "middle" }} />
                      </Link>
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Messenger>
          </Box>
        </Dialog>
      </TrpcContextProvider>
    </AvaContextProvider>
  );
};
