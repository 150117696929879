import { editionModes, SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import { useEffect, useMemo, useState } from "react";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { BillingMode } from "../../../Pages/Customer/constants";
import { formatCurrency, formatDecimalNumber } from "../../../utils/common";
import { useCloudConnect } from "../../hooks/useCloudConnect";
import { SkeletonCard } from "../SkeletonCard";
import RecommendationsDetailsView from "./RecommendationsDetailsView";
import "./codemirror.css";
import { recommendationKey, timeframeOptions } from "./constants";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetCardContentWithTable } from "../WidgetCards/Common/WidgetCardContentWithTable";
import { useBigQueryLensDashboardContext } from "./BigQueryLensDashboard";

export default function RecommendationsCard({ widgetHeight = 200, fallbackComponent }) {
  const [rows, setRows] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openDetailsView, setOpenDetailsView] = useState(false);
  const [detailsViewData, setDetailsViewData] = useState(null);
  const { selectedTimeframe, selectedBillingMode } = useBigQueryLensDashboardContext();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [cloudConnect] = useCloudConnect(customer);
  const bigqueryFinopsPermission = cloudConnect?.some(
    (sa) => sa.cloudPlatform === "google-cloud" && sa.categoriesStatus["bigquery-finops"] === 1
  );
  const [data, setData] = useState();

  useEffect(() => {
    const promises = [
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection("output")
        .doc(customer.id)
        .collection("recommendations")
        .doc(selectedTimeframe)
        .get(),
    ];

    if (editionModes.includes(selectedBillingMode)) {
      promises.push(
        getCollection(SuperQueryModel)
          .doc("simulation-recommender")
          .collection(selectedBillingMode)
          .doc(customer.id)
          .collection("recommendations")
          .doc(selectedTimeframe)
          .get()
      );
    } else if (selectedBillingMode === BillingMode.onDemand) {
      promises.push(
        getCollection(SuperQueryModel)
          .doc("simulation-recommender")
          .collection("on-demand")
          .doc(customer.id)
          .collection("recommendations")
          .doc(selectedTimeframe)
          .get()
      );
    }

    Promise.all(promises).then((values) => {
      const [timeframeData, billingData] = [values[0].asModelData(), values?.[1]?.asModelData()];

      setData({
        ...(timeframeData ?? {}),
        ...(billingData ?? {}),
      });
    });
  }, [customer.id, selectedBillingMode, selectedTimeframe]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const tmpRows = [];
    let total_price = 0;
    let total_price_storage = 0;

    Object.keys(data)
      .filter((key) => key !== "lastUpdate")
      .sort()
      .forEach((key) => {
        if (data[key] && "savingsPrice" in data[key] && data[key].savingsPrice) {
          total_price += key !== "storageSavings" ? data[key].savingsPrice : 0;
          total_price_storage += key === "storageSavings" ? data[key].savingsPrice : 0;
        }

        tmpRows.push({ ...data[key], [recommendationKey]: key });
      });

    setTotalPrice({ scan: total_price, storage: total_price_storage });
    setRows(
      sortBy(tmpRows, ["savingsPrice"])
        .reverse()
        .map((row) => {
          return { ...row, id: row.recommendationKey };
        })
    );
  }, [data]);

  const openDetailsViewDialog = (e, data) => {
    e.preventDefault();
    if (data.detailedTable) {
      setDetailsViewData(data);
      setOpenDetailsView(true);
    } else {
      setDetailsViewData({ superQuery: true, recommendation: data.recommendation });
      setOpenDetailsView(true);
    }
  };
  const closeDetailsViewDialog = () => {
    setDetailsViewData(null);
    setOpenDetailsView(false);
  };

  const columns = useMemo(
    () => [
      {
        field: "recommendation",
        headerName: "Your Recommendations",
        flex: 1,
        renderCell: (params) => (
          <Link color="inherit" sx={{ cursor: "pointer" }} onClick={(e) => openDetailsViewDialog(e, params.row)}>
            {params.row.recommendation}
          </Link>
        ),
      },
      {
        field: "savingsPercentage",
        headerName: "Savings(%)",
        renderCell: (params) => {
          return `${formatDecimalNumber(params.row.savingsPercentage, 2)}%`;
        },
      },
      {
        field: "savingsPrice",
        headerName: "Savings($)",
        renderCell: (params) => {
          return formatCurrency(params.row.savingsPrice, "USD");
        },
      },
    ],
    []
  );

  if (data === null) {
    return fallbackComponent;
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card>
      <WidgetCardHeader
        title="Recommendations"
        subheader={
          bigqueryFinopsPermission
            ? `You can save up to ${formatCurrency(totalPrice.scan + totalPrice.storage, "USD")} (${
                find(timeframeOptions, { value: selectedTimeframe }).label
              })`
            : "List of recommendations"
        }
      />

      <WidgetCardContentWithTable height={widgetHeight} rows={rows} columns={columns} />
      <RecommendationsDetailsView open={openDetailsView} onClose={closeDetailsViewDialog} data={detailsViewData} />
    </Card>
  );
}
