import { TenantModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { firebaseProjectId } from "../../constants";
import { doitCustomerId } from "../customers";

export function customerIdToTenantDocId(customerId: string) {
  switch (customerId) {
    case "amazon_web_services":
      return "amazon.com";
    case "google_cloud_platform":
      return "google.com";
    case "doit":
      return doitCustomerId;
    default:
      return customerId;
  }
}

export const getTenantId = async (customerId: string): Promise<string> => {
  const actualCustomerId = customerIdToTenantDocId(customerId);
  const customerToTenantSnap = await getCollection(TenantModel)
    .doc(firebaseProjectId)
    .collection("customerToTenant")
    .doc(actualCustomerId)
    .get();

  const tenantId = customerToTenantSnap.get("tenantId");
  if (!tenantId) {
    throw new Error(`could not get tenant id for customer ${actualCustomerId}`);
  }

  return tenantId;
};
