import { DashboardType } from "@doitintl/cmp-models";

import { type Widget } from "../../../Context/CurrentDashboardContext";
import { type WidgetStateRecord } from "../../../Context/DashboardProcessingContext";

export const filterWidgetsByName = (widgets: Widget[], name: string) =>
  widgets.filter((item) => item.name.includes(name));

export const excludeWidgetsByName = (widgets: Widget[], name: string) =>
  widgets.filter((item) => !item.name.includes(name));

export const areAllWidgetsEmpty = (widgets: WidgetStateRecord) =>
  Object.values(widgets ?? {}).every((widgetState) => !widgetState.hasData);

export const getDashboardType = (dashboardName: string) => {
  switch (dashboardName) {
    case "Pulse":
      return DashboardType.Pulse;
    case "AWS Lens":
      return DashboardType.AwsLens;
    case "SaaS AWS Lens":
      return DashboardType.SaaSAwsLens;
    case "GCP Lens":
      return DashboardType.GcpLens;
    case "SaaS GCP Lens":
      return DashboardType.SaaSGcpLens;
    case "Azure Lens":
      return DashboardType.AzureLens;
    case "EKS Lens":
      return DashboardType.EKSLens;
    case "Snowflake Lens":
      return DashboardType.SnowflakeLens;
    case "AWS MAP Lens":
      return DashboardType.AWSMAPLens;
    default:
      return dashboardName;
  }
};

export const areAllCloudReportsProcessed = (allWidgets: Widget[], processedWidgets: WidgetStateRecord) =>
  Object.values(processedWidgets)?.length === filterWidgetsByName(allWidgets, "cloudReports").length;

export const isDashboardLoading = (allWidgets: Widget[], dashboardName: string, processedWidgets: WidgetStateRecord) =>
  areAllCloudReportsProcessed(allWidgets, processedWidgets) &&
  areAllWidgetsEmpty(processedWidgets) &&
  [
    DashboardType.Pulse,
    DashboardType.GcpLens,
    DashboardType.SaaSGcpLens,
    DashboardType.AwsLens,
    DashboardType.SaaSAwsLens,
    DashboardType.EKSLens,
    DashboardType.AzureLens,
  ].includes(getDashboardType(dashboardName) as DashboardType);
