import { Container } from "@mui/material";

import CloudReportCard from "../../../Components/Dashboard/Analytics/CloudReportCard";
import { sageMakerReportElementId } from "../AWS/consts";
import { useFlexsaveSageMakerData } from "../AWS/hooks";
import { SAGEMAKER_REPORT_ID } from "../consts";
import { useDelay } from "../utils";

type Props = {
  customerId: string;
};

export default function SageMakerReport({ customerId }: Props) {
  const { sageMakerData } = useFlexsaveSageMakerData();
  const shouldRender = useDelay(1000);

  if (!shouldRender) {
    return null;
  }

  if (!sageMakerData?.timeEnabled) {
    return null;
  }

  return (
    <Container sx={{ pt: 5 }} data-cy={sageMakerReportElementId} id={sageMakerReportElementId}>
      <CloudReportCard widgetId={`${customerId}_${SAGEMAKER_REPORT_ID}`} enableWidgetLegend />
    </Container>
  );
}
