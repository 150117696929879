import { useCallback } from "react";

import { Metadata, TimeInterval, type TimeSettingsConfig, TimeSettingsMode } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { FixedFilters } from "../../../../Pages/CloudAnalytics/utilities";
import { type DraftBudget } from "../../../../types";
import useGenerateReport from "../../useGenerateReport";

const getTimeInterval = (interval: string) => {
  const map = {
    [TimeInterval.YEAR]: TimeInterval.MONTH,
    [TimeInterval.QUARTER]: TimeInterval.MONTH,
    [TimeInterval.MONTH]: TimeInterval.DAY,
    [TimeInterval.WEEK]: TimeInterval.DAY,
    [TimeInterval.DAY]: TimeInterval.HOUR,
  };

  return map[interval as TimeInterval];
};

const getTimeSettingsConfig = (interval: TimeInterval): TimeSettingsConfig => {
  switch (interval) {
    case TimeInterval.MONTH:
      return {
        amount: 6,
        unit: TimeInterval.MONTH,
        mode: TimeSettingsMode.Last,
        includeCurrent: true,
      };
    case TimeInterval.DAY:
      return {
        amount: 2,
        unit: TimeInterval.MONTH,
        mode: TimeSettingsMode.Last,
        includeCurrent: true,
      };
    case TimeInterval.HOUR:
      return {
        amount: 7,
        unit: TimeInterval.DAY,
        mode: TimeSettingsMode.Last,
        includeCurrent: true,
      };
    default:
      return {
        unit: interval,
        mode: TimeSettingsMode.Last,
        includeCurrent: false,
        amount: 7,
      };
  }
};

const useBudgetInvestigation = (budget: DraftBudget) => {
  const { customer } = useCustomerContext();
  const generateReport = useGenerateReport();

  return useCallback(async () => {
    const {
      name,
      config: { timeInterval, scope, currency, filters },
    } = budget.data;

    const fields =
      scope?.length > 0
        ? [
            {
              type: Metadata.FIXED,
              id: FixedFilters.SERVICE,
              values: [],
              groupBy: true,
            },
            {
              type: Metadata.ATTRIBUTION,
              id: Metadata.ATTRIBUTION,
              values: scope.map((a) => a.id),
            },
          ]
        : (filters?.map((filter) => ({
            type: filter.type,
            id: filter.key,
            values: filter.values,
            groupBy: true,
          })) ?? []);

    const timeSettings = getTimeSettingsConfig(timeInterval);
    const params = {
      name: `Explore Cloud Analytics report: ${name}`,
      description: "",
      config: {
        currency,
        fields,
        timeInterval: getTimeInterval(timeInterval),
        timeSettings,
      },
    };

    const reportId = await generateReport(params, false);
    return `/customers/${customer.id}/analytics/reports/${reportId}`;
  }, [budget.data, customer.id, generateReport]);
};

export default useBudgetInvestigation;
