import { TextField, type TextFieldProps } from "@mui/material";
import { type FormikProps } from "formik";

import { toSentenceCase } from "../utils/string";

export type FormField = TextFieldProps & {
  // Force name to be required
  name: string;
};

interface FormItemProps<T> {
  name: string;
  field: FormField;
  formik: FormikProps<T>;
}

const FormItem = <T,>({ name, field, formik }: FormItemProps<T>) => {
  const inputProps = formik.getFieldProps(name);
  const meta = formik.getFieldMeta(name);

  const label = field.label ?? toSentenceCase(name);

  return (
    <TextField
      {...field}
      {...inputProps}
      label={label}
      required={field.required ?? false}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
    />
  );
};

export default FormItem;
