import { useCallback, useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { type CloudFlowNodeType, type ConditionExpression, ModelType } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { ReferencedFieldChip } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldContextProvider } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import { FilterDialog } from "./FilterDialog";
import { type EditActionParams } from "./types";
import { getModelByFieldReference } from "./utils";

type FilterConditionProps = {
  condition: ConditionExpression;
  groupIndex: number;
  conditionIndex: number;
  selectedNode: NodeWitOutputModel | undefined;
  referenceableNodes: NodeWitOutputModel[];
};

const FilterCondition = ({
  condition,
  groupIndex,
  conditionIndex,
  selectedNode,
  referenceableNodes,
}: FilterConditionProps) => {
  const [editConditionOpen, setEditConditionOpen] = useState(false);
  const isDarkTheme = useDarkThemeCheck();
  const theme = useTheme();

  const { updateNode } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();

  const handleEditFilter = useCallback(
    async ({ operator, conditionType, value, fieldReference }: EditActionParams) => {
      const updatedCondition: ConditionExpression = {
        type: conditionType,
        field: fieldReference.referencedField,
        comparisonOperator: operator,
        value,
      };

      updateNode((prevNode) => {
        const conditionGroups = prevNode.parameters?.conditionGroups ?? [];
        const updatedConditionGroups = [...conditionGroups];

        const groupConditions = [...updatedConditionGroups[groupIndex].conditions];
        groupConditions[conditionIndex] = updatedCondition;

        updatedConditionGroups[groupIndex] = {
          ...updatedConditionGroups[groupIndex],
          conditions: groupConditions,
        };

        return {
          parameters: {
            ...prevNode.parameters!,
            conditionGroups: updatedConditionGroups,
          },
        };
      });

      setEditConditionOpen(false);
    },
    [conditionIndex, groupIndex, updateNode]
  );

  const handleDeleteFilter = useCallback(() => {
    updateNode((prevNode) => {
      const conditionGroups = prevNode.parameters?.conditionGroups ?? [];
      const updatedConditionGroups = [...conditionGroups];

      const groupConditions = [...updatedConditionGroups[groupIndex].conditions];
      groupConditions.splice(conditionIndex, 1);

      updatedConditionGroups[groupIndex] = {
        ...updatedConditionGroups[groupIndex],
        conditions: groupConditions,
      };

      return {
        parameters: {
          ...prevNode.parameters!,
          conditionGroups: updatedConditionGroups,
        },
      };
    });
  }, [conditionIndex, groupIndex, updateNode]);

  const getStaticValue = useCallback(() => {
    const value = condition.value;

    if (!isReferencedNodeValue(value)) {
      const model = selectedNode ? getModelByFieldReference(selectedNode.outputModel, condition.field) : undefined;

      if (model && model.type === ModelType.TIMESTAMP) {
        const timestampFormat = model.timestampFormat ?? "X";
        let dateTime: DateTime;

        if (typeof value === "number" || typeof value === "string") {
          if (timestampFormat === "X") {
            dateTime =
              typeof value === "number" ? DateTime.fromSeconds(value) : DateTime.fromSeconds(parseFloat(value));
          } else {
            dateTime = DateTime.fromFormat(String(value), timestampFormat);
          }

          if (dateTime.isValid) {
            return dateTime.toLocaleString(DateTime.DATETIME_MED);
          } else {
            return String(value);
          }
        } else {
          return JSON.stringify(value);
        }
      }

      return JSON.stringify(value);
    }
    return "";
  }, [condition.field, condition.value, selectedNode]);

  const getNodeName = useCallback(
    (nodeId: string) => referenceableNodes.find(({ id }) => id === nodeId)?.name,
    [referenceableNodes]
  );

  return (
    <>
      <FilterDialog
        open={editConditionOpen}
        groupIndex={groupIndex}
        referenceableNodes={referenceableNodes}
        mode="edit"
        selectedNode={selectedNode}
        initialValue={condition.value}
        conditionIndex={conditionIndex}
        initialFieldReference={
          selectedNode ? { referencedNodeId: selectedNode.id, referencedField: condition.field || [] } : undefined
        }
        initialOperator={condition.comparisonOperator}
        handleClose={() => {
          setEditConditionOpen(false);
        }}
        handleAction={handleEditFilter}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          border: "1px solid",
          borderColor: theme.palette.general.outlineBorder,
          borderRadius: 1,
          width: "100%",
          backgroundColor: isDarkTheme ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight,
          minHeight: "47px",
          px: 2,
        }}
      >
        <Tooltip
          placement="top"
          title={
            isReferencedNodeValue(condition.value)
              ? `${selectedNode?.name}.${condition.field.join(".")} ${condition.comparisonOperator} ${getNodeName(
                  condition.value.referencedNodeId
                )}.${condition.value.referencedField.join(".")}`
              : `${selectedNode?.name}.${condition.field.join(".")} ${condition.comparisonOperator} ${getStaticValue()}`
          }
        >
          <Stack direction={"row"} gap={0.5} sx={{ overflow: "hidden" }} alignItems={"center"}>
            <Typography noWrap variant="body2" sx={{ flexShrink: "0.5" }}>
              {`${condition.field.join(".")} ${condition.comparisonOperator} `}
            </Typography>
            {!isReferencedNodeValue(condition.value) ? (
              <Typography noWrap variant="body2">
                {getStaticValue()}
              </Typography>
            ) : (
              <Box sx={{ overflow: "hidden" }}>
                <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
                  <ReferencedFieldChip value={condition.value} />
                </ReferencedFieldContextProvider>
              </Box>
            )}
          </Stack>
        </Tooltip>
        <Stack direction={"row"} gap={0.5} p={0}>
          <IconButton
            onClick={() => {
              setEditConditionOpen(true);
            }}
            sx={{ p: 0.5 }}
          >
            <EditIcon sx={{ fontSize: "16px" }} />
          </IconButton>
          <IconButton onClick={handleDeleteFilter} sx={{ p: 0.5 }}>
            <CloseIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};

export default FilterCondition;
