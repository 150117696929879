import { type Cluster } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface DeleteClusterDialogProps {
  cluster: Cluster;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteClusterDialog = ({ cluster, open, onClose, onDelete }: DeleteClusterDialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      Delete cluster <strong>{cluster.name}</strong>?
    </DialogTitle>
    <DialogContent>Are you sure you want to delete this cluster?</DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button color="error" onClick={onDelete}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteClusterDialog;
