import { useParams } from "react-router";
import { CLOUD_FLOW_CREATION_STATUS } from "@doitintl/cmp-models";

import { useCloudflow } from "../../../hooks";

export function useIsCloudflowPublished() {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { cloudflow } = useCloudflow(flowId);

  return cloudflow?.data.status === CLOUD_FLOW_CREATION_STATUS.PUBLISHED;
}
