import { useMemo } from "react";

import { type ReportConfig } from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";

import { type MetadataOption } from "../../../../types";

export type EqualityCheckProps = {
  name: string;
  description: string;
  newConfig: ReportConfig | null;
  initialConfig?: ReportConfig;
  reportDescription: string;
  reportName: string;
  dimensions?: MetadataOption[];
};

export type getEqualityCheckProps = {
  name: string;
  description: string;
  newConfig: ReportConfig | null;
  initialConfig?: ReportConfig;
  reportDescription: string;
  reportName: string;
};

export const getEqualityCheck = ({
  description,
  name,
  newConfig,
  reportName,
  reportDescription,
  initialConfig,
}: getEqualityCheckProps) => {
  if (!initialConfig || !newConfig) {
    return false;
  }

  const comparisonObject = {
    ...initialConfig,
    ...pickBy(newConfig, (v, k) => initialConfig[k] === undefined && !isEqual(initialConfig[k], v)),
  };

  const filtersEquality =
    newConfig.filters.length === initialConfig.filters.length &&
    newConfig.filters.every((filter, idx) => {
      const initialConfigFilter = initialConfig.filters[idx];
      return (
        filter.id === initialConfigFilter.id &&
        Object.keys(filter).every((key) => isEqual(filter[key], initialConfigFilter[key]))
      );
    });

  const forecastSettingsEquality = isEqual(initialConfig.forecastSettings, newConfig.forecastSettings);

  const othersEquality = isEqualWith(
    omit(newConfig, ["filters", "mode"]),
    omit(comparisonObject, ["filters", "mode"]),
    (objectValue, otherValue, key) => {
      if (key === "calculatedMetric") {
        return objectValue?.id === otherValue?.id;
      }

      if (key === "splits") {
        const baseEquality = objectValue.every(
          (v, i) => v.mode === otherValue[i]?.mode && v.includeOrigin === otherValue[i]?.includeOrigin
        );
        const targetsEquality = objectValue.every((v, i) =>
          v.targets.every((t, j) => isEqual(t, otherValue[i]?.targets[j]))
        );
        return baseEquality && targetsEquality;
      }
    }
  );

  return (
    name === reportName &&
    description === reportDescription &&
    filtersEquality &&
    othersEquality &&
    forecastSettingsEquality
  );
};

const useEqualityCheck = ({
  name,
  description,
  newConfig,
  reportName,
  reportDescription,
  initialConfig,
  dimensions,
}: EqualityCheckProps): { equalityCheck: boolean; equalityCheckLoading: boolean } =>
  useMemo(() => {
    if (!dimensions || !initialConfig || !newConfig) {
      return { equalityCheck: false, equalityCheckLoading: true };
    }

    const equalityCheck = getEqualityCheck({
      initialConfig,
      name,
      description,
      newConfig,
      reportName,
      reportDescription,
    });

    return { equalityCheck, equalityCheckLoading: false };
  }, [dimensions, initialConfig, name, description, newConfig, reportName, reportDescription]);

export default useEqualityCheck;
