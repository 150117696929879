import { CustomerModel, type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, useCollectionData } from "@doitintl/models-firestore";

import { type Customer } from "../../types";

export function useCloudConnect(
  customer: Customer | undefined | null
): [ModelIdRef<CustomerModelCloudConnectModel>[], boolean] {
  const [cloudConnect, loading] = useCollectionData<CustomerModelCloudConnectModel, "id", "ref">(
    customer ? getCollection(CustomerModel).doc(customer.id).collection("cloudConnect") : undefined,
    {
      idField: "id",
      refField: "ref",
    }
  );

  return [cloudConnect ?? [], loading];
}
