import { useCallback, useEffect, useState } from "react";

import {
  InviteModel,
  NotificationProviderType,
  type NotificationSettings,
  UserModel,
  type UserPersonalNotificationModel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelData, type ModelReference, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { serverTimestamp } from "../../../utils/firebase";

function isInviteModel(
  userRef: ModelReference<UserModel> | ModelReference<InviteModel>
): userRef is ModelReference<InviteModel> {
  return userRef.path.startsWith("invites/");
}

export function useUserEmailNotification(userRef: ModelReference<UserModel> | ModelReference<InviteModel>) {
  const {
    customer: { ref: customerRef },
  } = useCustomerContext();

  const userNotificationsRef = isInviteModel(userRef)
    ? getCollection(InviteModel).doc(userRef.id).collection("userPersonalNotifications")
    : getCollection(UserModel).doc(userRef.id).collection("userPersonalNotifications");

  const [userEmailNotifications, loadingUserNotifications] = useCollectionData(userNotificationsRef?.limit(1), {
    idField: "id",
    refField: "ref",
  });

  const [userEmailNotification, setUserEmailNotification] =
    useState<ModelData<UserPersonalNotificationModel, "id", "ref">>();

  useEffect(() => {
    if (!loadingUserNotifications && userEmailNotifications?.[0] !== undefined) {
      setUserEmailNotification(userEmailNotifications[0]);
    }
  }, [loadingUserNotifications, userEmailNotifications]);

  const updateUserEmailNotification = useCallback(
    async (selectedNotifications: NotificationSettings) => {
      const selectedNotificationsKeys = Object.keys(selectedNotifications || {}).map((key) => parseInt(key, 10));
      if (userEmailNotification !== undefined) {
        await userEmailNotification.ref.update({ selectedNotifications, selectedNotificationsKeys });
      } else {
        const user = await userRef.get();
        const data = user.asModelData();

        if (data) {
          await userNotificationsRef.add({
            createdAt: serverTimestamp(),
            createdBy: data.email,
            name: "My email notifications",
            providerTarget: { [NotificationProviderType.EMAIL]: [data.email] },
            selectedNotifications,
            selectedNotificationsKeys,
            customerRef,
          });
        }
      }
    },
    [customerRef, userEmailNotification, userRef, userNotificationsRef]
  );

  return {
    loadingUserNotifications,
    userEmailNotification,
    updateUserEmailNotification,
  };
}
