import { SyncLoader } from "react-spinners";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function Loader() {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center" flex="1" flexDirection="column" height="70vh" alignItems="center">
      <SyncLoader size={10} color={theme.palette.primary.main} loading />
    </Box>
  );
}
