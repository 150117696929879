import { useMemo } from "react";

import { type ChartSeriesRecord } from "../useChartsRenderer";
import { type EChartType, type FormatterFunc } from "./types";

export type SeriesSums = {
  positive: number[];
  negative: number[];
};

export function calculateSeriesSums(categories: string[], series: ChartSeriesRecord[]): SeriesSums {
  const length = categories.length + 1;
  const sums: SeriesSums = {
    positive: Array(length).fill(0),
    negative: Array(length).fill(0),
  };

  series.forEach((s) => {
    s.data.forEach((value, index) => {
      if (value > 0) {
        if (s.id === "forecast" && sums.positive[index] === 0) {
          sums.positive[index] = value;
        } else if (s.id !== "forecast") {
          sums.positive[index] += value;
        }
      } else if (value < 0) {
        sums.negative[index] += value;
      }
    });
  });

  return sums;
}

export function useSumSeries(
  type: EChartType,
  categories: string[],
  series: ChartSeriesRecord[],
  seriesVisibility: Record<string, boolean>,
  widgetView: boolean,
  formatter: FormatterFunc,
  fontColor: string
) {
  const seriesForSum = useMemo(
    () => series.filter((s) => seriesVisibility[s.name] === undefined || seriesVisibility[s.name]),
    [series, seriesVisibility]
  );

  return useMemo(() => {
    if (type !== "stacked-column" && type !== "stacked-area" && type !== "stacked-bar") {
      return [];
    }

    const sums = calculateSeriesSums(categories, seriesForSum);

    return [
      {
        type: "line",
        data: sums.positive,
        barWidth: 1,
        barGap: "-50%",
        silent: true,
        animation: !widgetView,
        itemStyle: {
          color: "transparent",
        },
        symbolSize: 0,
        label: {
          show: true,
          position: type === "stacked-bar" ? ["6", "-6"] : "top",
          color: fontColor,
          formatter: (params) => {
            if (params.value > 0.1) {
              return formatter(params.value, true);
            }
            return "";
          },
        },
        labelLayout: {
          hideOverlap: true,
        },
      },
      {
        type: "line",
        data: sums.negative,
        barWidth: 1,
        barGap: "-50%",
        silent: true,
        animation: !widgetView,
        itemStyle: {
          color: "transparent",
        },
        symbolSize: 0,
        label: {
          show: true,
          position: "bottom",
          color: fontColor,
          formatter: (params) => {
            if (params.value < -0.1) {
              return formatter(params.value, true);
            }
            return "";
          },
        },
      },
    ];
  }, [categories, fontColor, formatter, seriesForSum, type, widgetView]);
}
