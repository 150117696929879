import { Box, Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

const InfoBlock = ({ title, children }: { title: string; children: React.ReactNode }) => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <Grid item xs={12} md={3} sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Box
        p={2}
        sx={{
          backgroundColor: isDarkMode ? "#2D2D44" : grey[50],
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "4px 4px 0 0",
        }}
      >
        <Typography variant="subtitle1" fontWeight={500}>
          {title}
        </Typography>
      </Box>
      <Stack
        gap={1.25}
        p={2}
        sx={{
          borderWidth: "1px",
          borderStyle: "none solid solid solid",
          borderRadius: "0 0 4px 4px",
          borderColor: "divider",
          flexGrow: 1,
        }}
      >
        {children}
      </Stack>
    </Grid>
  );
};

export default InfoBlock;
