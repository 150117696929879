import { useTranslation } from "react-i18next";
import { Button, Container, Stack, Typography, useTheme } from "@mui/material";

import { ThemeModes } from "../../muiThemeTypes";

type Props = {
  onClickOpenRequest: () => void;
};

export default function HeaderEssentials({ onClickOpenRequest }: Props) {
  const { t } = useTranslation("services");

  const theme = useTheme();

  return (
    <Stack sx={{ backgroundColor: theme.palette.mode === ThemeModes.LIGHT ? "#FAFAFA" : "", mx: -2, mt: -1, py: 5 }}>
      <Container maxWidth="md">
        <Stack spacing={2} alignItems="center">
          <Typography variant="h3" fontWeight={500}>
            {t("SupportHeaderEssentials.title")}
          </Typography>

          <Typography variant="body2" color="text.secondary" textAlign="center">
            {t("SupportHeaderEssentials.content")}
          </Typography>

          <Button variant={"contained"} onClick={onClickOpenRequest} data-cy="raiseTicketButton">
            {t("SupportHeaderEssentials.button")}
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}
