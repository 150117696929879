export const filterDialogText = {
  ALL_OPTIONS: (fieldName, num) => `All ${fieldName} (${num})`,
  ALL_OPTIONS_SELECTED: (num) => `All ${num} selected`,
  SELECTED_OUT_OF: (numSelected, numTotal) => `${numSelected} out of ${numTotal} selected`,
  FILTERED_RESULTS: (num) => `${num} filtered results`,
  TITLE: (fieldName) => `Filter ${fieldName}`,
  REGEX_LABEL: "Regular expression",
  USING_REGEX_LABEL: "using regular expression",
  REGEX_WRONG_SYNTAX: "Expression is not supported by re2 syntax",
  FILTER_USING_REGEX: (fieldName) => `Filter ${fieldName} using regular expression`,
  RE2: "re2",
  REGEX_ALERT_PART_1: "The regular expression must follow the syntax accepted by the ",
  REGEX_ALERT_PART_2:
    ` library. By default, it returns values that are a partial match for the expression. You can search for a full match by using ^ (beginning of text) and $ (end of text).\n\n` +
    `re2 syntax does not support negative lookahead. Consider combining the "Exclude Selection" below with your regexp instead.\n\n`,
  REGEX_ALERT_EXAMPLE: "Example:",
  REGEX_ALERT_EXAMPLE_TEXT: `The regexp "(?i)prod|dev" on the Projects dimension filter will select all projects that include "prod" or "dev" (case-insensitive) in their name.`,
  REGEX_NOT_SUPPORTED: (fieldName) => `Advanced filters are not supported for ${fieldName}`,
  TYPE_TO_FILTER: "Type to filter",
  NO_EXACT_MATCH: (val) => `No exact match for "${val}"`,
  NO_MATCHES: (val) => `No matches for "${val}"`,
  CLICK_TO_ADD: "Click + to add it to the filters",
  EXCLUDE_SELECTION: "Exclude selection",
  SIMPLE: "Simple",
  USE_BASIC_FILTERING: "Use basic filtering",
  USE_REGEX: "Use regex",
};
