import { type ReactNode } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";

type SwitchDataSourceDialogProps = {
  isDialogOpened: boolean;
  content?: ReactNode;
  handleClose: (() => Promise<any> | void) | undefined;
  handleConfirm: (() => Promise<any> | void) | undefined;
};

const SwitchDataSourceDialog = ({
  isDialogOpened,
  content,
  handleClose,
  handleConfirm,
}: SwitchDataSourceDialogProps) => (
  <Dialog open={isDialogOpened} onClose={handleClose} fullWidth maxWidth="xs">
    <DialogTitle>{cloudflowTexts.SWITCH_DATA_SOURCE_TITLE}</DialogTitle>
    <DialogContent>
      <Typography>{content || cloudflowTexts.SWITCH_DATA_SOURCE_CONTENT}</Typography>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        {cloudflowTexts.SWITCH_DATA_SOURCE_CANCEL}
      </Button>
      <Button onClick={handleConfirm} color="primary" variant="contained">
        {cloudflowTexts.SWITCH_DATA_SOURCE_CONFIRM}
      </Button>
    </DialogActions>
  </Dialog>
);

export default SwitchDataSourceDialog;
