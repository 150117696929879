import { useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";
import { DashboardType } from "@doitintl/cmp-models";

import { useDashboardsContext } from "../../Context/DashboardContext";
import mixpanel from "../../utils/mixpanel";

export const isHomeDashboard = (dashboardName?: string): boolean => {
  const currentAndLegacyDashboardNames = ["default", "account", "home"];
  if (!dashboardName) {
    return false;
  }
  return currentAndLegacyDashboardNames.includes(dashboardName.toLowerCase());
};

export const useDashboardNavigation = () => {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const { dashboards, defaultDashboard } = useDashboardsContext();

  const historyGotoDashboard = useCallback(
    (nameOrIndex: string | number) => {
      let dashboardName = typeof nameOrIndex === "number" ? dashboards[nameOrIndex].name : nameOrIndex;

      // Clicking the "Home" button always goes to the Home dashboard
      // that dashboard is called "Account" right now
      if (dashboardName === "") {
        dashboardName = "default";
      }

      let actualDashboardName: string;

      if (dashboardName.localeCompare("default", undefined, { sensitivity: "base" }) === 0) {
        actualDashboardName = defaultDashboard ?? "Account";
      } else if (isHomeDashboard(dashboardName)) {
        actualDashboardName = "Account";
      } else {
        actualDashboardName = dashboardName;
      }

      if (actualDashboardName.replace(/\s+/g, "-").toLowerCase() === DashboardType.GkeLensV2) {
        mixpanel.track("dashboards.gke-lens");
      }

      if (actualDashboardName.replace(/\s+/g, "-").toLowerCase() === DashboardType.SnowflakeLens) {
        mixpanel.track("dashboards.snowflake-lens");
      }

      if (actualDashboardName.replace(/\s+/g, "-").toLowerCase() === DashboardType.AWSMAPLens) {
        mixpanel.track("dashboards.map-lens");
      }

      const path = `/customers/${customerId}/dashboards/${actualDashboardName}`;

      if (dashboardName === "" || dashboardName === "default") {
        history.replace(path);
        return;
      }

      history.push(path);
    },
    [dashboards, history, customerId, defaultDashboard]
  );

  return { historyGotoDashboard };
};
