import { useState, useMemo, useCallback, useEffect } from "react";
import uniq from "lodash/uniq";
import pullAll from "lodash/pullAll";
import { string as YupString } from "yup";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  Chip,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import { preventOnCloseOnBackdropClick, useFullScreen } from "../../utils/dialog";
import { globalText } from "../../assets/texts";

const useStyles = makeStyles((theme) => ({
  collaboratorsList: {
    paddingTop: 0,
    height: "auto",
  },
  roleSelect: {
    width: 104,
    marginRight: theme.spacing(1),
  },
  roleSecondaryAction: {
    right: 0,
  },
  selectOutlined: {
    "&$outlined": {
      ...theme.typography.body2,
      padding: theme.spacing(0.75, 1.5),
    },
  },
  outlined: {},
}));

const DefaultEmailCCDialog = ({
  defaultEmailsListFromDB,
  sendNewDefaultEmailCC,
  users,
  open,
  onClose,
  title = "Manage default emails cc addresses",
}) => {
  const classes = useStyles();
  const { fullScreen, isMobile } = useFullScreen();
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState([]);
  const [collaborators, setCollaborators] = useState([]);

  useEffect(() => {
    if (defaultEmailsListFromDB) {
      setCollaborators(defaultEmailsListFromDB);
      setValues(defaultEmailsListFromDB);
    }
  }, [defaultEmailsListFromDB]);

  const saveToDB = useCallback(
    async (updatedEmailList) => {
      sendNewDefaultEmailCC(updatedEmailList);
    },
    [sendNewDefaultEmailCC]
  );

  const onSave = () => {
    const updatedEmailList = uniq(values);
    setCollaborators(updatedEmailList);
    saveToDB(updatedEmailList);
    setValues([]);
    setCollaborators([]);
    onClose();
  };

  const onCancel = () => {
    const resetValues = defaultEmailsListFromDB || [];
    setCollaborators(resetValues);
    setValues(resetValues);
    onClose();
  };
  const options = useMemo(
    () =>
      pullAll(
        users.slice().map((user) => user.email),
        collaborators
      ),
    [users, collaborators]
  );

  const autoCompleteTagsLimit = isMobile ? 1 : 3;

  return (
    <Dialog
      open={open}
      onClose={preventOnCloseOnBackdropClick(onClose)}
      aria-labelledby="dialog-title"
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={1} wrap="nowrap" alignItems="center">
          <Grid item xs>
            <Autocomplete
              multiple
              freeSolo
              openOnFocus
              filterSelectedOptions
              fullWidth
              autoSelect
              limitTags={autoCompleteTagsLimit}
              options={options}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              value={values}
              onChange={async (event, newValues, reason) => {
                const addedValue = newValues[newValues.length - 1];
                switch (reason) {
                  case "selectOption": {
                    try {
                      await YupString().email().validate(addedValue);
                    } catch (error) {
                      // the added value is not a valid email address
                      return;
                    }
                    if (collaborators.findIndex((c) => c.email === addedValue) === -1) {
                      setValues(newValues);
                    }
                    break;
                  }
                  case "removeOption": {
                    setValues(newValues);
                    break;
                  }
                  default:
                    try {
                      await YupString().email().validate(addedValue);
                    } catch (error) {
                      return;
                    }
                    setValues(newValues);
                }
              }}
              size="small"
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={index} variant="outlined" size="small" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} margin="dense" variant="outlined" placeholder="Add people" />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {globalText.CANCEL}
        </Button>
        <Button color="primary" variant="contained" onClick={onSave}>
          {globalText.SAVE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultEmailCCDialog;
