import { stringify } from "qs";

import { AWSFeatureName } from "../../types";

const NEW_STACK_NAME = "DoitCmpNewRole";
const UPDATE_STACK_NAME = "DoitCmpExistingRole";
const DEFAULT_ROLE_NAME = "doitintl-cmp";

const SNS_ARN = "arn:aws:sns:us-east-1:068664126052:doitintl-aws-connect";

const AMAZON_TEMPLATE_URL_PREFIX = `https://doit-cmp-ops-pub.s3.us-east-1.amazonaws.com/${
  process.env.REACT_APP_FIREBASE_PROJECT_ID === "me-doit-intl-com" ? "prod" : "dev"
}`;
const UPDATE_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/cmp_create_policies.yml`;
const UPDATE_TEMPLATE_WITH_FSK8S_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/cmp_create_policies_fsk8s.yml`;
const CREATE_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/cmp_create_role_N_policies.yml`;
const CREATE_TEMPLATE_WITH_FSK8S_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/cmp_create_role_N_policies_fsk8s.yml`;

const UPDATE_LAMBDA_PREFIX = `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.uc.r.appspot.com/webhooks/v1/aws`;
const UPDATE_ROLE_LAMBDA_URL = `${UPDATE_LAMBDA_PREFIX}/updateAccountRole`;
const UPDATE_FEATURE_LAMBDA_URL = `${UPDATE_LAMBDA_PREFIX}/updateAwsFeature`;

const REAL_TIME_DATA_SNS_PROD_ACCOUNT_ID = "676206900418";
const REAL_TIME_DATA_SNS_DEV_ACCOUNT_ID = "913524940965";
const REAL_TIME_DATA_SNS_ACCOUNT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID === "me-doit-intl-com"
    ? REAL_TIME_DATA_SNS_PROD_ACCOUNT_ID
    : REAL_TIME_DATA_SNS_DEV_ACCOUNT_ID;

function generateUrl(params: any) {
  const paramsStr = stringify(params);
  return `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?${paramsStr}`;
}

function addTimeStamp(str: string) {
  return `${str}-${Date.now()}`;
}

export function getQuickCreateUrl(
  customerId: string,
  selectedFeatures: string[],
  isFSK8SFeatureEnabled: boolean,
  realTimeDataBucketName: string
) {
  const stackName = addTimeStamp(NEW_STACK_NAME);
  const params = {
    templateUrl: isFSK8SFeatureEnabled ? CREATE_TEMPLATE_WITH_FSK8S_URL : CREATE_TEMPLATE_URL,
    stackName,
    param_NotificationUrl: UPDATE_ROLE_LAMBDA_URL,
    param_SnsArn: SNS_ARN,
    param_RoleToAddPolicyTo: DEFAULT_ROLE_NAME,
    param_ExternalId: customerId,
    param_SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    param_Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
    param_FSK8SCore: selectedFeatures.includes(AWSFeatureName.fsk8s_core),
    param_FSK8SClusterConnect: selectedFeatures.includes(AWSFeatureName.fsk8s_auto_connect),
    param_RealTimeData: selectedFeatures.includes(AWSFeatureName.realTimeData),
    param_RealTimeDataBucketName: realTimeDataBucketName,
    param_RealTimeDataSNSAccountId: REAL_TIME_DATA_SNS_ACCOUNT_ID,
  };
  return [generateUrl(params), stackName];
}

export function getQuickUpdateUrl(
  customerId: string,
  roleName: string,
  selectedFeatures: string[],
  isFSK8SFeatureEnabled: boolean,
  realTimeDataBucketName: string
) {
  const stackName = addTimeStamp(UPDATE_STACK_NAME);
  const params = {
    templateUrl: isFSK8SFeatureEnabled ? UPDATE_TEMPLATE_WITH_FSK8S_URL : UPDATE_TEMPLATE_URL,
    stackName,
    param_NotificationUrl: UPDATE_FEATURE_LAMBDA_URL,
    param_SnsArn: SNS_ARN,
    param_RoleToAddPolicyTo: roleName,
    param_ExternalId: customerId,
    param_SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    param_Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
    param_FSK8SCore: selectedFeatures.includes(AWSFeatureName.fsk8s_core),
    param_FSK8SClusterConnect: selectedFeatures.includes(AWSFeatureName.fsk8s_auto_connect),
    param_RealTimeData: selectedFeatures.includes(AWSFeatureName.realTimeData),
    param_RealTimeDataBucketName: realTimeDataBucketName,
  };

  return [generateUrl(params), stackName];
}

function generateCLICommand(stackName: string, templateUrl: string, params: Record<string, string | boolean>) {
  const paramsList: string[] = [];
  for (const [key, value] of Object.entries(params)) {
    paramsList.push(`ParameterKey=${key},ParameterValue=${value}`);
  }

  return `aws cloudformation create-stack --stack-name ${stackName} --template-url ${templateUrl} --parameters ${paramsList.join(
    " "
  )}  --region us-east-1 --capabilities CAPABILITY_NAMED_IAM`;
}

export function getCLICreateCommand(
  customerId: string,
  selectedFeatures: string[],
  isFSK8SFeatureEnabled: boolean,
  realTimeDataBucketName: string
) {
  const stackName = addTimeStamp(NEW_STACK_NAME);
  const params = {
    ExternalId: customerId,
    NotificationUrl: UPDATE_ROLE_LAMBDA_URL,
    RoleToAddPolicyTo: DEFAULT_ROLE_NAME,
    SnsArn: SNS_ARN,
    SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
    FSK8SCore: selectedFeatures.includes(AWSFeatureName.fsk8s_core),
    FSK8SClusterConnect: selectedFeatures.includes(AWSFeatureName.fsk8s_auto_connect),
    RealTimeData: selectedFeatures.includes(AWSFeatureName.realTimeData),
    RealTimeDataBucketName: realTimeDataBucketName,
  };

  return [
    generateCLICommand(stackName, isFSK8SFeatureEnabled ? CREATE_TEMPLATE_WITH_FSK8S_URL : CREATE_TEMPLATE_URL, params),
    stackName,
  ];
}

export function getRealTimeDataCommand(bucketName: string) {
  return `aws s3api put-bucket-notification-configuration \\
    --bucket=${bucketName} \\
    --notification-configuration notification_configuration.json`;
}

export function getCLIUpdateCommand(
  roleName: string,
  selectedFeatures: string[],
  isFSK8SFeatureEnabled: boolean,
  realTimeDataBucketName: string
) {
  const stackName = addTimeStamp(UPDATE_STACK_NAME);
  const params = {
    RoleToAddPolicyTo: roleName,
    NotificationUrl: UPDATE_FEATURE_LAMBDA_URL,
    SnsArn: SNS_ARN,
    SpotScaling: selectedFeatures.includes(AWSFeatureName.spotScaling),
    Quotas: selectedFeatures.includes(AWSFeatureName.quotas),
    FSK8SCore: selectedFeatures.includes(AWSFeatureName.fsk8s_core),
    FSK8SClusterConnect: selectedFeatures.includes(AWSFeatureName.fsk8s_auto_connect),
    RealTimeData: selectedFeatures.includes(AWSFeatureName.realTimeData),
    RealTimeDataBucketName: realTimeDataBucketName,
  };

  return [
    generateCLICommand(stackName, isFSK8SFeatureEnabled ? UPDATE_TEMPLATE_WITH_FSK8S_URL : UPDATE_TEMPLATE_URL, params),
    stackName,
  ];
}
