import { type ReactNode } from "react";

import { Card, type SxProps } from "@mui/material";
import { type Theme } from "@mui/material/styles";

export type Props = {
  children: ReactNode;
  style?: SxProps<Theme>;
};

export const CustomCard = ({ children, style }: Props) => (
  <Card sx={{ bgcolor: "general.backgroundDark", p: 2, ...style }}>{children}</Card>
);
