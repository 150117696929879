import { type FC } from "react";

import { ModelType, type ReferencedNodeValue, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Tooltip } from "@mui/material";

import { ReferencedFieldContextProvider } from "./ReferencedFieldContextProvider";
import { ReferencedFieldWrapper, type ReferencedFieldWrapperFieldProps } from "./ReferencedFieldWrapper";
import { type NodeWitOutputModel } from "./useReferencedFieldContext";

const model: UnwrappedApiServiceModelDescriptor = { type: ModelType.STRUCTURE, members: {} };

export const ReferencedFieldStandalone: FC<
  ReferencedFieldWrapperFieldProps & {
    referenceableNodes: NodeWitOutputModel[];
    disabled?: boolean;
    tooltip?: string;
    rootReferencedNodeValue?: ReferencedNodeValue;
  }
> = ({ referenceableNodes, tooltip, disabled, rootReferencedNodeValue, ...commonProps }) => (
  <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
    <Tooltip title={tooltip} placement="top-start">
      <ReferencedFieldWrapper
        model={model}
        commonFieldProps={commonProps}
        disabled={disabled}
        rootReferencedNodeValue={rootReferencedNodeValue}
      />
    </Tooltip>
  </ReferencedFieldContextProvider>
);
