import { useReducer, useState } from "react";

import { Dialog } from "@mui/material";

import { Stepper } from "../../Components/Stepper";
import { useFullScreen } from "../../utils/dialog";
import mixpanel from "../../utils/mixpanel";
import StepEscalate from "./EscalateStepper/StepEscalate";
import StepPayment from "./EscalateStepper/StepPayment";

type State = {
  comment: string;
  reason: string;
  billingProfile?: string;
  hasAgreedToTerms: boolean;
  isAuthorizedToMakeDecisions: boolean;
  hasAgreedToCosts: boolean;
};

type Action =
  | { type: "SET_COMMENT"; payload: string }
  | { type: "SET_REASON"; payload: string }
  | { type: "SET_BILLING_PROFILE"; payload?: string }
  | { type: "SET_COST_CHECKED"; payload: boolean }
  | { type: "SET_AUTHORIZED_CHECKED"; payload: boolean }
  | { type: "SET_AGREED_TO_TERMS"; payload: boolean };

const initialState: State = {
  comment: "",
  reason: "",
  billingProfile: undefined,
  hasAgreedToCosts: false,
  isAuthorizedToMakeDecisions: false,
  hasAgreedToTerms: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_COMMENT":
      return { ...state, comment: action.payload };
    case "SET_REASON":
      return { ...state, reason: action.payload };
    case "SET_BILLING_PROFILE":
      return { ...state, billingProfile: action.payload };
    case "SET_COST_CHECKED":
      return { ...state, hasAgreedToCosts: action.payload };
    case "SET_AUTHORIZED_CHECKED":
      return { ...state, isAuthorizedToMakeDecisions: action.payload };
    case "SET_AGREED_TO_TERMS":
      return { ...state, hasAgreedToTerms: action.payload };
    default:
      return state;
  }
}

type Props = {
  onClose: () => void;
  onSubmit: (payload: State) => void;
  loading: boolean;
  requestID: string;
  requestTitle: string;
};

export default function EscalateDialogEssentials({ onClose, onSubmit, requestID, loading, requestTitle }: Props) {
  const { fullScreen } = useFullScreen();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [_currentStep, setCurrentStep] = useState(0);

  return (
    <Dialog
      open
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          width: fullScreen ? "100%" : "900px",
          maxWidth: "900px",
          margin: "auto",
          paddingTop: 6,
        },
      }}
    >
      <Stepper
        footerMaxWidth={900}
        relativeFooter
        contentSx={{
          padding: 0,
        }}
        maxWidth={900}
        loading={loading}
        onCancel={() => {
          onClose();
        }}
        onSubmit={() => {
          onSubmit(state);
          mixpanel.track("ava.essentials.escalate.confirm-payment");
        }}
        getCurrentStep={setCurrentStep}
        submitButtonLabel="Confirm payment and escalate"
        steps={[
          {
            children: (
              <StepEscalate
                requestTitle={requestTitle}
                comment={state.comment}
                setComment={(payload: string) => {
                  dispatch({ type: "SET_COMMENT", payload });
                }}
                reason={state.reason}
                setReason={(payload: string) => {
                  dispatch({ type: "SET_REASON", payload });
                  mixpanel.track("ava.essentials.escalate.escalation-details", { reason: payload });
                }}
                requestID={requestID}
              />
            ),
            label: "Escalation details",
            order: 0,
            required: true,
            state: state.reason ? "complete" : "editing",
            nextButtonLabel: "Next step",
          },
          {
            children: (
              <StepPayment
                hasAgreedToTerms={state.hasAgreedToTerms}
                isAuthorizedToMakeDecisions={state.isAuthorizedToMakeDecisions}
                billingProfile={state.billingProfile}
                hasAgreedToCosts={state.hasAgreedToCosts}
                setBillingProfile={(payload: string) => {
                  dispatch({ type: "SET_BILLING_PROFILE", payload });
                }}
                setAgreedToTerms={(payload: boolean) => {
                  dispatch({ type: "SET_AGREED_TO_TERMS", payload });
                  mixpanel.track("ava.essentials.escalate.agreement-checkbox");
                }}
                setAuthorizedChecked={(payload: boolean) => {
                  dispatch({ type: "SET_AUTHORIZED_CHECKED", payload });
                  mixpanel.track("ava.essentials.escalate.authorized-checkbox");
                }}
                setCostChecked={(payload: boolean) => {
                  dispatch({ type: "SET_COST_CHECKED", payload });
                  mixpanel.track("ava.essentials.escalate.understanding-checkbox");
                }}
              />
            ),
            label: "Contract and payment",
            order: 1,
            required: true,
            state:
              state.hasAgreedToCosts &&
              state.isAuthorizedToMakeDecisions &&
              state.billingProfile &&
              state.hasAgreedToTerms
                ? "complete"
                : "editing",
          },
        ]}
      />
    </Dialog>
  );
}
