import { useEffect, useMemo } from "react";

import { TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { MenuItem, TextField } from "@mui/material";

import { contractTypeTiers, packageTypeMap } from "../../../const";
import { stateDefaultValues, useContractFormContext } from "../../../ContractsFormContext";
import { calculateChargePerTerm, isMonthlyFlatRateApplicable } from "../../../utils";

const tiersOrder = ["standard", "enhanced", "premium", "enterprise"];

export const TierSelector = () => {
  const {
    state: { currency, commitmentMonths, paymentTerm, type, monthlyFlatRate, isEditForbidden, errors, tierId, tier },
    setState,
  } = useContractFormContext();
  const [tiers] = useCollectionDataOnce(
    getCollection(TierModel)
      .where("packageType", "==", packageTypeMap[type])
      .where("name", "in", contractTypeTiers[type]),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const sortedTiers = useMemo(
    () =>
      (tiers ?? [])
        .filter((x) => !x.displayName.toLowerCase().includes("dci"))
        .sort((a, b) => tiersOrder.indexOf(a.name) - tiersOrder.indexOf(b.name)),
    [tiers]
  );

  useEffect(() => {
    if (sortedTiers.length && tierId && !tier) {
      const tier = sortedTiers.find((t) => t.id === tierId);
      setState((prevState) => ({ ...prevState, tier }));
    }
  }, [sortedTiers, setState, tierId, tier]);

  const handleChange = ({ target: { value } }) => {
    const tierId = value;
    const tierDetails = sortedTiers?.find((t) => t.id === tierId);
    const isEnterprise = tierDetails?.name === "enterprise";
    const tierPrice = isEnterprise ? 0 : tierDetails?.price?.[currency];
    const chargePerTerm = calculateChargePerTerm(paymentTerm, commitmentMonths, tierPrice);
    const updatedMonthlyFlatRate = isMonthlyFlatRateApplicable(tierDetails)
      ? isEnterprise
        ? monthlyFlatRate === undefined || monthlyFlatRate === stateDefaultValues.monthlyFlatRate
          ? 0 // Set to 0 if state.monthlyFlatRate is the default value
          : monthlyFlatRate // Retain the updated value
        : monthlyFlatRate || stateDefaultValues.monthlyFlatRate // For non-enterprise tiers
      : undefined;
    const errors = { chargePerTerm: false, monthlyFlatRate: false, tierId: false };

    setState((prevState) => ({
      ...prevState,
      tierId,
      tier: tierDetails,
      chargePerTerm,
      monthlyFlatRate: updatedMonthlyFlatRate,
      errors: {
        ...prevState.errors,
        ...errors,
      },
    }));
  };
  return (
    <TextField
      label="Tier"
      data-cy="tier"
      value={tierId}
      onChange={handleChange}
      error={errors.tierId}
      disabled={isEditForbidden}
      select
      fullWidth
      margin="normal"
      variant="outlined"
    >
      {sortedTiers?.map((tier) => (
        <MenuItem key={tier.id} value={tier.id}>
          {tier.displayName}
        </MenuItem>
      ))}
    </TextField>
  );
};
