import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import {
  AnalyticsResourceType,
  ChannelModel,
  type CurrencyCode,
  type InvoiceProduct,
  type StripePaymentIntent,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import CloudDownload from "@mui/icons-material/CloudDownload";
import FilterIcon from "@mui/icons-material/FilterListRounded";
import DownloadIcon from "@mui/icons-material/GetAppRounded";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import ResetIcon from "@mui/icons-material/SettingsBackupRestoreRounded";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabledRounded";
import SyncIcon from "@mui/icons-material/SyncRounded";
import {
  Badge,
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import Stack from "@mui/system/Stack";
import { DatePicker } from "@mui/x-date-pickers";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import AmazonWebServicesIcon from "../../../assets/amazon-web-services.png";
import DoitLogo from "../../../assets/doit-logo-hero-square.svg";
import DoitNavigatorIcon from "../../../assets/doit-navigator.svg";
import DoitSolveIcon from "../../../assets/doit-solve.svg";
import DoitSolveAcceleratorIcon from "../../../assets/doit-solve-accelerator.svg";
import FlexsaveStandaloneIcon from "../../../assets/flexsave-logo-small.png";
import GSuiteIcon from "../../../assets/g-suite.png";
import GoogleCloudIcon from "../../../assets/google-cloud.png";
import GoogleLookerIcon from "../../../assets/google-looker.png";
import MicrosoftAzureIcon from "../../../assets/microsoft-azure.png";
import Office365Icon from "../../../assets/office-365.png";
import SuperQueryIcon from "../../../assets/superquery.png";
import { invoicesText } from "../../../assets/texts";
import { invoicesTxt } from "../../../assets/texts/Billing/invoices";
import Hide from "../../../Components/HideChildren/Hide";
import { useAttributionGroups } from "../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { MultiSelect } from "../../../Components/MultiSelect";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { consoleErrorWithSentry } from "../../../utils";
import { assetTypeName, formatCurrency, sanitizeDate } from "../../../utils/common";
import { exportCSVFile } from "../../../utils/csv";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { type RequiredProperties } from "../../../utils/RequiredProperties";
import { handleDownloadInvoice } from "../downloadInvoice";
import { InvoicesFaqButton } from "../FAQ/InvoicesFAQ";
import { type RowItem, useInvoiceRows } from "../InvoiceDetails/invoiceRow";
import { type Invoice, type InvoiceFilters, type InvoiceStatus, type SetFilters } from "../types";
import { InvoiceInReportsDialog } from "./InvoiceInReportsDialog";
import { NoInvoicesFound } from "./NoInvoicesFound";

const columns = [
  {
    id: "IVNUM",
    align: "inherit",
    label: "Invoice",
    tooltip: "Invoice number",
    hidden: false,
    doitEmployee: false,
  },
  {
    id: "_STATUS_ID",
    align: "inherit",
    label: "Status",
    tooltip: "Invoice status",
    hidden: false,
    doitEmployee: false,
  },
  {
    id: "CDES",
    align: "inherit",
    label: "Billing profile",
    tooltip: "Billing Profile Name",
    hidden: true,
    doitEmployee: false,
  },
  {
    id: "IVDATE",
    align: "inherit",
    label: "Invoice date",
    tooltip: "Invoice Date",
    hidden: true,
    doitEmployee: false,
  },
  {
    id: "PAYDATE",
    align: "inherit",
    label: "Due date",
    tooltip: "Due Date",
    hidden: true,
    doitEmployee: false,
  },
  {
    id: "ESTPAYDATE",
    align: "inherit",
    label: "Expected",
    tooltip: "Exptected Pay Date",
    hidden: true,
    doitEmployee: true,
  },
  {
    id: "LINKEDINVOICES",
    align: "inherit",
    label: "Linked invoices",
    tooltip: "Linked invoices",
    hidden: false,
    doitEmployee: true,
    ccFees: true,
  },
  {
    id: "TOTPRICE",
    align: "right",
    label: "Total",
    tooltip: "Total Amount",
    hidden: true,
    doitEmployee: false,
  },
  {
    id: "TOTALWFEES",
    align: "right",
    label: "Total w/ fees",
    tooltip: "Total with fees",
    hidden: false,
    doitEmployee: true,
    ccFees: true,
  },
  {
    id: "DEBIT",
    align: "right",
    label: "Balance",
    tooltip: "Invoice Balance",
    hidden: false,
    doitEmployee: false,
  },
] as const;

const products = [
  {
    title: "Amazon Web Services",
    value: "amazon-web-services",
  },
  {
    title: "Google Cloud",
    value: "google-cloud",
  },
  {
    title: "Looker",
    value: "looker",
  },
  {
    title: "Google Workspace",
    value: "g-suite",
  },
  {
    title: "Standalone",
    value: "standalone",
  },
  {
    title: "Microsoft Azure",
    value: "microsoft-azure",
  },
  {
    title: "Office 365",
    value: "office-365",
  },
  {
    title: "superQuery",
    value: "superquery",
  },
  {
    title: "DoiT Navigator",
    value: "navigator",
  },
  {
    title: "DoiT Solve",
    value: "solve",
  },
  {
    title: "DoiT Solve Accelerator",
    value: "solve-accelerator",
  },
  {
    title: "DoiT Cloud Intelligence",
    value: "doit-cloud-intelligence",
  },
  {
    title: "DoiT Cloud Intelligence Single Purchase",
    value: "doit-cloud-intelligence-sp",
  },
] as const;

const standaloneProducts = ["google-cloud-standalone", "amazon-web-services-standalone"];

const productIcon = (type) => {
  switch (type) {
    case "google-cloud":
      return GoogleCloudIcon;
    case "amazon-web-services":
      return AmazonWebServicesIcon;
    case "g-suite":
      return GSuiteIcon;
    case "office-365":
      return Office365Icon;
    case "microsoft-azure":
      return MicrosoftAzureIcon;
    case "superquery":
      return SuperQueryIcon;
    case "looker":
      return GoogleLookerIcon;
    case "standalone":
    case "google-cloud-standalone":
    case "amazon-web-services-standalone":
      return FlexsaveStandaloneIcon;
    case "navigator":
      return DoitNavigatorIcon;
    case "solve":
      return DoitSolveIcon;
    case "solve-accelerator":
      return DoitSolveAcceleratorIcon;
    case "doit-cloud-intelligence":
    case "doit-cloud-intelligence-sp":
      return DoitLogo;
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  selected: {},
  menu: {},
  centerTableCell: {
    textAlign: "center",
  },
  badge: {
    top: "15%",
    right: -6,
    backgroundColor: theme.palette.text.secondary,
  },
  logo: {
    margin: theme.spacing(0.5, 0, 0, 0.25),
    height: 20,
    width: 20,
  },
  toggleButtonGroup: {
    margin: theme.spacing(1, 0, 0.5),
  },
  productFilterIcon: {
    height: 20,
    width: 20,
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    border: `1px solid ${
      theme.palette.mode === ThemeModes.LIGHT ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)"
    }`,
  },
}))(ToggleButton);

const today = DateTime.utc().startOf("day");
const minDate = DateTime.utc(2011, 1, 1).startOf("year");
const maxDate = today.plus({ year: 1 }).startOf("year");

const isLinkedInvoiceNotCanceled = (paymentIntent, invoices) =>
  paymentIntent.linked_invoice &&
  invoices.find((invoice: Invoice) => invoice.IVNUM === paymentIntent.linked_invoice.invoice_id);

const calculateTotalWFees = (invoiceData: RowItem, invoices: Invoice[]) =>
  invoiceData?.stripePaymentIntents?.reduce((acc: number, paymentIntent: StripePaymentIntent) => {
    if (
      !isLinkedInvoiceNotCanceled(paymentIntent, invoices) ||
      paymentIntent.status === "canceled" ||
      !paymentIntent.linked_invoice
    ) {
      return acc;
    }
    return acc + paymentIntent.linked_invoice.amount_fees / 100;
  }, invoiceData.TOTPRICE) || invoiceData.TOTPRICE;

const getLinkedInvoicesLinks = (invoiceData, invoices: Invoice[]) => {
  const links = invoiceData.stripePaymentIntents
    ?.filter((paymentIntent) => isLinkedInvoiceNotCanceled(paymentIntent, invoices))
    .map((paymentIntent) => (
      <Typography
        key={paymentIntent.linked_invoice.invoice_id}
        component={Link}
        to={`${location.pathname}/${invoiceData.entity.id}/${paymentIntent.linked_invoice.invoice_id}`}
        color="inherit"
        variant="body2"
        style={{ cursor: "pointer" }}
      >
        {paymentIntent.linked_invoice.invoice_id}
      </Typography>
    ))
    .reduce((prev, curr, index, array) => (index === array.length - 1 ? [...prev, curr] : [...prev, curr, ", "]), []);

  return links?.length === 0 ? null : links;
};

type Props = {
  invoices: Invoice[];
  setFilters: SetFilters;
  filters: InvoiceFilters;
  isFetching: boolean;
  invoicesProducts: InvoiceProduct[];
  orgEntities: string[] | undefined;
};

export const InvoicesList = ({ invoices, setFilters, filters, isFetching, invoicesProducts, orgEntities }: Props) => {
  const classes = useStyles();
  const api = useApiContext();
  const { isDoitEmployee, currentUser } = useAuthContext();

  const { isMobile: matches } = useFullScreen();
  const { customer, entities, isProductOnlyCustomer } = useCustomerContext();
  const [attributionGroups] = useAttributionGroups();
  const [sorting, setSorting] = useState<{ active: string; direction: "asc" | "desc" }>({
    active: "IVDATE",
    direction: "desc",
  });
  const [pageFilter, setPageFilter] = useState<{ page: number; rowsPerPage: number }>({ page: 0, rowsPerPage: 10 });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [channel, setChannel] = useState<boolean>();
  const errorSnackbar = useErrorSnackbar();
  const [inReportsDialogOpen, setInReportsDialogOpen] = useState(false);

  useEffect(() => {
    mixpanel.track("invoices.open");
  }, []);

  useEffect(() => {
    if (isDoitEmployee) {
      try {
        const startTime = DateTime.utc().minus({ minutes: 5 }).toJSDate();
        return getCollection(ChannelModel)
          .where("uid", "==", currentUser?.uid)
          .where("complete", "==", false)
          .where("type", "==", "customer.invoices.sync")
          .where("customer", "==", customer.id)
          .where("timestamp", ">=", startTime)
          .orderBy("timestamp", "desc")
          .limit(1)
          .onSnapshot((querySnap) => {
            if (querySnap.empty) {
              setChannel(false);
              return;
            }

            setChannel(true);
          });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    }
  }, [customer.id, isDoitEmployee, currentUser?.uid]);

  const handleChangePage = (event, page) => {
    setPageFilter({ ...pageFilter, page });
  };

  const handleChangeRowsPerPage = (event) => {
    setPageFilter({ rowsPerPage: event.target.value, page: 0 });
  };

  const handleRequestSort = (active) => () => {
    let direction: "desc" | "asc" = "desc";
    if (sorting.active === active && sorting.direction === "desc") {
      direction = "asc";
    }
    setSorting({ active, direction });
  };

  const handleChangeEntity = ({ target }) => {
    setFilters({
      products: [],
      entityId: target.value ?? undefined,
    });

    setPageFilter({ ...pageFilter, page: 0 });
  };

  const handleChangeDate = (name: "startDate" | "endDate") => (date) => {
    if (date?.isValid) {
      const chosenDate = DateTime.utc(date.year, date.month);
      if (name === "startDate" && filters.endDate < date) {
        setFilters({
          products: [],
          ["startDate"]: chosenDate,
          ["endDate"]: chosenDate,
        });
      } else if (name === "endDate" && filters.startDate > date) {
        setFilters({
          products: [],
          ["startDate"]: chosenDate,
          ["endDate"]: chosenDate,
        });
      } else {
        setFilters({
          products: [],
          [name]: chosenDate,
        });
      }

      setPageFilter({ ...pageFilter, page: 0 });
    }
  };

  const handleChangeStatus = (status: string[]) => {
    setFilters({
      products: [],
      status: status as InvoiceStatus[],
    });

    setPageFilter({ ...pageFilter, page: 0 });
  };

  const handleChangeProducts = (event, newProducts) => {
    let productsToShow = [...newProducts];

    // in case 'standalone' item was selected we need to add to the list both AWS and GCP standalone
    if (productsToShow.includes("standalone")) {
      productsToShow.push(...standaloneProducts);
    } else {
      productsToShow = productsToShow.filter((name) => !standaloneProducts.includes(name));
    }

    setFilters({
      products: productsToShow,
    });

    setPageFilter({ ...pageFilter, page: 0 });
  };

  const { rows } = useInvoiceRows(invoices);

  const linkedInvoices = useMemo(
    () =>
      rows.flatMap((row) => {
        if (row.stripePaymentIntents) {
          return row.stripePaymentIntents
            .filter(
              (payment): payment is RequiredProperties<typeof payment, "linked_invoice"> =>
                !!payment.linked_invoice?.invoice_id
            )
            .map((payment) => payment.linked_invoice.invoice_id);
        }
        return [];
      }),
    [rows]
  );

  const rowsFiltered = useMemo(() => {
    if (filters.startDate < minDate || filters.endDate > maxDate) {
      return [];
    }

    return rows.filter((r) => !linkedInvoices?.includes(r.IVNUM || ""));
  }, [linkedInvoices, rows, filters.startDate, filters.endDate]);

  const currentBalancesByCurrency = useMemo(() => {
    const balancesByCurrency = rowsFiltered.reduce<Partial<Record<CurrencyCode, number>>>((acc, invoiceRowData) => {
      if (!acc[invoiceRowData.CODE]) {
        acc[invoiceRowData.CODE] = 0;
      }
      if (invoiceRowData._STATUS !== "Paid") {
        const currentValue = acc[invoiceRowData.CODE] ?? 0;
        const balance: number = invoiceRowData._BALANCE;
        acc[invoiceRowData.CODE] = currentValue + balance;
      }
      return acc;
    }, {});

    // remove all currencies with 0 net balance
    return Object.keys(balancesByCurrency).reduce<Partial<Record<CurrencyCode, number>>>((acc, key) => {
      if (balancesByCurrency[key] !== 0) {
        acc[key] = balancesByCurrency[key];
      }
      return acc;
    }, {});
  }, [rowsFiltered]);

  const handleRequestSync = useCallback(async () => {
    setChannel(true);
    try {
      await api.request({
        method: "get",
        url: `/v1/customers/${customer.id}/invoices?sinceMonths=24`,
      });
    } catch (error) {
      consoleErrorWithSentry(error);
      setChannel(false);
    }
  }, [api, customer.id]);

  const downloadInvoices = useCallback(
    () => () => {
      const headers = {
        invoice: "Invoice Number",
        status: "Status",
        entity: "Entity",
        invoiceDate: "Invoice Date",
        dueDate: "Due Date",
        currency: "Currency",
        total: "Total",
        balance: "Balance",
      };
      const invoiceItems = rowsFiltered.map((rowData) => ({
        invoice: rowData.IVNUM,
        status: rowData._STATUS,
        entity: rowData.CDES,
        invoiceDate: rowData.IVDATE_STRING,
        dueDate: rowData.PAYDATE_STRING,
        currency: rowData.SYMBOL,
        total: rowData.TOTPRICE,
        balance: rowData._BALANCE,
      }));
      const fileTitle = `invoices-${filters.startDate.year}-${filters.endDate.year}`;

      exportCSVFile(headers, invoiceItems, fileTitle);
    },
    [filters.startDate.year, filters.endDate.year, rowsFiltered]
  );

  const showCCFeesColumns =
    linkedInvoices?.length > 0 && linkedInvoices.some((li) => !!invoices.find((i) => i.IVNUM === li));

  // return only the existing products in invoices
  const productFilters = useMemo(() => {
    const uniqProducts = new Set(invoicesProducts);
    return products.filter((product) => {
      if (product.value === "standalone") {
        return uniqProducts.has("google-cloud-standalone") || uniqProducts.has("amazon-web-services-standalone");
      }
      return uniqProducts.has(product.value);
    });
  }, [invoicesProducts]);

  const orgConstraint = useMemo<boolean>(() => !!orgEntities?.length, [orgEntities]);

  const filteredEntities = useMemo<Entity[]>(
    () => (orgEntities?.length ? entities.filter((entity) => orgEntities.includes(entity.id)) : entities),
    [entities, orgEntities]
  );

  const filtersCardContent = (
    <CardContent className={classes.cardContent} sx={{ px: 0 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
        <Grid item xs={6} md={2} lg={2}>
          <TextField
            size="small"
            select
            name="entity"
            label="Billing Profile"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              value: filters.entityId ?? "",
              onChange: handleChangeEntity,
              displayEmpty: true,
              MenuProps: {
                className: classes.menu,
                MenuListProps: {
                  dense: true,
                },
              },
            }}
            margin="dense"
            data-cy="invoices-entity-filter"
            fullWidth
          >
            {!orgConstraint && <MenuItem value="">Show All</MenuItem>}
            {filteredEntities.map((entity) => (
              <MenuItem
                data-cy={`invoices-entity-filter-option-${entity.id}`}
                key={entity.id}
                value={entity.id}
                classes={{
                  selected: classes.selected,
                }}
              >
                {entity.priorityId} - {entity.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <DatePicker
            label="From"
            renderInput={(params) => (
              <TextField size="small" margin="dense" fullWidth data-cy="invoices-start-date-filter" {...params} />
            )}
            value={filters.startDate}
            onChange={handleChangeDate("startDate")}
            inputFormat="LLLL yyyy"
            views={["month", "year"]}
            minDate={minDate}
            maxDate={filters.endDate.endOf("year")}
          />
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <DatePicker
            label="To"
            renderInput={(params) => (
              <TextField size="small" margin="dense" fullWidth data-cy="invoices-end-date-filter" {...params} />
            )}
            value={filters.endDate}
            onChange={handleChangeDate("endDate")}
            inputFormat="LLLL yyyy"
            views={["month", "year"]}
            minDate={filters.startDate.startOf("year")}
            maxDate={maxDate}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <MultiSelect
            label="Filter by status"
            options={["Open", "Paid", "Partially Paid", "Past Due", "Processing", "Proforma", "Canceled"]}
            selectedOptions={filters.status}
            onChange={handleChangeStatus}
            textFieldProps={{ size: "small", margin: "dense" }}
            data-cy="invoices-status-filter"
          />
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          {!isProductOnlyCustomer && (
            <ToggleButtonGroup
              value={filters.products}
              onChange={handleChangeProducts}
              arial-label="products filter"
              size="small"
              className={classes.toggleButtonGroup}
            >
              {productFilters.map((p) => (
                <StyledToggleButton
                  value={p.value}
                  aria-label={p.title}
                  key={p.value}
                  data-cy={`products-filter-${p.value}`}
                >
                  <Tooltip title={p.title}>
                    <img src={productIcon(p.value)} className={classes.productFilterIcon} alt={p.title} />
                  </Tooltip>
                </StyledToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Grid>
      </Grid>
    </CardContent>
  );

  const invoiceAG = useMemo(
    () => attributionGroups.find((ag) => ag.data.type === AnalyticsResourceType.MANAGED),
    [attributionGroups]
  );

  const pageHeaderAction = useMemo(
    () => (
      <>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
          {isDoitEmployee && !isProductOnlyCustomer && !!invoiceAG && (
            <Button
              variant="contained"
              onClick={() => {
                setInReportsDialogOpen(true);
              }}
              endIcon={<OpenNewIcon />}
              sx={{ mr: 1 }}
            >
              {invoicesTxt.OPEN_IN_REPORTS}
            </Button>
          )}
          <InvoicesFaqButton />
          <Button
            href={invoicesText.INVOICE_HELP_URL}
            target="_blank"
            variant="text"
            sx={{
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "transparent",
              },
            }}
            endIcon={<OpenNewIcon sx={{ fontSize: 14, mr: 1 }} />}
          >
            {invoicesText.LEARN_MORE}
          </Button>
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <Tooltip key="reset-filters" title="Reset filters">
            <span>
              <IconButton
                aria-label="Reset filters"
                onClick={() => {
                  setFilters(null);
                }}
                size="large"
              >
                <ResetIcon />
              </IconButton>
            </span>
          </Tooltip>

          {matches ? (
            <IconButton
              key="filters"
              aria-label="Open Filters"
              onClick={() => {
                setDrawerOpen(true);
              }}
              size="large"
            >
              <FilterIcon />
            </IconButton>
          ) : (
            <Tooltip key="download-csv" title="Download CSV">
              <span>
                <IconButton
                  aria-label="Back"
                  onClick={downloadInvoices()}
                  disabled={rowsFiltered.length <= 0}
                  size="large"
                >
                  <CloudDownload />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {isDoitEmployee && channel && (
            <Tooltip key="invoice-sync-disabled" title="Hold on! Invoices are being synced with priority">
              <span>
                <IconButton aria-label="Sync Invoices" onClick={handleRequestSync} disabled size="large">
                  <SyncDisabledIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {isDoitEmployee && !channel && (
            <Tooltip key="invoice-sync" title="Sync invoices with priority">
              <span>
                <IconButton aria-label="Sync Invoices" onClick={handleRequestSync} size="large">
                  <SyncIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Stack>
      </>
    ),
    [
      channel,
      downloadInvoices,
      handleRequestSync,
      invoiceAG,
      isProductOnlyCustomer,
      isDoitEmployee,
      matches,
      rowsFiltered,
      setFilters,
    ]
  );

  const getPage = (rowsFiltered, rowsPerPage: number, page: number) =>
    rowsPerPage > -1 ? rowsFiltered.slice(page * rowsPerPage, rowsPerPage * (page + 1)) : rowsFiltered;

  const balancesSubheader = useMemo(() => {
    if (isEmpty(currentBalancesByCurrency) && !isFetching) {
      return <Typography variant="subtitle1">None</Typography>;
    }
    return Object.keys(currentBalancesByCurrency).map((key, idx) => {
      const printSeparator = (
        <Typography variant="subtitle1" sx={{ mr: 2 }} key={key}>
          |
        </Typography>
      );
      const printMoney = (
        <Typography variant="subtitle1" sx={{ mr: 2, fontWeight: "medium" }} key={key}>
          {formatCurrency(currentBalancesByCurrency[key], key as CurrencyCode)}
        </Typography>
      );

      if (idx < Object.keys(currentBalancesByCurrency).length - 1) {
        return (
          <Fragment key={key}>
            {printMoney}
            {printSeparator}
          </Fragment>
        );
      } else {
        return printMoney;
      }
    });
  }, [currentBalancesByCurrency, isFetching]);

  return (
    <>
      <CardHeader
        action={pageHeaderAction}
        sx={{ px: 0, py: 1, alignItems: "start" }}
        title="Invoices"
        titleTypographyProps={{ variant: "h1" }}
        subheader={
          <Box flexDirection="row" display="flex" alignItems="center">
            <Typography variant="body1">Total balance to pay:&nbsp;</Typography>
            {balancesSubheader}
          </Box>
        }
      />

      {matches ? (
        <SwipeableDrawer
          anchor="top"
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
          }}
          onOpen={() => {
            setDrawerOpen(true);
          }}
          hysteresis={0.25}
        >
          {/* <CardHeader title="Filters" /> */}
          {filtersCardContent}
        </SwipeableDrawer>
      ) : (
        filtersCardContent
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <Hide mdDown>
                <TableCell align="center" padding="checkbox" />
                <TableCell padding="none" />
              </Hide>
              {columns.map(
                (column) =>
                  (!column.doitEmployee || isDoitEmployee) &&
                  (!("ccFees" in column) || showCCFeesColumns) &&
                  (column.hidden ? (
                    <Hide mdDown key={column.id}>
                      <TableCell
                        align={column.align}
                        sortDirection={sorting.active === column.id ? sorting.direction : false}
                      >
                        <Tooltip title={column.tooltip}>
                          <TableSortLabel
                            active={sorting.active === column.id}
                            direction={sorting.direction}
                            onClick={handleRequestSort(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    </Hide>
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={sorting.active === column.id ? sorting.direction : undefined}
                      size="small"
                    >
                      <Tooltip title={column.tooltip}>
                        <TableSortLabel
                          active={sorting.active === column.id}
                          direction={sorting.direction}
                          onClick={handleRequestSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))
              )}
            </TableRow>
          </TableHead>

          {isFetching ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9} className={classes.centerTableCell}>
                  <CircularProgress size={32} thickness={5} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody>
                {getPage(
                  orderBy(rowsFiltered, [sorting.active], [sorting.direction]),
                  pageFilter.rowsPerPage,
                  pageFilter.page
                ).map((row: RowItem) => {
                  const extFile = row.EXTFILES?.[0];
                  return (
                    <TableRow hover key={row.IVNUM}>
                      <Hide mdDown>
                        <TableCell align="center" padding="checkbox">
                          {!!extFile?.url && (
                            <Tooltip title="Download invoice">
                              <span>
                                <IconButton
                                  size="small"
                                  component="button"
                                  onClick={handleDownloadInvoice(extFile, errorSnackbar)}
                                >
                                  <DownloadIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell padding="none">
                          {row.PRODUCTS?.map((type) => {
                            const icon = productIcon(type);
                            if (icon) {
                              return (
                                <Tooltip title={assetTypeName(type)} key={`${row.id}-${type}`}>
                                  <img src={icon} className={classes.logo} alt={type} />
                                </Tooltip>
                              );
                            }
                            return null;
                          })}
                        </TableCell>
                      </Hide>
                      <TableCell align="left">
                        <Typography
                          component={Link}
                          to={`${location.pathname}/${row.entity?.id}/${row.IVNUM}`}
                          color="inherit"
                          variant="body2"
                          style={{ cursor: "pointer" }}
                        >
                          {row.IVNUM}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {row._SHOW_BADGE ? (
                          <Badge variant="dot" classes={{ badge: classes.badge }}>
                            <Typography className={row.statusClassName} variant="body2">
                              {row._ICON} {row._STATUS}
                            </Typography>
                          </Badge>
                        ) : (
                          <Typography className={row.statusClassName} variant="body2">
                            {row._ICON} {row._STATUS}
                          </Typography>
                        )}
                      </TableCell>
                      <Hide mdDown>
                        <TableCell>{row.CDES}</TableCell>
                        <TableCell>
                          {sanitizeDate(DateTime.fromJSDate(row.IVDATE.toDate())).toLocaleString(DateTime.DATE_MED)}
                        </TableCell>
                        <TableCell>
                          {row._STATUS === "Proforma"
                            ? "-"
                            : sanitizeDate(DateTime.fromJSDate(row.PAYDATE.toDate())).toLocaleString(DateTime.DATE_MED)}
                        </TableCell>
                        {isDoitEmployee && (
                          <TableCell>
                            {row.ESTPAYDATE
                              ? sanitizeDate(DateTime.fromJSDate(row.ESTPAYDATE.toDate())).toLocaleString(
                                  DateTime.DATE_MED
                                )
                              : "N/A"}
                          </TableCell>
                        )}
                        {showCCFeesColumns && <TableCell>{getLinkedInvoicesLinks(row, invoices) ?? "N/A"}</TableCell>}
                        <TableCell align="right">{formatCurrency(row.TOTPRICE ?? 0, row.CODE)}</TableCell>
                        {showCCFeesColumns && (
                          <TableCell align="right">
                            {formatCurrency(calculateTotalWFees(row, invoices), row.CODE)}
                          </TableCell>
                        )}
                      </Hide>
                      <TableCell align="right">
                        {row._STATUS === "Proforma" ? "-" : formatCurrency(row._BALANCE, row.CODE)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                {rowsFiltered.length > 0 && (
                  <TableRow>
                    <TablePagination
                      count={rowsFiltered.length}
                      rowsPerPage={pageFilter.rowsPerPage}
                      rowsPerPageOptions={[10, 25, { value: -1, label: "All" }]}
                      labelRowsPerPage="Per Page"
                      page={pageFilter.page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      SelectProps={{
                        "aria-label": "Rows per page",
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                )}
              </TableFooter>
            </>
          )}
        </Table>
      </TableContainer>
      {rowsFiltered.length <= 0 && !isFetching && <NoInvoicesFound />}
      {inReportsDialogOpen && (
        <InvoiceInReportsDialog
          invoiceAG={invoiceAG}
          onClose={() => {
            setInReportsDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
