import { useEffect, useMemo, useState } from "react";

import { LoadingButton as MUILoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Link, Tooltip } from "@mui/material";
import capitalize from "lodash/capitalize";

import { errorsText } from "../../../../assets/texts/CloudAnalytics";
import LoadingButton from "../../../../Components/LoadingButton";
import { purifiedHtml } from "../../../../Components/SafeHtml";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { navigatorSubscriptionURL } from "../../../../utils/common";
import { ErrorCode } from "../../utilities";

type Props = {
  cacheReport: () => void;
  disableCacheBtn: boolean;
  errorCode: ErrorCode;
  errorMessage?: string;
  isReportCachedBtnLoading: boolean;
  showReportCacheBtn: boolean;
};

const ErrorAlerts = ({
  cacheReport,
  disableCacheBtn,
  errorCode,
  errorMessage,
  isReportCachedBtnLoading,
  showReportCacheBtn,
}: Props) => {
  const [description, setDescription] = useState("");
  const [list, setList] = useState<string[]>([]);
  const { userOrganization } = useCustomerContext();

  const warning = errorCode === ErrorCode.EMPTY || errorCode === ErrorCode.EMPTY_DUE_TO_TREND;
  const entitlementError = errorCode === ErrorCode.ENTITLEMENT_NOT_ENABLED;
  const severity = entitlementError ? "info" : warning ? "warning" : "error";
  const isTooLarge = errorCode === ErrorCode.TOO_LARGE;
  const organizationName = userOrganization?.data.name;

  const tooltipTitle = disableCacheBtn ? errorsText.NOT_SAVED_TOOLTIP : "";

  useEffect(() => {
    switch (errorCode) {
      case ErrorCode.ENTITLEMENT_NOT_ENABLED:
        if (errorMessage) {
          setDescription(errorMessage);
        } else {
          setDescription(errorsText.ENTITLEMENT_NOT_ENABLED.DESCRIPTION);
        }
        setList([]);
        break;
      case ErrorCode.OUTSIDE_ORGANIZATION:
        setDescription(
          errorsText.OUTSIDE_ORGANIZATION.DESCRIPTION.replace("(%s)", organizationName ? `(${organizationName})` : "")
        );
        setList(errorsText.OUTSIDE_ORGANIZATION.LIST);
        break;
      case ErrorCode.EMPTY:
        setDescription(errorsText.EMPTY.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.SERIES_TOO_LARGE:
        setDescription(errorsText.SERIES_TOO_LARGE.DESCRIPTION);
        setList(errorsText.SERIES_TOO_LARGE.LIST);
        break;
      case ErrorCode.TOO_LARGE:
        setDescription(errorsText.TOO_LARGE.DESCRIPTION);
        setList(errorsText.TOO_LARGE.LIST);
        break;
      case ErrorCode.QUERY_TIMEOUT:
        setDescription(errorsText.QUERY_TIMEOUT.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.EMPTY_DUE_TO_TREND:
        setDescription(errorsText.EMPTY_DUE_TO_TREND.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.QUERY_TIMEOUT_524:
        setDescription(errorsText.QUERY_TIMEOUT_524.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.EXCEEDED_FIRESTORE_LIMIT:
        setDescription(errorsText.EXCEEDED_FIRESTORE_LIMIT.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.CALCULATED_METRIC_NOT_PROVIDED:
      case ErrorCode.EXTENDED_METRIC_NOT_PROVIDED:
        setDescription(errorsText.NO_ACCESS_METRIC);
        break;
      case ErrorCode.BQ_PROXY_NOT_FOUND:
        setDescription(errorsText.BQ_PROXY_NOT_FOUND.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.BQ_PROXY_FORBIDDEN:
        setDescription(errorsText.BQ_PROXY_FORBIDDEN.DESCRIPTION);
        setList([]);
        break;
      case ErrorCode.BQ_PROXY_IAM_PERMISSION_DENIED:
        setDescription(errorsText.BQ_PROXY_IAM_PERMISSION_DENIED.DESCRIPTION);
        setList([]);
        break;
      default:
        setDescription(errorsText.DEFAULT.DESCRIPTION);
        setList([]);
        break;
    }
  }, [errorCode, errorMessage, organizationName]);

  const descriptionElement = useMemo(
    // eslint-disable-next-line @eslint-react/dom/no-dangerously-set-innerhtml
    () => <div dangerouslySetInnerHTML={{ __html: purifiedHtml(description) ?? "" }} />,
    [description]
  );

  return (
    <Alert variant="standard" severity={severity}>
      <AlertTitle>{capitalize(severity)}</AlertTitle>
      {descriptionElement}
      {list.length > 0 && (
        <ul>
          {list.map((l) => (
            <li key={l}>{l}</li>
          ))}
          {isTooLarge && (
            <li>
              {errorsText.TOO_LARGE.LINK.DESC}
              <Link
                href="https://developer.doit.com/reference/getreport"
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
                variant="body2"
              >
                {errorsText.TOO_LARGE.LINK.TITLE}
              </Link>
            </li>
          )}
        </ul>
      )}
      {entitlementError && (
        <Box pt={1}>
          <LoadingButton
            href={navigatorSubscriptionURL}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            loading={false}
            mixpanelEventId="analytics.reports.tiers.learn_more"
            eventProperties={{ message: errorMessage }}
          >
            {errorsText.LEARN_MORE}
          </LoadingButton>
        </Box>
      )}
      {showReportCacheBtn && (
        <Tooltip title={tooltipTitle} disableHoverListener={!disableCacheBtn}>
          <span>
            <MUILoadingButton
              disabled={disableCacheBtn}
              loading={isReportCachedBtnLoading}
              onClick={cacheReport}
              variant="outlined"
            >
              <span>{errorsText.CACHE_REPORT_BTN}</span>
            </MUILoadingButton>
          </span>
        </Tooltip>
      )}
    </Alert>
  );
};

export default ErrorAlerts;
