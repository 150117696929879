import { useEffect, useState } from "react";

import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { getUserOrgID } from "../../utils/common";
import { getWidgetDocId } from "../../utils/widgets";
import { mapSpendBySku } from "./constants";

// hook checks for MAP SKU data - if no SKU is detected, the customer is not using MAP
// https://console.doit.com/customers/csMnyYprdHvOnSZ50klq/analytics/reports/sP6ihdMfhqdhnb3bhl0k
export const useIsCustomerUsingMap = () => {
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization, customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const [isCustomerUsingMap, setIsCustomerUsingMap] = useState<boolean>();
  const presentationMode = customer && isCustomerInPresentationMode(customer);

  useEffect(() => {
    if (
      isDoitEmployee === undefined ||
      presentationMode === undefined ||
      presentationMode === null ||
      userOrganization === undefined ||
      !customerOrPresentationModeCustomer
    ) {
      return;
    }

    const customerID = customerOrPresentationModeCustomer?.id;

    const cloudAnalyticsWidgets = getCollection(CloudAnalyticsModel).doc("widgets").collection("cloudAnalyticsWidgets");
    const orgId = getUserOrgID(isDoitEmployee, userOrganization);

    const mapBySkuDocId = getWidgetDocId(customerID, mapSpendBySku, orgId);

    cloudAnalyticsWidgets
      .doc(mapBySkuDocId)
      .get()
      .then((mapBySkuDoc) => {
        const mapBySkuData = mapBySkuDoc.asModelData();
        const mapBySkuRowsLength =
          (mapBySkuData?.data?.rows && Object.entries(mapBySkuData?.data?.rows).reduce((prevR) => prevR + 1, 0)) || 0;

        setIsCustomerUsingMap(mapBySkuRowsLength > 0 || presentationMode);
      });
  }, [customerOrPresentationModeCustomer, userOrganization, isDoitEmployee, presentationMode]);

  return { isCustomerUsingMap };
};
