import { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  type EntitlementsAccess,
  type KnownIssuePlatform,
  type SupportServiceModel,
  type ZendeskPlatformModel,
} from "@doitintl/cmp-models";
import { Alert, Box, Button } from "@mui/material";

import Sauron from "../../../Components/Sauron";
import { productAlertList } from "../../consts";
import { useIsDCIEssentialsTier } from "../../hooks/useIsDCIEssentialsTier";
import KnownIssuesList from "../KnownIssuesList";
import SelectCategory from "../SelectCategory";
import { ContentHeader } from "../Stepper/ContentHeader";
import SupportPlatformDropdown from "../SupportPlatformDropdown";
import { TermisAgreementDialog } from "../TermsAgreementDialog";

type ProductStepProps = {
  handleChangePlatform: (data: React.ChangeEvent<Element>) => void;
  platforms: ZendeskPlatformModel[];
  sauronRole: boolean;
  categories: SupportServiceModel[];
  platform: string;
  error: boolean;
  helperText: string;
  handleChangeCategory: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectedPlatform: ZendeskPlatformModel;
  category: string;
  changeCategoryInfo: (event: React.ChangeEvent<{ value: unknown }>) => void;
  categoryInfo: string;
  categoryInfoList: { asset: string }[];
  knownIssues: { platform: KnownIssuePlatform; title: string; docId: string }[];
  supportEntitlementsCheck: EntitlementsAccess;
  setCurrentStep: (step: number) => void;
  billingProfile: string;
  setBillingProfile: (profile: string) => void;
};

const ProductStep = ({
  handleChangePlatform,
  platforms,
  categories,
  platform,
  error,
  helperText,
  handleChangeCategory,
  selectedPlatform,
  category,
  changeCategoryInfo,
  categoryInfo,
  categoryInfoList,
  knownIssues,
  sauronRole,
  supportEntitlementsCheck,
  setCurrentStep,
  billingProfile,
  setBillingProfile,
}: ProductStepProps) => {
  const { t } = useTranslation("services");
  const [open, setOpen] = useState(false);
  const isDCIEssentials = useIsDCIEssentialsTier();

  // check if today is later than 1st of December 2024 remove this after that date
  const isAfterDecember2024 = new Date() > new Date(2024, 11, 1);

  const shouldShowAlert = productAlertList.includes(selectedPlatform?.value);

  return (
    <Box sx={{ pl: 1 }}>
      <ContentHeader title={t("ProductStep.title")} />
      <Box sx={{ mt: 4 }}>
        <SupportPlatformDropdown
          platform={platform}
          platformsList={platforms}
          handleChangePlatformField={handleChangePlatform}
          error={error && !platform}
        />
        <SelectCategory
          helperText={helperText}
          handleChangeCategory={handleChangeCategory}
          platforms={platforms}
          selectedPlatform={selectedPlatform}
          category={category ? category : null}
          categories={categories}
          changeCategoryInfo={changeCategoryInfo}
          categoryInfo={categoryInfo}
          error={error}
          categoryInfoList={categoryInfoList}
        />
        <Box mt={5} mb={5}>
          <KnownIssuesList platform={platform} issues={knownIssues} />
          {shouldShowAlert && isDCIEssentials && (
            <Alert
              severity="info"
              sx={{ ".MuiAlert-action": { minWidth: "fit-content", alignItems: "center" }, mt: 2 }}
              action={
                <Button
                  disabled={category === null}
                  variant="text"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("ProductStep.dciEssentialsAlertAction")}
                </Button>
              }
            >
              {t("ProductStep.dciEssentialsAlert")}
            </Alert>
          )}
        </Box>
        {selectedPlatform?.value === "amazon_web_services" && !isAfterDecember2024 && (
          <Sauron accountId={categoryInfo} sauronRole={sauronRole}>
            <SelectCategory
              helperText={helperText}
              handleChangeCategory={handleChangeCategory}
              platforms={platforms}
              selectedPlatform={selectedPlatform}
              category={category ? category : null}
              categories={categories}
              changeCategoryInfo={changeCategoryInfo}
              categoryInfo={categoryInfo}
              categoryInfoList={categoryInfoList}
              step={2}
              supportEntitlementsCheck={supportEntitlementsCheck}
            />
          </Sauron>
        )}
      </Box>
      <TermisAgreementDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          setOpen(false);
          setCurrentStep(1);
        }}
        billingProfile={billingProfile}
        setBillingProfile={setBillingProfile}
      />
    </Box>
  );
};

export default ProductStep;
