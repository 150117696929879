import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { matchRoutes, type RouteConfig } from "react-router-config";
import { matchPath, useLocation } from "react-router-dom";
import { AvaOpeningSource, useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import { type EntitlementKey, TierPackageTypes } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import useMountEffect from "../../Components/hooks/useMountEffect";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import { isPartnerDomain } from "../../utils/domains";
import { type FirestoreTimestamp } from "../../utils/firebase";
import { getCustomerTierTrialDatesAsTimestamp, getTierTrialDates } from "../../utils/tiers";
import { accessControlConfig, partnerAccessControlConfig } from "../config/accessControlConfig";
import { navigationCategories, partnerNavigationCategories } from "../config/navigationCategory";
import { getAllowedRoutesForAccess } from "../core/routes";
import { type AccessControlConfig, type LoginContext, type NavigationCategory, type PageIdInfo } from "../types";
import { useAssetsExistCheck } from "./useAssetsExistCheck";

const matchCustomerPath =
  (locationPathName: string) =>
  <Params extends { [K in keyof Params]?: string }>(pathname: string) =>
    matchPath<Params>(locationPathName, {
      path: `/customers/:customerId${pathname}`,
      exact: false,
      strict: false,
    });

const convertToDateTime = (date?: FirestoreTimestamp | null) => (date ? DateTime.fromJSDate(date.toDate()) : undefined);

export const useLoginContext = (): LoginContext | undefined => {
  const { hasAWSAssets, hasGCPAssets, hasAWSStandaloneAssets } = useAssetsExistCheck();
  const { isDoitEmployee, isDoitOwner, isDoitPartner, currentUser } = useAuthContext();
  const { customer, navigatorTierTrialDates, isProductOnlyCustomer } = useCustomerContext({
    allowNull: true,
  });
  const location = useLocation();
  const { userRoles } = useUserContext();
  const { isFeatureEntitled, getCustomerTier, getTrialTierRef } = useTier();

  return useMemo(() => {
    // build the context that hold all the information about user and customer
    if (
      currentUser &&
      userRoles &&
      isDoitEmployee !== undefined &&
      isDoitOwner !== undefined &&
      isDoitPartner !== undefined
    ) {
      const loginContext: LoginContext = {
        isDoitEmployee,
        isDoitOwner,
        isDoitPartner,
        permissions: userRoles,
      };

      if (customer === null) {
        loginContext.customerState = null;
      } else if (customer) {
        loginContext.customerId = customer.id;

        const navigatorTrialDates = getTierTrialDates(navigatorTierTrialDates);
        const solveTrialDates = getTierTrialDates(
          getCustomerTierTrialDatesAsTimestamp(customer, TierPackageTypes.SOLVE)
        );

        loginContext.customerState = {
          earlyAccessFeatures: customer.earlyAccessFeatures ?? [],
          hasAWSAssets,
          hasAWSStandaloneAssets,
          hasGCPAssets,
          trialEndDate: convertToDateTime(customer.trialEndDate),
          navigatorTierTrialStartDate: navigatorTrialDates?.start,
          navigatorTierTrialEndDate: navigatorTrialDates?.end,
          solveTierTrialStartDate: solveTrialDates?.start,
          solveTierTrialEndDate: solveTrialDates?.end,
          navigatorTier: getCustomerTier(TierPackageTypes.NAVIGATOR),
          navigatorTrialTier: getTrialTierRef(TierPackageTypes.NAVIGATOR),
          solveTier: getCustomerTier(TierPackageTypes.SOLVE)?.ref,
          solveTrialTier: getTrialTierRef(TierPackageTypes.SOLVE),
          isStandalone: !!isProductOnlyCustomer,
          hasFlexsaveEnabled: !!customer.enabledFlexsave,
          flexsaveGCPConfigured: !!customer.enabledFlexsave?.GCP,
          flexsaveAWSConfigured: !!customer.enabledFlexsave?.AWS,
          saasConsoleGCPConfigured: !!customer.enabledSaaSConsole?.GCP,
          saasConsoleAWSConfigured: !!customer.enabledSaaSConsole?.AWS,
          saasConsoleAzureConfigured: !!isProductOnlyCustomer,
          providers: customer.onboarding?.advantage?.providers ?? [],
          matchPath: matchCustomerPath(location.pathname),
          inPresentationMode: !!customer.presentationMode?.enabled,
          startTrialInitiated: !!customer.onboarding?.startTrialInitiated,
          isFeatureEntitled: (featureKey: EntitlementKey) => isFeatureEntitled(featureKey),
        };
      }

      return loginContext;
    }
  }, [
    currentUser,
    userRoles,
    isDoitEmployee,
    isDoitOwner,
    isDoitPartner,
    customer,
    navigatorTierTrialDates,
    hasAWSAssets,
    hasAWSStandaloneAssets,
    hasGCPAssets,
    getCustomerTier,
    getTrialTierRef,
    isProductOnlyCustomer,
    location.pathname,
    isFeatureEntitled,
  ]);
};

export function useNavConfig(): {
  navCategories: NavigationCategory[];
  accessControl: AccessControlConfig;
} {
  const { t } = useTranslation("navigation");

  return useMemo(() => {
    if (isPartnerDomain()) {
      return { navCategories: partnerNavigationCategories, accessControl: partnerAccessControlConfig };
    }
    return { navCategories: navigationCategories(t), accessControl: accessControlConfig };
  }, [t]);
}

export function useRoutesConfig(): RouteConfig[] | undefined {
  const loginContext = useLoginContext();
  const { accessControl } = useNavConfig();
  const tierConfig = useTier();

  return useMemo(() => {
    if (loginContext) {
      return getAllowedRoutesForAccess(loginContext, accessControl, tierConfig);
    }
  }, [loginContext, accessControl, tierConfig]);
}

export function useCurrentPageIdInfo(routesConfig?: RouteConfig[]): PageIdInfo | undefined {
  const location = useLocation();

  return useMemo(() => {
    if (routesConfig) {
      const matchedRoutes = matchRoutes(routesConfig, location.pathname);

      // only one route should always be returned, as there is check to prevent multiple routes in config
      if (matchedRoutes.length === 1) {
        const { customerId } = matchedRoutes[0].match.params as { customerId: string };

        return {
          customerId,
          pageId: matchedRoutes[0].route.pageId,
          isCustomerRoute: matchedRoutes[0].route.isCustomerRoute,
          hasEntitlement: matchedRoutes[0].route.hasEntitlement,
          featureGate: matchedRoutes[0].route.featureGate,
          presentationModeSupported: matchedRoutes[0].route.presentationModeSupported,
        };
      }
    }
  }, [routesConfig, location.pathname]);
}

// check if the url has a parametes &ava=true and open dialog
export const useOpenDialogIfHasAvaParams = () => {
  const location = useLocation();
  const hasAvaParam = location.search.includes("ava=true");
  const { handleOpenAvaDialog } = useAvaDialogContext();
  useMountEffect(() => {
    if (hasAvaParam) {
      handleOpenAvaDialog(AvaOpeningSource.URL);
    }
  });
};
