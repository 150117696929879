import { type ReactElement } from "react";

import { Link, Stack, Typography } from "@mui/material";

const InfoRow = ({ field, value, onClick }: { field: string; value?: string | ReactElement; onClick?: () => void }) => {
  const cy = field.toLowerCase().replace(/ /g, "-");

  return (
    <Stack direction="row">
      <Typography variant="subtitle2" fontWeight={500} display="flex" flexBasis="115px" data-cy={`${cy}-field`}>
        {field}:
      </Typography>
      {onClick ? (
        <Link
          onClick={onClick}
          component="button"
          underline="hover"
          variant="body2"
          display="flex"
          flex={1}
          data-cy={`${cy}-value`}
        >
          {value}
        </Link>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          display="flex"
          flex={1}
          data-cy={`${cy}-value`}
          sx={{ wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default InfoRow;
