import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../Context/CustomerContext";
import { getCachingKeys } from "../../utils/cachingKeys";

export function useClusters() {
  const { customer } = useCustomerContext({ allowNull: true });

  const query = getCollection(IntegrationModel)
    .doc("fsk8s")
    .collection("fsk8sClusters")
    .where("customerRef", "==", customer?.ref);

  return useCollectionData(query, {
    caching: true,
    cachingKeys: getCachingKeys(customer?.id),
    idField: "id",
  });
}
