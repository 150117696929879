import { useCallback, useEffect, useState } from "react";

import {
  type ApprovalConfig,
  type CloudFlowNodeType,
  NotificationProviders,
  type SlackChannel,
} from "@doitintl/cmp-models";
import { Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";

import { useCustomerId } from "../../../../../../Components/hooks/useCustomerId";
import { useSlackChannelsApi } from "../../../../../../Components/Slack/ChannelSelection/useSlackChannelApi";
import { consoleErrorWithSentry } from "../../../../../../utils";
import { useNodeConfigurationContext } from "../../NodeConfigurationContext";
import { AutoRejectApproval } from "./AutoRejectApproval";
import { NotificationProvider } from "./NotificationProvider";

const Approval = () => {
  const customerId = useCustomerId();
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const { getCustomerSlackChannels, channelsLoading } = useSlackChannelsApi(customerId);
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const { required, recipient, message } = nodeConfig.approval || {};

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const channels = await getCustomerSlackChannels();
        setSlackChannels(channels);
      } catch (e) {
        consoleErrorWithSentry(e);
        setSlackChannels([]);
      }
    };
    if (recipient?.notificationProvider === NotificationProviders.SLACK) {
      fetchChannels();
    }
  }, [customerId, getCustomerSlackChannels, recipient]);

  const updateApprovalConfig = useCallback(
    (name: keyof ApprovalConfig, value: any) => {
      updateNode((prevNode) => ({
        approval: {
          ...prevNode.approval!,
          [name]: value,
        },
      }));
    },
    [updateNode]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        Approval
      </Typography>

      <Typography variant="body2" color={"text.secondary"}>
        If you want this action to be approved by someone in your organization before it runs you can set it to require
        approval
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            name="required"
            checked={Boolean(required)}
            onChange={(e) => {
              updateApprovalConfig("required", e.target.checked);
            }}
          />
        }
        label="Require approval for this action"
      />

      {required && (
        <>
          <NotificationProvider
            loadingSlackChannels={channelsLoading}
            slackChannels={slackChannels}
            onUpdateConfig={updateApprovalConfig}
          />
          <TextField
            fullWidth
            name="message"
            label="Message"
            value={message}
            onChange={(e) => {
              updateApprovalConfig("message", e.target.value);
            }}
            multiline
            rows={4}
          />
          <AutoRejectApproval onUpdateConfig={updateApprovalConfig} />
        </>
      )}
    </Stack>
  );
};

export default Approval;
