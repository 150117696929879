import { useEffect, useState } from "react";

import { FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import type { AnomalyFeedbackReason, CustomerFeedback } from "@doitintl/cmp-models";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import mixpanel from "../../../utils/mixpanel";
import { usePatchCostAnomaly } from "../hooks";
import AnomalyFeedbackReasonSelect from "./AnomalyFeedbackReasonSelect";

type Props = {
  onClose: () => void;
  customerFeedback?: CustomerFeedback;
};

const AnomalyAcknowledgementForm = ({ onClose, customerFeedback }: Props) => {
  const { isAnomaly, reason, additionalComments } = customerFeedback ?? {};
  const [isAnomalyValue, setIsAnomalyValue] = useState(!!isAnomaly);
  const [reasonValue, setReasonValue] = useState<AnomalyFeedbackReason | undefined>(reason);
  const [comments, setComments] = useState(additionalComments ?? undefined);
  const patchAnomaly = usePatchCostAnomaly();
  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  useEffect(() => {
    mixpanel.track("anomalies.detail.acknowledge.open");
  }, []);

  const handleIsAnomalyChange = (event) => {
    setReasonValue(undefined);
    setIsAnomalyValue(event.target.value === "true");
  };

  const handleReasonChange = (event) => {
    setReasonValue(event);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleSubmit = async () => {
    mixpanel.track(`anomalies.detail.acknowledge.${customerFeedback ? "update" : "save"}`, { reason: reasonValue });

    await patchAnomaly({
      anomaly: {
        customerFeedback: {
          isAnomaly: isAnomalyValue,
          additionalComments: comments,
          reason: reasonValue as AnomalyFeedbackReason,
        },
      },
      onSuccess: () => {
        showSuccess("Anomaly feedback saved");
        onClose();
      },
      onError: () => {
        showError("An error occurred while saving anomaly feedback");
      },
    });
  };

  return (
    <SimpleDialog
      open
      title={`${customerFeedback ? "Edit" : "Acknowledge"} anomaly`}
      titleCy="anomaly-acknowledgement-form"
      confirmButtonText="Save"
      confirmButtonCy="save-acknowledgement-button"
      onConfirm={handleSubmit}
      disableConfirm={!reasonValue}
      onCancel={onClose}
      cancelButtonCy="close-acknowledgement-form"
    >
      <Box px={3} py={2}>
        <Typography variant="subtitle1" mb={1}>
          Would you classify this as an anomaly?
        </Typography>

        <Typography variant="caption" color="text.secondary" mb={1.5} component="p">
          An anomaly is a spike in cloud service expenses, often indicating potential issues like misconfigurations,
          over-provisioning, or unauthorized usage.
        </Typography>

        <FormControl sx={{ pl: 1, mb: 3 }}>
          <RadioGroup value={isAnomalyValue} onChange={handleIsAnomalyChange}>
            <FormControlLabel value={true} control={<Radio size="small" />} label="Yes, this was an anomaly" />
            {isAnomalyValue && (
              <AnomalyFeedbackReasonSelect
                isAnomaly={isAnomalyValue}
                onChange={handleReasonChange}
                value={reasonValue}
                sx={{ ml: "27px", width: 320, my: 1 }}
              />
            )}
            <FormControlLabel value={false} control={<Radio size="small" />} label="No, this wasn't an anomaly" />
            {isAnomalyValue !== undefined && !isAnomalyValue && (
              <AnomalyFeedbackReasonSelect
                isAnomaly={isAnomalyValue}
                onChange={handleReasonChange}
                value={reasonValue}
                sx={{ ml: "27px", width: 320 }}
              />
            )}
          </RadioGroup>
        </FormControl>

        <TextField
          label="Additional comments"
          multiline
          rows={6}
          value={comments}
          onChange={handleCommentsChange}
          variant="outlined"
          fullWidth
        />
      </Box>
    </SimpleDialog>
  );
};

export default AnomalyAcknowledgementForm;
