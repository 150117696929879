import { useMemo } from "react";

import { type ContractModel, type CurrencyCode, TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { Box, Stack, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import DataCouplet from "../../../Components/DataCouplet";
import { getCurrencySymbol, numberWithCommas } from "../../../utils/common";
import { chargePerTermLabelMap, paymentTermOptions, pointOfSaleMap } from "../ContractForm/const";
import { calculateTotalSalesPrice } from "../ContractForm/utils";
import { contractDate, isNext10Contract } from "../utils";

const SubscriptionSection = ({ contract }: { contract: ContractModel }) => {
  const [tiers] = useCollectionDataOnce(getCollection(TierModel), {
    idField: "id",
  });

  const tierName = (tiers ?? []).find((t) => contract?.tier?.id === t.id)?.displayName;

  const paymentTermText = useMemo(
    () => paymentTermOptions.find((option) => option.value === contract.paymentTerm)?.label ?? "-",
    [contract.paymentTerm]
  );

  const pointOfSaleLabel = useMemo(
    () => pointOfSaleMap.find((option) => option.value === contract.pointOfSale)?.label ?? "-",
    [contract.pointOfSale]
  );

  const chargePerTermLabel = chargePerTermLabelMap[contract.paymentTerm ?? ""]?.label;

  const commitmentValue = useMemo(() => {
    if (contract.commitmentMonths) {
      if (contract.commitmentMonths > 0) {
        return `${contract.commitmentMonths} months`;
      }

      return "Custom";
    }
    return "Monthly automatic renewal";
  }, [contract.commitmentMonths]);

  const currency = contract.currency as CurrencyCode;

  return (
    <Box component="section" data-cy="subscription-section">
      <Typography variant="h2">Contract details</Typography>
      <Stack component="dl" spacing={2}>
        <DataCouplet field="Subscription start date" value={contractDate(contract.startDate)} />
        <DataCouplet field="Subscription end date" value={contractDate(contract.endDate)} />
        <DataCouplet field="Tier" value={capitalize(tierName) ?? "-"} />
        <DataCouplet field="Subscription term" value={commitmentValue} />
        <DataCouplet field="Subscription type" value={contract.isCommitment ? "Commitment" : "On-demand"} />
        <DataCouplet field="Payment term" value={paymentTermText} />
        {isNext10Contract(contract.type) && <DataCouplet field="Point of sale" value={pointOfSaleLabel ?? "-"} />}
        <DataCouplet
          field={chargePerTermLabel}
          value={
            contract.chargePerTerm ? `${getCurrencySymbol(currency)} ${numberWithCommas(contract.chargePerTerm)}` : "-"
          }
        />
        {Boolean(contract.monthlyFlatRate) && (
          <DataCouplet
            field="Monthly variable fee"
            value={contract.monthlyFlatRate ? `${contract.monthlyFlatRate}%` : "-"}
          />
        )}
        <DataCouplet
          field="Discount"
          value={contract.discount ? `${contract.discount}%` : "-"}
          key="contract-discount"
        />
        <DataCouplet
          field="Total sales price"
          value={calculateTotalSalesPrice(
            contract.chargePerTerm || 0,
            contract.paymentTerm || "monthly",
            contract.discount,
            currency
          )}
        />
      </Stack>
    </Box>
  );
};

export default SubscriptionSection;
