import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Button, Card, CardHeader, Stack, Typography, useTheme } from "@mui/material";

import { useNodeConfigurationContext } from "../NodeConfigurationContext";

export const ManualTriggerTab = () => {
  const { onChangeTriggerType } = useNodeConfigurationContext<CloudFlowNodeType.MANUAL_TRIGGER>();

  const {
    palette: { primary },
  } = useTheme();

  return (
    <Box>
      <Stack p={2} justifyContent="center" gap={2}>
        <Card sx={{ "&:hover": { borderColor: primary.main } }}>
          <CardHeader
            avatar={<AccountCircleIcon color="primary" />}
            subheader="Triggered manually by the user"
            title="Manual"
            titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
            subheaderTypographyProps={{ variant: "caption" }}
            action={<Button onClick={onChangeTriggerType}>Change</Button>}
            sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
          />
        </Card>
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }} color="text.primary">
          About Manual CloudFlow triggers
        </Typography>
        <Typography variant="body2" color="text.secondary">
          A manual trigger allows you to start this CloudFlow whenever you choose. When a CloudFlow is published, you
          can trigger it by clicking the ‘Run’ button in the top bar of the CloudFlow editor.
        </Typography>
      </Stack>
    </Box>
  );
};
