import { useCallback, useEffect, useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import {
  type NodeTransformationConcatenation,
  NodeTransformationType,
  type ReferencedNodeValue,
} from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { ReferencedFieldChip } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { generateConcatenationSchema } from "./schemaValidators";
import { useTransformationNodeSchemaContext } from "./TransformationNodeForm";

export const useConcatenationForm = () => {
  const { setTransformationSchema, referenceableNodes } = useTransformationNodeSchemaContext();
  const { getFieldProps, setFieldValue } = useFormikContext();
  const { value: rootReferencedNodeValue }: { value: ReferencedNodeValue | undefined } =
    getFieldProps("referencedNodeField");
  const [validationSchema, setValidationSchema] = useState(
    generateConcatenationSchema(referenceableNodes, rootReferencedNodeValue)
  );

  const { value: transformationFieldValue }: { value: NodeTransformationConcatenation | undefined } =
    getFieldProps("transformation");
  const newFieldNameCommonProps = useFieldCommonProps(
    getFieldProps("transformation.newFieldName"),
    cloudflowTexts.NEW_FIELD_NAME,
    true
  );

  useEffect(() => {
    setTransformationSchema(validationSchema);
  }, [setTransformationSchema, validationSchema]);

  useEffect(() => {
    setValidationSchema(generateConcatenationSchema(referenceableNodes, rootReferencedNodeValue));
  }, [referenceableNodes, rootReferencedNodeValue]);

  useEffect(() => {
    if (!transformationFieldValue || transformationFieldValue.type !== NodeTransformationType.CONCATENATION) {
      setFieldValue("transformation", validationSchema.getDefault());
    }
  }, [setFieldValue, validationSchema, transformationFieldValue]);

  const addField: () => void = useCallback(() => {
    const payload = transformationFieldValue?.payload ?? [];
    setFieldValue("transformation.payload", [...payload, rootReferencedNodeValue]);
  }, [rootReferencedNodeValue, setFieldValue, transformationFieldValue?.payload]);

  const removeField: (index: number) => void = useCallback(
    (index: number) => {
      const payload = transformationFieldValue?.payload ?? [];
      setFieldValue(
        "transformation.payload",
        payload.filter((_, i: number) => i !== index)
      );
    },
    [setFieldValue, transformationFieldValue?.payload]
  );

  const renderTags = useCallback(
    (value: (string | ReferencedNodeValue)[]) => {
      const mergedValues = value.reduce<(string | ReferencedNodeValue)[]>((acc, curr) => {
        const prev = acc[acc.length - 1];
        if (typeof curr === "string" && typeof prev === "string") {
          acc[acc.length - 1] = `${prev}${curr}`;
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);

      return mergedValues.map((v, index) => (
        <Box key={index} mr={1}>
          {isReferencedNodeValue(v) ? (
            <ReferencedFieldChip
              value={v}
              onDelete={() => {
                removeField(index);
              }}
            />
          ) : (
            <Typography sx={{ color: (theme) => theme.palette.text.primary }}>{v}</Typography>
          )}
        </Box>
      ));
    },
    [removeField]
  );
  return {
    addField,
    newFieldNameCommonProps,
    transformationFieldValue,
    referenceableNodes,
    removeField,
    getFieldProps,
    renderTags,
  };
};
