import TicketEscalation from "./TicketEscalation";

type Props = {
  billingProfile?: string;
  hasAgreedToCosts: boolean;
  isAuthorizedToMakeDecisions: boolean;
  hasAgreedToTerms: boolean;
  setBillingProfile: (profile: string) => void;
  setCostChecked: (checked: boolean) => void;
  setAuthorizedChecked: (checked: boolean) => void;
  setAgreedToTerms: (checked: boolean) => void;
};

export default function StepPayment(props: Props) {
  return <TicketEscalation {...props} />;
}
