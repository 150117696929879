import { type JSX } from "react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCustomerIntegrations } from "../../../Pages/CloudAnalytics/utilities";
import { useIsEntitledToCurrentDashboardType } from "../hooks/useIsEntitledByDashboardType";
import { Loader } from "../Loader";
import { SnowflakeLensEnticement } from "./SnowflakeLensEnticement";

type Props = {
  children: JSX.Element;
};

export const SnowflakeLensGuard = ({ children }: Props) => {
  const isEntitled = useIsEntitledToCurrentDashboardType();
  const { customerIntegrations, loading } = useCustomerIntegrations();
  const { customer } = useCustomerContext();

  if (loading || !customerIntegrations) {
    return <Loader />;
  }

  if (customer.presentationMode?.enabled || customer.presentationMode?.isPredefined) {
    return children;
  }

  const hasSnowflakeIntegration = customerIntegrations.includes("snowflake");

  if (isEntitled && hasSnowflakeIntegration) {
    return children;
  }

  return <SnowflakeLensEnticement state={isEntitled ? "activate" : "upgrade"} />;
};
