import { type Insight, type SavingsPeriod } from "./types";

export const totalPotentialDailySavings = (insight: Insight): number | null => {
  // Not a relevant insight
  if (!insight.results?.potentialDailySavings) {
    return null;
  }

  // Sum across all entries - if there's none, stays at 0 = optimized
  let savings = 0;

  if (!insight.results.potentialDailySavings.isOptimized && insight.results.potentialDailySavings.breakdown) {
    for (const savingsData of insight.results.potentialDailySavings.breakdown.data) {
      savings += savingsData.value;
    }
  }

  return savings;
};

export const totalCustomProblems = (insight: Insight): number | null => {
  // Not a relevant insight
  if (!insight.results?.customProblems) {
    return null;
  }

  // Sum across all entries - if there's none, stays at 0 = optimized
  let problems = 0;

  if (!insight.results.customProblems.isOptimized && insight.results.customProblems.breakdown) {
    for (const dataItem of insight.results.customProblems.breakdown.data) {
      problems += dataItem.value;
    }
  }

  return problems;
};

export const sortByTotalPotentialDailySavings = (insights: Insight[], ascending = false) => {
  const sortedData = [...insights];

  // The "standard" order is descending - if we want to sort ascending instead, flip everything
  const multiplier = ascending ? -1 : 1;

  sortedData.sort((a, b) => {
    const savingsA = (totalPotentialDailySavings(a) || 0) + (totalCustomProblems(a) || 0);
    const savingsB = (totalPotentialDailySavings(b) || 0) + (totalCustomProblems(b) || 0);
    if (savingsA === 0) {
      // If neither having potential savings, keep order
      if (savingsB === 0) {
        return 0;
      }

      // If only B has savings, B should go first
      return 1 * multiplier;
    }

    // If only A has savings, A should go first
    if (savingsB === 0) {
      return -1 * multiplier;
    }

    // If they both have savings, whoever has more should go first
    return (savingsB - savingsA) * multiplier;
  });

  return sortedData;
};

export const isIrrelevantOrDismissed = (insight: Insight): boolean =>
  insight.userStatusChanges?.status === "dismissed" || !insight.results?.isRelevant;

export const isOptimized = (insight: Insight): boolean => {
  // If the insight has a status of "optimized", you guessed it, it's optimized
  if (insight.userStatusChanges?.status === "optimized") {
    return true;
  }

  // If there's no results at all or if the insight is not relevant, this doesn't really make sense - but consider not optimized
  if (!insight.results?.isRelevant) {
    return false;
  }

  // If we have a daily savings insight, and we could save $1 or more a day (lower values would display as $0) => not optimized
  const potentialDailySavings = totalPotentialDailySavings(insight);
  if (potentialDailySavings !== null && potentialDailySavings >= 1) {
    return false;
  }

  // If we have a custom problems insight and any amount of problems => not optimized
  const customProblems = totalCustomProblems(insight);
  if (customProblems !== null && customProblems > 0) {
    return false;
  }

  // We consider an insight not optimized if _any_ of the result types are not optimized -
  // if we haven't found anything until now => optimized
  return true;
};

export const calculateTotalSavingsForTimePeriod = (insights: Insight[], period: SavingsPeriod): number => {
  const multiplier = period === "daily" ? 1 : period === "monthly" ? 30 : 365;
  return insights.reduce((total, insight) => {
    const dailySavings =
      insight.results?.potentialDailySavings?.breakdown?.data.reduce((sum, dataItem) => sum + dataItem.value, 0) || 0;
    return total + dailySavings * multiplier;
  }, 0);
};
