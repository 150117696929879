import { useCallback, useMemo } from "react";

import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";

import SuperqueryTableTypeCard from "../../Components/Dashboard/BigQueryLens/TableTypeCard";
import RecommenderCard from "../../Components/Dashboard/RecommenderCard";
import { type DashboardsContextType } from "../../Context/DashboardContext";
import { GSuiteAssetCard } from "../../Pages/Assets/Cards/GSuiteAssetCard";
import { Office365AssetCard } from "../../Pages/Assets/Cards/Office365AssetCard";
import AccountManagersCard from "./AccountManagersCard";
import BudgetsCard from "./Analytics/BudgetsCard";
import CloudReportCard from "./Analytics/CloudReportCard";
import RecommendationsCard from "./BigQueryLens/RecommendationsCard";
import RollUpsCard from "./BigQueryLens/RollUpsCard";
import { SlotExplorerCard } from "./BigQueryLens/SlotExplorerCard";
import CloudIncidentsCard from "./CloudIncidentsCard";
import { CloudSpendSummaryCards } from "./CloudSpendSummaryCards";
import CommitmentContractsCard from "./CommitmentContractsCard";
import CostAnomaliesWidgetCard from "./CostAnomaliesWidgetCard";
import CreditsCard from "./CreditsCard";
import EntitiesCard from "./EntitiesCard";
import ErrorMessageWidgetCard from "./ErrorMessageWidgetCard";
import { FlexsaveCardAWS, FlexsaveCardGCP } from "./Flexsave/FlexsaveCard";
import { FlexsaveWidgetKey } from "./Flexsave/types";
import InfoMessageWidgetCard from "./InfoMessageWidgetCard";
import InvoicesCard from "./InvoicesCard";
import LicensesCard from "./LicensesCard";
import { CloudFlowUpsell } from "./MAPLens/CloudFlowUpsell";
import RampPlansWidgetCard from "./RampPlansWidgetCard";
import RenewalsCard from "./RenewalsCard";
import { ServiceLimitsAWS, ServiceLimitsGCP } from "./ServiceLimitsCard";
import SupportCard from "./SupportCard";

const componentsTypesMapping = {
  accountManagers: AccountManagersCard,
  entities: EntitiesCard,
  invoices: InvoicesCard,
  commitmentContracts: CommitmentContractsCard,
  credits: CreditsCard,
  renewals: RenewalsCard,
  licenseGraph: LicensesCard,
  supportGraph: SupportCard,
  serviceLimits: ServiceLimitsAWS,
  serviceLimitsGoogleCloud: ServiceLimitsGCP,
  recommender: RecommenderCard,
  superqueryTableType: SuperqueryTableTypeCard,
  rollUps: RollUpsCard,
  bigQueryrecommendationsTable: RecommendationsCard,
  gsuiteAssetCard: GSuiteAssetCard,
  office365AssetCard: Office365AssetCard,
  cloudReports: CloudReportCard,
  budgets: BudgetsCard,
  costAnomalies: CostAnomaliesWidgetCard,
  slotExplorer: SlotExplorerCard,
  [FlexsaveWidgetKey.AWS]: FlexsaveCardAWS,
  [FlexsaveWidgetKey.GCP]: FlexsaveCardGCP,
  cloudSpendSummary: CloudSpendSummaryCards,
  activeCloudIncidents: CloudIncidentsCard,
  rampPlans: RampPlansWidgetCard,
  cloudflowUpsell: CloudFlowUpsell,
};

type Props = {
  widget: DashboardsContextType["dashboards"][number]["widgets"][number];
  widgetHeight: number;
};

export function WidgetContent({ widget, widgetHeight }: Props) {
  const childWithProps = useMemo(() => {
    const isTemplateWidget = widget.name.includes("::");

    const widgetBaseName = isTemplateWidget ? widget.name.substring(0, widget.name.indexOf("::")) : widget.name;
    const widgetId = isTemplateWidget ? widget.name.substring(widget.name.lastIndexOf(":") + 1) : widget.name;

    const Component = componentsTypesMapping[widgetBaseName];
    const noDataFallbackComponent = <InfoMessageWidgetCard widgetHeight={widgetHeight} name={widgetBaseName} />;

    if (!Component) {
      return noDataFallbackComponent;
    }

    return <Component fallbackComponent={noDataFallbackComponent} widgetId={widgetId} widgetHeight={widgetHeight} />;
  }, [widgetHeight, widget.name]);

  const exceptionErrorFallbackCard = useCallback(
    ({ error }) => <ErrorMessageWidgetCard error={error} widget={widget} />,
    [widget]
  );

  return (
    <Box width="100%" height="100%">
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Sentry.ErrorBoundary
          fallback={exceptionErrorFallbackCard}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          {childWithProps}
        </Sentry.ErrorBoundary>
      </div>
    </Box>
  );
}
