import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models";

import { type ConfigurationTab, type HandleUpdateNodeFn, type NodeConfigs } from "../../types";
import { useTabs } from "./Tabs/hooks";

type NodeConfigurationContextType<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  activeTab: ConfigurationTab;
  setActiveTab: (tab: ConfigurationTab) => void;
  nodeConfig: NodeConfigs<TNodeType>;
  onChangeTriggerType: () => void;
  updateNode: (values: Parameters<HandleUpdateNodeFn<TNodeType>>[1]) => void;
};

type NodeConfigurationProviderType<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  nodeConfig: NodeConfigs<TNodeType>;
  updateNode: HandleUpdateNodeFn<TNodeType>;
  children: ReactNode;
  onChangeTriggerType: () => void;
};

const NodeConfigurationContext = createContext<NodeConfigurationContextType<any>>({
  activeTab: "Parameters",
  setActiveTab: () => {},
  nodeConfig: {} as unknown as NodeConfigs<any>,
  onChangeTriggerType: () => {},
  updateNode: () => {},
});

export const NodeConfigurationProvider = <TNodeType extends CloudFlowNodeType = CloudFlowNodeType>({
  nodeConfig,
  updateNode,
  children,
  onChangeTriggerType,
}: NodeConfigurationProviderType<TNodeType>) => {
  const [activeTab, setActiveTab] = useState<ConfigurationTab>("Parameters");
  const tabs = useTabs(nodeConfig);

  useEffect(() => {
    setActiveTab(tabs[0] || "Parameters");
  }, [tabs]);

  const updateNodeCallback = useCallback(
    (values: Parameters<HandleUpdateNodeFn<TNodeType>>[1]) => {
      updateNode(nodeConfig.id, values);
    },
    [nodeConfig.id, updateNode]
  );

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      nodeConfig,
      updateNode: updateNodeCallback,
      onChangeTriggerType,
    }),
    [activeTab, nodeConfig, onChangeTriggerType, updateNodeCallback]
  );

  return <NodeConfigurationContext.Provider value={value}>{children}</NodeConfigurationContext.Provider>;
};

export const useNodeConfigurationContext = <TNodeType extends CloudFlowNodeType = CloudFlowNodeType>() =>
  useContext<NodeConfigurationContextType<TNodeType>>(NodeConfigurationContext);
