import { useEffect, useState } from "react";

import { AppModel, AvaConversationsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type FirebaseUserWithEmail } from "../Context/UserContext";

export const useIsDCIAcceleratorCustomer = (customerId?: string, currentUser?: FirebaseUserWithEmail | null) => {
  const [cohort, setCohort] = useState<string>("");
  const [isUsedAvaSupport, setIsUsedAvaSupport] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!customerId || !currentUser?.email) {
        return;
      }
      const ref = getCollection(AppModel).doc("support").collection("dciAccelerationCustomers").doc(customerId);
      const isUsedAvaSupportRef = getCollection(AvaConversationsModel)
        .where("userId", "==", currentUser?.email)
        .where("client", "==", "zendesk")
        .limit(1);

      const [doc, isUsedAvaSupportDoc] = await Promise.all([ref.get(), isUsedAvaSupportRef.get()]);

      if (doc.exists()) {
        const dciCustomer = doc.asModelData();
        if (dciCustomer?.cohort) {
          setCohort(`Cohort ${dciCustomer?.cohort}`);
        } else {
          setCohort("Cohort 1");
        }
      }

      if (!isUsedAvaSupportDoc.empty) {
        setIsUsedAvaSupport(true);
      }
    })();
  }, [customerId, currentUser?.email]);

  return { cohort, isUsedAvaSupport };
};
