import { Button, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { description, descriptionHeader, widgetCardSubheader, widgetCardTitle } from "./constants";

export const CloudFlowUpsell = () => {
  const { customer } = useCustomerContext();

  const cloudFlowLink = `/customers/${customer.id}/cloudflow`;

  return (
    <Card>
      <WidgetCardHeader title={widgetCardTitle} subheader={widgetCardSubheader} />

      <CardContent
        sx={{ height: "288px", margin: "4px", display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        <Stack spacing={2} alignItems="center" textAlign="center">
          <Typography variant="h4">{descriptionHeader}</Typography>
          <Typography variant="body2" sx={{ textWrap: "balance" }}>
            {description}
          </Typography>
          <Button variant="contained" href={cloudFlowLink} sx={{ marginTop: 2 }}>
            <Typography variant="h4">DoiT CloudFlow</Typography>
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
