import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSagas from "../sagas";
import rootReducer from "./reducers";

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));

  store.runSaga = sagaMiddleware.run(rootSagas);

  return store;
}
