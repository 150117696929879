import { useEffect, useState } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { addIdealFirestoreHookOnce, getCollection } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { Button, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { globalText } from "../assets/texts";
import { useAuthContext } from "../Context/AuthContext";
import mixpanel from "../utils/mixpanel";
import { useSnackbar } from "./SharedSnackbar/SharedSnackbar.context";

type AppVersionData = {
  commitSha: string;
  shortCommitSha: string;
  branch: string;
  versionTimestamp: DateTime;
  forceRefresh?: boolean;
  isNewVersionAvailable?: boolean;
  ready?: boolean;
};

export const useAppVersion = () => {
  const snackbar = useSnackbar();
  const [userAppVersionState, setUserAppVersionState] = useState<AppVersionData | null>(null);
  const [currentAppVersionState, setCurrentAppVersionState] = useState<AppVersionData | null>(null);
  const theme = useTheme();
  const { tokenValue, isDoitEmployee } = useAuthContext();
  const [firestoreIdeal, setFirestoreIdeal] = useState(false);
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    addIdealFirestoreHookOnce("versionUpdater", 5000, () => {
      setFirestoreIdeal(true);
    });
  }, []);

  useEffect(() => {
    if (!tokenValue || !firestoreIdeal) {
      return;
    }

    return getCollection(AppModel)
      .doc("version")
      .onSnapshot((docSnap) => {
        const data = docSnap.asModelData();
        if (!data) {
          return;
        }

        const appVersionData = {
          commitSha: data.commitSha,
          shortCommitSha: data.shortCommitSha,
          branch: data.branch,
          versionTimestamp: DateTime.fromJSDate(data.timestamp.toDate()),
          forceRefresh: !!data.forceRefresh,
        };

        setUserAppVersionState((prevUserAppVersionState) => {
          if (!prevUserAppVersionState) {
            return appVersionData;
          }

          return prevUserAppVersionState;
        });
        setCurrentAppVersionState(appVersionData);
      });
  }, [tokenValue, firestoreIdeal]);

  useEffect(() => {
    if (!currentAppVersionState || !userAppVersionState) {
      return;
    }

    const isVersionOlderThanADay =
      userAppVersionState.versionTimestamp &&
      currentAppVersionState.versionTimestamp &&
      currentAppVersionState.versionTimestamp.diff(userAppVersionState.versionTimestamp, "days").days > 0;

    const isNewVersionAvailable = Boolean(
      currentAppVersionState.commitSha && currentAppVersionState.commitSha !== userAppVersionState.commitSha
    );

    if (isVersionOlderThanADay || (isDoitEmployee && isNewVersionAvailable)) {
      const showVersionUpdate = () => {
        snackbar.onOpen({
          message: globalText.NEW_VERSION_AVAILABLE,
          variant: "info",
          autoHideDuration: null,
          action: [
            <Button
              key="refresh"
              aria-label="Refresh"
              variant="contained"
              size="small"
              onClick={() => {
                mixpanel.track("app.refresh");
                window.location.reload();
              }}
              startIcon={<RefreshIcon fontSize="small" />}
              sx={{
                backgroundColor: "snackbar.buttonBg",
                color: "snackbar.buttonText",
                "&:hover": {
                  background: "none",
                  backgroundColor: "snackbar.buttonHover",
                  color: "snackbar.buttonTextHover",
                },
              }}
            >
              {globalText.REFRESH}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              size="small"
              sx={{ ml: 1 }}
              onClick={() => {
                // Close snackbar, but show it again in 1 hour if the page was not refreshed.
                snackbar.onClose();
                // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
                setTimeout(
                  () => {
                    showVersionUpdate();
                  },
                  60 * 60 * 1000
                );
              }}
            >
              <CloseIcon />
            </IconButton>,
          ],
        });
      };

      const t = setTimeout(() => {
        showVersionUpdate();
      }, 30000);

      setIsNewVersionAvailable(true);

      return () => {
        clearTimeout(t);
      };
    } else if (isNewVersionAvailable && currentAppVersionState.forceRefresh) {
      const t = setTimeout(() => {
        globalThis.location.reload();
      }, 180000);

      setIsNewVersionAvailable(true);

      return () => {
        clearTimeout(t);
      };
    }

    setIsNewVersionAvailable(false);
  }, [isDoitEmployee, snackbar, currentAppVersionState, userAppVersionState, theme.mixins]);

  return { ...userAppVersionState, isNewVersionAvailable };
};
