import { type Dispatch, type SetStateAction, useCallback, useMemo, useState } from "react";

import { type Collaborators, type PublicAccess, type SlackChannel } from "@doitintl/cmp-models";
import { Box, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import concat from "lodash/concat";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { budgetText, globalText } from "../../../../assets/texts";
import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import useAnalyticsUsers from "../../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useEntitiesContext } from "../../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useUserContext } from "../../../../Context/UserContext";
import ShareDialog from "../../../../Pages/CloudAnalytics/dialogs/shareDialog/ShareDialog";
import { type CurrentLastPeriods } from "../../../../Pages/CloudAnalytics/ReportData";
import Invites from "../../../../Pages/IAM/InviteUserDialog/handleInvite";
import { type Budget, type BudgetAlert, type BudgetInfo, type DraftBudget } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";
import { dateFormatFullDateWithDay } from "../../../../utils/dateTimeFormats";
import { useAnalyticsContext } from "../../CloudAnalyticsContext";
import { checkAllowedSubdomains, CloudAnalyticsEntities, getNewUsers } from "../../utilities";
import { getAlertAmountFromPercentage, shareBudget, textFieldBaseProps } from "../shared";
import NotificationSettings from "./NotificationSettings";

type Props = {
  budgetInfo: BudgetInfo;
  setBudgetInfo: Dispatch<SetStateAction<BudgetInfo>>;
  isCurrentUserEditor: boolean;
  budget: DraftBudget;
  shouldRefreshData: boolean;
  recipients: string[];
  recipientsSlackChannels: SlackChannel[];
  setRecipients: Dispatch<SetStateAction<string[]>>;
  setRecipientsSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>;
  isNewBudget: boolean;
  currentLastPeriods: CurrentLastPeriods;
  thresholdsAmount: number;
};

const Step3 = ({
  budgetInfo,
  setBudgetInfo,
  isCurrentUserEditor,
  budget,
  shouldRefreshData,
  recipients,
  recipientsSlackChannels,
  setRecipients,
  setRecipientsSlackChannels,
  isNewBudget,
  currentLastPeriods,
  thresholdsAmount,
}: Props) => {
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { handleMissingPermission } = useAnalyticsContext(); // check if valid
  const api = useApiContext();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { entities } = useEntitiesContext();

  const isCurrentUserUserManager = userRoles.usersManager || userRoles.doitEmployee;

  const renderForecastedDate = useCallback(
    (alert: BudgetAlert) => {
      const loading = false;
      const alertAmount = alert.amount ?? 0;
      const currentPeriodAmount = currentLastPeriods?.currentPeriod ?? 0;

      if (alert.amount && loading) {
        return (
          <Typography color="text.secondary" variant="body2">
            {globalText.LOADING}
          </Typography>
        );
      }

      if (alertAmount > 0 && currentPeriodAmount > 0 && alertAmount <= currentPeriodAmount) {
        return (
          <Typography color="error" variant="body2">
            {budgetTxt.CREATE_BUDGET.STEP_3.ALERT_THRESHOLD_CROSSED}
            {budgetInfo.currentTypeAndFrequency.period}
          </Typography>
        );
      }

      const forecastedDate = alert.forecastedDate;

      if (forecastedDate) {
        return (
          <Typography color="text.secondary" variant="body2">
            Forecasted Date:
            <Typography ml={0.5} fontWeight={700} component="span" variant="body2">
              {DateTime.fromMillis(forecastedDate.seconds * 1000).toFormat(dateFormatFullDateWithDay)}
            </Typography>
          </Typography>
        );
      } else if (shouldRefreshData) {
        return (
          <Typography color="text.secondary" variant="caption">
            {globalText.NA}
          </Typography>
        );
      } else if (alert.amount) {
        return (
          <Typography color="text.secondary" variant="caption" lineHeight="20px">
            {budgetText.ALERT_THRESHOLD_NO_REACH}
          </Typography>
        );
      }
      return (
        <Typography color="text.secondary" variant="caption">
          {globalText.NA}
        </Typography>
      );
    },
    [budgetInfo.currentTypeAndFrequency.period, currentLastPeriods, shouldRefreshData]
  );

  const getAlertPercentageFromAmount = useCallback(
    (alertAmount: number) => {
      if (!alertAmount) {
        return 0;
      }
      return (alertAmount / thresholdsAmount) * 100;
    },
    [thresholdsAmount]
  );

  const handleAlertChange = useCallback(
    (fieldName: string, value, index: number) => {
      setBudgetInfo((prev) => {
        const newAlert: BudgetAlert = {
          amount: 0,
          percentage: 0,
          triggered: false,
        };
        switch (fieldName) {
          case "percentage":
            newAlert.percentage = value ? parseInt(value) : 0;
            newAlert.amount = getAlertAmountFromPercentage(value ? parseInt(value) : 0, thresholdsAmount);
            break;
          case "amount":
            newAlert.amount = value ? parseInt(value) : 0;
            newAlert.percentage = getAlertPercentageFromAmount(value ? parseInt(value) : 0);
        }
        const newAlerts = cloneDeep(prev.alerts);
        newAlerts[index] = newAlert;

        return {
          ...prev,
          alerts: newAlerts,
        };
      });
    },
    [thresholdsAmount, getAlertPercentageFromAmount, setBudgetInfo]
  );

  const handleOnBlur = useCallback(() => {
    setBudgetInfo((prev) => {
      const zeros = prev.alerts.filter((a) => a.percentage === 0);
      const sortedNonZeros = prev.alerts.filter((a) => a.percentage !== 0).sort((a, b) => a.percentage - b.percentage);
      const alerts = [...sortedNonZeros, ...zeros];

      return {
        ...prev,
        alerts,
      };
    });
  }, [setBudgetInfo]);

  const addNewUsers = useCallback(
    async (collaborators) => {
      const emails = await getNewUsers({
        collaborators,
        users: userEmails,
        invites,
        baseEntity: budget,
        allUsersAndInvites,
      });
      if (emails.length) {
        await Invites.handleInvite({
          newEmails: emails,
          customer,
          currentUser,
          api,
          entities,
        });
      }
    },
    [api, budget, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );

  const handleChangeSharing = useCallback(
    async (
      collaborators: Collaborators,
      publicAccess: PublicAccess,
      newRecipients: string[] = recipients,
      newRecipientsSlackChannels: SlackChannel[] = recipientsSlackChannels
    ) => {
      if (isNewBudget) {
        return;
      }
      try {
        setShareLoading(true);
        if (isCurrentUserUserManager) {
          try {
            await addNewUsers(collaborators);
          } catch (e) {
            handleMissingPermission(e as string);
            setShareLoading(false);
            return;
          }
        }
        const verifiedRecipients = newRecipients.filter(
          (r) => checkAllowedSubdomains(r) || collaborators.find((c) => c.email === r)
        );
        const newCollabs = differenceWith(collaborators, budget.data.collaborators, isEqual);
        const recipientsWithNewCollaborators = uniq(
          concat(
            verifiedRecipients,
            newCollabs.map((r) => r.email)
          )
        );
        await shareBudget({
          api,
          customer,
          budget: budget as Budget,
          publicAccess,
          collaborators,
          recipients: recipientsWithNewCollaborators,
          recipientsSlackChannels: newRecipientsSlackChannels,
        });
        showSharedSnackbar({ message: "Budget sharing and alerting saved successfully" });
        setShareDialogOpen(false);
        setRecipients(recipientsWithNewCollaborators);
      } catch (error) {
        setRecipients(budget.data.recipients);
        setRecipientsSlackChannels(budget.data.recipientsSlackChannels ?? []);
        consoleErrorWithSentry(error);
      } finally {
        setShareLoading(false);
      }
    },
    [
      recipients,
      recipientsSlackChannels,
      isNewBudget,
      isCurrentUserUserManager,
      budget,
      api,
      customer,
      showSharedSnackbar,
      setRecipients,
      addNewUsers,
      handleMissingPermission,
      setRecipientsSlackChannels,
    ]
  );

  const newCollaborators = useMemo(
    () => recipients.filter((r) => !budget.data.collaborators.find((c) => c.email === r)),
    [recipients, budget.data.collaborators]
  );

  const alertItem = (alert: BudgetAlert, i: number) => (
    <Grid container spacing={1} mb={2}>
      <Grid item xs={6}>
        <TextField
          label={budgetTxt.CREATE_BUDGET.STEP_3.PERCENTAGE}
          type="number"
          onKeyUp={onKeyPressPreventNonNumeric}
          onBlur={handleOnBlur}
          inputProps={{ min: 0 }}
          {...textFieldBaseProps}
          value={alert.percentage || ""}
          onChange={(event) => {
            handleAlertChange("percentage", event.target.value, i);
          }}
          disabled={!thresholdsAmount || !isCurrentUserEditor}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          data-testid="percentage"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={budgetTxt.CREATE_BUDGET.STEP_3.BUDGET_AMOUNT}
          type="number"
          onKeyUp={onKeyPressPreventNonNumeric}
          inputProps={{ min: 0 }}
          onBlur={handleOnBlur}
          {...textFieldBaseProps}
          value={alert.amount || ""}
          onChange={(event) => {
            handleAlertChange("amount", event.target.value, i);
          }}
          disabled={!thresholdsAmount || !isCurrentUserEditor}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(budgetInfo.currency)}</InputAdornment>,
          }}
          data-testid="amount"
        />
        {renderForecastedDate(alert)}
      </Grid>
    </Grid>
  );
  return (
    <Stack mt={4}>
      <Typography variant="subtitle1" fontWeight={500} mb={2}>
        {budgetTxt.CREATE_BUDGET.STEP_3.TITLE}
      </Typography>

      <Box>{budgetInfo.alerts.map((alert, i) => alertItem(alert, i))}</Box>

      <NotificationSettings
        isCurrentUserEditor={isCurrentUserEditor}
        setShareDialogOpen={setShareDialogOpen}
        budget={budget}
        setRecipients={setRecipients}
        setRecipientsSlackChannels={setRecipientsSlackChannels}
        recipients={recipients}
        recipientsSlackChannels={recipientsSlackChannels}
        handleChangeSharing={handleChangeSharing}
        shareLoading={shareLoading}
        isCurrentUserUserManager={isCurrentUserUserManager}
      />

      {!isNewBudget && !!budget.data.collaborators.length && (
        <ShareDialog
          open={shareDialogOpen}
          title="Share budget"
          onClose={() => {
            setShareDialogOpen(false);
            setRecipients(budget.data.recipients);
          }}
          entity={CloudAnalyticsEntities.BUDGET}
          handleChangeSharing={handleChangeSharing}
          predefinedCollaborators={newCollaborators}
          loading={shareLoading}
          shareEntities={[budget.data]}
        />
      )}
    </Stack>
  );
};

export default Step3;
