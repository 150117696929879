import {
  type AssetModelBillingAnomalyModel,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  CurrencyCodes,
  type DashboardModelAttributionModel,
  LimitAggregation,
  Metadata,
  type MetricFilter,
  MetricFilterOperator,
  type ReportFilter,
  TimeInterval,
} from "@doitintl/cmp-models";
import { type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";
import { DateTime, type Zone } from "luxon";

import { type Cloud } from "../../constants/common";
import { type QueryRequest, QueryType } from "../CloudAnalytics/report/ReportQuery";
import { type DataRecord } from "../CloudAnalytics/ReportData";
import { Positions, type TimeSettings } from "../CloudAnalytics/utilities";
import { s3ServiceName } from "./CostAnomalyDetails";
import { type AnomalyPreviewTableRow, type CostAnomaliesItem } from "./types";
import type ReportData from "../CloudAnalytics/ReportData";

export const makeAnomalyAttributionNoValueLabel = (cloud: string | Cloud) => {
  if (cloud === "google-cloud") {
    return "All GCP Resources";
  }

  return "All AWS Resources";
};

export const timeFrame = [
  { id: "RISING_DAILY_COSTS", title: "Daily" },
  { id: "MULTIPLE_ALERTS_IN_A_DAY", title: "Hourly" },
];

export const levelText = ["No alert", "Information", "Warning", "Critical"];

export enum AnomalySidePanelHeaders {
  summary = "Summary",
  optimization = "Optimization",
  skus_explanation = "SKU explanation",
}
export type AlertData = WithFirebaseModel<AssetModelBillingAnomalyModel>;

export const getAnomalyStartTime = (
  anomaly: AssetModelBillingAnomalyModel["metadata"],
  options?:
    | {
        zone: string | Zone | undefined;
      }
    | undefined
): DateTime => {
  if (anomaly.timestamp) {
    return DateTime.fromJSDate(anomaly.timestamp.toDate(), options);
  }

  return DateTime.fromSQL(anomaly.usage_start_time ?? "", options);
};

export const getAnomalyTimeInterval = (anomaly: AssetModelBillingAnomalyModel["metadata"]): TimeInterval => {
  if (anomaly.frequency) {
    switch (anomaly.frequency) {
      case "DAILY":
        return TimeInterval.DAY;

      case "HOURLY":
        return TimeInterval.HOUR;

      case "WEEKLY":
        return TimeInterval.WEEK;
    }
  }
  return anomaly?.context === "RISING_DAILY_COSTS" ? TimeInterval.DAY : TimeInterval.HOUR;
};

export const getAnomalyLevel = (anomaly: AssetModelBillingAnomalyModel["metadata"]): number => {
  if (anomaly.severity) {
    return anomaly.severity;
  }

  return parseInt(anomaly.explorated_level?.rules_model ?? "0");
};

export const getDateColumns = (isDailyAnomaly: boolean): DataRecord[] => {
  // hardcode year, month, day
  const columns: DataRecord[] = [];
  const a: DataRecord = {
    label: "Year",
    type: Metadata.DATETIME,
    id: "datetime:year",
    field: "T.usage_date_time",
    key: "year",
    position: Positions.COL,
    nullFallback: "[N/A]",
  };
  const b: DataRecord = {
    label: "Month",
    type: Metadata.DATETIME,
    id: "datetime:month",
    field: "T.usage_date_time",
    key: "month",
    position: Positions.COL,
    nullFallback: "[N/A]",
  };
  const c: DataRecord = {
    label: "Day",
    type: Metadata.DATETIME,
    id: "datetime:day",
    field: "T.usage_date_time",
    key: "day",
    position: Positions.COL,
    nullFallback: "[N/A]",
  };
  if (!isDailyAnomaly) {
    const d: DataRecord = {
      label: "Hour",
      type: Metadata.DATETIME,
      id: "datetime:hour",
      field: "T.usage_date_time",
      key: "hour",
      position: Positions.COL,
      nullFallback: "[N/A]",
    };
    columns.push(d);
  }
  columns.push(a);
  columns.push(b);
  columns.push(c);
  return columns;
};
export const getGroupByRows = (showOperationColumn: boolean): DataRecord[] => {
  // hardcode all the groupBy rows
  const rows: DataRecord[] = [];
  const a: DataRecord = {
    field: "T.sku_description",
    id: "fixed:sku_description",
    key: "sku_description",
    label: "SKU",
    nullFallback: "[SKU N/A]",
    position: Positions.ROW,
    type: Metadata.FIXED,
  };
  const b: DataRecord = {
    field: "T.resource_id",
    id: "fixed:resource_id",
    key: "resource_id",
    label: "Resource",
    nullFallback: "[Resource N/A]",
    position: Positions.ROW,
    type: Metadata.FIXED,
  };
  if (showOperationColumn) {
    const c: DataRecord = {
      field: "T.operation",
      id: "fixed:operation",
      key: "operation",
      label: "Operation",
      nullFallback: "[Operation N/A]",
      position: Positions.ROW,
      type: Metadata.FIXED,
    };
    rows.push(c);
  }
  rows.push(a);
  rows.push(b);
  return rows;
};
export const getAttributionId = (
  cloudProvider: string,
  attributionRef?: ModelReference<DashboardModelAttributionModel>
): string | undefined => {
  const allGCPResourcesAttrb = "7mJDP8HtXXlpFTb2pVz7";
  const allAWSResourcesAttrb = "PvqyGcdFcTHh7aLUdGdf";
  if (attributionRef) {
    return attributionRef.id;
  } else if (cloudProvider === AssetTypeGoogleCloud) {
    // Handle old GCP anomalies that don't have attribution field
    return allGCPResourcesAttrb;
  } else if (cloudProvider === AssetTypeAmazonWebServices) {
    // Handle old AWS anomalies that don't have attribution field
    return allAWSResourcesAttrb;
  }
};
export const getFilters = (alertData: AlertData): ReportFilter[] => {
  const filters: ReportFilter[] = [];
  const cloud_provider: any = {
    allowNull: false,
    field: "T.cloud_provider",
    id: "fixed:cloud_provider",
    includeInFilter: true,
    inverse: false,
    key: "cloud_provider",
    limit: 0,
    limitMetric: 0,
    position: Positions.UNUSED,
    type: Metadata.FIXED,
    values: [alertData?.metadata.platform],
  };
  const service_description: any = {
    allowNull: false,
    field: "T.service_description",
    id: "fixed:service_description",
    includeInFilter: true,
    key: "service_description",
    position: Positions.UNUSED,
    type: Metadata.FIXED,
    values: [alertData?.metadata.service_name],
  };
  const credit: any = {
    allowNull: true,
    field: "report_value.credit",
    id: "fixed:credit",
    includeInFilter: true,
    inverse: false,
    key: "credit",
    position: Positions.UNUSED,
    type: Metadata.FIXED,
    values: [],
  };
  const attribution: any = {
    allowNull: false,
    field: "",
    id: "attribution:attribution",
    includeInFilter: true,
    key: "attribution",
    position: Positions.UNUSED,
    type: Metadata.ATTRIBUTION,
    values: [getAttributionId(alertData?.metadata.platform, alertData.attribution)],
  };
  const sku_description: any = {
    allowNull: false,
    field: "T.sku_description",
    id: "fixed:sku_description",
    includeInFilter: true,
    inverse: false,
    key: "sku_description",
    limit: 4,
    limitMetric: 0,
    limitOrder: "desc",
    position: Positions.ROW,
    type: Metadata.FIXED,
    values: alertData.metadata.sku_name ? [alertData.metadata.sku_name] : [],
  };
  const resource_id: any = {
    allowNull: false,
    field: "T.resource_id",
    id: "fixed:resource_id",
    includeInFilter: true,
    inverse: false,
    key: "resource_id",
    limit: 4,
    limitMetric: 0,
    limitOrder: "desc",
    position: Positions.ROW,
    type: Metadata.FIXED,
    values: [],
  };

  filters.push(...[cloud_provider, service_description, credit, attribution, sku_description, resource_id]);

  if (alertData?.metadata.project_id) {
    const project_id: any = {
      allowNull: false,
      field: "T.project_id",
      id: "fixed:project_id",
      includeInFilter: true,
      key: "project_id",
      position: Positions.UNUSED,
      type: Metadata.FIXED,
      values: [alertData?.metadata.project_id],
    };
    filters.push(project_id);
  }

  return filters;
};
const operationCostThreshold = 1;
const getMetricFilters = (showOperationColumn: boolean): MetricFilter[] | null => {
  if (showOperationColumn) {
    const a: MetricFilter = {
      metric: 0,
      operator: MetricFilterOperator.GREATER_THAN,
      values: [operationCostThreshold],
    };
    return [a];
  }
  return null;
};
export const getPreviewQueryRequestPayload = (customerId: string, alertData: AlertData | null): QueryRequest | null => {
  if (!alertData) {
    return null;
  }
  const showOperationColumn = alertData.metadata.service_name === s3ServiceName;
  const startTime = getAnomalyStartTime(alertData.metadata, {
    zone: "utc",
  });
  if (!startTime) {
    return null;
  }
  const interval = getAnomalyTimeInterval(alertData.metadata);
  const endTime = interval === TimeInterval.DAY ? startTime : startTime.plus({ hours: 1 });
  return {
    id: getAttributionId(alertData?.metadata.platform, alertData.attribution) ?? "",
    type: QueryType.ATTRIBUTION,
    accounts: [alertData?.metadata.billing_account_id?.toString() ?? ""],
    cloudProviders: alertData?.metadata.platform ? [alertData.metadata.platform] : null,
    cols: getDateColumns(
      alertData?.metadata.context === "RISING_DAILY_COSTS" || alertData.metadata.frequency === "DAILY"
    ),
    rows: getGroupByRows(showOperationColumn),
    filters: getFilters(alertData),
    currency: CurrencyCodes.USD,
    limitAggregation: LimitAggregation.NONE,
    timeSettings: {
      interval,
      from: startTime,
      to: endTime,
    },
    calculatedMetric: null,
    metricFilters: getMetricFilters(showOperationColumn),
  };
};

const skuIndexInRes = 0;
const resourceIndexInRes = 1;
const costIndexInDataArray = 6;
const operationIndexInRes = 2;
export const getAnomalyPreviewTableRows = (
  alertData: AlertData | null,
  data: ReportData,
  request: QueryRequest | null
): AnomalyPreviewTableRow[] => {
  const rows: AnomalyPreviewTableRow[] = [];
  const indexes = getColumnIndexes(data);
  if (indexes.length < 2) {
    return rows;
  }
  let operationIndex = -1;
  if (!alertData) {
    return rows;
  }
  const showOperationColumn = alertData.metadata.service_name === s3ServiceName;
  if (showOperationColumn) {
    operationIndex = indexes[operationIndexInRes];
  }
  const skuIndex = indexes[skuIndexInRes];
  const resourceIndex = indexes[resourceIndexInRes];
  const rowKeys = data.getRowKeys();
  for (const element of rowKeys) {
    const row: AnomalyPreviewTableRow = {
      sku: "",
      resource: "",
      cost: 0,
    };
    row.sku = element[skuIndex];
    row.resource = element[resourceIndex];
    row.cost = getCostForResource(data, request?.timeSettings, row.sku, row.resource, skuIndex, resourceIndex);
    if (operationIndex !== -1) {
      row.operation = element[operationIndex];
    }
    rows.push(row);
  }

  return rows;
};

function getTotalForRow(sku: string, resource: string, data: ReportData): number {
  const rowTotals = data.getRowTotals();
  for (const total in rowTotals) {
    if (total.includes(sku) && total.includes(resource) && total.endsWith(resource)) {
      return rowTotals[total].sum;
    }
  }
  return -1;
}

function getCostForResource(
  data: ReportData,
  timeSettings: TimeSettings | undefined,
  sku: string,
  resource: string,
  skuIndex,
  resourceIndex: number
): number {
  if (timeSettings?.interval === TimeInterval.DAY) {
    return getTotalForRow(sku, resource, data);
  }
  const timeString = timeSettings?.from?.setZone("America/Los_Angeles").toFormat("HH:mm");
  // Filter the data to only include rows with the correct hour
  const hourIndex = data.cols.findIndex((col) => col.label === "Hour") + data.rows.length;
  const filteredData = data.data.filter((row) => {
    const hour = row[hourIndex];
    return hour === timeString; // && day === timeSettings?.from?.day.toString();
  });
  const resourceComp = resource === "[Resource N/A]" ? null : resource;
  const sum = filteredData.reduce((total, row) => {
    if (row[skuIndex] === sku && row[resourceIndex] === resourceComp) {
      return total + (row[costIndexInDataArray] as number);
    } else {
      return total;
    }
  }, 0);
  return sum;
}

function getColumnIndexes(data: ReportData): Array<number> {
  const indexes: Array<number> = [];
  let i = 0;
  for (const rowSchema of data.rows) {
    if (rowSchema.label === "SKU") {
      indexes[skuIndexInRes] = i;
    } else if (rowSchema.label === "Resource") {
      indexes[resourceIndexInRes] = i;
    } else if (rowSchema.label === "Operation") {
      indexes[operationIndexInRes] = i;
    }
    i++;
  }
  return indexes;
}

export function getAnomalyDuration(anomaly: {
  status?: "ACTIVE" | "INACTIVE";
  inactiveDate?: DateTime;
  _ts: DateTime;
}): string {
  if (anomaly.status === "ACTIVE") {
    return "Ongoing";
  }

  if (anomaly.status === "INACTIVE") {
    return calculateDuration(anomaly.inactiveDate, anomaly._ts);
  }

  return "";
}

function calculateDuration(endDate: DateTime | undefined, startDate: DateTime): string {
  const durationDays = endDate?.diff(startDate, ["days"]).toObject();
  const durationHours = endDate?.diff(startDate, ["hours"]).toObject();
  const durationMinutes = endDate?.diff(startDate, ["minutes"]).toObject();

  if (durationDays?.days && durationDays.days >= 1) {
    return `${Math.round(durationDays.days)} day${Math.round(durationDays.days) > 1 ? "s" : ""}`;
  }

  if (durationHours?.hours && durationHours.hours >= 1) {
    return `${Math.round(durationHours.hours)} hour${Math.round(durationHours.hours) > 1 ? "s" : ""}`;
  }

  if (durationMinutes?.minutes && durationMinutes.minutes >= 1) {
    return `${Math.round(durationMinutes.minutes)} minute${Math.round(durationMinutes.minutes) > 1 ? "s" : ""}`;
  }

  return "";
}

export const levelChipColor = {
  0: {
    backgroundColor: "#0277bd",
    color: "white",
  },
  1: {
    backgroundColor: "#0277bd",
    color: "white",
  },
  2: {
    backgroundColor: "#FF9800",
    color: "black",
  },
  3: {
    backgroundColor: "#E53935",
    color: "white",
  },
};

export const statusChipColor = (alertData: AlertData | CostAnomaliesItem) => ({
  backgroundColor: alertData.status === "INACTIVE" || !alertData.status ? "#E0E0E0" : "#388E3C",
  color: alertData.status === "INACTIVE" || !alertData.status ? "#000" : "#fff",
});
