import { type BQLensBillingMode, BQLensBillingModes } from "@doitintl/cmp-models";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import { billingModeChanger } from "../../../assets/texts/BQLens/billingModeChanger";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { CircularProgressLoader } from "../../Loader";
import { useBigQueryLensDashboardContext } from "./BigQueryLensDashboard";
import { getFilteredUnitsBasedOnMetricValue, useSelectStyles } from "./utils";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

export const BillingModeChanger = () => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();

  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const {
    selectedBillingMode,
    selectedTimeframe,
    selectedMetric,
    setDashboardWidgetContextMultipleValues,
    spendProfile,
  } = useBigQueryLensDashboardContext();

  const { loading } = useTier();

  if (loading) {
    return <CircularProgressLoader />;
  }

  return (
    <>
      <Box p={1} />

      <FormControl margin="dense" className={classes.formControl}>
        <InputLabel>Usage type</InputLabel>
        <Select
          className={selectClasses.select}
          size="small"
          label="Usage type"
          value={selectedBillingMode}
          data-cy="billing-mode"
          onChange={async (e) => {
            const newBillingMode = e.target.value as BQLensBillingMode;

            const filteredUnits = await getFilteredUnitsBasedOnMetricValue({
              metricType: selectedMetric,
              selectedBillingMode: newBillingMode,
              customerId: customer.id,
              selectedTimeframe,
            });

            if (filteredUnits.length === 0) {
              throw new Error(
                `No units available for the selected metric and billing mode ${selectedMetric} ${newBillingMode}`
              );
            }

            const measurement = filteredUnits[0];

            setDashboardWidgetContextMultipleValues({
              selectedMeasurement: measurement,
              selectedMetric,
              selectedBillingMode: newBillingMode,
              availableUnitOptions: filteredUnits,
            });
          }}
        >
          {spendProfile?.isOnDemand && (
            <MenuItem value={BQLensBillingModes.onDemand} className={selectClasses.select}>
              {billingModeChanger.ON_DEMAND}
            </MenuItem>
          )}

          {spendProfile?.isStandardEdition && (
            <MenuItem value={BQLensBillingModes.standardEdition} className={selectClasses.select}>
              {billingModeChanger.STANDARD_EDITION}
            </MenuItem>
          )}

          {spendProfile?.isEnterpriseEdition && (
            <MenuItem value={BQLensBillingModes.enterpriseEdition} className={selectClasses.select}>
              {billingModeChanger.ENTERPRISE_EDITION}
            </MenuItem>
          )}

          {spendProfile?.isEnterprisePlusEdition && (
            <MenuItem value={BQLensBillingModes.enterprisePlusEdition} className={selectClasses.select}>
              {billingModeChanger.ENTERPRISE_PLUS_EDITION}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
