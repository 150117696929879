import { useCallback, useEffect, useState } from "react";

import { type CloudFlowNodeType, type NodeConfigApiParameters } from "@doitintl/cmp-models";

import { useApiContext } from "../../../../api/context";
import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { type AWSPermissions, type CloudPermissions, type NodeConfigs, type PermissionMap } from "../../types";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useReferenceableNodes } from "../Common/hooks/useReferenceableNodes";
import { getApiServiceDescriptor } from "../utils/getApiServiceDescriptor";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

const validateAPIParams = (parameters: NodeConfigApiParameters) => {
  if (!parameters) {
    consoleErrorWithSentry("parameters are required for permissions validation");
    return false;
  }
  switch (parameters.provider) {
    case "AWS":
      if (typeof parameters.configurationValues?.accountId !== "string") {
        consoleErrorWithSentry("accountId is required for AWS permissions validation");
        return false;
      }
      break;
    case "GCP":
      if (
        typeof parameters.configurationValues?.serviceAccount !== "string" ||
        typeof parameters.configurationValues?.organization !== "string"
      ) {
        consoleErrorWithSentry("serviceAccount and organization are required for GCP permissions validation");
        return false;
      }
      break;
    default:
      consoleErrorWithSentry(`Unsupported provider: ${parameters.provider}`);
      return false;
  }

  return true;
};

function isAwsPermissionGranted(
  awsPermissions: AWSPermissions,
  permissionsNamespace: string,
  operationName: string
): boolean {
  return awsPermissions[permissionsNamespace]?.some((grantedPermission) =>
    new RegExp(`^${grantedPermission.replace("*", "\\w+")}$`).test(operationName)
  );
}

/*
 * This hook is used to get the permissions for the cloud provider
 * nodeConfig should contain the operationRef, accountId(AWS) organizationId(AWS) or serviceAccount(GCP),
 */
export const useCloudPermissions = (): CloudPermissions => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const customerId = useCustomerId();
  const api = useApiContext();
  const showError = useErrorSnackbar(7);

  const [requiredPermissions, setRequiredPermissions] = useState<string[]>([]);
  const [command, setCommand] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);

  // TODO this request may be moved higher in the component tree
  const getAWSPermissions = useCallback(
    async ({ customerId, accountId }: { customerId: string; accountId: string }) => {
      const response = await api.request<AWSPermissions>({
        method: "GET",
        url: `/v1/customers/${customerId}/cloudflow/get-aws-permissions/${accountId}`,
      });

      return response.data;
    },
    [api]
  );

  const validateGCPPermissions = useCallback(
    async ({
      customerId,
      serviceAccount,
      permissions,
    }: {
      customerId: string;
      serviceAccount: string;
      permissions: string[];
    }) => {
      const response = await api.request<PermissionMap>({
        method: "POST",
        url: `/v1/customers/${customerId}/cloudflow/validate-gcp-permissions`,
        data: {
          serviceAccount,
          permissions,
          scopes: ["https://www.googleapis.com/auth/cloud-platform"],
        },
      });

      return response.data;
    },
    [api]
  );

  function updateCommand(nodeConfig: NodeConfigs<CloudFlowNodeType.ACTION>, permission: string) {
    switch (nodeConfig?.parameters?.provider) {
      case "AWS":
        setCommand(
          `aws iam put-role-policy \\
--role-name doitintl-cmp \\
--policy-name doit-cloudflow-${nodeConfig.flowId}-node-${nodeConfig.id} \\
--policy-document "{
\\"Version\\": \\"2012-10-17\\",
\\"Statement\\": [{\\"Action\\": [\\"${permission}\\"],
\\"Resource\\": \\"*\\",
\\"Effect\\": \\"Allow\\"}]}"`
        );
        break;
      case "GCP": {
        const orgId = nodeConfig.parameters?.configurationValues?.organization.split("/").pop();
        setCommand(`gcloud iam roles update doit_cmp_role --organization ${orgId} --add-permissions ${permission}`);
        break;
      }
      default:
        throw new Error(`Provider not supported`);
    }
  }

  const updatePermissionsError = useCallback(
    (valid: boolean) => {
      updateNode((prevNode) => {
        const errors = { ...prevNode.errors };
        if (valid) {
          delete errors.permission_error;
        } else {
          errors.permission_error = "Incorrect permissions";
        }

        return { errors };
      });
    },
    [updateNode]
  );

  const updatePermissions = useCallback(async () => {
    if (!nodeConfig.parameters) {
      return;
    }

    if (!validateAPIParams(nodeConfig.parameters)) {
      showError("Invalid parameters for permissions validation");
      return;
    }

    let permissionMap: PermissionMap = {};
    const operation = nodeConfig.parameters.operation;
    setLoading(true);
    try {
      switch (nodeConfig.parameters.provider) {
        case "AWS":
          {
            const [serviceDescriptor, awsPermissions] = await Promise.all([
              getApiServiceDescriptor(operation.provider, operation.service),
              getAWSPermissions({
                customerId,
                accountId: nodeConfig.parameters.configurationValues.accountId,
              }),
            ]);
            const permissionExists = isAwsPermissionGranted(
              awsPermissions,
              serviceDescriptor.permissionsNamespace ?? operation.service,
              operation.id
            );

            if (!permissionExists) {
              const awsPermission = `${serviceDescriptor.permissionsNamespace}:${operation.id}`;
              setRequiredPermissions([awsPermission]);
              updateCommand(nodeConfig, awsPermission);
            } else {
              setValid(true);
            }

            updatePermissionsError(permissionExists);
          }
          break;
        case "GCP":
          {
            const gcpPermission = `${operation.service}.${operation.id}`;
            permissionMap = await validateGCPPermissions({
              customerId,
              serviceAccount: nodeConfig.parameters.configurationValues.serviceAccount,
              permissions: [gcpPermission],
            });

            const filteredPermissions = Object.entries(permissionMap)
              .filter(([, value]) => !value)
              .map(([key]) => key);

            if (filteredPermissions.length > 0) {
              setRequiredPermissions(filteredPermissions);
              updateCommand(nodeConfig, gcpPermission);
            } else {
              setValid(true);
            }

            updatePermissionsError(filteredPermissions.length > 0);
          }
          break;
        default:
          consoleErrorWithSentry(`Unsupported provider`);
      }
    } catch (error: any) {
      showError(`Failed to update permissions, error: ${error.message}`);
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  }, [customerId, getAWSPermissions, nodeConfig, updatePermissionsError, showError, validateGCPPermissions]);

  return { requiredPermissions, updatePermissions, command, loading, isValid };
};

export const useNodeDataSourceUpdate = ({ nodeId, referencedNodeId }: { nodeId: string; referencedNodeId: string }) => {
  const [referenceableNodes] = useReferenceableNodes(nodeId);

  const [selectedNode, setSelectedNode] = useState<NodeWitOutputModel | undefined>();
  const [switchDataSourceOpen, setSwitchDataSourceOpen] = useState(false);
  const [handleConfirm, setHandleConfirm] = useState<(() => Promise<any>) | undefined>();
  const [handleClose, setHandleClose] = useState<(() => Promise<any>) | undefined>();

  useEffect(() => {
    const preselectedNode = referenceableNodes.find((node) => node.id === referencedNodeId);
    setSelectedNode(preselectedNode);
  }, [referencedNodeId, referenceableNodes]);

  const confirmSwitchDataSource = useCallback(
    async (onConfirm: (() => Promise<void>) | undefined, onClose: (() => Promise<void>) | undefined): Promise<void> => {
      setSwitchDataSourceOpen(true);
      const handleConfirmWrapper = async () => {
        setSwitchDataSourceOpen(false);
        if (onConfirm) {
          await onConfirm();
        }
      };
      const handleCloseWrapper = async () => {
        setSwitchDataSourceOpen(false);
        if (onClose) {
          await onClose();
        }
      };
      setHandleConfirm(() => handleConfirmWrapper);
      setHandleClose(() => handleCloseWrapper);
    },
    []
  );

  return {
    selectedNode,
    setSelectedNode,
    referenceableNodes,
    switchDataSourceOpen,
    setSwitchDataSourceOpen,
    handleConfirm,
    handleClose,
    confirmSwitchDataSource,
  };
};
