import { type ReactNode, useCallback } from "react";

import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeMicrosoftAzure } from "@doitintl/cmp-models";
import { Alert, Box } from "@mui/material";
import intersection from "lodash/intersection";

import { cloudAnalyticsText } from "../../assets/texts";
import { useCloudAnalyticsContext } from "../../Context/AnalyticsContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type AttributionWRef, type ReportWSnap } from "../../types";
import { type AnalyticsAlertWithRef } from "./alerts";
import AllocationBrowser from "./allocations/AllocationBrowser";
import AnalyticsResourcesBrowser from "./analyticsResources/AnalyticsResourcesBrowser";
import { DialogContextProvider } from "./analyticsResources/Context";
import AttributionGroupsBrowser from "./attributionGroups/AttributionGroupsBrowser";
import { type AttributionGroupWithRef } from "./attributionGroups/types";
import AttributionBrowser from "./attributions/AttributionBrowser";
import { BudgetBrowser } from "./budgets/BudgetBrowser";
import { LabelsBrowser } from "./labels/LabelsBrowser";
import { MetricBrowser } from "./metrics";
import ReportBrowser from "./reports/ReportBrowser";
import ReportTemplateWrapper from "./templateLibrary/ReportTemplateWrapper";
import { type ReportTemplateCard } from "./templateLibrary/types";

const customerSupportedAssetTypes = [AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeMicrosoftAzure];

type Props = {
  reports: ReportWSnap[];
  reportsLoading: boolean;
  attributionsLoading: boolean;
  pageId: string;
  attributions: AttributionWRef[];
  attributionGroups: AttributionGroupWithRef[];
  alerts: AnalyticsAlertWithRef[];
  reportTemplateCards: ReportTemplateCard[];
};

const AnalyticsTabs = ({
  attributions,
  attributionGroups,
  alerts,
  reports,
  reportsLoading,
  attributionsLoading,
  pageId,
  reportTemplateCards,
}: Props) => {
  const { customerOrPresentationModeCustomer: customer, isProductOnlyCustomer } = useCustomerContext();
  const { hasMetadata } = useCloudAnalyticsContext();

  const isFSStandaloneCustomer =
    isProductOnlyCustomer && (customer?.enabledFlexsave?.AWS || customer?.enabledFlexsave?.GCP);
  const isSaaSConsoleCustomer =
    isProductOnlyCustomer && (customer?.enabledSaaSConsole?.AWS || customer?.enabledSaaSConsole?.GCP);

  let TabPanel: ReactNode | null = null;

  switch (pageId) {
    case "analytics:resources":
      TabPanel = (
        <DialogContextProvider>
          <AnalyticsResourcesBrowser
            reports={reports}
            attributions={attributions}
            alerts={alerts}
            attributionGroups={attributionGroups}
          />
        </DialogContextProvider>
      );
      break;
    case "analytics:reports":
      TabPanel = <ReportBrowser reports={reports} loading={reportsLoading} />;
      break;
    case "analytics:report-templates":
      TabPanel = <ReportTemplateWrapper reportTemplateCards={reportTemplateCards} />;
      break;
    case "analytics:allocations":
      TabPanel = <AllocationBrowser />;
      break;
    case "analytics:attributions":
      TabPanel = <AttributionBrowser attributions={attributions} loading={attributionsLoading} />;
      break;
    case "analytics:attribution-groups":
      TabPanel = <AttributionGroupsBrowser attributionGroups={attributionGroups} />;
      break;
    case "analytics:metrics":
      TabPanel = <MetricBrowser reports={reports} />;
      break;
    case "analytics:budgets":
      TabPanel = <BudgetBrowser />;
      break;
    case "analytics:labels":
      TabPanel = <LabelsBrowser />;
      break;
    default:
  }

  const noMetadataAlert = useCallback(() => {
    let message;

    if (isFSStandaloneCustomer) {
      message = cloudAnalyticsText.ANALYTICS_TABS_ALERT_STANDALONE;
    } else if (isSaaSConsoleCustomer) {
      message = cloudAnalyticsText.ANALYTICS_TABS_ALERT_SAAS_CONSOLE;
    } else {
      message =
        intersection(customer?.assets ?? [], customerSupportedAssetTypes).length > 0
          ? cloudAnalyticsText.ANALYTICS_TABS_ALERT
          : cloudAnalyticsText.ANALYTICS_TABS_ALERT_NO_ASSETS;
    }

    return (
      <Box>
        <Alert severity="warning">{message}</Alert>
      </Box>
    );
  }, [customer.assets, isFSStandaloneCustomer, isSaaSConsoleCustomer]);

  if (!TabPanel) {
    return null;
  }

  if (hasMetadata === undefined) {
    return;
  }

  return <Box>{!hasMetadata ? noMetadataAlert() : TabPanel}</Box>;
};

export default AnalyticsTabs;
