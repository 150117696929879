import ClusterPageHeader from "./Header";
import ClusterTable from "./Table/ClusterTable";

const ClustersPage = () => (
  <>
    <ClusterPageHeader />
    <ClusterTable />
  </>
);

export default ClustersPage;
