import { AppModel, ChannelModel, CustomerModel, type EarlyAccessFeature } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Feature, FeatureDetails } from "./types";

/**
 * Return all the defined available aws features
 */
export const getAllFeaturesDetails = async (
  earlyAccessFeatures: EarlyAccessFeature[] | undefined
): Promise<Feature[]> => {
  const cloudConnect = await getCollection(AppModel).doc("cloud-connect").get();
  const data = cloudConnect.asModelData();
  if (!data) {
    return [];
  }
  const permissions = data.awsFeaturePermissions;

  // Filter permissions based on featureFlag and earlyAccessFeatures
  const filteredPermissions = permissions.filter((featurePermissions) => {
    const { featureFlag } = featurePermissions;

    if (featureFlag) {
      // If feature has a feature flag, check if it's in earlyAccessFeatures
      const isEnabled = earlyAccessFeatures?.includes(featureFlag as EarlyAccessFeature);
      return isEnabled;
    } else {
      // If there's no feature flag, show the feature by default
      return true;
    }
  });

  // Map the filtered permissions to the Feature format
  return filteredPermissions.map((featurePermissions) => ({
    name: featurePermissions.name,
    permissions: featurePermissions.permissions,
    policies: featurePermissions.policies,
    textFields: featurePermissions.textFields,
    displayName: FeatureDetails[featurePermissions.name].displayName,
    description: FeatureDetails[featurePermissions.name].description,
    mandatory: FeatureDetails[featurePermissions.name].mandatory,
  }));
};

/**
 * Delete account from firebase
 */
export const deleteAccount = (customerId: string, docId: string) =>
  getCollection(CustomerModel).doc(customerId).collection("cloudConnect").doc(docId).delete();

/**
 * Craete new document that will be used to update status of stack creation
 * @param customerId - customer
 * @param userUid
 * @param stackName - will be used as uniq id to track changes
 */
export const addChannelsDoc = (customerId: string, userUid: string, stackName: string) =>
  getCollection(ChannelModel).add({
    stackName,
    complete: false,
    customer: customerId,
    type: "aws.manage.account",
    uid: userUid,
  });
