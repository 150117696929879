import { useMemo, useRef } from "react";

import { type Message, type MessageAva, type MessageUser } from "../Common";
import { type AnswerExtras } from "./../useAva";

export const isReportAvaReply = (reply: string): boolean =>
  reply.includes("reportConfig") && reply.includes("reportResult");
export const isReportBlocked = (reply: string): boolean =>
  reply.includes(JSON.stringify({ run_report_from_config: "abort" }));
export const isValidReportAvaReply = (reply: string): boolean =>
  isReportAvaReply(reply) && !reply.includes("parsingErrors");

export const useMessagesInProgress = (
  generationStopped: boolean,
  question: string,
  answer: string,
  answerExtras?: AnswerExtras,
  reportLengths?: Record<string, number>,
  setReportLengths?: (reportLengths: Record<string, number>) => void
) => {
  const prevMessageInProgress = useRef<[MessageUser, MessageAva]>();

  const messageUserInProgress = useMemo(() => {
    const { current } = prevMessageInProgress;
    if (generationStopped && current) {
      return current[0];
    }

    const userMessage: MessageUser = {
      content: question,
      role: "user",
      createdAt: new Date(),
    };
    if (current) {
      current[0] = userMessage;
    }
    return userMessage;
  }, [question]);

  const messageAvaInProgress = useMemo(() => {
    const { current } = prevMessageInProgress;
    if (generationStopped && current) {
      return current[1];
    }
    const isReport = isReportAvaReply(answer);

    if (
      isReport &&
      reportLengths &&
      setReportLengths &&
      answerExtras?.answerId &&
      !reportLengths[answerExtras?.answerId]
    ) {
      // save the length of the report to be able to extract additional data later efficiently
      setReportLengths({ ...reportLengths, [answerExtras?.answerId ?? ""]: answer.length });
    }

    // If the answer is a report with more words appended, we need to extract the additional data:
    const isAdditionalData =
      reportLengths &&
      answerExtras?.answerId &&
      reportLengths[answerExtras.answerId] &&
      reportLengths[answerExtras.answerId] !== answer.length;

    const avaMessage: MessageAva = {
      content: isAdditionalData ? "" : answer,
      role: "ava",
      completed: answerExtras?.done ?? false,
      answerId: answerExtras?.answerId,
      createdAt: new Date(),
      isReport,
      reportBlocked: isReportBlocked(answer),
      additionalData: isAdditionalData ? answer.substring(reportLengths[answerExtras.answerId]) : undefined,
    };
    if (current) {
      current[1] = avaMessage;
    }

    return avaMessage;
  }, [question, answer, answerExtras]);

  return { messageUserInProgress, messageAvaInProgress };
};

export const useHistoryToShow = (
  question: string,
  processingQuestion: boolean,
  history?: Message[],
  answerId?: string
) =>
  useMemo(() => {
    if (history?.length) {
      let auxHistory = history.filter((message) => message.role !== "ava" || message.answerId !== answerId);

      let lastHistoryMessage = auxHistory.slice(-1)[0];

      if (lastHistoryMessage.role === "ava" && question && !processingQuestion) {
        auxHistory = history.slice(0, -1);
        lastHistoryMessage = auxHistory.slice(-1)[0];
      }
      if (lastHistoryMessage.role === "user" && question) {
        auxHistory = auxHistory.slice(0, -1);
      }
      auxHistory.forEach((message: Message) => {
        if (isReportAvaReply(message.content)) {
          if (message.role !== "user") {
            message.isReport = true;
          }
        }
        if (isReportBlocked(message.content) && "reportBlocked" in message) {
          message.reportBlocked = true;
        }
      });
      return auxHistory;
    }

    return history ?? [];
  }, [history, question, processingQuestion]);
