import { getModelByPath } from "@doitintl/cloudflow-commons";
import { ModelType, NodeTransformationType, type ReferencedNodeValue } from "@doitintl/cmp-models";
import * as yup from "yup";

import { cloudflowTexts } from "../../../../../../assets/texts";
import type { NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";

function validateReferencedFieldOrString(
  referenceableNodes: NodeWitOutputModel[],
  rootReferencedNodeValue: ReferencedNodeValue = {
    referencedField: [],
    referencedNodeId: "",
  }
) {
  return yup.mixed().test({
    name: "isObjectOrString",
    message: "Referenced field must be either a string or a string type reference",
    test: (value) =>
      typeof value === "string" ||
      (typeof value === "object" &&
        yup
          .object({
            referencedNodeId: yup.string().default(rootReferencedNodeValue.referencedNodeId),
            referencedField: yup.array().of(yup.string().required()).default(rootReferencedNodeValue.referencedField),
          })
          .test("referenced-field-model", "Referenced field must reference a string field", (value) => {
            const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
            if (referencedNodeModel === undefined) {
              return false;
            }
            const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);
            return referencedModel.type === ModelType.STRING;
          })
          .isValidSync(value)),
  });
}

export function generateConcatenationSchema(
  referenceableNodes: NodeWitOutputModel[],
  rootReferencedNodeValue: ReferencedNodeValue = {
    referencedField: [],
    referencedNodeId: "",
  }
) {
  return yup.object({
    type: yup.string().default(NodeTransformationType.CONCATENATION),
    newFieldName: yup.string().default("").required().label(cloudflowTexts.NEW_FIELD_NAME_REQUIRED).trim(),
    payload: yup
      .array()
      .default([
        {
          referencedNodeId: rootReferencedNodeValue.referencedNodeId,
          referencedField: rootReferencedNodeValue.referencedField,
        },
      ])
      .of(validateReferencedFieldOrString(referenceableNodes, rootReferencedNodeValue))
      .required(),
  });
}
