import { useMemo } from "react";

import { type AlgoliaConfig, AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import { useQuery } from "@tanstack/react-query";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import type { AxiosError } from "axios";

import { useApiContext } from "../api/context";
import { isProduction } from "../constants";
import { consoleErrorWithSentry } from "../utils";
import { useAuthContext } from "./AuthContext";
import { useCustomerContext } from "./CustomerContext";
import type { AlgoliaIndexType } from "../Components/AlgoliaSearch/types";

const useAlgoliaDoerConfig = (enabled = true) => {
  const { currentUser, isDoitEmployee } = useAuthContext();

  const doerAlgoliaConfigDoc = useMemo(() => {
    if (!enabled || !currentUser || !isDoitEmployee) {
      return;
    }

    return getCollection(AppModel).doc("algolia");
  }, [enabled, currentUser, isDoitEmployee]);

  const [doerAlgoliaConfig] = useDocumentData(doerAlgoliaConfigDoc, { caching: true });

  return doerAlgoliaConfig;
};

const hooksParamsFromConfig = (config: AlgoliaConfig | undefined) => {
  if (config) {
    try {
      if (!isProduction && config.devAppId && config.devSearchKey) {
        return {
          searchClient: algoliasearch(config.devAppId, config.devSearchKey),
          restrictedIndices: config.restrictedIndices as AlgoliaIndexType[],
        };
      }

      return {
        searchClient: algoliasearch(config.appId, config.searchKey),
        restrictedIndices: config.restrictedIndices as AlgoliaIndexType[],
      } as const;
    } catch (e) {
      consoleErrorWithSentry(e);
    }
  }

  return {};
};

const useAlgoliaDoer = (enabled = true) => {
  const doerAlgoliaConfig = useAlgoliaDoerConfig(enabled);

  return useMemo(() => hooksParamsFromConfig(doerAlgoliaConfig), [doerAlgoliaConfig]);
};

const useAlgoliaDoerOrUser = (enabled = true) => {
  const api = useApiContext();
  const { customer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const doerAlgoliaConfig = useAlgoliaDoerConfig(enabled);

  const { data: userAlgoliaConfig } = useQuery<AlgoliaConfig, AxiosError>(
    [customer?.id ?? null, "algoliaConfig"],
    async () => {
      const response = await api.get<AlgoliaConfig>("/v1/algolia/config");

      return response.data;
    },
    // The key is valid for 24h
    { staleTime: 1000 * 60 * 60 * 23, enabled: Boolean(customer?.id) && !isDoitEmployee && enabled }
  );

  return useMemo(
    () => hooksParamsFromConfig(doerAlgoliaConfig || userAlgoliaConfig),
    [doerAlgoliaConfig, userAlgoliaConfig]
  );
};

export const AlgoliaHooks = {
  useAlgoliaDoer,
  useAlgoliaDoerOrUser,
};
