import { Container } from "@mui/material";

import CloudReportCard from "../../../Components/Dashboard/Analytics/CloudReportCard";
import { computeReportElementId } from "../AWS/consts";
import { COVERAGE_REPORT_ID } from "../consts";
import { useDelay } from "../utils";

type Props = {
  customerId: string;
};

export default function ComputeReport({ customerId }: Props) {
  const shouldRender = useDelay(1000);

  if (!shouldRender) {
    return null;
  }

  return (
    <Container sx={{ pt: 5 }} data-cy={computeReportElementId} id={computeReportElementId}>
      <CloudReportCard widgetId={`${customerId}_${COVERAGE_REPORT_ID}`} enableWidgetLegend />
    </Container>
  );
}
