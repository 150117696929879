import React, { useMemo } from "react";

import { type ContractModel } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import DataCouplet from "../../../Components/DataCouplet";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { getCurrencySymbol } from "../../../utils/common";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";
import { paymentTermOptions, pointOfSaleMap } from "../ContractForm/const";
import { contractDate } from "../utils";

type Props = {
  contract: ContractModel;
  accountManagerName?: string;
};

const ContractPageDci = ({ contract, accountManagerName }: Props) => {
  const { entities, entitiesLoading } = useCustomerContext();
  const { allTiers, loading: tiersLoading } = useTier();

  const entity = useMemo<Entity | undefined>(
    () => entities.find((ent) => ent.id === contract.entity?.id),
    [contract.entity?.id, entities]
  );

  const tier = useMemo(() => allTiers?.find((t) => t.ref.id === contract.tier?.id), [contract.tier, allTiers]);

  return (
    <Stack spacing={4}>
      <Typography variant="h1">DoiT Cloud Intelligence™</Typography>

      <Stack spacing={2}>
        <Typography variant="h4">General</Typography>
        <DataCouplet
          key={`point-of-sale`}
          field="Point of sale"
          value={pointOfSaleMap.find((x) => x.value === contract.pointOfSale)?.label ?? ""}
        />
        <DataCouplet
          key={`billing-profile`}
          field="Billing profile"
          value={entity ? `${entity.priorityId} - ${entity.name}` : NotAvailable}
          loading={entitiesLoading}
        />
        <DataCouplet key="account-manager" field="Account manager" value={accountManagerName ?? NotAvailable} />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h4">Contract details</Typography>
        <DataCouplet
          field="Subscription start date"
          value={contractDate(contract.startDate)}
          key="contract-start-date"
        />
        <DataCouplet field="Subscription end date" value={contractDate(contract.endDate)} key="contract-end-date" />
        <DataCouplet
          field="Tier"
          value={tier?.displayName ?? NotAvailable}
          key="commitment-months"
          loading={tiersLoading}
        />
        <DataCouplet
          field="Subscription term"
          value={contract.commitmentMonths ? `${contract.commitmentMonths} months` : NotAvailable}
          key="commitment-months"
        />
        <DataCouplet field="Subscription type" value={contract.isCommitment ? "Commitment" : "On-demand"} />
        <DataCouplet
          field="Payment term"
          value={paymentTermOptions.find((x) => x.value === contract.paymentTerm)?.label ?? NotAvailable}
        />
        <DataCouplet
          field="Minimum service fee"
          value={
            contract.minimumServiceFee
              ? `${contract.currency && getCurrencySymbol(contract.currency)}${contract.minimumServiceFee}`
              : NotAvailable
          }
        />
        <DataCouplet
          field="Monthly cloud spend percentage"
          value={contract.cloudSpendPercentage ? `${contract.cloudSpendPercentage}%` : NotAvailable}
        />
      </Stack>
    </Stack>
  );
};

export default ContractPageDci;
