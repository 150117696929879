import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type UserModel } from "./User";

@collection("insights")
export class InsightModel {
  subCollections?: {
    insightsStatusChanges: InsightStatusChangeModel;
  };
}

@subCollection("insightsStatusChanges")
export class InsightStatusChangeModel {
  insightRef!: Reference<InsightModel>;

  customerId!: string;

  statusChanges!: InsightStatusChangesModel[];
}

export type InsightUserStatus = "actionable" | "optimized" | "dismissed";

export class InsightStatusChangesModel {
  status!: InsightUserStatus;

  timestamp!: Timestamp;

  userRef!: Reference<UserModel>;

  dismissalDetails?: DismissalDetails;
}

export class DismissalDetails {
  reasons!: string[];

  comment?: string;
}
