import { type SetStateAction, useCallback, useEffect, useState } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Customer } from "../types";
import { consoleErrorWithSentry } from "../utils";
import { AlgoliaHooks } from "./algoliaContext";
import { useAuthContext } from "./AuthContext";

type NonEmptyString = string & { __brand: "NonEmptyString" };

// Set customer context on path change
export const CustomerSubscribe = ({
  setCustomer,
}: {
  setCustomer: (customer: SetStateAction<Customer | null | undefined>) => void;
}) => {
  const routeMatch = useRouteMatch<{ customerId: string }>("/customers/:customerId");
  const history = useHistory();
  const { customerId: userCustomerId, currentUser, isDoitEmployee, isDoitPartner } = useAuthContext();
  const [searchCustomerId, setSearchCustomerId] = useState<NonEmptyString | undefined>();
  const algoliaContext = AlgoliaHooks.useAlgoliaDoer(!!searchCustomerId);

  useEffect(() => {
    if (!searchCustomerId) {
      return;
    }

    if (!algoliaContext.searchClient) {
      return;
    }

    setSearchCustomerId(undefined);

    algoliaContext.searchClient
      .searchForHits({
        requests: [{ indexName: "customers", query: searchCustomerId, hitsPerPage: 1 }],
      })
      .then((contents) => {
        const [content] = contents.results;
        if (content.hits.length > 0) {
          history.replace(`/customers/${content.hits[0].objectID}`);
        } else {
          setCustomer(null);
        }
      })
      .catch(() => {
        setCustomer(null);
      });
  }, [algoliaContext.searchClient, history, searchCustomerId, setCustomer]);

  const executeSetCustomer = useCallback(
    (customerId: NonEmptyString | undefined) => {
      if (!customerId) {
        setCustomer(undefined);
        return;
      }

      if (!isDoitEmployee && !isDoitPartner && customerId !== userCustomerId) {
        setCustomer(null);
        return;
      }

      const customerRef = getCollection(CustomerModel).doc(customerId);
      return customerRef.onSnapshot(
        (docSnapshot) => {
          const data = docSnapshot.asModelData();
          if (data) {
            setCustomer((prevState) => ({
              ...data,
              id: docSnapshot.id,
              ref: prevState?.id === docSnapshot.id ? prevState.ref : docSnapshot.modelRef,
            }));
          } else if (isDoitEmployee) {
            getCollection(CustomerModel)
              .where("domains", "array-contains", customerId)
              .limit(1)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                  history.replace(`/customers/${querySnapshot.docs[0].id}`);
                } else {
                  // fallback to search by domain name
                  setSearchCustomerId(customerId);
                }
              })
              .catch(() => {
                setCustomer(null);
              });
          } else {
            setCustomer(null);
          }
        },
        (error) => {
          consoleErrorWithSentry(error);
        }
      );
    },
    [isDoitEmployee, isDoitPartner, userCustomerId, setCustomer, history]
  );

  useEffect(() => {
    const setter = (id: NonEmptyString | undefined) => executeSetCustomer(id);

    setter(undefined);

    if (routeMatch?.params.customerId) {
      // don't request to update the customer until the currentUser (auth) state has been set
      if (currentUser?.uid) {
        return setter(routeMatch.params.customerId as NonEmptyString);
      }
    } else {
      return setter(undefined);
    }
  }, [routeMatch?.params.customerId, currentUser?.uid, executeSetCustomer]);

  return null;
};
