import EditIcon from "@mui/icons-material/Edit";
import { Box, Typography } from "@mui/material";
import type { CustomerFeedback } from "@doitintl/cmp-models";

import SimpleDialog from "../../../Components/SimpleDialog";
import { anomalyFeedbackReasonsLabels } from "./consts";

type Props = {
  customerFeedback: CustomerFeedback;
  onConfirm: () => void;
  onCancel: () => void;
};

const EditAcknowledgementDialog = ({ customerFeedback, onConfirm, onCancel }: Props) => {
  const { isAnomaly, reason, updatedBy, additionalComments, updatedAt } = customerFeedback;

  return (
    <SimpleDialog
      open
      title={`${isAnomaly ? "Anomalous" : "Not anomalous"}, and resolution is '${anomalyFeedbackReasonsLabels[reason]}'`}
      titleCy="edit-acknowledgement-dialog"
      confirmButtonText="Edit"
      confirmButtonIcon={<EditIcon fontSize="small" sx={{ ml: 1 }} />}
      confirmButtonCy="open-acknowledgement-form"
      onConfirm={onConfirm}
      onCancel={onCancel}
      dialogProps={{ maxWidth: "xs" }}
    >
      <Box px={3} py={2}>
        <Box>
          <Typography variant="body2" display="inline" fontWeight={500}>
            Acknowledged by:&nbsp;
          </Typography>
          <Typography variant="body2" display="inline" color="text.secondary">
            {updatedBy}
          </Typography>
        </Box>

        <Box mb={1.5}>
          <Typography variant="body2" display="inline" fontWeight={500}>
            Date:&nbsp;
          </Typography>
          <Typography variant="body2" display="inline" color="text.secondary">
            {updatedAt?.toDate().toLocaleString("en-GB", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            })}
          </Typography>
        </Box>

        <Typography variant="body2" fontWeight={500} mb={0.5}>
          Comments:
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {additionalComments || "none"}
        </Typography>
      </Box>
    </SimpleDialog>
  );
};

export default EditAcknowledgementDialog;
