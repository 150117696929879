import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

export type SimulationRecommenderExplorerDoc = {
  day: {
    bar: number[];
    line: number[];
    xAxis: string[];
  };

  hour: {
    bar: number[];
    line: number[];
    xAxis: string[];
  };
};

export enum BQLensMeasurements {
  "scanPrice" = "scanPrice",
  "scanTB" = "scanTB",
  "storagePrice" = "storagePrice",
  "storageTB" = "storageTB",
  "slots" = "slots",
}

export type BQLensMeasurement = `${BQLensMeasurements}`;

export enum BQLensTimeFrames {
  "pastOneDay" = "past-1-day",
  "pastSevenDays" = "past-7-days",
  "pastThirtyDays" = "past-30-days",
}

export type BQLensTimeFrame = `${BQLensTimeFrames}`;

export enum BQLensMetrics {
  "billingProject" = "billingProject",
  "project" = "project",
  "dataset" = "dataset",
  "table" = "table",
  "user" = "user",
}

export enum BQLensBillingModes {
  onDemand = "on-demand",
  standardEdition = "standard-edition",
  enterpriseEdition = "enterprise-edition",
  enterprisePlusEdition = "enterprise-plus-edition",
}

export const editionModes: BQLensBillingMode[] = [
  BQLensBillingModes.standardEdition,
  BQLensBillingModes.enterpriseEdition,
  BQLensBillingModes.enterprisePlusEdition,
];

export type BQLensBillingMode = `${BQLensBillingModes}`;

export enum BQLensOptimizerModes {
  onDemand = "on-demand",
  output = "output",
  enterpriseEdition = "enterprise-edition",
  enterprisePlusEdition = "enterprise-plus-edition",
  standardEdition = "standard-edition",
}

export type BQLensMetric = `${BQLensMetrics}`;
class SimulationRecommenderExplorer {
  docs?: {
    [key in BQLensTimeFrame]: SimulationRecommenderExplorerDoc;
  };
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class SimulationRecommenderRollUpsUserSlotsDoc {}

class SimulationRecommenderRollUpsUser {
  docs?: {
    [key in BQLensMeasurement]: SimulationRecommenderRollUpsUserSlotsDoc;
  };
}

class SimulationRecommenderRollUpsDoc {
  subCollections?: {
    [key in BQLensMetric]: SimulationRecommenderRollUpsUser;
  };
}

class SimulationRecommenderRollUps {
  docs?: {
    [key in BQLensTimeFrame]: SimulationRecommenderRollUpsDoc;
  };
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class SimulationRecommenderRecommendationsDoc {}

class SimulationRecommenderRecommendations {
  docs?: {
    [key in BQLensTimeFrame]: SimulationRecommenderRecommendationsDoc;
  };
}

class SimulationRecommender {
  subCollections?: {
    explorer: SimulationRecommenderExplorer;
    rollUps: SimulationRecommenderRollUps;
    recommendations: SimulationRecommenderRecommendations;
  };
}

class SimulationRecommenderDoc {
  subCollections?: {
    "flat-rate": SimulationRecommender;
    "on-demand": SimulationRecommender;
    "standard-edition": SimulationRecommender;
    "enterprise-edition": SimulationRecommender;
    "enterprise-plus-edition": SimulationRecommender;
    output: SimulationRecommender;
  };
}

class SimulationOptimisationDoc {
  subCollections?: {
    output: OutputModel;
  };
}

class CmpDisplayMessagesDoc {
  NO_BQ_USAGE!: string;
}

class JobsSinksMetadataModel {
  backfillProgress!: number;

  backfillDone?: boolean;
}

class JobsSinksDoc {
  subCollections?: {
    jobsSinksMetadata: JobsSinksMetadataModel;
  };
}

type DisplayMessagesDoc = {
  clusterTables: string;
  limitingJobsSavings: string;
  partitionTables: string;
  purchaseSlots: string;
  scheduledQueriesMovement: string;
  storageSavings: string;
  usePartitionField: string;
};

@collection("superQuery")
export class SuperQueryModel {
  docs?: {
    "simulation-recommender": SimulationRecommenderDoc;
    "simulation-optimisation": SimulationOptimisationDoc;
    cmpDisplayMessages: CmpDisplayMessagesDoc;
    "jobs-sinks": JobsSinksDoc;
    "display-messages": DisplayMessagesDoc;
  };
}

type StorageSavings = {
  cost: number;
  datasetId: string;
  lastAccess: string;
  projectId: string;
  storageSizeTB: number;
  tableCreateDate: Timestamp;
  tableId: string;
};

type FieldMapping = {
  isPartition: boolean;
  order: number;
  sign: string;
  title: string;
  visible: boolean;
};

type OutputModelRecommendationsDoc = {
  storageSavings: {
    detailedTable: StorageSavings &
      {
        partitionsAvailable: StorageSavings &
          {
            tableIdBaseName: string;
          }[];
      }[];
    detailedTableFieldsMapping: Record<keyof StorageSavings & "tableIdBaseName", FieldMapping>[];
    recommendation: string;
    savingsPercentage: number;
    savingsPrice: number;
  };
};

class OutputModelRecommendations {
  docs?: {
    [key in BQLensTimeFrame]: OutputModelRecommendationsDoc;
  };
}

@subCollection("output")
export class OutputModel {
  progress!: number;

  lastUpdate?: Timestamp;

  displayMessage?: string;

  subCollections?: {
    recommendations: OutputModelRecommendations;
    rollUps?: SimulationRecommenderRollUps;
  };
}

@subCollection("bqLensOptimizer")
export class SpendProfile {
  isOnDemand!: boolean;

  isStandardEdition!: boolean;

  isEnterpriseEdition!: boolean;

  isEnterprisePlusEdition!: boolean;
}

@subCollection("bqLensCustomers")
export class BQLensCustomers {
  subCollections?: {
    bqLensOptimizer: SpendProfile;
  };
}

@collection("bigqueryLens")
export class BigQueryLensModel {
  subCollections?: {
    bqLensCustomers: BQLensCustomers;
  };
}
