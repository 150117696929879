import { useCallback, useEffect, useRef } from "react";

import { Stack } from "@mui/material";

import { useIsCloudflowPublished } from "../Common/hooks/useIsCloudflowPublished";
import Title from "../Common/Title";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

export const Header = () => {
  const { updateNode, nodeConfig } = useNodeConfigurationContext();
  const isPublished = useIsCloudflowPublished();
  const initialTitle = useRef<string | null>(null);
  const nodeId = useRef<string>(nodeConfig.id);

  useEffect(() => {
    if (initialTitle.current === null || nodeId.current !== nodeConfig.id) {
      initialTitle.current = nodeConfig.name;
      nodeId.current = nodeConfig.id;
    }
  }, [nodeConfig.id, nodeConfig.name]);

  const handleUpdateTitle = useCallback(
    (title: string) => {
      updateNode({ name: title });
    },
    [updateNode]
  );

  const handleBlurTitle = useCallback(() => {
    if (!nodeConfig.name?.trim()) {
      updateNode({ name: initialTitle.current || "" });
    }
  }, [nodeConfig.name, updateNode]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Title
        disabled={isPublished}
        title={nodeConfig.name}
        onUpdateTitle={handleUpdateTitle}
        onBlur={handleBlurTitle}
        placeholder="Add Node name"
      />
    </Stack>
  );
};
