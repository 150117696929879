import { Link, Stack, Typography } from "@mui/material";

import { essentialsEscalatePrice } from "../../../constants";
import { type PackageType, type ServiceType } from "../types";
import { PackageCard } from "./PackageCard";

type Props = {
  selectedPackageType: PackageType;
  setSelectedPackageType: (serviceType: PackageType) => void;
  selectedServiceType: ServiceType;
};

export const PackagesSelector = ({ selectedPackageType, setSelectedPackageType, selectedServiceType }: Props) => {
  const accelerators = (
    <Typography>
      <Link underline="none">Accelerators</Link> on demand
    </Typography>
  );

  if (selectedServiceType === "one-time") {
    return (
      <Stack direction={{ xs: "column", md: "row" }} spacing={1.5}>
        <PackageCard
          packageType="single-ticket"
          amount={`$${essentialsEscalatePrice}`}
          active={selectedPackageType === "single-ticket"}
          serviceList={["Core services support for one support ticket"]}
          note="Note: Enhanced subscription - SLOs"
          onClick={() => {
            setSelectedPackageType("single-ticket");
          }}
          selectedServiceType={selectedServiceType}
        />
        <PackageCard
          packageType="accelerator"
          amount="$10,000"
          active={selectedPackageType === "accelerator"}
          serviceList={["Enhanced support", accelerators, "Cloud optimization", "Cloud native training"]}
          note="Note: Enhanced subscription - SLOs"
          onClick={() => {
            setSelectedPackageType("accelerator");
          }}
          selectedServiceType={selectedServiceType}
        />
      </Stack>
    );
  }

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1.5}>
      <PackageCard
        packageType="standard"
        subtitle="Ideal for digital native Startups"
        amount="$200"
        active={selectedPackageType === "standard"}
        serviceList={["Enhanced support", accelerators]}
        note="Note: Standard subscription - SLOs"
        onClick={() => {
          setSelectedPackageType("standard");
        }}
        selectedServiceType={selectedServiceType}
      />
      <PackageCard
        packageType="enhanced"
        subtitle="Ideal for scaling digital natives"
        amount="$1,000"
        plus="1%"
        active={selectedPackageType === "enhanced"}
        serviceList={["Enhanced support", accelerators, "Cloud optimization", "Cloud native training"]}
        note="Note: Enhanced subscription - SLOs"
        onClick={() => {
          setSelectedPackageType("enhanced");
        }}
        selectedServiceType={selectedServiceType}
      />
      <PackageCard
        packageType="premium"
        subtitle="Ideal for established digital natives"
        amount="$5,000"
        plus="1%"
        active={selectedPackageType === "premium"}
        serviceList={[
          "Premium support",
          accelerators,
          "Cloud optimization",
          "Cloud native training",
          "Named resources",
        ]}
        note="Note: Premium subscription - SLAs"
        onClick={() => {
          setSelectedPackageType("premium");
        }}
        selectedServiceType={selectedServiceType}
      />
    </Stack>
  );
};
