import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { useDocument } from "@doitintl/models-firestore/src/react-firebase-hooks/firestore/useDocument";
import { Alert, AlertTitle, Typography } from "@mui/material";

export const DisabledContractEditingBanner = () => {
  const [appContracts] = useDocument(getCollection(AppModel).doc("contracts"));

  const appContractData = appContracts?.asModelData();

  return (
    <Alert severity="warning">
      <AlertTitle>Maintenance</AlertTitle>
      <Typography>
        Contract editing and creation will be temporarily unavailable
        {appContractData?.disabledEditingDate &&
          ` on ${appContractData?.disabledEditingDate.toDate().toLocaleDateString()}`}
        . Thank you for your patience.
      </Typography>
    </Alert>
  );
};
