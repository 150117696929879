import { type FC, useEffect, useState } from "react";

import { getModelByPath } from "@doitintl/cloudflow-commons";
import { ModelType, NodeTransformationType } from "@doitintl/cmp-models";
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { ReferencedFieldStandalone } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useCheckboxCommonProps, useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { useTransformationNodeSchemaContext } from "./TransformationNodeForm";

const newFieldNameLabel = "New field name";
const regexPatternLabel = "Regex pattern";

const CheckboxLabel: FC<{ label: string; caption: string }> = ({ label, caption }) => (
  <>
    <Typography variant="body2">{label}</Typography>
    <Typography variant="caption" color="text.secondary" mb={1}>
      {caption}
    </Typography>
  </>
);

function generateExtractActionSchema(referenceableNodes: NodeWitOutputModel[]) {
  return yup.object({
    type: yup.string().default(NodeTransformationType.EXTRACT),
    newFieldName: yup.string().default("").required().label(newFieldNameLabel).trim(),
    payload: yup
      .object({
        referencedNodeId: yup.string().default(""),
        referencedField: yup.array().of(yup.string().required()).default([]),
      })
      .test("referenced-field-model", "Referenced field must reference a string field", (value) => {
        const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
        if (referencedNodeModel === undefined) {
          return false;
        }
        const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);
        return referencedModel.type === ModelType.STRING;
      }),
    regexp: yup.string().required().default("").label(regexPatternLabel).trim(),
    global: yup.boolean().default(false),
    multiline: yup.boolean().default(false),
    ignoreCase: yup.boolean().default(true),
  });
}

export const ExtractTransformationForm = () => {
  const { setTransformationSchema, referenceableNodes } = useTransformationNodeSchemaContext();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const { value: rootReferencedNodeValue } = getFieldProps("referencedNodeField");

  const [validationSchema, setValidationSchema] = useState<yup.AnyObjectSchema>(
    generateExtractActionSchema(referenceableNodes)
  );

  const [dataSourceNodes, setDataSourceNodes] = useState<NodeWitOutputModel[]>(
    referenceableNodes.filter(({ id }) => rootReferencedNodeValue.referencedNodeId === id)
  );

  const { value: transformationFieldValue } = getFieldProps("transformation");

  const newFieldNameCommonProps = useFieldCommonProps(
    getFieldProps("transformation.newFieldName"),
    newFieldNameLabel,
    true
  );
  const payloadCommonProps = useFieldCommonProps(getFieldProps("transformation.payload"), "Referenced value", true);
  const regexpCommonProps = useFieldCommonProps(getFieldProps("transformation.regexp"), regexPatternLabel, true);
  const globalCommonProps = useCheckboxCommonProps(getFieldProps("transformation.global"), "Global");
  const multilineCommonProps = useCheckboxCommonProps(getFieldProps("transformation.multiline"), "Multi line");
  const insensitiveCommonProps = useCheckboxCommonProps(getFieldProps("transformation.ignoreCase"), "Insensitive");

  useEffect(() => {
    setTransformationSchema(validationSchema);
  }, [setTransformationSchema, validationSchema]);

  useEffect(() => {
    setValidationSchema(generateExtractActionSchema(referenceableNodes));
  }, [referenceableNodes]);

  useEffect(() => {
    if (!transformationFieldValue) {
      setFieldValue("transformation", validationSchema.getDefault());
      setFieldValue("transformation.payload", rootReferencedNodeValue, true);
    }
  }, [setFieldValue, validationSchema, transformationFieldValue, rootReferencedNodeValue]);

  useEffect(() => {
    setDataSourceNodes(referenceableNodes.filter(({ id }) => rootReferencedNodeValue.referencedNodeId === id));
  }, [referenceableNodes, rootReferencedNodeValue.referencedNodeId]);

  const isReferencedFieldDisabled = dataSourceNodes.length === 0;

  return (
    <Stack spacing={2} pb={4}>
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader
        title="How to use extract"
        subtitle="Using regex format accepted by the re2 library, extract part of a referenced value"
      />
      <ReferencedFieldStandalone
        {...payloadCommonProps}
        referenceableNodes={dataSourceNodes}
        disabled={dataSourceNodes.length === 0}
        tooltip={isReferencedFieldDisabled ? cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE : ""}
        rootReferencedNodeValue={rootReferencedNodeValue}
      />
      <TextField fullWidth variant="outlined" size="small" {...regexpCommonProps} />
      <Typography variant="subtitle2">Regex flags</Typography>
      <FormGroup sx={{ gap: 1 }}>
        <FormControlLabel
          control={<Checkbox {...globalCommonProps} />}
          label={<CheckboxLabel label={globalCommonProps.label} caption="Don't return after the first match" />}
        />
        <FormControlLabel
          control={<Checkbox {...multilineCommonProps} />}
          label={<CheckboxLabel label={multilineCommonProps.label} caption="^ and $ match start/end of line" />}
        />
        <FormControlLabel
          control={<Checkbox {...insensitiveCommonProps} />}
          label={<CheckboxLabel label={insensitiveCommonProps.label} caption="Case insensitive match" />}
        />
      </FormGroup>
    </Stack>
  );
};
