import { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, ButtonBase, InputAdornment, TextField } from "@mui/material";
import { alpha } from "@mui/material/styles";

import { AlgoliaHooks } from "../../Context/algoliaContext";
import { useHotkeyContext } from "../../Context/HotkeyContext";
import { isMac } from "../../utils/common";
import { AlgoliaSearchModal } from "./AlgoliaSearchModal";
import { type AlgoliaIndexType } from "./types";

type Props = {
  searchWidth?: number;
  restrictedIndices?: AlgoliaIndexType[];
};

export const AlgoliaAutocomplete = ({ searchWidth, restrictedIndices }: Props) => {
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const algoliaContext = AlgoliaHooks.useAlgoliaDoerOrUser(searchModalVisible);

  const searchClient = algoliaContext.searchClient;
  const allRestrictedIndices = [...(algoliaContext.restrictedIndices ?? []), ...(restrictedIndices ?? [])];

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key, metaKey, ctrlKey } = event;

      if (ctrlKey && key === "k" && !isMac()) {
        setSearchModalVisible(true);
        event.preventDefault();
      }
      if (metaKey && key === "k" && isMac()) {
        setSearchModalVisible(true);
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (searchModalVisible) {
      setHotKeysDisabled();
    } else {
      setHotKeysEnabled();
    }
  }, [searchModalVisible, setHotKeysDisabled, setHotKeysEnabled]);

  return (
    <Box>
      <ButtonBase>
        <TextField
          onClick={() => {
            setSearchModalVisible(true);
          }}
          sx={(theme) => ({
            caretColor: "transparent",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
            borderRadius: 0.5,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            "&:hover": {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            input: {
              WebkitTextFillColor: theme.palette.common.white,
              cursor: "pointer",
              width: searchWidth,
              padding: theme.spacing(0.75, 0, 0.75, 1),
            },
          })}
          placeholder={`Search ${isMac() ? "⌘" : "Ctrl+"}K`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "white" }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </ButtonBase>
      {searchModalVisible && searchClient && (
        <AlgoliaSearchModal
          searchClient={searchClient}
          restrictedIndices={allRestrictedIndices}
          setSearchModalVisible={setSearchModalVisible}
        />
      )}
    </Box>
  );
};
