import { useEffect, useMemo } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { Loader } from "../../../../Components/Loader";
import { filters, headerColumns } from "./columns";
import { useJobs as _useJobs } from "./hooks";
import IssueForm from "./IssueForm";
import JobListRow from "./JobListRow";
import { type JobTableRow } from "./types";
import { formatJob } from "./utils";

let timeOut;
const numMs = 5 * 60 * 1000;

const BillingTools = ({ useJobs = _useJobs }) => {
  const { jobs, loading, loadJobs } = useJobs();

  useEffect(() => {
    const startTimeout = () => {
      clearTimeout(timeOut);
      loadJobs();
      timeOut = setTimeout(() => {
        startTimeout();
      }, numMs);
    };

    startTimeout();

    return () => {
      clearTimeout(timeOut);
    };
  }, [loadJobs]);

  const formattedJobs = useMemo(() => jobs.map(formatJob), [jobs]);

  return (
    <>
      <Box width="350px">
        <Stack direction="row" mt={2} gap={1} justifyContent="space-between">
          <Typography variant="h1" data-cy="page-title">
            Billing Tools
            <Tooltip title="Read more" arrow>
              <IconButton
                href="https://doitintl.atlassian.net/wiki/spaces/ENG/pages/593526793/Recalculation+Invoicing+Tools"
                target="_blank"
              >
                <OpenInNewIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>

          <Button onClick={() => loadJobs()} disabled={loading} color="primary" startIcon={<RefreshIcon />}>
            <Typography fontWeight={500}>Refresh</Typography>
          </Button>
        </Stack>
        <IssueForm loadJobs={loadJobs} />
      </Box>

      <Loader loading={loading}>
        <FilterTable<JobTableRow>
          showRowsSelection={false}
          tableItems={formattedJobs}
          wrapRowComponent={true}
          headerColumns={headerColumns}
          rowComponent={JobListRow}
          filterColumns={filters}
        />
      </Loader>
    </>
  );
};

export default BillingTools;
