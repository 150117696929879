import { createContext, type ReactNode, useContext, useState } from "react";

import noop from "lodash/noop";

export enum AvaOpeningSource {
  NAV = "ava.console.icon",
  SUPPORT = "ava.support.button",
  DASHBOARDS = "ava.dashboards.button",
  PLATFORM_DROPDOWN = "ava.support.platform_dropdown",
  URL = "ava.url",
}
type MixpanelCallback = (event: string) => void;

type AvaDialogContextType = {
  isAvaDialogOpen: boolean;
  handleOpenAvaDialog: (source: AvaOpeningSource, mixpanelCallback?: MixpanelCallback, dashboardId?: string) => void;
  handleCloseAvaDialog: (withoutConversation: boolean, mixpanelCallback?: MixpanelCallback) => void;
  dashboardId: string;
};

type Props = {
  children: ReactNode;
};

export const AvaDialogContext = createContext<AvaDialogContextType>({
  isAvaDialogOpen: false,
  handleOpenAvaDialog: noop,
  handleCloseAvaDialog: noop,
  dashboardId: "",
});

export const AvaDialogContextProvider = ({ children }: Props) => {
  const [isAvaDialogOpen, setIsAvaDialogOpen] = useState(false);
  const [dashboardId, setDashboardId] = useState("");

  const handleOpenAvaDialog = (source: AvaOpeningSource, mixpanelCallback?: MixpanelCallback, dashboardId?: string) => {
    if (mixpanelCallback) {
      mixpanelCallback(source);
    }
    setIsAvaDialogOpen(true);
    setDashboardId(dashboardId ?? "");
  };

  const handleCloseAvaDialog = (withoutConversation: boolean, mixpanelCallback?: MixpanelCallback) => {
    if (withoutConversation) {
      if (mixpanelCallback) {
        mixpanelCallback("ava.console.abort");
      }
    }
    setIsAvaDialogOpen(false);
  };

  return (
    <AvaDialogContext.Provider value={{ isAvaDialogOpen, handleOpenAvaDialog, handleCloseAvaDialog, dashboardId }}>
      {children}
    </AvaDialogContext.Provider>
  );
};

export const useAvaDialogContext = () => useContext(AvaDialogContext);
