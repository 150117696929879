import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { type EntitlementKey } from "@doitintl/cmp-models";

import { isProduction } from "../constants";
import { type Severity, SeverityLevels } from "./types";

export const ticketsCsvFileName = "tickets.csv";

export const ticketFieldIds: Record<string, number> = {
  category: isProduction ? 360000101266 : 5977030357661,
  platformInfo: isProduction ? 360000113046 : 0, // field does not exist in sandbox
  product: isProduction ? 4560816337821 : 5460341562141,
  platform: isProduction ? 360000148226 : 360021974057,
};

export const usePriorities: () => Severity[] = () => {
  const { t } = useTranslation("services");
  return useMemo(
    () => [
      {
        value: SeverityLevels.low,
        title: t("SeverityLevels.low.title"),
        responseTime: "24 hours",
        description: t("SeverityLevels.low.description"),
      },
      {
        value: SeverityLevels.normal,
        title: t("SeverityLevels.normal.title"),
        responseTime: "12 hours",
        description: [
          t("SeverityLevels.normal.description1"),
          t("SeverityLevels.normal.description2"),
          t("SeverityLevels.normal.description3"),
        ],
        color: "#F7D859",
      },
      {
        value: SeverityLevels.high,
        title: t("SeverityLevels.high.title"),
        responseTime: "4 hours",
        description: [
          t("SeverityLevels.high.description1"),
          t("SeverityLevels.high.description2"),
          t("SeverityLevels.high.description3"),
          t("SeverityLevels.high.description4"),
        ],
        color: "#DF732C",
      },
      {
        value: SeverityLevels.urgent,
        title: t("SeverityLevels.urgent.title"),
        responseTime: "1 hour",
        description: [
          t("SeverityLevels.urgent.description1"),
          t("SeverityLevels.urgent.description2"),
          t("SeverityLevels.urgent.description3"),
          t("SeverityLevels.urgent.description4"),
        ],
        color: "#D32F2F",
      },
    ],
    [t]
  );
};

export const saasConsoleSupportTicketTexts = {
  DoiTConsole: "DoiT Console",
  CMP: "CMP",
  CloudManagementPlatform: "Cloud Management Platform",
};

export const platformsData = [
  {
    value: "amazon_web_services",
    title: "Amazon Web Services",
  },
  {
    value: "google_cloud_platform",
    title: "Google cloud platform",
  },
  {
    value: "cloud_management_platform",
    title: "DoiT",
  },
  {
    value: "google_g_suite",
    title: "Google workspace",
  },
  {
    value: "microsoft_azure",
    title: "Microsoft azure",
  },
  {
    value: "finance___billing",
    title: "Finance billing",
  },
  {
    value: "credits___request",
    title: "Credit request",
  },
  {
    value: "microsoft_office_365",
    title: "Microsoft office 365",
  },
];

export const entitlementsToContactSupport: EntitlementKey[] = [
  "support:ticket:menu:platform:all",
  "support:ticket:menu:product:all",
  "support:ticket:menu:platform:g-suite",
  "support:ticket:menu:platform:office-365",
  "support:ticket:menu:platform:amazon-web-services",
  "support:ticket:menu:platform:google-cloud",
  "support:ticket:menu:platform:microsoft-azure",
  "support:ticket:menu:product:aws/amazon-ec2",
  "support:ticket:menu:product:aws/amazon-elastic-block-store-ebs",
  "support:ticket:menu:product:aws/amazon-s3",
  "support:ticket:menu:product:aws/elastic-load-balancing-elb",
  "support:ticket:menu:product:gcp/cloud-load-balancing",
  "support:ticket:menu:product:gcp/cloud-storage",
  "support:ticket:menu:product:gcp/compute-engine",
  "support:ticket:menu:product:gcp/persistent-disk",
  "support:ticket:menu:product:azure/virtual-machines",
  "support:ticket:menu:product:azure/azure-load-balancing",
  "support:ticket:menu:product:azure/storage-blobs",
  "support:ticket:menu:product:azure/storage-disks",
  "analytics:calculatedMetrics",
  "lens:bigquery",
  "lens:eks",
  "lens:gke",
  "savings:spotScaling",
  "services:pro-serv",
  "services:training",
  "snowflake:connect",
];

export const mapEntitlementsToSupportMenu = {
  "support:ticket:menu:product:cmp/custom-metrics": "analytics:calculatedMetrics",
  "support:ticket:menu:product:cmp/bigquery-lens": "lens:bigquery",
  "support:ticket:menu:product:cmp/eks-lens": "lens:eks",
  "support:ticket:menu:product:cmp/gke-lens": "lens:gke",
  "support:ticket:menu:product:cmp/spot-scaling": "savings:spotScaling",
  "support:ticket:menu:product:cmp/professional-services": "services:pro-serv",
  "support:ticket:menu:product:cmp/training": "services:training",
  "support:ticket:menu:product:cmp/snowflake-integration": "snowflake:connect",
};

export const productAlertList = [
  "amazon_web_services",
  "google_cloud_platform",
  "cloud_management_platform",
  "microsoft_azure",
];
