import { type FC } from "react";

import { Box, Typography } from "@mui/material";

export const FieldSectionHeader: FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => (
  <Box>
    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {subtitle}
    </Typography>
  </Box>
);
