import { Link } from "react-router-dom";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { dateFormatDayMonthYear } from "../../../utils/dateTimeFormats";
import { useThreads } from "../../Threads/api";
import { useInsightsContext } from "../context";
import { type Insight } from "../types";
import { isIrrelevantOrDismissed, isOptimized } from "../utils";
import { CloudProvidersIcons } from "./CloudProvidersIcons";
import { InsightSourceLabel } from "./InsightSourceLabel";
import { PotentialSavingsPreview } from "./PotentialSavingsPreview";

export const Preview = ({ insight, isListView }: { insight: Insight; isListView: boolean }) => {
  const { savingsPeriod } = useInsightsContext();
  const { customer: originalCustomer } = useCustomerContext();
  const { threads } = useThreads();
  const theme = useTheme();
  const tagsToShow = insight.categories?.length ? insight.categories : insight.otherTags;

  const updatePublishLabel =
    insight.providerId === "custom" ? insight.customInsightAttributes?.published : insight.lastUpdated;

  const component = isListView && !isIrrelevantOrDismissed(insight) && !isOptimized(insight) && (
    <PotentialSavingsPreview insight={insight} savingsPeriod={savingsPeriod} />
  );

  let badgeComponent: React.ReactNode = null;
  if (isListView) {
    if (insight.status === "error") {
      badgeComponent = (
        <Chip
          label="Something went wrong"
          variant="filled"
          size="small"
          color="error"
          sx={{ fontSize: 12, fontWeight: 400, height: "20px" }}
        />
      );
    } else if (isIrrelevantOrDismissed(insight)) {
      badgeComponent = (
        <Chip
          label="Dismissed"
          variant="filled"
          size="small"
          sx={{ backgroundColor: "#E0E0E0", fontSize: 12, fontWeight: 400, height: "20px" }}
        />
      );
    } else if (isOptimized(insight)) {
      badgeComponent = (
        <Chip
          label="Optimized"
          variant="filled"
          size="small"
          color="success"
          sx={{ fontSize: 12, fontWeight: 400, height: "20px" }}
        />
      );
    }
  }

  const matchingThreads =
    threads?.filter(
      (thread) =>
        thread.source === "insight" &&
        thread.sourceData.insightKey === insight.key &&
        thread.sourceData.providerID === insight.providerId
    ) || [];

  return (
    <Stack direction="row" justifyContent="space-between" gap="48px">
      <Box>
        {!isListView && (
          <Typography mb={2} mt="6px" color="text.secondary" variant="body2">
            Insights
          </Typography>
        )}
        <Typography
          data-cy="insight-title"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          variant={isListView ? "h3" : "h1"}
        >
          {insight.title}
          {insight.isInternal ? (
            <Chip
              label="DoiT internal"
              color="primary"
              size="small"
              sx={{ fontSize: 12, fontWeight: 400, height: 20 }}
            />
          ) : null}
          {badgeComponent}
        </Typography>

        <Typography sx={{ mt: isListView ? 1 : 2 }} color="text.secondary" variant="body1">
          {insight.shortDescription}
        </Typography>

        <InsightSourceLabel insight={insight} />

        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          divider={<Divider orientation="vertical" sx={{ height: "22px" }} />}
          sx={{ color: "text.secondary", mt: 2 }}
          height="28px"
        >
          <CloudProvidersIcons cloudTags={insight.cloudTags} />

          <Stack direction="row" spacing={0.75} alignItems="center">
            <Typography sx={{ fontSize: "14px" }} color="text.primary" variant="body1">
              Category:{" "}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {tagsToShow?.join(", ")}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={0.75} alignItems="center">
            <Typography sx={{ fontSize: "14px" }} color="text.primary" variant="body1">
              {insight.providerId === "custom" ? "Published:" : "Last checked:"}
            </Typography>
            <Typography variant="body2">
              {DateTime.fromJSDate(new Date(updatePublishLabel ?? "")).toFormat(dateFormatDayMonthYear)}
            </Typography>
          </Stack>

          {isListView && matchingThreads?.length > 0 ? (
            <Link
              to={`/customers/${originalCustomer.id}/threads?filter=${insight.providerId}/${insight.key}`}
              style={{
                color: theme.palette.primary.main,
                fontSize: "14px",
                textDecoration: "none",
                display: "flex",
                gap: "6px",
                alignItems: "center",
              }}
            >
              <Typography color="text.primary" variant="body1">
                Linked threads:{" "}
              </Typography>
              {matchingThreads.length}
            </Link>
          ) : null}
        </Stack>
      </Box>

      {component}
    </Stack>
  );
};
