import { type JSX } from "react";

import { DashboardType } from "@doitintl/cmp-models";

import { useCurrentDashboardContext } from "../../Context/CurrentDashboardContext";
import GkeUsageAlert from "../Alerts/GkeUsageAlert";
import BigQueryLensGuard from "./BigQueryLens/BigQueryLensGuard";
import { EKSLensGuard } from "./EKSLens/EKSLensGuard";
import GKELensGuard from "./GKELens/GKELensGuard";
import { MAPLensGuard } from "./MAPLens/MAPLensGuard";
import { SnowflakeLensGuard } from "./SnowflakeLens/SnowflakeLensGuard";

type Props = {
  children: JSX.Element;
};

export function DashboardGuard({ children }: Props) {
  const { dashboardType } = useCurrentDashboardContext();

  switch (true) {
    case dashboardType === DashboardType.EKSLens:
      return <EKSLensGuard>{children}</EKSLensGuard>;
    case dashboardType === DashboardType.SnowflakeLens:
      return <SnowflakeLensGuard>{children}</SnowflakeLensGuard>;
    case dashboardType === DashboardType.GkeLensV2:
      return <>{children && <GKELensGuard>{children}</GKELensGuard>}</>;
    case dashboardType === DashboardType.BqLens:
      return <>{children && <BigQueryLensGuard widgets={children} />}</>;
    case dashboardType === DashboardType.AWSMAPLens:
      return <MAPLensGuard>{children}</MAPLensGuard>;
    case dashboardType === DashboardType.GcpLens:
      return (
        <>
          <GkeUsageAlert />
          {children}
        </>
      );
    default:
      return <>{children}</>;
  }
}
