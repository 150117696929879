import { type Cluster, ClusterConnectionStatus } from "@doitintl/cmp-models";
import { Chip, Divider, Tooltip } from "@mui/material";
import { type ChipOwnProps } from "@mui/material/Chip";

import { getDatetimeAndDiffFromTimestamp } from "../../../utils/time";

interface ClusterStatusProps {
  cluster: Cluster;
}

const statusColor: Record<ClusterConnectionStatus, ChipOwnProps["color"]> = {
  [ClusterConnectionStatus.HEALTHY]: "success",
  [ClusterConnectionStatus.UNHEALTHY]: "error",
  [ClusterConnectionStatus.UNKNOWN]: "secondary",
};

const ClusterStatus = ({ cluster }: ClusterStatusProps) => {
  const status = cluster.connectionStatus;
  const { exactDatetime, timeAgo } = getDatetimeAndDiffFromTimestamp(cluster.connectionStatusUpdated);

  const timeSection = (
    <>
      <p>Last updated {timeAgo}</p>
      <p>({exactDatetime})</p>
    </>
  );

  const errorSection =
    Object.keys(cluster.connectionErrors || {}).length > 0 ? (
      <>
        <Divider />
        <p>Errors:</p>
        <div>
          <ul>
            {Object.entries(cluster.connectionErrors).map(([key, value]) => (
              <li key={key}>
                <strong>{key}</strong>:
                <br />
                <span>{value}</span>
              </li>
            ))}
          </ul>
        </div>
      </>
    ) : null;

  const tooltipContent = (
    <>
      {timeSection}
      {errorSection}
    </>
  );

  return (
    <Tooltip title={tooltipContent}>
      <Chip sx={{ textTransform: "capitalize", cursor: "help" }} color={statusColor[status]} label={status} />
    </Tooltip>
  );
};

export default ClusterStatus;
