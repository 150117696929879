import { useEffect, useMemo, useRef, useState } from "react";

import { EarlyAccessFeature } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import capitalize from "lodash/capitalize";

import { useFeatureFlag } from "../../../../../Components/hooks/useFeatureFlag";
import useUnmountEffect from "../../../../../Components/hooks/useUnmountEffect";
import Icon from "../../../../../Components/Icons/Icon";
import { isLocalhost } from "../../../../../constants";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import mixpanel from "../../../../../utils/mixpanel";
import { addChannelsDoc } from "../../db";
import { AWSFeatureName, type CodeSnippet } from "../../types";
import { UpdateErrorAlert } from "../Alerts/UpdateErrorAlert";
import { WaitingForUpdateAlert } from "../Alerts/WaitingForUpdateAlert";
import { CommandLineDialog } from "../Dialogs/CommandLineDialog";
import { StackCreationDialog } from "../Dialogs/StackCreationDialog";
import {
  getCLICreateCommand,
  getCLIUpdateCommand,
  getQuickCreateUrl,
  getQuickUpdateUrl,
  getRealTimeDataCommand,
} from "./cloudFormationHelper";
import { FeaturesTable } from "./FeaturesTable";
import { listenForStackCreationUpdate } from "./listeners";

type FeaturesSelectionProps = {
  roleToUpdate?: string;
  accountSupportedFeatures?: string[];
  onUpdate: () => void;
};

enum Mode {
  Selecting,
  WaitingForUpdate,
  UpdateFailed,
}

/**
 * Component to show AWS features selection, will be used for creating new account of updating existing
 * @param accountIdToUpdate - (optional) accountId to update. if missing the component will be used to add new account
 * @constructor
 */
export const FeaturesSelection = ({
  onUpdate,
  accountSupportedFeatures = [AWSFeatureName.core],
  roleToUpdate = "",
}: FeaturesSelectionProps) => {
  const { customer } = useCustomerContext();
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [mode, setMode] = useState<Mode>(Mode.Selecting);
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [stackName, setStackName] = useState<string>("");
  const [cliCommands, setCliCommands] = useState<string[]>([]);
  const [codeSnippets, setCodeSnippets] = useState<CodeSnippet[]>([]);
  const [cliDialogOpen, setCliDialogOpen] = useState<boolean>(false);
  const [stackCreationDialogOpen, setStackCreationDialogOpen] = useState<boolean>(false);
  const [actionButtonText, setActionButtonText] = useState<string>("link account");
  const [operationTitle, setOperationTitle] = useState<string>("linking");
  const isFSK8SFeatureEnabled = useFeatureFlag(EarlyAccessFeature.FSK8S);

  const [realTimeDataBucketName, setRealTimeDataBucketName] = useState<string>("");

  // will hold the unsubscribe function of snapshot that is used to detect status change in stack creation
  const unsubscribeListener = useRef<() => void>(() => {});

  const notificationConfigurationSnippet = useMemo<CodeSnippet>(() => {
    const topicArn = isLocalhost ? 913524940965 : 676206900418;

    const code = `{
      "TopicConfigurations": [
        {
          "TopicArn": "${topicArn}",
          "Events": [
            "s3:ObjectCreated:*"
          ]
        }
      ]
    }`;

    const name = "notification_configuration.json";

    return {
      code,
      name,
    };
  }, []);

  useEffect(() => {
    if (roleToUpdate) {
      setActionButtonText("update account");
      setOperationTitle("updating");
    }

    if (realTimeDataBucketName !== "") {
      setCodeSnippets([notificationConfigurationSnippet]);
    } else {
      setCodeSnippets([]);
    }
  }, [roleToUpdate, realTimeDataBucketName, notificationConfigurationSnippet]);

  useUnmountEffect(() => {
    unsubscribeListener.current();
  });

  const disableToLinkAccount = useMemo(() => {
    if (roleToUpdate) {
      return selectedFeatures.length === 0 || mode !== Mode.Selecting;
    } else {
      return false;
    }
  }, [roleToUpdate, selectedFeatures, mode]);

  // start listen for status change for specific stack
  const startListeningForChanges = (stackName: string) => {
    const onChangeDetectedCallback = (error: string | undefined) => {
      unsubscribeListener.current();
      if (error) {
        setErrorMessage(error);
        setMode(Mode.UpdateFailed);
      } else {
        onUpdate();
      }
    };

    unsubscribeListener.current();
    addChannelsDoc(customer.id, currentUser.uid, stackName);
    setMode(Mode.WaitingForUpdate);
    unsubscribeListener.current = listenForStackCreationUpdate(
      customer.id,
      currentUser.uid,
      stackName,
      onChangeDetectedCallback
    );
  };

  const navigateToAwsQuickLinkPage = () => {
    setAlertMessage("After you create stack on AWS, please wait as this may take up to 30 seconds...");
    mixpanel.track("aws.link-account");
    if (selectedFeatures.includes(AWSFeatureName.spotScaling)) {
      mixpanel.track("spot-scaling.link-account");
    }
    if (selectedFeatures.includes(AWSFeatureName.quotas)) {
      mixpanel.track("aws.link-account.quotas");
    }
    if (selectedFeatures.includes(AWSFeatureName.realTimeData)) {
      mixpanel.track("aws.link-account.real-time-data");
    }
    const [url, stackName] = roleToUpdate
      ? getQuickUpdateUrl(customer.id, roleToUpdate, selectedFeatures, isFSK8SFeatureEnabled, realTimeDataBucketName)
      : getQuickCreateUrl(customer.id, selectedFeatures, isFSK8SFeatureEnabled, realTimeDataBucketName);

    startListeningForChanges(stackName);

    window.open(url, "_blank");

    setStackCreationDialogOpen(false);
  };

  const onOpenCLIDialogClicked = () => {
    const [stackCommand, stackName] = roleToUpdate
      ? getCLIUpdateCommand(roleToUpdate, selectedFeatures, isFSK8SFeatureEnabled, realTimeDataBucketName)
      : getCLICreateCommand(customer.id, selectedFeatures, isFSK8SFeatureEnabled, realTimeDataBucketName);

    const realTimeDataCommand = getRealTimeDataCommand(realTimeDataBucketName);
    const commands = [stackCommand];

    if (realTimeDataBucketName !== "") {
      commands.push(realTimeDataCommand);
    }

    setStackName(stackName);
    setCliCommands(commands);
    setCliDialogOpen(true);
    setAlertMessage("After you run the CLI commands, please wait as this may take up to 30 seconds...");
  };

  const onCloseAlertClicked = () => {
    unsubscribeListener.current();
    setMode(Mode.Selecting);
  };

  return (
    <>
      <StackCreationDialog
        open={stackCreationDialogOpen}
        setOpen={setStackCreationDialogOpen}
        operationName={actionButtonText}
        linkAccountHandler={navigateToAwsQuickLinkPage}
      />
      <CommandLineDialog
        open={cliDialogOpen}
        onCopy={() => {
          startListeningForChanges(stackName);
        }}
        setOpen={setCliDialogOpen}
        cliCommands={cliCommands}
        codeSnippets={codeSnippets}
      />
      <FeaturesTable
        accountSupportedFeatures={accountSupportedFeatures}
        disabled={mode !== Mode.Selecting}
        onSelectionChanged={setSelectedFeatures}
        realTimeDataBucketName={realTimeDataBucketName}
        onRealTimeDataBucketNameChanged={setRealTimeDataBucketName}
      />
      <Box pt={2}>
        <Collapse in={mode === Mode.WaitingForUpdate}>
          <WaitingForUpdateAlert operationTitle={operationTitle} message={alertMessage} onClose={onCloseAlertClicked} />
        </Collapse>
        <Collapse in={mode === Mode.UpdateFailed}>
          <UpdateErrorAlert error={errorMessage} onClose={onCloseAlertClicked} />
        </Collapse>
      </Box>
      <Box pt={3} display="flex" flexDirection="row-reverse">
        <Box pl={3}>
          <Button
            startIcon={<OpenInNewIcon />}
            color="primary"
            variant="contained"
            onClick={() => {
              setStackCreationDialogOpen(true);
            }}
            disabled={disableToLinkAccount}
          >
            {capitalize(actionButtonText)}
          </Button>
        </Box>
        <Box>
          <Button
            startIcon={<Icon icon="cli" size={20} />}
            color="primary"
            variant="outlined"
            disabled={disableToLinkAccount}
            onClick={onOpenCLIDialogClicked}
          >
            Prefer CLI?
          </Button>
        </Box>
      </Box>
    </>
  );
};
