import { useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import {
  AppModel,
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudSolve,
  AssetTypeLooker,
  type ContractModel,
  DoitRole,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { useDocument } from "@doitintl/models-firestore/src/react-firebase-hooks/firestore/useDocument";
import { Divider } from "@mui/material";

import { useAccountTeam } from "../../../Components/hooks/useAccountTeam";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import contractHooks from "../hooks";
import { canEditNext10Contract, isNext10Contract } from "../utils";
import { texts } from "./texts";

export const cyIds = {
  downloadContract: "contractDownload",
  editContract: "contractEdit",
  viewContract: "contractView",
  downloadVendorContract: "vendorContractDownload",
  viewVendorContract: "vendorContractView",
  cancelContract: "contractCancel",
};

const dayDivisior = 1000 * 3600 * 24;
const gracePeriodInDays = 5;

type Props = {
  contract: ContractModel;
  id: string;
  handleDownload: () => void;
  handleDownloadVendorContract?: () => void;
};

const ContractsListItemMenu = ({ contract, id, handleDownload, handleDownloadVendorContract }: Props) => {
  const contractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const contractOwner = useDoitRoleCheck(DoitRole.ContractOwner);
  const { customer } = useCustomerContext();
  const { accountTeam } = useAccountTeam({ customer });
  const { userModel } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const [appContracts] = useDocument(getCollection(AppModel).doc("contracts"));
  const history = useHistory();
  const isLookerAndAM = useMemo(
    () =>
      accountTeam.some(
        (am) => am.ref.id === userModel?.id && am.company === "doit" && contract.type === AssetTypeLooker
      ),
    [accountTeam, contract.type, userModel?.id]
  );

  const withinGracePeriod = useMemo(
    () =>
      Math.ceil((new Date().getTime() - contract.timeCreated?.toDate().getTime()) / dayDivisior) <= gracePeriodInDays,
    [contract.timeCreated]
  );

  const handleEditContract = useCallback<() => void>(() => {
    history.push(`/customers/${customer.id}/contracts/contracts-list/${id}`);
  }, [customer.id, history, id]);

  const handleViewContract = useCallback<() => void>(() => {
    history.push(`/customers/${customer.id}/contracts/contracts-list/${id}/view`);
  }, [customer.id, history, id]);

  const handleViewVendorContract = useCallback<() => void>(() => {
    history.push(`/customers/${customer.id}/contracts/contracts-list/${id}/view-vendor-contract`);
  }, [customer.id, history, id]);

  const canEdit = isNext10Contract(contract.type)
    ? canEditNext10Contract({
        isDoitContractAdmin: contractAdmin,
        isDoitContractOwner: contractOwner,
        startDate: contract.startDate as FirestoreTimestamp,
        isActive: contract.active,
      })
    : (contractOwner || contractAdmin || isLookerAndAM || withinGracePeriod) && isDoitEmployee;

  const snackbar = useSnackbar();
  const cancelContract = contractHooks.useCancelContract();
  const handleCancel = useCallback(async () => {
    await cancelContract(
      id,
      () => {
        snackbar.onOpen({
          message: "Contract cancelled successfully",
          variant: "success",
          autoHideDuration: 5000,
          withClose: true,
        });
      },
      () => {
        snackbar.onOpen({
          message: "Failed to cancel contract",
          variant: "error",
          autoHideDuration: 5000,
          withClose: true,
        });
      }
    );
  }, [cancelContract, id, snackbar]);
  const options = useMemo<ThreeDotsMenuOption[]>(() => {
    const menuItems: ThreeDotsMenuOption[] = [
      {
        key: `${cyIds.downloadContract}-${id}`,
        label: texts.DOWNLOAD_CONTRACT,
        action: handleDownload,
        dataCy: cyIds.downloadContract,
      },
      {
        key: `${cyIds.viewContract}-${id}`,
        label: texts.VIEW_CONTRACT,
        action: handleViewContract,
        dataCy: cyIds.viewContract,
      },
    ];

    if (canEdit && !appContracts?.asModelData()?.disableEditing) {
      menuItems.push({
        key: `${cyIds.editContract}-${id}`,
        label: texts.EDIT_CONTRACT,
        action: handleEditContract,
        dataCy: cyIds.editContract,
      });
    }

    if (
      (contractAdmin || contractOwner) &&
      [AssetTypeDoiTCloudNavigator, AssetTypeDoiTCloudSolve].includes(contract.type)
    ) {
      menuItems.push({
        key: `${cyIds.cancelContract}-${id}`,
        label: texts.CANCEL_CONTRACT,
        action: handleCancel,
        dataCy: cyIds.cancelContract,
      });
    }

    if (handleDownloadVendorContract && isDoitEmployee) {
      menuItems.push(
        { menuItem: <Divider key="divider" /> },
        {
          key: `${cyIds.downloadVendorContract}-${id}`,
          label: texts.DOWNLOAD_VENDOR_CONTRACT,
          action: handleDownloadVendorContract,
          dataCy: cyIds.downloadVendorContract,
        },
        {
          key: `${cyIds.viewVendorContract}-${id}`,
          label: texts.VIEW_VENDOR_CONTRACT,
          action: handleViewVendorContract,
          dataCy: cyIds.viewVendorContract,
        }
      );
    }

    return menuItems;
  }, [
    id,
    handleDownload,
    handleViewContract,
    canEdit,
    appContracts,
    contractAdmin,
    contractOwner,
    contract.type,
    handleDownloadVendorContract,
    isDoitEmployee,
    handleEditContract,
    handleCancel,
    handleViewVendorContract,
  ]);

  return <ThreeDotsMenu options={options} size="small" />;
};

export default ContractsListItemMenu;
